import React, { ReactNode } from "react";
import {
  Dialog,
  DialogActions,
  makeStyles,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Close, Send, Undo } from "@material-ui/icons";
import { colors } from "theme";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  closeButton: {
    width: 25,
    height: 25,
    position: "absolute",
    top: 10,
    right: 10,
    cursor: "pointer",
    overflow: "hidden",
  },
  divider: {
    margin: "35px auto 40px",
  },
  actions: {
    marginTop: 0,
    // justifyContent: 'space-between',
    flexDirection: "column-reverse",
    // gap: '30px',
    flexWrap: "wrap",
    [theme.breakpoints.up("sm")]: {
      marginTop: 20,
      justifyContent: "flex-end",
      flexDirection: "row",
    },
    "& > *": {
      margin: 15,
    },
  },
}));

interface DialogRequestProps {
  open: boolean;
  onClose: () => void;
  onSend: () => void;
  children: ReactNode;
  title?: string;
  disabled: boolean;
}

export const DialogRequest: React.FC<DialogRequestProps> = ({
  open,
  onClose,
  onSend,
  children,
  title,
  disabled,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const useTextButton = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <Dialog
      maxWidth="lg"
      fullWidth={true}
      open={open}
      scroll="body"
      className={classes.root}
    >
      <div className={classes.closeButton} onClick={onClose}>
        <Close
          htmlColor={colors.text.primary}
          style={{
            width: 35,
            height: 35,
            top: -6,
            left: -6,
            position: "absolute",
          }}
        />
      </div>

      {title && (
        <Typography
          variant="h5"
          color="textPrimary"
          style={{ marginBottom: "0.75em" }}
        >
          {title}
        </Typography>
      )}
      {children}
      <DialogActions className={classes.actions} disableSpacing={true}>
        {useTextButton ? (
          <Button
            variant="text"
            color="primary"
            size="medium"
            startIcon={<Undo />}
            onClick={onClose}
            disabled={disabled}
          >
            TORNA INDIETRO
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<Undo />}
            onClick={onClose}
            disabled={disabled}
          >
            TORNA INDIETRO
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          size="medium"
          startIcon={<Send />}
          onClick={onSend}
          disabled={disabled}
        >
          INVIA
        </Button>
      </DialogActions>
    </Dialog>
  );
};
