import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { REQUEST_RESET_PASSWORD } from "mutation";
import {
  RequestResetPassword,
  RequestResetPasswordVariables,
} from "types/generated/schemaTypes";
import { FormControl, Grid, TextField, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ActionType, DialogForm } from "components";
import { formatError } from "utils";

export interface RequestPasswordFormValues {
  email: string;
  emailCheck: string;
}

const defaultInitialValues = {
  email: "",
  emailCheck: "",
};

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Indirizzo email non valido")
    .required("Campo obbligatorio"),
  emailCheck: Yup.string()
    .email("Indirizzo email non valido")
    .test("equal", "Le email non combaciano", function (v) {
      const ref = Yup.ref("email");
      return v === this.resolve(ref);
    })
    .required("Campo obbligatorio"),
});

interface RequestPasswordFormProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export const RequestPasswordForm: React.FC<RequestPasswordFormProps> = ({
  open,
  onClose,
  onSuccess,
}) => {
  const [formError, setFormError] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [requestResetPasswordMutation] = useMutation<
    RequestResetPassword,
    RequestResetPasswordVariables
  >(REQUEST_RESET_PASSWORD, {
    onError: (err) => {
      setFormError(formatError(err));
      setIsSending(false);
    },
    onCompleted: () => {
      onSuccess();
      setIsSending(false);
      formik.resetForm();
    },
  });
  const formik = useFormik<RequestPasswordFormValues>({
    initialValues: defaultInitialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsSending(true);
      await requestResetPasswordMutation({
        variables: { email: values.email },
      });
    },
  });
  return (
    <DialogForm
      open={open}
      title="Recupero password"
      onClose={() => {
        formik.resetForm();
        onClose();
      }}
      actions={[
        {
          type: ActionType.EXIT,
          label: "ANNULLA",
          callback: () => {
            formik.resetForm();
            onClose();
          },
          disabled: isSending,
        },
        {
          type: ActionType.SEND,
          label: "INVIA",
          callback: formik.submitForm,
          disabled: isSending,
        },
      ]}
    >
      <div style={{ overflow: "hidden" }}>
        <Typography color="textSecondary" style={{ marginBottom: 28 }}>
          Inserisca l'indirizzo email associato al suo account per ricevere il
          link di reset password
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            {/* EMAIL */}
            <FormControl fullWidth={true}>
              <TextField
                name="email"
                error={formik.touched.email && !!formik.errors.email}
                helperText={
                  formik.touched.email &&
                  !!formik.errors.email &&
                  formik.errors.email
                }
                variant="standard"
                id="email"
                size="small"
                label="Email*"
                value={formik.values.email}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* VERIFICA EMAIL */}
            <FormControl fullWidth={true}>
              <TextField
                name="emailCheck"
                error={formik.touched.emailCheck && !!formik.errors.emailCheck}
                helperText={
                  formik.touched.emailCheck &&
                  !!formik.errors.emailCheck &&
                  formik.errors.emailCheck
                }
                variant="standard"
                id="emailCheck"
                size="small"
                label="Verifica email*"
                value={formik.values.emailCheck}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
        </Grid>
        {formError && (
          <Typography color="error" variant="body1">
            {formError}
          </Typography>
        )}
      </div>
    </DialogForm>
  );
};
