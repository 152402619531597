import React, { useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  SalesOrderFields,
  ProductFields,
  CreateSalesOrder,
  CreateSalesOrderVariables,
  UpdateSalesOrderVariables,
  UpdateSalesOrder,
  GetSalesClient,
  GetSalesClientVariables,
  SelectedSalesProductInput,
  SalesOrderPayment,
} from "types/generated/schemaTypes";
import {
  FormControl,
  Grid,
  TextField,
  Typography,
  Divider,
  makeStyles,
  RadioGroup,
  FormHelperText,
  Radio,
  FormControlLabel,
  Fab,
  IconButton,
  useTheme,
  useMediaQuery,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  Table,
} from "@material-ui/core";
import {
  Add as AddIcon,
  DeleteForever as DeleteIcon,
} from "@material-ui/icons";

import { useFormik } from "formik";
import * as Yup from "yup";
import { CREATE_SALES_ORDER, UPDATE_SALES_ORDER } from "mutation";
import { GET_SALES_CLIENT } from "query";
import { DialogForm, ActionType } from "components";
import { formatError } from "utils/errors";
import { Autocomplete } from "@material-ui/lab";
import {
  formatProductPrice,
  getProductDetailsByProductId,
} from "utils/products";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    width: "100%",
    borderBottom: `solid 1px ${theme.palette.divider}`,
    alignItems: "center",
    minHeight: 42,
    "&:last-child": {
      // marginBottom: 10,
    },
  },
  cellQuantity: {
    width: 30,
    fontSize: 12,
  },
  cellRadio: {},
  cellLabel: {
    paddingLeft: 27,
  },
  productRow: {
    paddingBottom: "10px !important",
    paddingTop: "10px !important",
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  radio: {
    "& .MuiFormControlLabel-label": {
      fontSize: 12,
    },
  },
}));

export interface SalesOrderFormValues {
  salesClientId: string;
  salesAddressId: string;
  salesProducts: SelectedSalesProductInput[];
  deliveryNotes: string;
  payment: SalesOrderPayment;
  discount: number;
}

const defaultInitialValues = {
  salesClientId: "",
  salesAddressId: "",
  salesProducts: [],
  deliveryNotes: "",
  payment: SalesOrderPayment.AS_IS,
  discount: 40,
};

const validationSchema = Yup.object({
  salesClientId: Yup.string().required("Campo obbligatorio"),
  salesAddressId: Yup.string().required("Campo obbligatorio"),
  discount: Yup.number().required("Campo obbligatorio"),
});

interface SalesOrderFormProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  salesOrder: SalesOrderFields | null;
  products: ProductFields[]; // Servono per la lista di selezione
  salesClients: Array<{ name: string; id: string }>; // Tutti i clienti dell'agente
  agentId: string;
  forceSelection: boolean;
}

const parseInitialValue = (input: SalesOrderFields): SalesOrderFormValues => {
  const values: SalesOrderFormValues = {
    salesClientId: input.salesClient.id,
    salesAddressId: input.salesAddress.id,
    salesProducts: input.selectedSalesProducts.map((product) => ({
      productId: product.productId,
      quantitySale: product.quantitySale,
      quantityDiscount: product.quantityDiscount,
      quantitySample: product.quantitySample,
    })),
    deliveryNotes: input.deliveryNotes || "",
    payment: input.payment,
    discount: input.discount,
  };
  return values;
};

export const SalesOrderForm: React.FC<SalesOrderFormProps> = ({
  open,
  onClose,
  onConfirm,
  salesOrder,
  products,
  salesClients,
  agentId,
  forceSelection,
}) => {
  const classes = useStyles();
  const [addresses, setAddresses] = useState<
    { label: string; value: string; notes: string | null }[]
  >([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [getSalesClientQuery] = useLazyQuery<
    GetSalesClient,
    GetSalesClientVariables
  >(GET_SALES_CLIENT, {
    onCompleted: (data) => {
      if (data.getSalesClient) {
        setAddresses(
          data.getSalesClient?.salesAddresses.map((address) => ({
            label:
              (address.name ? `[${address.name}] ` : "") +
              `${address.addressLine}, ${address.zip}, ${address.hamlet || ""}${
                address.hamlet ? " " : ""
              }${address.city} (${address.province})`,
            value: address.id,
            notes: address.notes,
          }))
        );
      }
    },
  });

  useQuery<GetSalesClient, GetSalesClientVariables>(GET_SALES_CLIENT, {
    skip: !(salesOrder || forceSelection),
    variables: {
      salesClientId: salesOrder
        ? salesOrder?.salesClient.id
        : salesClients[0].id,
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.getSalesClient) {
        formik.setFieldValue(
          "salesClientId",
          salesOrder ? salesOrder?.salesClient.id : salesClients[0].id
        );
        setAddresses(
          data.getSalesClient?.salesAddresses.map((address) => ({
            label:
              (address.name ? `[${address.name}]` : "") +
              `${address.addressLine}, ${address.zip}, ${address.hamlet || ""}${
                address.hamlet ? " " : ""
              }${address.city} (${address.province})`,
            value: address.id,
            notes: address.notes,
          }))
        );
      }
    },
  });

  const [createSalesOrderMutation, { error: createSalesOrderError }] =
    useMutation<CreateSalesOrder, CreateSalesOrderVariables>(
      CREATE_SALES_ORDER,
      {
        onCompleted: async (data) => {
          formik.resetForm();
          onConfirm();
        },
        onError: (err) => {
          console.log("Errore!", err);
        },
      }
    );
  const [updateSalesOrderMutation, { error: updateSalesOrderError }] =
    useMutation<UpdateSalesOrder, UpdateSalesOrderVariables>(
      UPDATE_SALES_ORDER,
      {
        onCompleted: async (data) => {
          formik.resetForm();
          onConfirm();
        },
        onError: (err) => {
          console.log("Errore!", err);
        },
      }
    );
  const [currentProduct, setCurrentProduct] = useState("");
  const [currentProductSale, setCurrentProductSale] = useState(0);
  const [currentProductDiscount, setCurrentProductDiscount] = useState(0);
  const [currentProductSample, setCurrentProductSample] = useState(0);
  const [showNoProductsError, setShowNoProductsError] = useState(false);
  const formik = useFormik<SalesOrderFormValues>({
    initialValues: salesOrder
      ? parseInitialValue(salesOrder as SalesOrderFields)
      : defaultInitialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (values.salesProducts.length < 1) {
        setShowNoProductsError(true);
        return;
      }
      let variables: CreateSalesOrderVariables | UpdateSalesOrderVariables;
      if (salesOrder) {
        variables = {
          salesOrderData: {
            deliveryNotes: formik.values.deliveryNotes || null,
            salesClientId: formik.values.salesClientId,
            salesAddressId: formik.values.salesAddressId,
            selectedSalesProducts: formik.values.salesProducts,
            payment: formik.values.payment,
            discount: formik.values.discount,
          },
          salesOrderId: salesOrder.id,
        } as UpdateSalesOrderVariables;
        await updateSalesOrderMutation({ variables });
      } else {
        variables = {
          salesOrderData: {
            date: new Date().toISOString(),
            deliveryNotes: formik.values.deliveryNotes || null,
            salesClientId: formik.values.salesClientId,
            agentId,
            salesAddressId: formik.values.salesAddressId,
            selectedSalesProducts: formik.values.salesProducts,
            payment: formik.values.payment,
            discount: formik.values.discount,
          },
        } as CreateSalesOrderVariables;
        await createSalesOrderMutation({ variables });
      }
    },
  });

  // Opzioni per la tendina clienti
  const clientsOptions = salesClients.map((client) => ({
    label: `${client.name}`,
    value: client.id,
  }));

  const productsOptions = products
    .map((product) => ({
      label: `${product.name} (${product.ean}) - ${product.description || ""}`,
      value: product.id,
    }))
    .sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

  let totalPrice = 0;

  formik.values.salesProducts.forEach((item) => {
    const product = getProductDetailsByProductId(item.productId, products);
    // Find price
    const price = (product?.price || 0) * item.quantitySale;
    totalPrice += price;
  });

  const finalPrice = totalPrice - (totalPrice * formik.values.discount) / 100;

  return (
    <DialogForm
      open={open}
      title="Richiesta ordine vendita"
      onClose={() => {
        formik.resetForm();
        onClose();
      }}
      actions={[
        {
          type: ActionType.EXIT,
          label: "CHIUDI SENZA SALVARE",
          callback: () => {
            formik.resetForm();
            onClose();
          },
          disabled: formik.isSubmitting,
        },
        {
          type: ActionType.SEND,
          label: salesOrder ? "MODIFICA RICHIESTA" : "INVIA RICHIESTA",
          callback: formik.submitForm,
          disabled: formik.isSubmitting,
        },
      ]}
    >
      <div style={{ overflow: "hidden" }}>
        <Typography color="textSecondary" style={{ marginBottom: 28 }}>
          Inserisci tutte le informazioni richieste e salva per aggiungere un
          nuovo ordine di vendita.
        </Typography>
        <Typography
          color="textPrimary"
          style={{ marginBottom: 13, fontWeight: 700 }}
        >
          Destinazione
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth={true}>
              <Autocomplete
                options={clientsOptions}
                disabled={forceSelection}
                value={
                  clientsOptions.find((client) => {
                    return client.value === formik.values.salesClientId;
                  }) || null
                }
                getOptionSelected={(option, test) => {
                  return test.value === option.value;
                }}
                onChange={async (_, newValue) => {
                  formik.setFieldValue("salesClientId", newValue?.value || "");
                  // Caricare gli indirizzi del cliente
                  if (newValue?.value) {
                    getSalesClientQuery({
                      variables: { salesClientId: newValue?.value },
                    });
                  } else {
                    setAddresses([]);
                  }
                }}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={
                      formik.touched.salesClientId &&
                      !!formik.errors.salesClientId
                    }
                    helperText={
                      formik.touched.salesClientId &&
                      !!formik.errors.salesClientId &&
                      formik.errors.salesClientId
                    }
                    label="Cliente"
                    variant="standard"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControl fullWidth={true}>
              <Autocomplete
                options={addresses}
                value={
                  addresses.find((address) => {
                    return address.value === formik.values.salesAddressId;
                  }) || null
                }
                getOptionSelected={(option, test) => {
                  return test.value === option.value;
                }}
                onChange={async (_, newValue) => {
                  formik.setFieldValue("salesAddressId", newValue?.value || "");

                  formik.setFieldValue(
                    "deliveryNotes",
                    newValue?.notes ? newValue?.notes : ""
                  );
                }}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={
                      formik.touched.salesAddressId &&
                      !!formik.errors.salesAddressId
                    }
                    helperText={
                      formik.touched.salesAddressId &&
                      !!formik.errors.salesAddressId &&
                      formik.errors.salesAddressId
                    }
                    label="Indirizzo di spedizione"
                    variant="standard"
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Typography
          color="textPrimary"
          style={{ marginTop: 12, marginBottom: 13, fontWeight: 700 }}
        >
          Note
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            {/* NOTE */}
            <FormControl fullWidth={true}>
              <TextField
                name="deliveryNotes"
                error={
                  formik.touched.deliveryNotes && !!formik.errors.deliveryNotes
                }
                helperText={
                  formik.touched.deliveryNotes &&
                  !!formik.errors.deliveryNotes &&
                  formik.errors.deliveryNotes
                }
                variant="outlined"
                multiline={true}
                rows={4}
                id="deliveryNotes"
                label="Note di consegna"
                value={formik.values.deliveryNotes}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Typography
          color="textPrimary"
          style={{ marginTop: 12, marginBottom: 13, fontWeight: 700 }}
        >
          Condizioni di pagamento
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <FormControl component="fieldset" className={classes.cellRadio}>
              <RadioGroup
                value={formik.values.payment}
                style={{
                  flexDirection: isMobile ? "column" : "row",
                }}
                onChange={(event) => {
                  formik.setFieldValue("payment", event.target.value);
                }}
              >
                <FormControlLabel
                  className={classes.radio}
                  value={SalesOrderPayment.TRANSFER}
                  control={<Radio color="primary" size="small" />}
                  label={"Bonifico anticipato"}
                />
                <FormControlLabel
                  className={classes.radio}
                  value={SalesOrderPayment.DAY_30_DF}
                  control={<Radio color="primary" size="small" />}
                  label={"30 gg d.f."}
                />
                <FormControlLabel
                  className={classes.radio}
                  value={SalesOrderPayment.AS_IS}
                  control={<Radio color="primary" size="small" />}
                  label={"Condizioni in essere"}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        <Typography
          color="textPrimary"
          style={{ marginTop: 12, marginBottom: 13, fontWeight: 700 }}
        >
          Sconto
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth={true}>
              <TextField
                name="discount"
                variant="standard"
                id="discount"
                size="small"
                label="Sconto %"
                value={formik.values.discount}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
                type="number"
                inputProps={{ step: 0.5 }}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Typography
          color="textPrimary"
          style={{ marginTop: 12, marginBottom: 13, fontWeight: 700 }}
        >
          Prodotti
        </Typography>

        <Grid item xs={12} md={12}>
          <FormControl fullWidth={true}>
            <Autocomplete
              options={productsOptions.filter((product) => {
                return !formik.values.salesProducts
                  .map((product) => product.productId)
                  .includes(product.value);
              })}
              value={
                productsOptions.find((product) => {
                  return product.value === currentProduct;
                }) || null
              }
              getOptionSelected={(option, test) => {
                return test.value === option.value;
              }}
              // getOptionDisabled={(option) =>
              //   formik.values.productIds.includes(option.value)
              // }
              onChange={async (_, newValue) => {
                setCurrentProduct(newValue?.value || "");
              }}
              getOptionLabel={(option) => option.label}
              disabled={
                formik.isSubmitting
                // formik.values.productIds.length === 4
              }
              renderInput={(params) => (
                <TextField {...params} label="Prodotto" variant="standard" />
              )}
            />
          </FormControl>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} md={2}>
            <FormControl fullWidth={true}>
              <TextField
                name="unity"
                variant="standard"
                id="unity"
                size="small"
                label="Pezzi vendita"
                value={currentProductSale}
                onChange={(val) => {
                  setCurrentProductSale(parseInt(val.target.value));
                }}
                disabled={formik.isSubmitting}
                type="number"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={2}>
            <FormControl fullWidth={true}>
              <TextField
                name="unity"
                variant="standard"
                id="unity"
                size="small"
                label="Pezzi omaggio"
                value={currentProductDiscount}
                onChange={(val) => {
                  setCurrentProductDiscount(parseInt(val.target.value));
                }}
                disabled={formik.isSubmitting}
                type="number"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={2}>
            <FormControl fullWidth={true}>
              <TextField
                name="unity"
                variant="standard"
                id="unity"
                size="small"
                label="Pezzi campione"
                value={currentProductSample}
                onChange={(val) => {
                  setCurrentProductSample(parseInt(val.target.value));
                }}
                disabled={formik.isSubmitting}
                type="number"
              />
            </FormControl>
          </Grid>

          <Grid item xs={2} md={1}>
            {/* ADD */}
            <Fab
              size="small"
              color="primary"
              disableRipple={true}
              disabled={
                !(
                  currentProduct &&
                  currentProductSale +
                    currentProductDiscount +
                    currentProductSample >
                    0
                )
              }
              onClick={() => {
                formik.setFieldValue("salesProducts", [
                  ...formik.values.salesProducts,
                  {
                    productId: currentProduct,
                    quantitySale: currentProductSale,
                    quantityDiscount: currentProductDiscount,
                    quantitySample: currentProductSample,
                  },
                ]);
                setCurrentProduct("");
                setCurrentProductSale(0);
                setCurrentProductDiscount(0);
                setCurrentProductSample(0);
                setShowNoProductsError(false);
              }}
            >
              <AddIcon />
            </Fab>
          </Grid>
        </Grid>

        <Typography
          color="textPrimary"
          style={{ marginTop: 12, marginBottom: 13, fontWeight: 700 }}
        >
          Riepilogo ordine
        </Typography>

        {formik.values.salesProducts.length > 0 ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Prodotto</TableCell>
                  <TableCell>Pezzi vendita</TableCell>
                  <TableCell>Pezzi omaggio</TableCell>
                  <TableCell>Pezzi campione</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                {formik.values.salesProducts.map((product) => {
                  const details = getProductDetailsByProductId(
                    product.productId,
                    products
                  );
                  if (!details) return null;
                  return (
                    <TableRow key={product.productId}>
                      <TableCell>
                        {details.name} (EAN: {details.ean})
                      </TableCell>
                      <TableCell>{product.quantitySale}</TableCell>
                      <TableCell>{product.quantityDiscount}</TableCell>
                      <TableCell>{product.quantitySample}</TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="delete"
                          color="secondary"
                          style={{ padding: 0 }}
                          disableRipple={true}
                          onClick={() => {
                            let removed = false;
                            const newIds = formik.values.salesProducts.filter(
                              (_product) => {
                                if (removed) return true;
                                if (_product.productId === product.productId) {
                                  removed = true;
                                  return false;
                                }
                                return true;
                                // return _product.productId !== product.productId;
                              }
                            );
                            formik.setFieldValue("salesProducts", [...newIds]);
                          }}
                        >
                          <DeleteIcon />
                          <Divider />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableHead>
            </Table>
          </TableContainer>
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              Seleziona almeno un prodotto.
            </Grid>
          </Grid>
        )}

        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Typography
              color="textPrimary"
              style={{ marginTop: 12, marginBottom: 13 }}
            >
              <b>Totale:</b> {formatProductPrice(totalPrice)}
              <br />
              <b>Totale scontato (Sconto {formik.values.discount} %): </b>
              {formatProductPrice(finalPrice)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          {showNoProductsError && (
            <Grid item xs={12} md={12}>
              <FormHelperText error={true}>
                Errore, seleziona almeno un prodotto
              </FormHelperText>
            </Grid>
          )}
        </Grid>

        <Divider />
        {createSalesOrderError && (
          <Typography color="error" variant="body1">
            {createSalesOrderError && formatError(createSalesOrderError)}
          </Typography>
        )}
        {updateSalesOrderError && (
          <Typography color="error" variant="body1">
            {updateSalesOrderError && formatError(updateSalesOrderError)}
          </Typography>
        )}
      </div>
    </DialogForm>
  );
};
