import React from "react";
import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  title: {
    margin: "25px 0 0px",
    [theme.breakpoints.up("md")]: {
      margin: "20px 0 0px",
    },
  },
}));

const PageTitle: React.FC<{ title: string }> = ({ title }) => {
  const classes = useStyles();
  return (
    <Typography
      className={classes.title}
      variant="h5"
      component="h1"
      color="textPrimary"
      paragraph={true}
    >
      {title}
    </Typography>
  );
};

export default PageTitle;
