import React, { useEffect, useState } from "react";
import {
  useApolloClient,
  useMutation,
  useQuery,
  useReactiveVar,
} from "@apollo/client";
import {
  Grid,
  InputAdornment,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  useTheme,
  TextField,
  makeStyles,
  Button,
  useMediaQuery,
} from "@material-ui/core";
import {
  Add as AddIcon,
  Search as SearchIcon,
  Lock as LockIcon,
} from "@material-ui/icons";

import { HourglassHalfFullIcon } from "icons";

import { Autocomplete } from "@material-ui/lab";
import {
  GET_ORDER,
  GET_ORDERS,
  GET_USERS,
  GET_PRODUCTS,
  GET_THERAPIES,
  GET_CLIENTS_SIMPLE,
} from "query";
import { DELETE_ORDER, UPDATE_ORDER } from "mutation";
import {
  GetOrder,
  GetOrderVariables,
  GetOrdersVariables,
  GetOrders,
  OrderOrderInput,
  OrderWhereInput,
  GetUsers,
  GetUsersVariables,
  UserOrderInput,
  OrderFields,
  GetProducts,
  GetProductsVariables,
  GetTherapies,
  GetTherapiesVariables,
  UpdateOrder,
  UpdateOrderVariables,
  OrderStatus,
  GetClientsSimple,
  GetClientsSimpleVariables,
  ClientOrderInput,
  DeleteOrder,
  DeleteOrderVariables,
} from "types/generated/schemaTypes";
import {
  ManageOrderForm,
  FiltersWrapper,
  ButtonChipHandle,
  OrderForm,
  AfterOrderForm,
  DialogError,
  DialogWarning,
} from "components";

import { formatError } from "utils/errors";

import { CheckCircle as HasAgentIcon } from "@material-ui/icons";
import { userRoleVar, userIdVar } from "localState";
import { UserRole } from "types/generated/schemaTypes";
import { formatDate } from "utils/date";
import { ButtonChipDelete } from "./buttonChipDelete";
import { ButtonChipUpdate } from "./buttonChipUpdate";

const useStyles = makeStyles((theme) => ({
  noBorderTextField: {
    // backgroundColor: "yellow",
    "& .MuiInput-underline:before": {
      display: "none",
    },
  },
}));

export const PendingOrders: React.FC = () => {
  const client = useApolloClient();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const role = useReactiveVar(userRoleVar);
  const isAgent = role === UserRole.AGENT;
  const userId = useReactiveVar(userIdVar);
  const [currentOrder, setCurrentOrder] = useState<null | OrderFields>(null);
  const [searchString, setSearchString] = useState("");
  const [offset, setOffset] = useState(0);
  const [orderClosedId, setOrderClosedId] = useState<string | null>(null);
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [filterAssigned, setFilterAssigned] = useState<string>("ALL");
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState<OrderOrderInput>(
    OrderOrderInput.CREATION_DESC
  );

  const [showManageOrderForm, setShowManageOrderForm] = useState(false);
  const [showAfterOrderForm, setShowAfterOrderForm] = useState(false);
  const [showOrderForm, setShowOrderForm] = useState(false);

  const classes = useStyles();

  const {
    data: operatorsData,
    loading: operatorsLoading,
    error: operatorsError,
  } = useQuery<GetUsers, GetUsersVariables>(GET_USERS, {
    fetchPolicy: "network-only",
    variables: {
      where: { active: true, role: UserRole.OPERATOR },
      order: UserOrderInput.LASTNAME_ASC,
      offset: 0,
      limit: 100,
    },
    skip: isAgent,
  });

  const {
    data: clientsData,
    // loading: clientsLoading,
    // error: clientsError,
  } = useQuery<GetClientsSimple, GetClientsSimpleVariables>(
    GET_CLIENTS_SIMPLE,
    {
      fetchPolicy: "network-only",
      variables: {
        where: { active: true, agentId: userId },
        order: ClientOrderInput.LASTNAME_ASC,
        offset: 0,
        limit: 500,
      },
      skip: !isAgent,
    }
  );

  const enableNewOrders =
    clientsData && clientsData.getClients?.clients?.length > 0;

  const productsWhere: { archived: boolean; forSale?: boolean } = {
    archived: false,
  };
  if (isAgent) productsWhere.forSale = false;

  const {
    data: productsData,
    loading: productsLoading,
    error: productsError,
  } = useQuery<GetProducts, GetProductsVariables>(GET_PRODUCTS, {
    fetchPolicy: "cache-first",
    variables: {
      where: productsWhere,
      offset: 0,
      limit: 100,
    },
  });

  const {
    data: therapiesData,
    loading: therapiesLoading,
    error: therapiesError,
  } = useQuery<GetTherapies, GetTherapiesVariables>(GET_THERAPIES, {
    fetchPolicy: "cache-first",
    variables: {
      offset: 0,
      limit: 100,
    },
  });

  const [setOrderOperatorMutation] = useMutation<
    UpdateOrder,
    UpdateOrderVariables
  >(UPDATE_ORDER);

  const where: OrderWhereInput = {};

  const assignedFilterOptions = [
    { label: "Tutti le richieste", id: "ALL" },
    { label: "Richieste assegnate", id: "ASSIGNED" },
    { label: "Richieste non assegnate", id: "NOT_ASSIGNED" },
  ];

  const operatorAssignmentOptions = [{ label: "-", id: "" }];

  if (operatorsData?.getUsers.users)
    operatorsData?.getUsers.users.forEach((operator) => {
      assignedFilterOptions.push({
        label: `${operator.name} ${operator.lastname}`,
        id: operator.id,
      });
      operatorAssignmentOptions.push({
        label: `${operator.name} ${operator.lastname[0]}.`,
        id: operator.id,
      });
    });

  if (isAgent) where.agentId = userId;

  if (filterAssigned !== "ALL") {
    if (filterAssigned === "NOT_ASSIGNED") {
      where.assigned = false;
    } else if (filterAssigned === "ASSIGNED") {
      where.assigned = true;
    } else {
      where.operatorId = filterAssigned;
    }
  }

  if (searchString.length > 2) where.searchPattern = searchString;

  const {
    data: ordersData,
    loading: ordersLoading,
    error: ordersError,
    refetch: refetchOrders,
    startPolling,
    stopPolling,
  } = useQuery<GetOrders, GetOrdersVariables>(GET_ORDERS, {
    fetchPolicy: "network-only",
    variables: {
      where,
      order,
      offset,
      limit,
    },
  });

  useEffect(() => {
    startPolling(30000);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  const [deleteOrderMutation] = useMutation<DeleteOrder, DeleteOrderVariables>(
    DELETE_ORDER,
    {
      refetchQueries: [
        {
          query: GET_ORDERS,
          variables: {
            where,
            order,
            offset,
            limit,
          },
        },
      ],
      onCompleted: () => {
        setShowWarningDialog(false);
      },
      onError: (err) => {
        setShowWarningDialog(false);
        setErrorMessage(formatError(err));
        setShowErrorDialog(true);
        console.log(err);
      },
    }
  );

  const deleteOrder = async (orderId: string | null) => {
    if (orderId)
      await deleteOrderMutation({
        variables: {
          orderId,
        },
      });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setOffset(newPage * limit);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLimit(parseInt(event.target.value, 10));
    setOffset(0);
  };

  const loadOrder = async (orderId: string) => {
    const data = await client.query<GetOrder, GetOrderVariables>({
      query: GET_ORDER,
      variables: { orderId },
      fetchPolicy: "cache-first",
    });
    setCurrentOrder(data.data.getOrder);
  };

  return (
    <>
      <FiltersWrapper>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item xs={6} md={3}>
            <FormControl fullWidth={true} variant="standard" size="medium">
              <InputLabel id="order-label">Ordina per</InputLabel>
              <Select
                name="order"
                value={order}
                labelId="order-label"
                id="order"
                label="Ordina per"
                onChange={(e) => {
                  setOffset(0);
                  setOrder(e.target.value as OrderOrderInput);
                }}
              >
                <MenuItem value={OrderOrderInput.CREATION_DESC}>
                  Data creazione ↓
                </MenuItem>
                <MenuItem value={OrderOrderInput.CREATION_ASC}>
                  Data creazione ↑
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {!isAgent && (
            <Grid item xs={6} md={3}>
              <FormControl fullWidth={true}>
                <Autocomplete
                  options={assignedFilterOptions}
                  value={
                    assignedFilterOptions.find(
                      (agent) => agent.id === filterAssigned
                    ) || null
                  }
                  getOptionSelected={(option, test) => {
                    return test.id === option.id;
                  }}
                  onChange={(event, newValue) => {
                    setFilterAssigned(newValue?.id || "ALL");
                  }}
                  getOptionLabel={(option) => option.label}
                  // style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Visualizza"
                      variant="standard"
                    />
                  )}
                />
              </FormControl>
            </Grid>
          )}
          <Grid item xs={isAgent ? 6 : 12} md={3}>
            <FormControl fullWidth={true} variant="standard" size="medium">
              <InputLabel htmlFor="search">Cerca...</InputLabel>
              <Input
                name="searchString"
                id="searchString"
                autoFocus={searchString.length > 2}
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>

          {isAgent && (
            <Grid
              item
              xs={isAgent ? 12 : 6}
              md={6}
              style={{ textAlign: "right" }}
            >
              <Button
                color="primary"
                disabled={!enableNewOrders}
                variant="contained"
                size="small"
                onClick={() => {
                  setCurrentOrder(null);
                  setShowOrderForm(true);
                }}
                startIcon={<AddIcon />}
              >
                Aggiungi
              </Button>
            </Grid>
          )}
        </Grid>
      </FiltersWrapper>
      {ordersLoading && therapiesLoading && productsLoading && (
        <div>Caricamento delle richieste in corso</div>
      )}
      {!ordersLoading &&
        !operatorsLoading &&
        !productsLoading &&
        !therapiesLoading &&
        !therapiesError &&
        !productsError &&
        !operatorsError &&
        !ordersError &&
        ordersData &&
        (isAgent || operatorsData) && (
          <>
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell variant="head" align="left">
                        DATA
                      </TableCell>
                      {!isAgent && !isMobile && (
                        <TableCell variant="head" align="left">
                          AGENTE
                        </TableCell>
                      )}
                      <TableCell
                        variant="head"
                        align="left"
                        style={{ minWidth: 160 }}
                      >
                        CLIENTE
                      </TableCell>

                      <TableCell
                        variant="head"
                        align="left"
                        style={{ width: isMobile ? 180 : 225, minWidth: 180 }}
                      >
                        STATO
                      </TableCell>
                      <TableCell
                        variant="head"
                        align="left"
                        style={{ width: isAgent ? 220 : 100 }}
                      >
                        AZIONI
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ordersData.getOrders.orders.map((order) => {
                      return (
                        <TableRow key={order.id}>
                          <TableCell align="left">
                            <Typography
                              component="span"
                              variant="body1"
                              color="textPrimary"
                            >
                              {formatDate(order.date)}
                            </Typography>
                          </TableCell>
                          {!isAgent && !isMobile && (
                            <TableCell align="left">
                              <Typography
                                component="span"
                                variant="body1"
                                color="textPrimary"
                              >
                                {order.agent.name} {order.agent.lastname}
                              </Typography>
                            </TableCell>
                          )}
                          <TableCell align="left">
                            <Typography
                              component="span"
                              variant="body1"
                              color="textPrimary"
                            >
                              {order.client.name} {order.client.lastname}
                            </Typography>
                          </TableCell>

                          {isAgent && (
                            <TableCell variant="head" align="left">
                              <div style={{ display: "flex" }}>
                                {order.status === OrderStatus.PENDING &&
                                order.operator ? (
                                  <>
                                    <LockIcon
                                      htmlColor={theme.palette.warning.main}
                                    />
                                    <Typography
                                      component="span"
                                      variant="body1"
                                      color="textPrimary"
                                    >
                                      &nbsp;Preso in carico
                                    </Typography>
                                  </>
                                ) : (
                                  <>
                                    <HourglassHalfFullIcon color="primary" />
                                    <Typography
                                      component="span"
                                      variant="body1"
                                      color="textPrimary"
                                    >
                                      &nbsp;In attesa{" "}
                                      {!isMobile && "di validazione"}
                                    </Typography>
                                  </>
                                )}
                              </div>
                            </TableCell>
                          )}

                          {!isAgent && (
                            <TableCell variant="head" align="left">
                              <div style={{ display: "flex" }}>
                                {order.operator ? (
                                  <HasAgentIcon
                                    style={{ marginRight: 16 }}
                                    htmlColor={theme.palette.success.main}
                                  />
                                ) : null}

                                <FormControl fullWidth={true}>
                                  <Autocomplete
                                    options={operatorAssignmentOptions}
                                    value={
                                      operatorAssignmentOptions.find(
                                        (operator) => {
                                          return (
                                            operator.id === order.operator?.id
                                          );
                                        }
                                      ) || { label: "Assegna a...", id: "" }
                                    }
                                    getOptionSelected={(option, test) => {
                                      return test.id === option.id;
                                    }}
                                    onChange={async (_, newValue) => {
                                      await setOrderOperatorMutation({
                                        variables: {
                                          orderId: order.id,
                                          orderData: {
                                            operatorId: newValue?.id || null,
                                          },
                                        },
                                      });
                                    }}
                                    getOptionLabel={(option) => option.label}
                                    renderInput={(params) => (
                                      <TextField
                                        className={classes.noBorderTextField}
                                        {...params}
                                        label=""
                                        variant="standard"
                                      />
                                    )}
                                  />
                                </FormControl>
                              </div>
                            </TableCell>
                          )}
                          <TableCell align="left">
                            <div style={{ display: "flex" }}>
                              {isAgent ? (
                                <>
                                  <ButtonChipDelete
                                    style={{ marginRight: 24 }}
                                    label="Elimina"
                                    disabled={!!order.operator?.id}
                                    onClick={async () => {
                                      await loadOrder(order.id);
                                      // setCurrentOrder(order.id);
                                      setShowWarningDialog(true);
                                      // setShowManageOrderForm(true);
                                    }}
                                  />

                                  <ButtonChipUpdate
                                    label="Modifica"
                                    disabled={!!order.operator?.id}
                                    onClick={async () => {
                                      await loadOrder(order.id);
                                      setShowOrderForm(true);
                                    }}
                                  />
                                </>
                              ) : (
                                <ButtonChipHandle
                                  onClick={async () => {
                                    await loadOrder(order.id);
                                    setShowManageOrderForm(true);
                                  }}
                                />
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {ordersData.getOrders.orders.length < 11 && (
                      <>
                        {new Array(10 - ordersData.getOrders.orders.length)
                          .fill(10)
                          .map((_, i) => (
                            <TableRow key={i}>
                              {!isMobile && (
                                <TableCell variant="head" align="left">
                                  &nbsp;
                                </TableCell>
                              )}
                              <TableCell variant="head" align="left">
                                &nbsp;
                              </TableCell>
                              <TableCell variant="head" align="left">
                                &nbsp;
                              </TableCell>
                              {!isAgent && !isMobile && (
                                <TableCell variant="head" align="left">
                                  &nbsp;
                                </TableCell>
                              )}
                              <TableCell variant="head" align="left">
                                &nbsp;
                              </TableCell>
                            </TableRow>
                          ))}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                labelRowsPerPage="Totale richieste per pagina"
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} di ${count}`
                }
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={ordersData ? ordersData.getOrders.total : 0}
                rowsPerPage={limit}
                page={Math.floor(offset / limit)}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </>
        )}

      {showManageOrderForm && currentOrder && therapiesData && productsData && (
        <ManageOrderForm
          products={productsData.getProducts.products}
          therapies={therapiesData.getTherapies.therapies}
          open={showManageOrderForm}
          onClose={() => {
            setShowManageOrderForm(false);
          }}
          onConfirm={async (orderClosedId, accepted) => {
            setShowManageOrderForm(false);
            // Visualizza finestra per scaricare ordine
            await refetchOrders({
              where,
              order,
              offset,
              limit,
            });
            // Visualizza finestra di download solo se l'ordine è stato accettato
            setOrderClosedId(orderClosedId);
            setShowAfterOrderForm(accepted);
          }}
          order={currentOrder}
        />
      )}

      {showOrderForm &&
        therapiesData &&
        productsData &&
        clientsData &&
        userId && (
          <OrderForm
            agentId={userId}
            forceSelection={false}
            products={productsData.getProducts.products}
            therapies={therapiesData.getTherapies.therapies}
            open={showOrderForm}
            onClose={() => {
              setShowOrderForm(false);
            }}
            clients={clientsData.getClients.clients}
            onConfirm={async () => {
              setShowOrderForm(false);
              await refetchOrders({
                where,
                order,
                offset,
                limit,
              });
            }}
            order={currentOrder}
          />
        )}

      <DialogWarning
        title="Attenzione"
        open={showWarningDialog}
        onClose={() => setShowWarningDialog(false)}
        onContinue={() => deleteOrder(currentOrder?.id || null)}
      >
        <Typography variant="body1">
          Questa operazione è irreversibile. Sei sicuro di voler procedere?
        </Typography>
      </DialogWarning>

      <DialogError
        title="Errore"
        open={showErrorDialog}
        onClose={() => setShowErrorDialog(false)}
      >
        <Typography variant="body1" color="error" paragraph={true}>
          {errorMessage}
        </Typography>
        <Typography variant="body1" color="textPrimary">
          Impossibile elimare l'ordine.
        </Typography>
      </DialogError>

      {showAfterOrderForm && orderClosedId && (
        <AfterOrderForm
          open={showAfterOrderForm}
          orderId={orderClosedId}
          onClose={() => {
            setShowAfterOrderForm(false);
          }}
        />
      )}
    </>
  );
};
