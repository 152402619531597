import React, { useState } from "react";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Grid,
  InputAdornment,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  useTheme,
} from "@material-ui/core";
import {
  Add as AddIcon,
  Search as SearchIcon,
  HowToReg as ActiveIcon,
} from "@material-ui/icons";
import { GET_USER, GET_USERS } from "query";
import { SET_USER_STATUS } from "mutation";
import {
  GetUser,
  GetUserVariables,
  GetUsersVariables,
  GetUsers,
  UserOrderInput,
  UserWhereInput,
  UserFields,
  UserRole,
  SetUserStatus,
  SetUserStatusVariables,
} from "types/generated/schemaTypes";
import {
  OperatorForm,
  ButtonChipActivate,
  ButtonChipSuspend,
  DialogError,
  Breadcrumbs,
  PageTitle,
  FiltersWrapper,
  ButtonChipUpdate,
} from "components";
import { formatError } from "utils/errors";
import { UserBlockedIcon } from "icons";

type Status = "ALL" | "SUSPENDED" | "ACTIVE";

export default function GestioneOperatori() {
  const client = useApolloClient();
  const theme = useTheme();
  const [currentOperator, setCurrentOperator] =
    useState<null | UserFields>(null);
  const [searchString, setSearchString] = useState("");
  const [offset, setOffset] = useState(0);
  const [status, setStatus] = useState<Status>("ALL");
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState<UserOrderInput>(
    UserOrderInput.LASTNAME_ASC
  );
  const [showOperatorForm, setShowOperatorForm] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleChangePage = (event: unknown, newPage: number) => {
    setOffset(newPage * limit);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLimit(parseInt(event.target.value, 10));
    setOffset(0);
  };

  const where: UserWhereInput = {
    role: UserRole.OPERATOR,
  };

  if (status !== "ALL") where.active = status === "ACTIVE";

  if (searchString.length > 2) where.searchPattern = searchString;

  const {
    data: operatorsData,
    loading: operatorsLoading,
    error: operatorsError,
    refetch: refetchAgents,
  } = useQuery<GetUsers, GetUsersVariables>(GET_USERS, {
    fetchPolicy: "network-only",
    variables: {
      where,
      order: order,
      offset,
      limit,
    },
  });

  const [setOperatorStatusMutation] = useMutation<
    SetUserStatus,
    SetUserStatusVariables
  >(SET_USER_STATUS, {
    refetchQueries: [
      {
        query: GET_USERS,
        variables: {
          where,
          order: order,
          offset,
          limit,
        },
      },
    ],
    onCompleted: () => {},
    onError: (err) => {
      setErrorMessage(formatError(err));
      setShowErrorDialog(true);
      console.log(err);
    },
  });

  const loadAgent = async (userId: string) => {
    const data = await client.query<GetUser, GetUserVariables>({
      query: GET_USER,
      variables: { userId },
      fetchPolicy: "cache-first",
    });
    setCurrentOperator(data.data.getUser);
  };

  const setOperatorStatus = async (
    operatorId: string | null,
    active: boolean
  ) => {
    if (operatorId)
      await setOperatorStatusMutation({
        variables: {
          userId: operatorId,
          active,
        },
      });
  };

  return (
    <>
      <PageTitle title="GESTIONE OPERATORI" />
      <Breadcrumbs current="Gestione Agenti" />
      <FiltersWrapper>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item xs={6} md={3}>
            <FormControl fullWidth={true} variant="standard" size="medium">
              <InputLabel id="order-label">Ordina per</InputLabel>
              <Select
                name="order"
                value={order}
                labelId="order-label"
                id="order"
                label="Ordina per"
                onChange={(e) => {
                  setOffset(0);
                  setOrder(e.target.value as UserOrderInput);
                }}
              >
                <MenuItem value={UserOrderInput.CREATION_DESC}>
                  Data creazione ↓
                </MenuItem>
                <MenuItem value={UserOrderInput.CREATION_ASC}>
                  Data creazione ↑
                </MenuItem>
                <MenuItem value={UserOrderInput.LASTNAME_DESC}>
                  Nome (Z-A)
                </MenuItem>
                <MenuItem value={UserOrderInput.LASTNAME_ASC}>
                  Nome (A-Z)
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControl fullWidth={true} variant="standard" size="medium">
              <InputLabel id="order-label">Visualizza</InputLabel>
              <Select
                name="status"
                value={status}
                labelId="status-label"
                id="status"
                label="Visualizza"
                onChange={(e) => {
                  setOffset(0);
                  setStatus(e.target.value as Status);
                }}
              >
                <MenuItem value="ALL">Tutti gli operatori</MenuItem>
                <MenuItem value="ACTIVE">Solo operatori attivi</MenuItem>
                <MenuItem value="SUSPENDED">Solo operatori sospesi</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControl fullWidth={true} variant="standard" size="medium">
              <InputLabel htmlFor="search">Cerca...</InputLabel>
              <Input
                name="searchString"
                id="searchString"
                autoFocus={searchString.length > 2}
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>

          <Grid item xs={6} md={3} style={{ textAlign: "right" }}>
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={() => {
                setCurrentOperator(null);
                setShowOperatorForm(true);
              }}
              startIcon={<AddIcon />}
            >
              Aggiungi
            </Button>
          </Grid>
        </Grid>
      </FiltersWrapper>
      {operatorsLoading && <div>Caricamento degli operatori in corso</div>}
      {!operatorsLoading && !operatorsError && operatorsData && (
        <>
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell variant="head" align="left">
                      STATO
                    </TableCell>
                    <TableCell variant="head" align="left">
                      NOME
                    </TableCell>
                    <TableCell variant="head" align="left">
                      COGNOME
                    </TableCell>
                    <TableCell variant="head" align="left">
                      EMAIL
                    </TableCell>
                    <TableCell variant="head" align="left">
                      AZIONI
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {operatorsData.getUsers.users.map((user) => {
                    return (
                      <TableRow key={user.id}>
                        <TableCell align="left">
                          {user.active ? (
                            <>
                              <ActiveIcon
                                style={{ marginRight: 10 }}
                                htmlColor={theme.palette.success.main}
                              />
                            </>
                          ) : (
                            <>
                              <UserBlockedIcon
                                style={{ marginRight: 10 }}
                                htmlColor={theme.palette.error.main}
                              />
                            </>
                          )}
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            component="span"
                            variant="body1"
                            color={user.active ? "textPrimary" : "error"}
                          >
                            {user.name}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            component="span"
                            variant="body1"
                            color={user.active ? "textPrimary" : "error"}
                          >
                            {user.lastname}
                          </Typography>
                        </TableCell>

                        <TableCell variant="head" align="left">
                          <Typography
                            component="span"
                            variant="body1"
                            color={user.active ? "textSecondary" : "error"}
                          >
                            {user.email}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <div style={{ display: "flex" }}>
                            {user.active ? (
                              <ButtonChipSuspend
                                style={{ marginRight: 16 }}
                                onClick={async () => {
                                  await setOperatorStatus(user.id, false);
                                }}
                              />
                            ) : (
                              <ButtonChipActivate
                                style={{ marginRight: 37 }}
                                onClick={async () => {
                                  await setOperatorStatus(user.id, true);
                                }}
                              />
                            )}
                            <ButtonChipUpdate
                              onClick={async () => {
                                await loadAgent(user.id);
                                setShowOperatorForm(true);
                              }}
                              label="Modifica"
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {operatorsData.getUsers.users.length < 11 && (
                    <>
                      {new Array(10 - operatorsData.getUsers.users.length)
                        .fill(10)
                        .map((_, index) => (
                          <TableRow key={index}>
                            <TableCell variant="head" align="left">
                              &nbsp;
                            </TableCell>
                            <TableCell variant="head" align="left">
                              &nbsp;
                            </TableCell>
                            <TableCell variant="head" align="left">
                              &nbsp;
                            </TableCell>
                            <TableCell variant="head" align="left">
                              &nbsp;
                            </TableCell>
                            <TableCell variant="head" align="left">
                              &nbsp;
                            </TableCell>
                          </TableRow>
                        ))}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              labelRowsPerPage="Totale operatori per pagina"
              labelDisplayedRows={({ from, to, count }) =>
                `Operatori ${from}-${to} di ${count}`
              }
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={operatorsData ? operatorsData.getUsers.total : 0}
              rowsPerPage={limit}
              page={Math.floor(offset / limit)}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </>
      )}
      <OperatorForm
        open={showOperatorForm}
        onClose={() => {
          setShowOperatorForm(false);
        }}
        onCreate={async () => {
          setShowOperatorForm(false);
          await refetchAgents({
            where,
            order,
            offset,
            limit,
          });
        }}
        onUpdate={async () => {
          setShowOperatorForm(false);
        }}
        operator={currentOperator}
      />
      <DialogError
        title="Errore"
        open={showErrorDialog}
        onClose={() => setShowErrorDialog(false)}
      >
        <Typography variant="body1" color="error">
          {errorMessage}
        </Typography>
      </DialogError>
    </>
  );
}
