import React, { useRef, useState } from "react";
import {
  IconButton,
  FormControl,
  InputLabel,
  InputAdornment,
  makeStyles,
  Input,
  Fab,
} from "@material-ui/core";
import {
  CloudUpload as UploadIcon,
  DeleteForever as DeleteIcon,
} from "@material-ui/icons";
import { useApolloClient, useMutation } from "@apollo/client";
import { GET_SIGNED_URL } from "query";
import { DELETE_FILE } from "mutation";
import {
  deleteFile,
  deleteFileVariables,
  getSignedUrl,
  getSignedUrlVariables,
} from "types/generated/schemaTypes";
import { DialogError } from "components";

const useStyles = makeStyles((theme) => ({
  imageWrapper: {
    position: "relative",
    maxWidth: 227,
    border: `solid 1px rgba(0,0,0,0.26)`,
  },
  deleteImage: {
    position: "absolute",
    bottom: 10,
    right: 10,
    backgroundColor: theme.palette.error.main,
  },
}));

interface FileUploadProps {
  url: string | null;
  label: string;
  labelSmall: string;
  onChange: (newUrl: string | null) => void;
  fullWidth?: boolean;
  id: string;
  error?: boolean;
}

export const FileUpload: React.FC<FileUploadProps> = ({
  url,
  label,
  labelSmall,
  onChange,
  fullWidth = false,
  id,
  error = false,
}) => {
  const classes = useStyles();
  const client = useApolloClient();
  const [showError, setShowError] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [errorMessage] = useState(
    "La dimensione del file non può superare i 10MB"
  );
  const [deleteFileMutation] = useMutation<deleteFile, deleteFileVariables>(
    DELETE_FILE
  );

  // const client = useApolloClient()
  const inputEl = useRef<HTMLInputElement>(null);
  const acceptFile = "image/jpeg,image/gif,image/png";

  const onSelectFile = () => {
    if (inputEl && inputEl.current) inputEl.current.click();
  };

  if (isUploading) return <div>Caricamento in corso</div>;
  return (
    <>
      <DialogError open={showError} onClose={() => setShowError(false)}>
        {errorMessage}
      </DialogError>
      <input
        ref={inputEl}
        type="file"
        id={`${id}_input`}
        accept={acceptFile}
        // id="input-upload"
        style={{ display: "none" }}
        onChange={async (e) => {
          const files = e.target.files;
          if (files && files.length > 0 && files[0].size < 10 * 1024 * 1024) {
            const urlRequest = await client.query<
              getSignedUrl,
              getSignedUrlVariables
            >({
              query: GET_SIGNED_URL,
              variables: { filename: (files as FileList)[0].name },
              fetchPolicy: "network-only",
            });
            const { uploadUrl, finalUrl } = urlRequest.data.getSignedUrl;
            // const fileUrl = signedUrl?.split('?')[0]
            setIsUploading(true);
            const response = await fetch(uploadUrl as string, {
              method: "PUT",
              body: (files as FileList)[0],
            });
            if (response.ok) {
              onChange(finalUrl || null);
              setIsUploading(false);
            }
          } else {
            setShowError(true);
          }
        }}
      />

      {url ? (
        <>
          <div className={classes.imageWrapper}>
            <img src={url} alt="" style={{ width: "100%", height: "auto" }} />
            <Fab
              className={classes.deleteImage}
              size="small"
              color="secondary"
              onClick={() => {
                deleteFileMutation({ variables: { url } });
                onChange(null);
              }}
            >
              <DeleteIcon />
            </Fab>
            {/* <IconButton
              aria-label="upload file"
              className={classes.deleteImage}
              edge="end"
              color="secondary"
              onClick={() => {
                deleteFileMutation({ variables: { url } });
                onChange(null);
              }}
            >
              <DeleteIcon />
            </IconButton> */}
          </div>
        </>
      ) : (
        <>
          <FormControl
            fullWidth={true}
            variant="standard"
            size="small"
            error={error}
          >
            <InputLabel htmlFor={id}>{label}</InputLabel>
            <Input
              name={id}
              id={id}
              onClick={onSelectFile}
              type={"text"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="upload file" edge="end">
                    <UploadIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </>
      )}
    </>
  );
};
