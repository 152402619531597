import React from "react";
import { Paper, Typography, makeStyles, Divider } from "@material-ui/core";
import { TherapyFields } from "types/generated/schemaTypes";
import { ButtonChipDelete } from "./buttonChipDelete";
import { ButtonChipUpdate } from "./buttonChipUpdate";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    overflow: "hidden",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    backgroundColor: theme.palette.primary.dark,
    color: "#ffffff",
    marginLeft: -theme.spacing(1),
    marginTop: -theme.spacing(1),
    width: `calc(100% + ${theme.spacing(2)}px)`,
    fontWeight: 700,
    textAlign: "center",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  entry: {
    padding: "8px 0",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  contentWrapper: {
    "& > *": {
      width: "100%",
    },
    display: "flex",
    flex: 1,
    width: "100%",
    flexWrap: "wrap",
    alignContent: "space-between",
  },
  buttonWrapper: {
    textAlign: "right",
    marginTop: theme.spacing(1),
  },
}));

interface TherapyItemProps {
  onUpdate: () => void;
  onDelete: () => void;
  therapy: TherapyFields;
}

const TherapyItem: React.FC<TherapyItemProps> = ({
  onDelete,
  onUpdate,
  therapy,
}) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <Typography
        className={classes.title}
        variant="body1"
        component="h2"
        color="inherit"
      >
        {therapy.name}
      </Typography>
      <div className={classes.contentWrapper}>
        <div>
          {therapy.therapyEntries
            .slice()
            .sort((a, b) => {
              if (a.products.length < b.products.length) {
                return 1;
              } else if (a.products.length > b.products.length) {
                return -1;
              } else {
                return 0;
              }
            })
            .map((therapyEntry, i) => (
              <React.Fragment key={`therapyEntry.__typename__${i}`}>
                <div className={classes.entry}>
                  <div>
                    {therapyEntry.products.map((product, i) => (
                      <Typography
                        key={product.id}
                        variant="caption"
                        component="span"
                        color="textSecondary"
                      >
                        {product.name}{" "}
                        {i < therapyEntry.products.length - 1 && " - "}
                      </Typography>
                    ))}
                  </div>
                  <Typography
                    variant="caption"
                    component="span"
                    color="textSecondary"
                  >
                    {therapyEntry.quantity} Box
                  </Typography>
                </div>
                <Divider />
              </React.Fragment>
            ))}
        </div>
        <div className={classes.buttonWrapper}>
          <ButtonChipDelete
            label="Elimina"
            onClick={onDelete}
            style={{ marginRight: 16 }}
          />
          <ButtonChipUpdate label="Modifica" onClick={onUpdate} />
        </div>
      </div>
    </Paper>
  );
};

export default TherapyItem;
