import React, { ReactNode, useState } from "react";

import {
  Drawer,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { VerticalNav } from "../components";
import { TopBar } from "./topBar";
import { userRoleVar } from "localState";
import { UserRole } from "types/generated/schemaTypes";
import { useReactiveVar } from "@apollo/client";
import { MessageBanner } from "./messageBanner";
import { NavigationMenuType } from "./verticalNav";
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
interface StyleProps {
  // showFilters: boolean
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  main: {
    width: "100%",
    overflowX: "hidden",
    minHeight: "100vh",
    display: "flex",
    flexWrap: "wrap",
    alignContent: "flex-start",
  },

  navigationWrapper: (props: StyleProps) => ({
    position: "relative",
    zIndex: 1,
  }),
  contentWrapper: (props: StyleProps) => ({
    marginLeft: 15,
    marginRight: 15,
    paddingTop: 10,
    marginTop: 60,
    width: "calc(100% - 30px)",
    // paddingBottom: 56,
    [theme.breakpoints.up("sm")]: {
      // paddingTop: 64,
      // paddingBottom: 64,
    },
    [theme.breakpoints.up("md")]: {
      // minHeight: '100%',
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: 0,
      width: "100%",
      marginRight: 20,
      marginLeft: 299,
    },
    "@media screen and (min-width: 1441px)": {
      maxWidth: 1100,
      marginLeft: `calc(50vw + 140px - ${1100 / 2}px)`,
      marginRight: "auto",
    },
  }),
  spacer: {
    minHeight: `${theme.mixins.toolbar.minHeight}px`,
  },
}));

const PageLayout: React.FC<{
  children?: ReactNode;
  menu: NavigationMenuType;
}> = ({ children = null, menu }) => {
  const theme = useTheme();
  const desktopLayout = useMediaQuery(theme.breakpoints.up("lg"));
  const [isDrowerOpen, setIsDrawerOpen] = useState(false);
  const classes = useStyles();
  const role = useReactiveVar(userRoleVar);
  const isAgent = role === UserRole.AGENT;
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={classes.root}>
        <TopBar
          onOpenMenu={() => setIsDrawerOpen(true)}
          salesArea={menu === "agenti-vendite"}
        />
        <main className={classes.main}>
          <div className={classes.navigationWrapper}>
            <Drawer
              open={desktopLayout ? true : isDrowerOpen}
              variant={desktopLayout ? "persistent" : "temporary"}
              onClose={() => setIsDrawerOpen(false)}
              PaperProps={{
                style: {
                  backgroundImage: `url("/nav-background${
                    menu === "agenti-vendite" ? "-light" : ""
                  }.png")`,
                  backgroundSize: "cover",
                  zIndex: theme.zIndex.drawer - 1,
                },
              }}
            >
              {/* {!desktopLayout && (
                <div style={{ textAlign: "right" }}>
                  <IconButton onClick={() => setIsDrawerOpen(false)}>
                    <CloseIcon />
                  </IconButton>
                </div>
              )} */}
              {desktopLayout && (
                <>
                  <div style={{ padding: 30 }}>
                    <img
                      src="/logo-small.png"
                      alt="bromatech"
                      width={177}
                      height={30}
                      style={{ display: "block" }}
                    />
                    {menu === "agenti-vendite" && (
                      <Typography
                        align="center"
                        style={{ color: "#ffffff", marginTop: 20, fontWeight: 700 }}
                        variant="body1"
                      >
                        - AREA VENDITE -
                      </Typography>
                    )}
                    {menu === "agenti-campionature" && (
                      <Typography
                        align="center"
                        style={{ color: "#ffffff", marginTop: 20, fontWeight: 700 }}
                        variant="body1"
                      >
                        - AREA CAMPIONATURE -
                      </Typography>
                    )}
                  </div>
                </>
              )}
              <VerticalNav menu={menu} closeMenu={() => setIsDrawerOpen(false)} />
              {isAgent && (
                <div style={{ bottom: 10, position: "absolute", width: "100%" }}>
                  <Typography
                    align="center"
                    style={{ color: "#ffffff" }}
                    variant="body1"
                  >
                    {
                      menu === "agenti-campionature" && 
                    <a style={{ color: "inherit" }} href="areagenti@bromatech.it">
                      areagenti@bromatech.it
                    </a>
                    }
                    {
                      menu === "agenti-vendite" && 
                    <a style={{ color: "inherit" }} href="ordini@bromatech.it">
                      ordini@bromatech.it
                    </a>
                    }
                  </Typography>
                </div>
              )}
            </Drawer>
          </div>
          <div className={classes.contentWrapper}>
            {isAgent && <MessageBanner />}
            {children}
          </div>
          {/* <Footer /> */}
        </main>
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default PageLayout;
