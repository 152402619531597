import React from "react";
import { useMutation } from "@apollo/client";
import {
  CloseOrder,
  CloseOrderVariables,
  OrderFinalStatus,
  OrderFields,
  TherapyFields,
  ProductFields,
} from "types/generated/schemaTypes";
import {
  FormControl,
  Grid,
  TextField,
  Typography,
  Divider,
  makeStyles,
  RadioGroup,
  FormHelperText,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CLOSE_ORDER } from "mutation";
import { DialogForm, ActionType } from "components";
import { formatError } from "utils/errors";
import { formatDate } from "utils/date";
import { getProductNameByProductId } from "utils/products";
import { Alert } from "@material-ui/lab";

const printTherapiesProducts = (
  therapyId: string,
  therapies: TherapyFields[],
  productsIds: string[]
) => {
  const therapy = therapies.find((therapy) => therapy.id === therapyId);
  if (!therapy) return null;
  const products: string[] = [];
  therapy.therapyEntries.forEach((entry) => {
    entry.products.forEach((product) => {
      if (productsIds.includes(product.id))
        products.push(`${entry.quantity}x ${product.name}`);
    });
  });
  return products;
};

const useStyles = makeStyles((theme) => ({
  noPaddingBottom: {
    paddingBottom: "4px !important",
  },
}));

export interface ManageOrderFormValues {
  finalStatus: OrderFinalStatus | null;
  notes: string;
}

const validationSchema = Yup.object({
  finalStatus: Yup.mixed().required("Campo obbligatorio"),
});

interface ManageOrderFormProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (orderClosedId: string, accepted: boolean) => void;
  order: OrderFields;
  therapies: TherapyFields[];
  products: ProductFields[];
}

export const ManageOrderForm: React.FC<ManageOrderFormProps> = ({
  open,
  onClose,
  onConfirm,
  order,
  therapies,
  products,
}) => {
  console.log(products)
  const classes = useStyles();
  const [closeOrderMutation, { error: closeOrderError }] = useMutation<
    CloseOrder,
    CloseOrderVariables
  >(CLOSE_ORDER, {
    onCompleted: async (data) => {
      formik.resetForm();
      onConfirm(
        data.closeOrder.id,
        data.closeOrder.finalStatus === OrderFinalStatus.APPROVED
      );
    },
    onError: (err) => {
      console.log("Errore!", err);
    },
  });

  const formik = useFormik<ManageOrderFormValues>({
    initialValues: { finalStatus: null, notes: "" },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const variables = {
        finalStatus: values.finalStatus || OrderFinalStatus.APPROVED,
        notes: values.notes || null,
        orderId: order.id,
      };
      await closeOrderMutation({ variables });
    },
  });

  return (
    <DialogForm
      open={open}
      title="Convalida la richiesta"
      onClose={() => {
        formik.resetForm();
        onClose();
      }}
      actions={[
        {
          type: ActionType.EXIT,
          label: "CHIUDI SENZA SALVARE",
          callback: () => {
            formik.resetForm();
            onClose();
          },
          disabled: formik.isSubmitting,
        },
        {
          type: ActionType.CHECK,
          label: "CONFERMA",
          callback: formik.submitForm,
          disabled: formik.isSubmitting || !order.address.destinationCode,
        },
      ]}
    >
      <div style={{ overflow: "hidden" }}>
        <Typography color="textSecondary" style={{ marginBottom: 28 }}>
          Verifica e convalida la richiesta di prodotti campione.
        </Typography>
        <Typography
          color="textPrimary"
          style={{ marginBottom: 13, fontWeight: 700 }}
        >
          Cliente
        </Typography>

        {!order.address.destinationCode && (
          <Alert severity="error" variant="filled" style={{ marginBottom: 28 }}>
            ATTENZIONE: Questo cliente non è ancora stato convalidato e non è
            possibile procedere con la richiesta (verificare che l'indirizzo
            abbia un codice di destinazione abbinato)
          </Alert>
        )}

        <Grid container spacing={1}>
          <Grid item xs={4} md={3} className={classes.noPaddingBottom}>
            <Typography
              color="inherit"
              style={{ fontWeight: 700, color: "rgba(0,0,0,0.26)" }}
            >
              NOMINATIVO
            </Typography>
          </Grid>
          <Grid item xs={8} md={9} className={classes.noPaddingBottom}>
            <Typography color="textPrimary" style={{ fontWeight: 700 }}>
              {order.client.name} {order.client.lastname}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} className={classes.noPaddingBottom}>
            <Typography
              color="textSecondary"
              style={{ fontWeight: 700, color: "rgba(0,0,0,0.26)" }}
            >
              ULTIMO ORDINE
            </Typography>
          </Grid>
          <Grid item xs={8} md={9} className={classes.noPaddingBottom}>
            <Typography color="textPrimary">
              {order.client.lastOrder
                ? formatDate(new Date(order.client.lastOrder))
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} className={classes.noPaddingBottom}>
            <Typography
              color="textSecondary"
              style={{ fontWeight: 700, color: "rgba(0,0,0,0.26)" }}
            >
              EMAIL
            </Typography>
          </Grid>
          <Grid item xs={8} md={9} className={classes.noPaddingBottom}>
            <Typography color="primary">
              <a
                href={`mailto:{order.client.email}`}
                style={{ color: "inherit", textDecoration: "none" }}
              >
                {order.client.email}
              </a>
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} className={classes.noPaddingBottom}>
            <Typography
              color="textSecondary"
              style={{ fontWeight: 700, color: "rgba(0,0,0,0.26)" }}
            >
              CODICE
            </Typography>
          </Grid>
          <Grid item xs={8} md={9} className={classes.noPaddingBottom}>
            <Typography color="textPrimary">
              {order.address.destinationCode || "-"}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} className={classes.noPaddingBottom}>
            <Typography
              color="textSecondary"
              style={{ fontWeight: 700, color: "rgba(0,0,0,0.26)" }}
            >
              INDIRIZZO
            </Typography>
          </Grid>
          <Grid item xs={8} md={9} className={classes.noPaddingBottom}>
            <Typography color="textPrimary">
              {order.address.addressLine}, {order.address.zip}{" "}
              {order.address.hamlet} {order.address.city} (
              {order.address.province})
              {order.address.careOf ? (
                <>
                  <br />
                  {order.address.careOf}
                </>
              ) : null}
            </Typography>
          </Grid>
        </Grid>
        <Divider style={{ margin: "24px 0" }} />
        <Typography
          color="textPrimary"
          style={{ marginBottom: 13, fontWeight: 700 }}
        >
          Agente
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={4} md={3} className={classes.noPaddingBottom}>
            <Typography
              color="inherit"
              style={{ fontWeight: 700, color: "rgba(0,0,0,0.26)" }}
            >
              NOMINATIVO
            </Typography>
          </Grid>
          <Grid item xs={8} md={9} className={classes.noPaddingBottom}>
            <Typography color="textPrimary" style={{ fontWeight: 700 }}>
              {order.agent.name} {order.agent.lastname}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} className={classes.noPaddingBottom}>
            <Typography
              color="textSecondary"
              style={{ fontWeight: 700, color: "rgba(0,0,0,0.26)" }}
            >
              EMAIL
            </Typography>
          </Grid>
          <Grid item xs={8} md={9} className={classes.noPaddingBottom}>
            <Typography color="primary">
              <a
                href={`mailto:{order.client.email}`}
                style={{ color: "inherit", textDecoration: "none" }}
              >
                {order.agent.email}
              </a>
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} className={classes.noPaddingBottom}>
            <Typography
              color="textSecondary"
              style={{ fontWeight: 700, color: "rgba(0,0,0,0.26)" }}
            >
              CODICE
            </Typography>
          </Grid>
          <Grid item xs={8} md={9} className={classes.noPaddingBottom}>
            <Typography color="textPrimary">{order.agent.agentCrm}</Typography>
          </Grid>
        </Grid>

        <Divider style={{ margin: "24px 0" }} />

        <Typography
          color="textPrimary"
          style={{ marginBottom: 13, fontWeight: 700 }}
        >
          Ordine
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={4} md={3} className={classes.noPaddingBottom}>
            <Typography
              color="inherit"
              style={{ fontWeight: 700, color: "rgba(0,0,0,0.26)" }}
            >
              ASSORTIMENTO PRODOTTI
            </Typography>
          </Grid>
          <Grid item xs={8} md={9} className={classes.noPaddingBottom}>
            {order.selectedTherapies.length > 0 && (
              <>
                <Typography color="textPrimary" style={{ fontWeight: 700 }}>
                  {
                    therapies.find(
                      (therapy) =>
                        therapy.id === order.selectedTherapies[0].therapyId
                    )?.name
                  }
                  <br />
                </Typography>
                {printTherapiesProducts(
                  order.selectedTherapies[0].therapyId,
                  therapies,
                  order.selectedTherapies[0].productIds
                )?.map((product) => (
                  <Typography color="textPrimary" display="block">
                    {product}
                  </Typography>
                ))}
              </>
            )}
            {order.selectedTherapies.length === 0 && (
              <Typography color="textPrimary" display="block">
                Nessuna assortimento prodotti
              </Typography>
            )}
          </Grid>
          <Grid item xs={4} md={3} className={classes.noPaddingBottom}>
            <Typography
              color="inherit"
              style={{ fontWeight: 700, color: "rgba(0,0,0,0.26)" }}
            >
              BOX SINGOLI PRODOTTI
            </Typography>
          </Grid>
          <Grid item xs={8} md={9} className={classes.noPaddingBottom}>
            {order.selectedProducts.map((product) => (
              <Typography color="textPrimary" display="block">
                {product.quantity}x{" "}
                {getProductNameByProductId(product.productId, products)}
              </Typography>
            ))}
            {order.selectedProducts.length === 0 && (
              <Typography color="textPrimary" display="block">
                Nessun prodotto singolo
              </Typography>
            )}
          </Grid>
        </Grid>

        <Divider style={{ margin: "24px 0" }} />
        <Typography
          color="textPrimary"
          style={{ marginBottom: 13, fontWeight: 700 }}
        >
          Note ordine
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} className={classes.noPaddingBottom}>
            <Typography color="textPrimary">
              {order.deliveryNotes || "Non ci sono note per quest'ordine."}
            </Typography>
          </Grid>
        </Grid>

        <Divider style={{ margin: "24px 0" }} />
        <Typography
          color="textPrimary"
          style={{ marginBottom: 13, fontWeight: 700 }}
        >
          Gestione{" "}
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <RadioGroup
              style={{ flexDirection: "row" }}
              name="finalStatus"
              value={formik.values.finalStatus}
              onChange={formik.handleChange}
            >
              <FormControlLabel
                value={OrderFinalStatus.APPROVED}
                control={<Radio color="primary" />}
                label="Ordine valido"
              />
              <FormControlLabel
                value={OrderFinalStatus.REFUSED}
                control={<Radio color="primary" />}
                label="Ordine rifiutato"
              />
            </RadioGroup>

            {formik.touched.finalStatus && !!formik.errors.finalStatus && (
              <FormHelperText error={true} variant="standard">
                {formik.errors.finalStatus}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} md={4}></Grid>
          <Grid item xs={12} md={12}>
            {/* NOTE */}
            <FormControl fullWidth={true}>
              <TextField
                name="notes"
                error={formik.touched.notes && !!formik.errors.notes}
                helperText={
                  formik.touched.notes &&
                  !!formik.errors.notes &&
                  formik.errors.notes
                }
                variant="outlined"
                multiline={true}
                rows={4}
                id="notes"
                label="Note"
                value={formik.values.notes}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Typography
          color="textSecondary"
          variant="caption"
          component="p"
          style={{ marginBottom: 30, marginTop: 0 }}
        >
          * I campi contrassegnati con l’asterisco sono obbligatori
        </Typography>
        <Divider />

        {closeOrderError && (
          <Typography color="error" variant="body1">
            {closeOrderError && formatError(closeOrderError)}
          </Typography>
        )}
      </div>
    </DialogForm>
  );
};
