import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import {
  Button,
  Grid,
  Typography,
  makeStyles,
  Paper,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { Create as UpdateIcon } from "@material-ui/icons";
import { GET_SETTINGS } from "query";
import { GetSettings, GetSettingsVariables } from "types/generated/schemaTypes";
import { Breadcrumbs, PageTitle, BannerForm } from "components";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "16px 10px 8px",
    backgroundColor: "#FAFAFA",
    margin: "0 auto 15px",
    [theme.breakpoints.up("md")]: {
      padding: "25px 16px 8px",
      margin: "0 auto 20px",
    },
  },
  label: {
    width: 200,
    fontWeight: 700,
    color: "rgba(0, 0, 0, 0.26)",
  },
  entry: {
    display: "flex",
    alignItems: "center",
    padding: "11px 0",
    borderTop: `solid 1px ${theme.palette.divider}`,
    "&:last-child": {
      borderBottom: `solid 1px ${theme.palette.divider}`,
    },
  },
}));

export default function Settings() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [showBannerForm, setShowBannerForm] = useState(false);

  const {
    error: errorSettings,
    data: dataSettings,
    loading: loadingSettings,
    refetch: refetchSettings,
  } = useQuery<GetSettings, GetSettingsVariables>(GET_SETTINGS, {
    fetchPolicy: "network-only",
    variables: {
      id: "BANNER",
    },
  });

  let content = "";
  if (
    !loadingSettings &&
    !errorSettings &&
    dataSettings &&
    dataSettings.getSettings?.value
  ) {
    content = JSON.parse(dataSettings.getSettings.value).content.replaceAll(
      "\n",
      "<br>"
    );
  }

  return (
    <>
      <PageTitle title="SETTINGS" />
      <Breadcrumbs current="Settings" />
      {loadingSettings && <div>Caricamento dei settings in corso</div>}

      {!loadingSettings && !errorSettings && dataSettings && (
        <>
          <Paper className={classes.root}>
            <Typography variant="h4" style={{ marginBottom: 22 }}>
              Banner
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} md={9}>
                <div className={classes.entry}>
                  <Typography variant="body1" className={classes.label}>
                    CONTENUTO
                  </Typography>
                  <Typography variant="h6">
                  <div dangerouslySetInnerHTML={{ __html: content}}/>
                  </Typography>
                </div>
                <div className={classes.entry}>
                  <Typography variant="body1" className={classes.label}>
                    ATTIVO
                  </Typography>
                  <Typography variant="h6">
                    {dataSettings.getSettings?.value
                      ? JSON.parse(dataSettings.getSettings?.value).enabled
                        ? "SI"
                        : "NO"
                      : ""}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={3} style={{ textAlign: "right" }}>
                <Button
                  color="primary"
                  variant={isMobile ? "text" : "contained"}
                  size="small"
                  onClick={() => {
                    setShowBannerForm(true);
                  }}
                  startIcon={<UpdateIcon />}
                >
                  MODIFICA BANNER
                </Button>
              </Grid>
            </Grid>
          </Paper>
          {dataSettings.getSettings?.value && (
            <BannerForm
              data={{
                enabled: JSON.parse(dataSettings.getSettings?.value).enabled,
                content: JSON.parse(dataSettings.getSettings?.value).content,
              }}
              open={showBannerForm}
              onClose={() => {
                setShowBannerForm(false);
              }}
              onUpdate={() => {
                setShowBannerForm(false);
                refetchSettings();
              }}
            />
          )}
        </>
      )}
    </>
  );
}
