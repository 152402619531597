import { Card, CardActions, CardContent, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

export default function AgentsHome() {
  // const classes = useStyles();

  return (
    <>
      {/* <PageTitle title="Manutenzione" /> */}
      {/* <Breadcrumbs current="Profilo" /> */}
      {/* <h1>Area agenti Bromatech</h1> */}
      <div style={{ display: "block", width: "100%", gap: 16 }}>
        <Link to={"/area-vendite/richieste"}>
          <Card
            style={{
              width: "50%",
              margin: "50px auto",
              backgroundColor: "rgba(62,183,160,0.2",
            }}
          >
            <CardContent>
              <Typography
                align="center"
                style={{
                  color: "black",
                  marginTop: 20,
                  fontWeight: 700,
                  textDecoration: "none",
                }}
                variant="h5"
              >
                Area vendite
              </Typography>
            </CardContent>
          </Card>
        </Link>

        <Link to={"/area-campionature/richieste"}>
          <Card
            style={{
              width: "50%",
              margin: "50px auto",
              backgroundColor: "rgb(0,44,85,0.2)",
            }}
          >
            <CardContent>
              <Typography
                align="center"
                style={{ color: "black", marginTop: 20, fontWeight: 700 }}
                variant="h5"
              >
                Area campionature
              </Typography>
            </CardContent>
          </Card>
        </Link>
      </div>
    </>
  );
}
