import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import {
  Button,
  Grid,
  Typography,
  makeStyles,
  Paper,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { Create as UpdateIcon } from "@material-ui/icons";
import { ME } from "query";

import { Me } from "types/generated/schemaTypes";
import { Breadcrumbs, PageTitle, ProfileForm, PasswordForm } from "components";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "16px 10px 8px",
    backgroundColor: "#FAFAFA",
    margin: "0 auto 15px",
    [theme.breakpoints.up("md")]: {
      padding: "25px 16px 8px",
      margin: "0 auto 20px",
    },
  },
  label: {
    width: 200,
    fontWeight: 700,
    color: "rgba(0, 0, 0, 0.26)",
  },
  entry: {
    display: "flex",
    alignItems: "center",
    padding: "11px 0",
    borderTop: `solid 1px ${theme.palette.divider}`,
    "&:last-child": {
      borderBottom: `solid 1px ${theme.palette.divider}`,
    },
  },
}));

export default function Profile() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [showProfileForm, setShowProfileForm] = useState(false);
  const [showPasswordForm, setShowPasswordForm] = useState(false);

  const {
    error: errorProfile,
    data: dataProfile,
    loading: loadingProfile,
    refetch,
  } = useQuery<Me>(ME, {
    fetchPolicy: "network-only",
  });

  return (
    <>
      <PageTitle title="PROFILO" />
      <Breadcrumbs current="Profilo" />
      {loadingProfile && <div>Caricamento del profilo corso</div>}

      {!loadingProfile && !errorProfile && dataProfile && (
        <>
          <Paper className={classes.root}>
            <Typography variant="h4" style={{ marginBottom: 22 }}>
              Profilo
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} md={9}>
                <div className={classes.entry}>
                  <Typography variant="body1" className={classes.label}>
                    NOME
                  </Typography>
                  <Typography variant="h6">{dataProfile.me?.name}</Typography>
                </div>
                <div className={classes.entry}>
                  <Typography variant="body1" className={classes.label}>
                    COGNOME
                  </Typography>
                  <Typography variant="h6">
                    {dataProfile.me?.lastname}
                  </Typography>
                </div>
                <div className={classes.entry}>
                  <Typography variant="body1" className={classes.label}>
                    EMAIL
                  </Typography>
                  <Typography variant="h6">{dataProfile.me?.email}</Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={3} style={{ textAlign: "right" }}>
                <Button
                  color="primary"
                  variant={isMobile ? "text" : "contained"}
                  size="small"
                  onClick={() => {
                    setShowProfileForm(true);
                  }}
                  startIcon={<UpdateIcon />}
                >
                  MODIFICA PROFILO
                </Button>
              </Grid>
            </Grid>
            <Typography
              variant="h4"
              style={{ marginBottom: 22, marginTop: 18 }}
            >
              Account
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} md={9}>
                <div className={classes.entry}>
                  <Typography variant="body1" className={classes.label}>
                    ID ACCOUNT*
                  </Typography>
                  <Typography variant="h6">{dataProfile.me?.email}</Typography>
                </div>
                <div className={classes.entry}>
                  <Typography variant="body1" className={classes.label}>
                    PASSOWRD
                  </Typography>
                  <Typography variant="h6">********</Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={3} style={{ textAlign: "right" }}>
                <Button
                  color="primary"
                  variant={isMobile ? "text" : "contained"}
                  size="small"
                  onClick={() => {
                    setShowPasswordForm(true);
                  }}
                  startIcon={<UpdateIcon />}
                >
                  MODIFICA PASSWORD
                </Button>
              </Grid>
            </Grid>
            <Typography
              variant="caption"
              color="textSecondary"
              display="block"
              style={{ marginTop: 10 }}
            >
              * L’ID account è il tuo indirizzo email, se cambi l’indirizzo
              email verrà automaticamente modificato anche il tuo ID account.
            </Typography>
          </Paper>
          {dataProfile.me && (
            <ProfileForm
              data={dataProfile.me}
              open={showProfileForm}
              onClose={() => {
                setShowProfileForm(false);
              }}
              onUpdate={() => {
                setShowProfileForm(false);
                refetch();
              }}
            />
          )}
          {dataProfile.me && (
            <PasswordForm
              userId={dataProfile.me.id}
              open={showPasswordForm}
              onClose={() => {
                setShowPasswordForm(false);
              }}
              onUpdate={() => {
                setShowPasswordForm(false);
                refetch();
              }}
            />
          )}
        </>
      )}
    </>
  );
}
