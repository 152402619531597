import React, { ReactNode } from "react";
import {
  Dialog,
  DialogActions,
  makeStyles,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Close, Save, Send, Check } from "@material-ui/icons";
import { colors } from "theme";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  closeButton: {
    width: 25,
    height: 25,
    position: "absolute",
    top: 10,
    right: 10,
    cursor: "pointer",
    overflow: "hidden",
  },
  divider: {
    margin: "35px auto 40px",
  },
  actions: {
    marginTop: 0,
    // justifyContent: 'space-between',
    flexDirection: "column-reverse",
    // gap: '30px',
    flexWrap: "wrap",
    [theme.breakpoints.up("sm")]: {
      marginTop: 20,
      justifyContent: "flex-end",
      flexDirection: "row",
    },
    "& > *": {
      margin: 15,
    },
  },
}));

export enum ActionType {
  EXIT,
  SAVE,
  SEND,
  CHECK,
}

interface Action {
  type: ActionType;
  label: string;
  disabled?: boolean;
  callback: () => void;
}

interface DialogFormProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  title?: string;
  actions: Action[];
  alignment?: "left" | "center" | "right";
}

export const DialogForm: React.FC<DialogFormProps> = ({
  alignment = "left",
  open,
  onClose,
  children,
  title,
  actions,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const useTextButton = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <Dialog
      maxWidth="lg"
      fullWidth={true}
      open={open}
      scroll="body"
      className={classes.root}
    >
      <div className={classes.closeButton} onClick={onClose}>
        <Close
          htmlColor={colors.text.primary}
          style={{
            width: 35,
            height: 35,
            top: -6,
            left: -6,
            position: "absolute",
          }}
        />
      </div>

      {title && (
        <Typography
          variant="h5"
          color="textPrimary"
          align={alignment}
          style={{ marginBottom: "0.75em" }}
        >
          {title}
        </Typography>
      )}
      {children}
      <DialogActions className={classes.actions} disableSpacing={true}>
        {actions.map((action, index) => {
          switch (action.type) {
            case ActionType.EXIT:
              return useTextButton ? (
                <Button
                  key={index}
                  variant="text"
                  color="primary"
                  size="medium"
                  startIcon={<Close />}
                  onClick={action.callback}
                  disabled={action.disabled}
                >
                  {action.label}
                </Button>
              ) : (
                <Button
                  key={index}
                  variant="outlined"
                  color="primary"
                  size="medium"
                  startIcon={<Close />}
                  onClick={action.callback}
                  disabled={action.disabled}
                >
                  {action.label}
                </Button>
              );
            case ActionType.SAVE:
              return (
                <Button
                  key={index}
                  variant="contained"
                  color="primary"
                  size="medium"
                  startIcon={<Save />}
                  onClick={action.callback}
                  disabled={action.disabled}
                >
                  {action.label}
                </Button>
              );
            case ActionType.SEND:
              return (
                <Button
                  key={index}
                  variant="contained"
                  color="primary"
                  size="medium"
                  startIcon={<Send />}
                  onClick={action.callback}
                  disabled={action.disabled}
                >
                  {action.label}
                </Button>
              );
            case ActionType.CHECK:
              return (
                <Button
                  key={index}
                  variant="contained"
                  color="primary"
                  size="medium"
                  startIcon={<Check />}
                  onClick={action.callback}
                  disabled={action.disabled}
                >
                  {action.label}
                </Button>
              );

            default:
              return null;
          }
        })}
      </DialogActions>
    </Dialog>
  );
};
