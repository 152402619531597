import React, { useState } from "react";
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import {
  Grid,
  InputAdornment,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  useTheme,
  TextField,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { GET_CLIENTS, GET_USERS } from "query";
import { ACTIVATE_CLIENT, UPDATE_CLIENT } from "mutation";
import {
  GetClientsVariables,
  GetClients,
  ClientOrderInput,
  ClientWhereInput,
  GetUsers,
  GetUsersVariables,
  UserOrderInput,
  UpdateClient,
  UpdateClientVariables,
  ActivateClient,
  ActivateClientVariables,
} from "types/generated/schemaTypes";
import { FiltersWrapper } from "components";
import {
  CheckCircle as HasAgentIcon,
  ReportProblem as HasNoAgentIcon,
  Info as InfoIcon,
} from "@material-ui/icons";
import { UserBlockedIcon } from "icons";
import { userRoleVar, userIdVar } from "localState";
import { UserRole } from "types/generated/schemaTypes";
import { ButtonChipResume } from "./buttonChipResume";
import { DialogInfo } from "./dialogInfo";

const useStyles = makeStyles((theme) => ({
  noBorderTextField: {
    // backgroundColor: "yellow",
    "& .MuiInput-underline:before": {
      display: "none",
    },
  },
}));

export const DeletedClients: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const role = useReactiveVar(userRoleVar);
  const userId = useReactiveVar(userIdVar);
  const [showClientNotes, setShowClientNotes] = useState(false);
  const [clientNotes, setClientNotes] = useState("");
  const [searchString, setSearchString] = useState("");
  const [offset, setOffset] = useState(0);
  const [filterAgent, setFilterAgent] = useState<string>("ALL");
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState<ClientOrderInput>(
    ClientOrderInput.LASTNAME_ASC
  );
  const classes = useStyles();

  const {
    data: agentsData,
    loading: agentsLoading,
    error: agentsError,
  } = useQuery<GetUsers, GetUsersVariables>(GET_USERS, {
    fetchPolicy: "network-only",
    variables: {
      where: { active: true, role: UserRole.AGENT },
      order: UserOrderInput.LASTNAME_ASC,
      offset: 0,
      limit: 100,
    },
  });

  const [updateClientMutation] =
    useMutation<UpdateClient, UpdateClientVariables>(UPDATE_CLIENT);

  const agentsFilterOptions = [
    { label: "Tutti gli agenti", id: "ALL" },
    { label: "Con agente", id: "ASSIGNED" },
    { label: "Senza agente", id: "NOT_ASSIGNED" },
  ];

  const agentsAssignmentOptions = [{ label: "-", id: "" }];

  if (agentsData?.getUsers.users)
    agentsData?.getUsers.users.forEach((agent) => {
      agentsFilterOptions.push({
        label: `${agent.name} ${agent.lastname}`,
        id: agent.id,
      });

      agentsAssignmentOptions.push({
        label: `${agent.name} ${agent.lastname}`,
        id: agent.id,
      });
    });

  const where: ClientWhereInput = {
    active: false,
  };

  if (role === UserRole.AGENT) {
    where.agentId = userId;
  } else {
    if (filterAgent !== "ALL") {
      if (filterAgent === "NOT_ASSIGNED") {
        where.notAssigned = true;
      } else if (filterAgent === "ASSIGNED") {
        where.notAssigned = false;
      } else {
        where.agentId = filterAgent;
      }
    }
  }

  if (searchString.length > 2) where.searchPattern = searchString;

  const {
    data: clientsData,
    loading: clientsLoading,
    error: clientsError,
  } = useQuery<GetClients, GetClientsVariables>(GET_CLIENTS, {
    fetchPolicy: "network-only",
    variables: {
      where,
      order,
      offset,
      limit,
    },
  });

  const [resumeClientMutation] = useMutation<
    ActivateClient,
    ActivateClientVariables
  >(ACTIVATE_CLIENT, {
    onCompleted: async (data) => {
      // onDelete();
    },
    onError: (err) => {
      console.log("Errore!", err);
    },
    refetchQueries: [
      {
        query: GET_CLIENTS,
        variables: {
          where,
          order,
          offset,
          limit,
        },
      },
    ],
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setOffset(newPage * limit);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLimit(parseInt(event.target.value, 10));
    setOffset(0);
  };

  return (
    <>
      <FiltersWrapper>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item xs={6} md={2}>
            <FormControl fullWidth={true} variant="standard" size="medium">
              <InputLabel id="order-label">Ordina per</InputLabel>
              <Select
                name="order"
                value={order}
                labelId="order-label"
                id="order"
                label="Ordina per"
                onChange={(e) => {
                  setOffset(0);
                  setOrder(e.target.value as ClientOrderInput);
                }}
              >
                <MenuItem value={ClientOrderInput.CREATION_DESC}>
                  Data creazione ↓
                </MenuItem>
                <MenuItem value={ClientOrderInput.CREATION_ASC}>
                  Data creazione ↑
                </MenuItem>
                <MenuItem value={ClientOrderInput.LASTNAME_DESC}>
                  Cognome (Z-A)
                </MenuItem>
                <MenuItem value={ClientOrderInput.LASTNAME_ASC}>
                  Cognome (A-Z)
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {role !== UserRole.AGENT ? (
            <Grid item xs={6} md={2}>
              <FormControl fullWidth={true}>
                <Autocomplete
                  options={agentsFilterOptions}
                  value={
                    agentsFilterOptions.find(
                      (agent) => agent.id === filterAgent
                    ) || null
                  }
                  getOptionSelected={(option, test) => {
                    return test.id === option.id;
                  }}
                  onChange={(event, newValue) => {
                    setFilterAgent(newValue?.id || "ALL");
                  }}
                  getOptionLabel={(option) => option.label}
                  // style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Filtra per agente"
                      variant="standard"
                    />
                  )}
                />
              </FormControl>
            </Grid>
          ) : (
            <Grid item xs={6} md={2}>
              &nbsp;
            </Grid>
          )}
          <Grid item xs={12} md={3}>
            <FormControl fullWidth={true} variant="standard" size="medium">
              <InputLabel htmlFor="search">Cerca</InputLabel>
              <Input
                name="searchString"
                id="searchString"
                autoFocus={searchString.length > 2}
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          {!isMobile && (
            <Grid item xs={6} md={2}>
              &nbsp;
            </Grid>
          )}
        </Grid>
      </FiltersWrapper>
      {clientsLoading && <div>Caricamento dei clienti in corso</div>}
      {!clientsLoading &&
        !agentsLoading &&
        !agentsError &&
        !clientsError &&
        clientsData &&
        agentsData && (
          <>
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell variant="head" align="left">
                        STATO
                      </TableCell>
                      <TableCell variant="head" align="left">
                        NOME
                      </TableCell>
                      <TableCell variant="head" align="left">
                        COGNOME
                      </TableCell>
                      {role !== UserRole.AGENT && (
                        <TableCell
                          variant="head"
                          align="left"
                          style={{ minWidth: 190 }}
                        >
                          AGENTE DI RIFERIMENTO
                        </TableCell>
                      )}
                      <TableCell
                        variant="head"
                        align="left"
                        style={{ width: 112 }}
                      >
                        AZIONI
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {clientsData.getClients.clients.map((client) => {
                      return (
                        <TableRow key={client.id}>
                          <TableCell align="left">
                            <UserBlockedIcon
                              style={{ marginRight: 10 }}
                              htmlColor={theme.palette.error.main}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              component="span"
                              variant="body1"
                              style={{
                                color: theme.palette.error.main,
                              }}
                            >
                              {client.name}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              component="span"
                              variant="body1"
                              style={{
                                color: theme.palette.error.main,
                              }}
                            >
                              {client.lastname}
                            </Typography>
                          </TableCell>

                          {role !== UserRole.AGENT && (
                            <TableCell
                              variant="head"
                              align="left"
                              style={{ width: 250 }}
                            >
                              <div style={{ display: "flex" }}>
                                {client.agent ? (
                                  <HasAgentIcon
                                    style={{ marginRight: 16 }}
                                    htmlColor={theme.palette.success.main}
                                  />
                                ) : (
                                  <HasNoAgentIcon
                                    style={{ marginRight: 16 }}
                                    htmlColor={theme.palette.error.main}
                                  />
                                )}

                                <FormControl fullWidth={true}>
                                  <Autocomplete
                                    options={agentsAssignmentOptions}
                                    value={
                                      agentsAssignmentOptions.find((agent) => {
                                        return agent.id === client.agent?.id;
                                      }) || { label: "-", id: "" }
                                    }
                                    getOptionSelected={(option, test) => {
                                      return test.id === option.id;
                                    }}
                                    onChange={async (_, newValue) => {
                                      await updateClientMutation({
                                        variables: {
                                          clientId: client.id,
                                          clientData: {
                                            agentId: newValue?.id || null,
                                          },
                                        },
                                      });
                                    }}
                                    getOptionLabel={(option) => option.label}
                                    renderInput={(params) => (
                                      <TextField
                                        className={classes.noBorderTextField}
                                        {...params}
                                        label=""
                                        variant="standard"
                                      />
                                    )}
                                  />
                                </FormControl>
                              </div>
                            </TableCell>
                          )}
                          <TableCell align="left">
                            <div style={{ display: "flex" }}>
                              <ButtonChipResume
                                style={{ marginRight: 16 }}
                                onClick={async () => {
                                  await resumeClientMutation({
                                    variables: { clientId: client.id },
                                  });
                                }}
                              />
                              <InfoIcon
                                color="primary"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  console.log(client);
                                  setClientNotes(
                                    client?.notes || "Non sono presenti note"
                                  );
                                  setShowClientNotes(true);
                                }}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {clientsData.getClients.clients.length < 11 && (
                      <>
                        {new Array(10 - clientsData.getClients.clients.length)
                          .fill(10)
                          .map((_, index) => (
                            <TableRow key={index}>
                              <TableCell variant="head" align="left">
                                &nbsp;
                              </TableCell>
                              <TableCell variant="head" align="left">
                                &nbsp;
                              </TableCell>
                              <TableCell variant="head" align="left">
                                &nbsp;
                              </TableCell>
                              {role !== UserRole.AGENT && (
                                <TableCell variant="head" align="left">
                                  &nbsp;
                                </TableCell>
                              )}
                              <TableCell variant="head" align="left">
                                &nbsp;
                              </TableCell>
                            </TableRow>
                          ))}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                labelRowsPerPage="Totale clienti per pagina"
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} di ${count}`
                }
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={clientsData ? clientsData.getClients.total : 0}
                rowsPerPage={limit}
                page={Math.floor(offset / limit)}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </>
        )}

      {showClientNotes && (
        <DialogInfo
          onClose={() => {
            setShowClientNotes(false);
          }}
          title="Note sul cliente eliminato"
          open={showClientNotes}
        >
          <TextField
            multiline={true}
            rows={5}
            disabled={true}
            label="Note"
            variant="outlined"
            fullWidth={true}
            value={clientNotes}
          />
        </DialogInfo>
      )}
    </>
  );
};
