import React from "react";

import { Grid, Typography, Divider, makeStyles } from "@material-ui/core";

import { DialogInfo } from "components";

import {
  OrderClosedFields,
  OrderFinalStatus,
} from "types/generated/schemaTypes";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  noPaddingBottom: {
    paddingBottom: "4px !important",
  },
}));

interface OrderDetailProps {
  open: boolean;
  order: OrderClosedFields;
  onClose: () => void;
  isAgent: boolean;
}
export const OrderDetail: React.FC<OrderDetailProps> = ({
  open,
  order,
  onClose,
  isAgent = true,
}) => {
  const classes = useStyles();

  return (
    <DialogInfo
      open={open}
      title="Dettaglio richiesta"
      onClose={onClose}
      closeIcon="close"
      closeLabel="CHIUDI"
    >
      <div style={{ overflow: "hidden" }}>
        {order.finalStatus === OrderFinalStatus.REFUSED && (
          <Alert
            severity="warning"
            variant="filled"
            style={{ marginBottom: 28 }}
          >
            <Typography variant="body1" style={{ fontWeight: 700 }}>
              La richiesta non è stata presa in carico
            </Typography>
            {order.orderNotes && (
              <Typography variant="body2">{order.orderNotes}</Typography>
            )}
          </Alert>
        )}
        <Typography
          color="textPrimary"
          style={{ marginBottom: 13, fontWeight: 700 }}
        >
          Cliente
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={3} className={classes.noPaddingBottom}>
            <Typography
              color="inherit"
              style={{ fontWeight: 700, color: "rgba(0,0,0,0.26)" }}
            >
              NOMINATIVO
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} className={classes.noPaddingBottom}>
            <Typography color="textPrimary" style={{ fontWeight: 700 }}>
              {order.client.name} {order.client.lastname}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} className={classes.noPaddingBottom}>
            <Typography
              color="textSecondary"
              style={{ fontWeight: 700, color: "rgba(0,0,0,0.26)" }}
            >
              INDIRIZZO
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} className={classes.noPaddingBottom}>
            <Typography color="textPrimary">
              {order.client.addressLine}, {order.client.zip}{" "}
              {order.client.hamlet} {order.client.city} ({order.client.province}
              )
              {order.client.careOf ? (
                <>
                  <br />
                  {order.client.careOf}
                </>
              ) : null}
            </Typography>
          </Grid>
        </Grid>
        <Divider style={{ margin: "24px 0" }} />
        <Typography
          color="textPrimary"
          style={{ marginBottom: 13, fontWeight: 700 }}
        >
          Note ordine
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs={12} md={12} className={classes.noPaddingBottom}>
            <Typography color="inherit">
              {order.deliveryNotes || "Nessuna nota"}
            </Typography>
          </Grid>
        </Grid>
        <Divider style={{ margin: "24px 0" }} />

        <Typography
          color="textPrimary"
          style={{ marginBottom: 13, fontWeight: 700 }}
        >
          Richiesta
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={3} className={classes.noPaddingBottom}>
            <Typography
              color="inherit"
              style={{ fontWeight: 700, color: "rgba(0,0,0,0.26)" }}
            >
              PRODOTTI CAMPIONE
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} className={classes.noPaddingBottom}>
            {order.productUnities.map((product) => (
              <Typography color="textPrimary" display="block">
                {(product?.totalUnities as number) /
                  (product?.boxUnities as number)}
                x {product?.name}{" "}
                {!isAgent && <>({product?.totalUnities} unità totali)</>}
              </Typography>
            ))}
          </Grid>
        </Grid>
      </div>
    </DialogInfo>
  );
};
