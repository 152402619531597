import React, { ReactNode } from "react";
import { makeStyles, Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "16px 10px 8px",
    backgroundColor: "#FAFAFA",
    margin: "0 auto 15px",
    [theme.breakpoints.up("md")]: {
      padding: "16px 16px 8px",
      margin: "0 auto 20px",
    },
  },
}));

const FiltersWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
  const classes = useStyles();
  return <Paper className={classes.root}>{children}</Paper>;
};

export default FiltersWrapper;
