import { createTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const breakpoints = createBreakpoints({});

export const colors = {
  primary: {
    main: "#3F51B5",
    dark: "#303F9F",
    light: "#7986CB",
    contrast: "#FFFFFF",
  },
  text: {
    primary: "rgba(0,0,0,0.87)",
    secondary: "rgba(0,0,0,0.60)",
  },
  brand: "#003087",
  disabledBackground: "rgba(0,0,0,0.12)",
};

// xs: 0,
// sm: 600,
// md: 960,
// lg: 1280,
// xl: 1920

const theme = createTheme({
  spacing: 16,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1440,
    },
  },
  typography: {
    fontFamily: ["Roboto", "Arial", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: colors.primary.main,
      dark: colors.primary.dark,
      light: colors.primary.light,
      contrastText: colors.primary.contrast,
    },
    text: {
      primary: colors.text.primary,
      secondary: colors.text.secondary,
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          marginBottom: 0,
          color: colors.text.primary,
          backgroundColor: "rgba(234,234,234)",
        },
        li: {
          // listStyle: 'none',
          listStylePosition: "inside",
          padding: 0,
          margin: 0,
        },
        ul: {
          padding: 0,
          margin: 0,
        },
        // a: {
        //   color: colors.primary.main,
        // },
        p: {
          marginBottom: "1em",
        },
        '.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child':
          {
            paddingTop: "3px !important",
          },

        // 'input::placeholder': {
        //   color: `${colors.text.secondary} !important`,
        //   opacity: '1 !important',
        // },
        // ".Mui-error input::placeholder": {
        //   color: `${palette.error.main} !important`,
        //   opacity: "1 !important",
        // },
      },
    },
    MuiBreadcrumbs: {
      li: {
        fontSize: 12,
        lineHeight: "20px",
        letterSpacing: "0.4px",
        fontWeight: 400,
      },
      separator: {
        marginLeft: 15,
        marginRight: 15,
      },
    },
    // MuiCheckbox: { root: { top: -1 } },
    MuiButton: {
      root: {
        fontFamily: "Roboto",
        // minWidth: 143,
      },
    },
    // MuiOutlinedInput: {
    //   root: {
    //     // '&:hover .MuiOutlinedInput-notchedOutline': {
    //     //   borderColor: colors.primary.main,
    //     // },
    //     // },
    //   },
    //   notchedOutline: {
    //     top: -7,
    //     "&:hover": {
    //       borderColor: "green",
    //     },
    //   },
    // },

    MuiContainer: {
      maxWidthMd: {
        paddingLeft: 15,
        paddingRight: 15,
        [breakpoints.up("md")]: {
          paddingLeft: 20,
          paddingRight: 20,
        },
      },
      maxWidthLg: {
        paddingLeft: 15,
        paddingRight: 15,
        [breakpoints.up("md")]: {
          paddingLeft: 60,
          paddingRight: 60,
          maxWidth: 1260,
          [breakpoints.up("lg")]: {
            paddingLeft: 10,
            paddingRight: 10,
          },
        },
      },
    },
    // MuiInputBase: {
    //   root: {
    //     marginBottom: "0",
    //     // marginBottom: '0.25em',
    //   },
    // },
    MuiDialog: {
      paperWidthMd: {
        maxWidth: 912,
        margin: 15,
        [breakpoints.up("md")]: {
          margin: 32,
        },
      },
      paperWidthLg: {
        maxWidth: 900,
        margin: "15px 0",
        width: "100%",
        // margin: 15,
        [breakpoints.up("md")]: {
          margin: 32,
        },
        [breakpoints.down("md")]: {
          "&.MuiDialog-paperScrollBody": {
            maxWidth: "100% !important",
          },
        },
      },
      paper: {
        padding: "30px 10px 10px",
        // padding: "65px 15px 15px 15px",
        [breakpoints.up("md")]: {
          padding: "30px 40px",
          // padding: "65px 128px 60px 128px",
        },
      },
      paperFullWidth: {
        width: "calc(100% - 30px)",
        [breakpoints.up("md")]: {
          width: "calc(100% - 64px)",
        },
      },
    },
    // MuiFormLabel: {
    //   root: {
    //     fontSize: 16,
    //     color: colors.text.secondary,
    //     // color: 'green',
    //     letterSpacing: "0.15px",
    //   },
    // },
    // MuiFormControl: {
    //   root: {
    //     marginBottom: 0,
    //     // marginBottom: 20,
    //     [breakpoints.up("md")]: {
    //       // marginBottom: 30,
    //       marginBottom: 0,
    //     },
    //   },
    // },
    // MuiTextField: {
    //   root: {
    //     marginBottom: 0,
    //     // marginBottom: 20,
    //     [breakpoints.up("md")]: {
    //       // marginBottom: 30,
    //       marginBottom: 0,
    //     },
    //   },
    // },
    MuiFormHelperText: {
      root: {
        marginLeft: 14,
        marginRight: 14,
      },
    },
    MuiDialogActions: {
      root: {
        padding: 0,
      },
    },
    MuiGrid: {
      item: {
        paddingTop: "0 !important",
        // paddingBottom: '0 !important',
        paddingBottom: "12px !important",
        [breakpoints.up("md")]: {
          paddingBottom: "30px",
        },
      },
      container: {
        marginTop: "0 !important",
        marginBottom: "0 !important",
      },
    },
    MuiDrawer: {
      paper: {
        width: 280,
      },
    },
    MuiToolbar: {
      gutters: {
        paddingLeft: 10,
        paddingRight: 10,
      },
    },
    // MuiTablePagination: {
    //   select: {
    //     position: "relative",
    //     top: 3,
    //   },
    // },
    MuiTab: {
      root: {
        borderBottom: "solid 1px rgb(209,209,209)",
        width: "50%",
        [breakpoints.up("sm")]: {
          width: "auto",
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: "13px 10px",
        [breakpoints.up("md")]: {
          padding: "13px 16px",
        },
      },
      sizeSmall: {
        paddingLeft: 0,
      },
    },
    MuiChip: {
      label: {
        position: "relative",
        top: 1,
      },
    },
  },
});

export { theme };
