import React from "react";
import { Chip, ChipProps } from "@material-ui/core";
import { Receipt as OrderIcon } from "@material-ui/icons";

interface ButtonChipOrderProps {
  onClick: () => void;
  label: string;
}

export const ButtonChipOrder: React.FC<ButtonChipOrderProps & ChipProps> = ({
  onClick,
  label,
  ...props
}) => {
  return (
    <Chip
      {...props}
      clickable={true}
      variant="default"
      size="small"
      color="primary"
      deleteIcon={<OrderIcon />}
      label={label}
      onDelete={onClick}
      onClick={onClick}
    />
  );
};
