import React, { useEffect, useState } from "react";
import {
  useApolloClient,
  useMutation,
  useQuery,
  useReactiveVar,
} from "@apollo/client";
import {
  Grid,
  InputAdornment,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  useTheme,
  TextField,
  makeStyles,
  Button,
  useMediaQuery,
} from "@material-ui/core";
import {
  Add as AddIcon,
  Search as SearchIcon,
  Lock as LockIcon,
} from "@material-ui/icons";

import { HourglassHalfFullIcon } from "icons";

import { Autocomplete } from "@material-ui/lab";
import {
  GET_SALES_ORDER,
  GET_SALES_ORDERS,
  GET_USERS,
  GET_PRODUCTS,
  GET_SALES_CLIENTS_SIMPLE,
} from "query";
import { DELETE_SALES_ORDER, UPDATE_SALES_ORDER } from "mutation";
import {
  GetSalesOrdersVariables,
  GetSalesOrders,
  SalesOrderOrderInput,
  SalesOrderWhereInput,
  GetUsers,
  GetUsersVariables,
  UserOrderInput,
  GetProducts,
  GetProductsVariables,
  UpdateSalesOrder,
  UpdateSalesOrderVariables,
  SalesOrderStatus,
  SalesClientOrderInput,
  DeleteSalesOrder,
  DeleteSalesOrderVariables,
  GetSalesClientsSimple,
  GetSalesClientsSimpleVariables,
  GetSalesOrderVariables,
  GetSalesOrder,
  SalesOrderFields,
} from "types/generated/schemaTypes";
import {
  FiltersWrapper,
  ButtonChipHandle,
  AfterSalesOrderForm,
  DialogError,
  DialogWarning,
} from "components";

import { formatError } from "utils/errors";

import { CheckCircle as HasAgentIcon } from "@material-ui/icons";
import { userRoleVar, userIdVar } from "localState";
import { UserRole } from "types/generated/schemaTypes";
import { formatDate } from "utils/date";
import { ButtonChipDelete } from "./buttonChipDelete";
import { ButtonChipUpdate } from "./buttonChipUpdate";
import { SalesOrderForm } from "./salesOrderForm";
import { ManageSalesOrderForm } from "./manageSalesOrderForm";

const useStyles = makeStyles((theme) => ({
  noBorderTextField: {
    // backgroundColor: "yellow",
    "& .MuiInput-underline:before": {
      display: "none",
    },
  },
}));

export const SalesPendingOrders: React.FC = () => {
  const client = useApolloClient();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const role = useReactiveVar(userRoleVar);
  const isAgent = role === UserRole.AGENT;
  const userId = useReactiveVar(userIdVar);
  const [currentSalesOrder, setCurrentSalesOrder] =
    useState<null | SalesOrderFields>(null);
  const [searchString, setSearchString] = useState("");
  const [offset, setOffset] = useState(0);
  const [salesOrderClosedId, setSalesOrderClosedId] = useState<string | null>(
    null
  );
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [filterAssigned, setFilterAssigned] = useState<string>("ALL");
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState<SalesOrderOrderInput>(
    SalesOrderOrderInput.CREATION_DESC
  );

  const [showManageSalesOrderForm, setShowManageSalesOrderForm] =
    useState(false);
  const [showAfterSalesOrderForm, setShowAfterSalesOrderForm] = useState(false);
  const [showSalesOrderForm, setShowSalesOrderForm] = useState(false);

  const classes = useStyles();

  const {
    data: operatorsData,
    loading: operatorsLoading,
    error: operatorsError,
  } = useQuery<GetUsers, GetUsersVariables>(GET_USERS, {
    fetchPolicy: "network-only",
    variables: {
      where: { active: true, role: UserRole.OPERATOR },
      order: UserOrderInput.LASTNAME_ASC,
      offset: 0,
      limit: 100,
    },
    skip: isAgent,
  });

  const {
    data: salesClientsData,
    // loading: clientsLoading,
    // error: clientsError,
  } = useQuery<GetSalesClientsSimple, GetSalesClientsSimpleVariables>(
    GET_SALES_CLIENTS_SIMPLE,
    {
      fetchPolicy: "network-only",
      variables: {
        where: { active: true, agentId: userId },
        order: SalesClientOrderInput.NAME_ASC,
        offset: 0,
        limit: 700,
      },
      skip: !isAgent,
    }
  );

  const enableNewOrders =
    salesClientsData &&
    salesClientsData.getSalesClients?.salesClients?.length > 0;

  const {
    data: productsData,
    loading: productsLoading,
    error: productsError,
  } = useQuery<GetProducts, GetProductsVariables>(GET_PRODUCTS, {
    fetchPolicy: "cache-first",
    variables: {
      where: { archived: false, forSale: true },
      offset: 0,
      limit: 100,
    },
  });

  const [setSalesOrderOperatorMutation] = useMutation<
    UpdateSalesOrder,
    UpdateSalesOrderVariables
  >(UPDATE_SALES_ORDER);

  const where: SalesOrderWhereInput = {};

  const assignedFilterOptions = [
    { label: "Tutti le richieste", id: "ALL" },
    { label: "Richieste assegnate", id: "ASSIGNED" },
    { label: "Richieste non assegnate", id: "NOT_ASSIGNED" },
  ];

  const operatorAssignmentOptions = [{ label: "-", id: "" }];

  if (operatorsData?.getUsers.users)
    operatorsData?.getUsers.users.forEach((operator) => {
      assignedFilterOptions.push({
        label: `${operator.name} ${operator.lastname}`,
        id: operator.id,
      });
      operatorAssignmentOptions.push({
        label: `${operator.name} ${operator.lastname[0]}.`,
        id: operator.id,
      });
    });

  if (isAgent) where.agentId = userId;

  if (filterAssigned !== "ALL") {
    if (filterAssigned === "NOT_ASSIGNED") {
      where.assigned = false;
    } else if (filterAssigned === "ASSIGNED") {
      where.assigned = true;
    } else {
      where.operatorId = filterAssigned;
    }
  }

  if (searchString.length > 2) where.searchPattern = searchString;

  const {
    data: salesOrdersData,
    loading: salesOrdersLoading,
    error: salesOrdersError,
    refetch: refetchSalesOrders,
    startPolling,
    stopPolling,
  } = useQuery<GetSalesOrders, GetSalesOrdersVariables>(GET_SALES_ORDERS, {
    fetchPolicy: "network-only",
    variables: {
      where,
      order,
      offset,
      limit,
    },
  });

  useEffect(() => {
    startPolling(30000);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  const [deleteSalesOrderMutation] = useMutation<
    DeleteSalesOrder,
    DeleteSalesOrderVariables
  >(DELETE_SALES_ORDER, {
    refetchQueries: [
      {
        query: GET_SALES_ORDERS,
        variables: {
          where,
          order,
          offset,
          limit,
        },
      },
    ],
    onCompleted: () => {
      setShowWarningDialog(false);
    },
    onError: (err) => {
      setShowWarningDialog(false);
      setErrorMessage(formatError(err));
      setShowErrorDialog(true);
      console.log(err);
    },
  });

  const deleteSalesOrder = async (salesOrderId: string | null) => {
    if (salesOrderId)
      await deleteSalesOrderMutation({
        variables: {
          salesOrderId,
        },
      });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setOffset(newPage * limit);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLimit(parseInt(event.target.value, 10));
    setOffset(0);
  };

  const loadOrder = async (salesOrderId: string) => {
    const data = await client.query<GetSalesOrder, GetSalesOrderVariables>({
      query: GET_SALES_ORDER,
      variables: { salesOrderId },
      fetchPolicy: "cache-first",
    });
    setCurrentSalesOrder(data.data.getSalesOrder);
  };

  return (
    <>
      <FiltersWrapper>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item xs={6} md={3}>
            <FormControl fullWidth={true} variant="standard" size="medium">
              <InputLabel id="order-label">Ordina per</InputLabel>
              <Select
                name="order"
                value={order}
                labelId="order-label"
                id="order"
                label="Ordina per"
                onChange={(e) => {
                  setOffset(0);
                  setOrder(e.target.value as SalesOrderOrderInput);
                }}
              >
                <MenuItem value={SalesOrderOrderInput.CREATION_DESC}>
                  Data creazione ↓
                </MenuItem>
                <MenuItem value={SalesOrderOrderInput.CREATION_ASC}>
                  Data creazione ↑
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {!isAgent && (
            <Grid item xs={6} md={3}>
              <FormControl fullWidth={true}>
                <Autocomplete
                  options={assignedFilterOptions}
                  value={
                    assignedFilterOptions.find(
                      (agent) => agent.id === filterAssigned
                    ) || null
                  }
                  getOptionSelected={(option, test) => {
                    return test.id === option.id;
                  }}
                  onChange={(event, newValue) => {
                    setFilterAssigned(newValue?.id || "ALL");
                  }}
                  getOptionLabel={(option) => option.label}
                  // style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Visualizza"
                      variant="standard"
                    />
                  )}
                />
              </FormControl>
            </Grid>
          )}
          <Grid item xs={isAgent ? 6 : 12} md={3}>
            <FormControl fullWidth={true} variant="standard" size="medium">
              <InputLabel htmlFor="search">Cerca...</InputLabel>
              <Input
                name="searchString"
                id="searchString"
                autoFocus={searchString.length > 2}
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>

          {isAgent && (
            <Grid
              item
              xs={isAgent ? 12 : 6}
              md={6}
              style={{ textAlign: "right" }}
            >
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={() => {
                  setCurrentSalesOrder(null);
                  setShowSalesOrderForm(true);
                }}
                disabled={!enableNewOrders}
                startIcon={<AddIcon />}
              >
                Aggiungi
              </Button>
            </Grid>
          )}
        </Grid>
      </FiltersWrapper>
      {salesOrdersLoading && productsLoading && (
        <div>Caricamento delle richieste in corso</div>
      )}
      {!salesOrdersLoading &&
        !operatorsLoading &&
        !productsLoading &&
        !productsError &&
        !operatorsError &&
        !salesOrdersError &&
        salesOrdersData &&
        (isAgent || operatorsData) && (
          <>
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell variant="head" align="left">
                        DATA
                      </TableCell>
                      {!isAgent && !isMobile && (
                        <TableCell variant="head" align="left">
                          AGENTE
                        </TableCell>
                      )}
                      <TableCell
                        variant="head"
                        align="left"
                        style={{ minWidth: 160 }}
                      >
                        CLIENTE
                      </TableCell>

                      <TableCell
                        variant="head"
                        align="left"
                        style={{ width: isMobile ? 180 : 225, minWidth: 180 }}
                      >
                        STATO
                      </TableCell>
                      <TableCell
                        variant="head"
                        align="left"
                        style={{ width: isAgent ? 220 : 100 }}
                      >
                        AZIONI
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {salesOrdersData.getSalesOrders.salesOrders.map((order) => {
                      return (
                        <TableRow key={order.id}>
                          <TableCell align="left">
                            <Typography
                              component="span"
                              variant="body1"
                              color="textPrimary"
                            >
                              {formatDate(order.date)}
                            </Typography>
                          </TableCell>
                          {!isAgent && !isMobile && (
                            <TableCell align="left">
                              <Typography
                                component="span"
                                variant="body1"
                                color="textPrimary"
                              >
                                {order.agent.name} {order.agent.lastname}
                              </Typography>
                            </TableCell>
                          )}
                          <TableCell align="left">
                            <Typography
                              component="span"
                              variant="body1"
                              color="textPrimary"
                            >
                              {order.salesClient.name}
                            </Typography>
                            {!isAgent && (
                              <>
                                <br />
                                <Typography
                                  component="span"
                                  variant="body2"
                                  color="textPrimary"
                                >
                                  ({order.salesClient.vat})
                                </Typography>
                              </>
                            )}
                          </TableCell>

                          {isAgent && (
                            <TableCell variant="head" align="left">
                              <div style={{ display: "flex" }}>
                                {order.status === SalesOrderStatus.PENDING &&
                                order.operator ? (
                                  <>
                                    <LockIcon
                                      htmlColor={theme.palette.warning.main}
                                    />
                                    <Typography
                                      component="span"
                                      variant="body1"
                                      color="textPrimary"
                                    >
                                      &nbsp;Preso in carico
                                    </Typography>
                                  </>
                                ) : (
                                  <>
                                    <HourglassHalfFullIcon color="primary" />
                                    <Typography
                                      component="span"
                                      variant="body1"
                                      color="textPrimary"
                                    >
                                      &nbsp;In attesa{" "}
                                      {!isMobile && "di validazione"}
                                    </Typography>
                                  </>
                                )}
                              </div>
                            </TableCell>
                          )}

                          {!isAgent && (
                            <TableCell variant="head" align="left">
                              <div style={{ display: "flex" }}>
                                {order.operator ? (
                                  <HasAgentIcon
                                    style={{ marginRight: 16 }}
                                    htmlColor={theme.palette.success.main}
                                  />
                                ) : null}

                                <FormControl fullWidth={true}>
                                  <Autocomplete
                                    options={operatorAssignmentOptions}
                                    value={
                                      operatorAssignmentOptions.find(
                                        (operator) => {
                                          return (
                                            operator.id === order.operator?.id
                                          );
                                        }
                                      ) || { label: "Assegna a...", id: "" }
                                    }
                                    getOptionSelected={(option, test) => {
                                      return test.id === option.id;
                                    }}
                                    onChange={async (_, newValue) => {
                                      await setSalesOrderOperatorMutation({
                                        variables: {
                                          salesOrderId: order.id,
                                          salesOrderData: {
                                            operatorId: newValue?.id || null,
                                          },
                                        },
                                      });
                                    }}
                                    getOptionLabel={(option) => option.label}
                                    renderInput={(params) => (
                                      <TextField
                                        className={classes.noBorderTextField}
                                        {...params}
                                        label=""
                                        variant="standard"
                                      />
                                    )}
                                  />
                                </FormControl>
                              </div>
                            </TableCell>
                          )}
                          <TableCell align="left">
                            <div style={{ display: "flex" }}>
                              {isAgent ? (
                                <>
                                  <ButtonChipDelete
                                    style={{ marginRight: 24 }}
                                    label="Elimina"
                                    disabled={!!order.operator?.id}
                                    onClick={async () => {
                                      await loadOrder(order.id);
                                      // setCurrentSalesOrder(order.id);
                                      setShowWarningDialog(true);
                                      // setShowManageSalesOrderForm(true);
                                    }}
                                  />

                                  <ButtonChipUpdate
                                    label="Modifica"
                                    disabled={!!order.operator?.id}
                                    onClick={async () => {
                                      await loadOrder(order.id);
                                      setShowSalesOrderForm(true);
                                    }}
                                  />
                                </>
                              ) : (
                                <ButtonChipHandle
                                  onClick={async () => {
                                    await loadOrder(order.id);
                                    setShowManageSalesOrderForm(true);
                                  }}
                                />
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {salesOrdersData.getSalesOrders.salesOrders.length < 11 && (
                      <>
                        {new Array(
                          10 - salesOrdersData.getSalesOrders.salesOrders.length
                        )
                          .fill(10)
                          .map((_, i) => (
                            <TableRow key={i}>
                              {/* {!isMobile && ( */}
                              <TableCell variant="head" align="left">
                                &nbsp;
                              </TableCell>
                              {/* )} */}
                              <TableCell variant="head" align="left">
                                &nbsp;
                              </TableCell>
                              <TableCell variant="head" align="left">
                                &nbsp;
                              </TableCell>
                              {!isAgent && !isMobile && (
                                <TableCell variant="head" align="left">
                                  &nbsp;
                                </TableCell>
                              )}
                              <TableCell variant="head" align="left">
                                &nbsp;
                              </TableCell>
                            </TableRow>
                          ))}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                labelRowsPerPage="Totale richieste per pagina"
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} di ${count}`
                }
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={
                  salesOrdersData ? salesOrdersData.getSalesOrders.total : 0
                }
                rowsPerPage={limit}
                page={Math.floor(offset / limit)}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </>
        )}

      {showManageSalesOrderForm && currentSalesOrder && productsData && (
        <ManageSalesOrderForm
          products={productsData.getProducts.products}
          open={showManageSalesOrderForm}
          onClose={() => {
            setShowManageSalesOrderForm(false);
          }}
          onConfirm={async (salesOrderClosedId, accepted) => {
            setShowManageSalesOrderForm(false);
            // Visualizza finestra per scaricare ordine
            await refetchSalesOrders({
              where,
              order,
              offset,
              limit,
            });
            // Visualizza finestra di download solo se l'ordine è stato accettato
            console.log(accepted);
            setSalesOrderClosedId(salesOrderClosedId);
            setShowAfterSalesOrderForm(accepted);
          }}
          salesOrder={currentSalesOrder}
        />
      )}

      {showSalesOrderForm && productsData && salesClientsData && userId && (
        <SalesOrderForm
          agentId={userId}
          forceSelection={false}
          products={productsData.getProducts.products}
          open={showSalesOrderForm}
          onClose={() => {
            setShowSalesOrderForm(false);
          }}
          salesClients={salesClientsData.getSalesClients.salesClients}
          onConfirm={async () => {
            setShowSalesOrderForm(false);
            await refetchSalesOrders({
              where,
              order,
              offset,
              limit,
            });
          }}
          salesOrder={currentSalesOrder}
        />
      )}

      <DialogWarning
        title="Attenzione"
        open={showWarningDialog}
        onClose={() => setShowWarningDialog(false)}
        onContinue={() => deleteSalesOrder(currentSalesOrder?.id || null)}
      >
        <Typography variant="body1">
          Questa operazione è irreversibile. Sei sicuro di voler procedere?
        </Typography>
      </DialogWarning>

      <DialogError
        title="Errore"
        open={showErrorDialog}
        onClose={() => setShowErrorDialog(false)}
      >
        <Typography variant="body1" color="error" paragraph={true}>
          {errorMessage}
        </Typography>
        <Typography variant="body1" color="textPrimary">
          Impossibile elimare l'ordine.
        </Typography>
      </DialogError>

      {showAfterSalesOrderForm && salesOrderClosedId && (
        <AfterSalesOrderForm
          open={showAfterSalesOrderForm}
          salesOrderId={salesOrderClosedId}
          onClose={() => {
            setShowAfterSalesOrderForm(false);
          }}
        />
      )}
    </>
  );
};
