import React from "react";
import {
  Dialog,
  DialogActions,
  makeStyles,
  Typography,
  Button,
  Link,
} from "@material-ui/core";
import { Close, GetApp, Forward } from "@material-ui/icons";
import { colors } from "theme";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  closeButton: {
    width: 25,
    height: 25,
    position: "absolute",
    top: 10,
    right: 10,
    cursor: "pointer",
    overflow: "hidden",
  },
  divider: {
    margin: "35px auto 40px",
  },
  actions: {
    marginTop: 0,
    // justifyContent: 'space-between',
    flexDirection: "column",
    // gap: '30px',
    flexWrap: "wrap",
    [theme.breakpoints.up("sm")]: {
      marginTop: 20,
      justifyContent: "flex-end",
      flexDirection: "row",
    },
    "& > *": {
      margin: 10,
      [theme.breakpoints.up("sm")]: {
        margin: 15,
      },
    },
  },
}));

interface AfterSalesOrderFormProps {
  open: boolean;
  onClose: () => void;
  salesOrderId: string;
}

export const AfterSalesOrderForm: React.FC<AfterSalesOrderFormProps> = ({
  open,
  onClose,
  salesOrderId,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      maxWidth="lg"
      fullWidth={true}
      open={open}
      scroll="body"
      className={classes.root}
    >
      <div className={classes.closeButton} onClick={onClose}>
        <Close
          htmlColor={colors.text.primary}
          style={{
            width: 35,
            height: 35,
            top: -6,
            left: -6,
            position: "absolute",
          }}
        />
      </div>

      <Typography
        variant="h5"
        color="textPrimary"
        align="left"
        style={{ marginBottom: "0.75em" }}
      >
        La richiesta è stata convalidata
      </Typography>

      <div style={{ overflow: "hidden" }}>
        <Typography color="textSecondary" style={{ marginBottom: 36 }}>
          Troverai questa richiesta in "
          <Link color="primary" href="/richieste">
            Storico richieste
          </Link>
          "
        </Typography>
      </div>

      <DialogActions className={classes.actions} disableSpacing={true}>
        <Button
          variant="contained"
          color="secondary"
          size="medium"
          startIcon={<GetApp />}
          href={`${process.env.REACT_APP_ENDPOINT}/sales-order-doc/${salesOrderId}`}
        >
          SCARICA ORDINE
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          startIcon={<Forward />}
          onClick={onClose}
        >
          PROCEDI
        </Button>
      </DialogActions>
    </Dialog>
  );
};
