import { gql } from "@apollo/client";

export const PRODUCT_FIELDS = gql`
  fragment ProductFields on Product {
    id
    name
    description
    sku
    unity
    ean
    forSale
    price
    available
  }
`;

export const THERAPY_FIELDS = gql`
  fragment TherapyFields on Therapy {
    id
    name
    therapyEntries {
      quantity
      products {
        ...ProductFields
      }
    }
  }
`;

export const USER_FIELDS = gql`
  fragment UserFields on User {
    id
    name
    lastname
    active
    email
    role
    agentCrm
  }
`;

export const CLIENT_FIELDS = gql`
  fragment ClientFields on Client {
    id
    name
    lastname
    active
    email
    verified
    addresses {
      id
      destinationCode
      label
      addressLine
      zip
      city
      hamlet
      province
      region
      careOf
      notes
    }
    profession
    specialization
    customProfession
    customSpecialization
    notes
    phone
    fiscalCode
    agent {
      id
      name
      lastname
    }
    lastOrder
  }
`;

export const ORDER_FIELDS = gql`
  fragment OrderFields on Order {
    id
    status
    date
    client {
      id
      name
      lastname
      active
      email
      verified
      profession
      specialization
      customProfession
      customSpecialization
      phone
      fiscalCode
      lastOrder
    }
    deliveryNotes
    agent {
      id
      name
      lastname
      email
      agentCrm
    }
    operator {
      id
      name
      lastname
      email
    }
    address {
      id
      destinationCode
      label
      addressLine
      zip
      city
      hamlet
      province
      region
      careOf
      notes
    }
    selectedTherapies {
      therapyId
      productIds
    }
    selectedProducts {
      productId
      quantity
    }
  }
`;

export const ORDER_CLOSED_FIELDS = gql`
  fragment OrderClosedFields on OrderClosed {
    id
    finalStatus
    orderDate
    client {
      id
      name
      lastname
      email
      phone
      fiscalCode
      profession
      specialization
      addressLine
      zip
      city
      hamlet
      province
      region
      careOf
      destinationCode
    }
    agent {
      id
      crmCode
      name
      lastname
      email
    }
    orderNotes
    deliveryNotes
    productUnities {
      id
      name
      boxUnities
      totalUnities
      sku
    }
    selectedProducts {
      id
      quantity
      name
      sku
    }
    selectedTherapies {
      id
      name
      quantity
    }
  }
`;

export const DOCUMENT_FIELDS = gql`
  fragment DocumentFields on MedicalDocument {
    id
    name
    unity
    crmCode
    cover
    available
  }
`;

export const SALES_CLIENT_FIELDS = gql`
  fragment SalesClientFields on SalesClient {
    id
    name
    addressLine
    zip
    city
    hamlet
    province
    sdi
    pec
    iban
    active
    email
    verified
    phone
    vat
    notes
    salesAddresses {
      id
      destinationCode
      name
      addressLine
      zip
      city
      hamlet
      province
      phone
      notes
    }
    agent {
      id
      name
      lastname
    }
    lastOrder
  }
`;

export const SALES_ORDER_FIELDS = gql`
  fragment SalesOrderFields on SalesOrder {
    id
    status
    payment
    date
    discount
    salesClient {
      id
      name
      addressLine
      zip
      city
      hamlet
      province
      notes
      iban
      pec
      sdi
      active
      email
      verified
      phone
      vat
      lastOrder
    }
    deliveryNotes
    agent {
      id
      name
      lastname
      email
      agentCrm
    }
    operator {
      id
      name
      lastname
      email
    }
    salesAddress {
      id
      destinationCode
      name
      addressLine
      zip
      city
      hamlet
      province
      notes
      phone
    }
    selectedSalesProducts {
      productId
      quantitySale
      quantityDiscount
      quantitySample
    }
  }
`;

export const SALES_ORDER_CLOSED_FIELDS = gql`
  fragment SalesOrderClosedFields on SalesOrderClosed {
    id
    finalStatus
    finalPayment
    salesOrderDate
    salesOrderNotes
    deliveryNotes
    discount
    salesClient {
      # id
      name
      email
      destinationCode
      phone
      vat
      iban
      sdi
      pec
      addressLine
      zip
      city
      hamlet
      province
      shippingName
      shippingPhone
      shippingAddressLine
      shippingZip
      shippingCity
      shippingHamlet
      shippingProvince
    }
    agent {
      id
      crmCode
      name
      lastname
      email
    }
    selectedSalesProducts {
      id
      quantitySale
      quantityDiscount
      quantitySample
      name
      sku
      ean
      price
    }
  }
`;