import React, { useState } from "react";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Grid,
  InputAdornment,
  Input,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Hidden,
} from "@material-ui/core";
import { Add as AddIcon, Search as SearchIcon } from "@material-ui/icons";
import { GET_THERAPY, GET_THERAPIES } from "query";
import { DELETE_THERAPY } from "mutation";
import {
  DeleteTherapy,
  DeleteTherapyVariables,
  GetTherapies,
  GetTherapiesVariables,
  GetTherapy,
  GetTherapyVariables,
  TherapyFields,
  TherapyOrderInput,
  TherapyWhereInput,
} from "types/generated/schemaTypes";
import {
  DialogError,
  DialogWarning,
  Breadcrumbs,
  PageTitle,
  TherapyForm,
  FiltersWrapper,
  TherapyItem,
} from "components";
import { formatError } from "utils/errors";
import { Pagination } from "@material-ui/lab";

export default function GestioneTerapie() {
  const client = useApolloClient();
  const [currentTherapy, setCurrentTherapy] = useState<null | TherapyFields>(
    null
  );
  const [searchString, setSearchString] = useState("");
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState<TherapyOrderInput>(
    TherapyOrderInput.NAME_ASC
  );
  const [showTherapyForm, setShowTherapyForm] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const limit = 6;

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setOffset((value - 1) * 5);
    setPage(value);
  };

  const where: TherapyWhereInput = {};

  if (searchString.length > 2) where.searchPattern = searchString;

  const {
    data: getTherapiesData,
    loading: getTherapiesLoading,
    error: getTherapiesError,
    refetch: refetchGetTherapies,
  } = useQuery<GetTherapies, GetTherapiesVariables>(GET_THERAPIES, {
    fetchPolicy: "network-only",
    variables: {
      where,
      order,
      offset,
      limit,
    },
  });

  const [deleteTherapyMutation] = useMutation<
    DeleteTherapy,
    DeleteTherapyVariables
  >(DELETE_THERAPY, {
    refetchQueries: [
      {
        query: GET_THERAPIES,
        variables: {
          where,
          order,
          offset,
          limit,
        },
      },
    ],
    onCompleted: () => {
      setShowWarningDialog(false);
    },
    onError: (err) => {
      setShowWarningDialog(false);
      setErrorMessage(formatError(err));
      setShowErrorDialog(true);
      console.log(err);
    },
  });

  const loadTherapy = async (therapyId: string) => {
    const data = await client.query<GetTherapy, GetTherapyVariables>({
      query: GET_THERAPY,
      variables: { therapyId },
      fetchPolicy: "cache-first",
    });
    setCurrentTherapy(data.data.getTherapy);
  };

  const deleteTherapy = async (therapyId: string | null) => {
    if (therapyId)
      await deleteTherapyMutation({
        variables: {
          therapyId,
        },
      });
  };

  return (
    <>
      <PageTitle title="GESTIONE ASSORTIMENTO PRODOTTI" />
      <Breadcrumbs current="Gestione Assortimento Prodotti" />
      <FiltersWrapper>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item xs={6} md={3}>
            <FormControl fullWidth={true} variant="standard" size="medium">
              <InputLabel id="order-label">Ordina per</InputLabel>
              <Select
                name="order"
                value={order}
                labelId="order-label"
                id="order"
                label="Ordina per"
                onChange={(e) => {
                  setOffset(0);
                  setOrder(e.target.value as TherapyOrderInput);
                }}
              >
                <MenuItem value={TherapyOrderInput.CREATION_DESC}>
                  Data creazione ↓
                </MenuItem>
                <MenuItem value={TherapyOrderInput.CREATION_ASC}>
                  Data creazione ↑
                </MenuItem>
                <MenuItem value={TherapyOrderInput.NAME_DESC}>
                  Nome (Z-A)
                </MenuItem>
                <MenuItem value={TherapyOrderInput.NAME_ASC}>
                  Nome (A-Z)
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControl fullWidth={true} variant="standard" size="medium">
              <InputLabel htmlFor="search">Cerca</InputLabel>
              <Input
                name="searchString"
                id="searchString"
                autoFocus={searchString.length > 2}
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          {/*
          // @ts-ignore */}
          <Hidden mdDown>
            <Grid item md={3}>
              &nbsp;
            </Grid>
          </Hidden>
          <Grid item xs={12} md={3} style={{ textAlign: "right" }}>
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={() => {
                setCurrentTherapy(null);
                setShowTherapyForm(true);
              }}
              startIcon={<AddIcon />}
            >
              Aggiungi
            </Button>
          </Grid>
        </Grid>
      </FiltersWrapper>
      {getTherapiesLoading && <div>Caricamento delle terapia in corso</div>}
      <Grid container spacing={2}>
        {!getTherapiesLoading && !getTherapiesError && getTherapiesData && (
          <>
            {getTherapiesData.getTherapies.therapies.map((therapy) => (
              <Grid item xs={12} md={4} key={therapy.id}>
                <TherapyItem
                  onDelete={async () => {
                    await loadTherapy(therapy.id);
                    setShowWarningDialog(true);
                  }}
                  onUpdate={async () => {
                    await loadTherapy(therapy.id);
                    setShowTherapyForm(true);
                  }}
                  therapy={therapy}
                />
              </Grid>
            ))}
          </>
        )}
      </Grid>
      {getTherapiesData && getTherapiesData.getTherapies.therapies.length > 0 && (
        <div
          style={{
            marginTop: 30,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Pagination
            count={Math.ceil(getTherapiesData.getTherapies.total / 5)}
            // count={1}
            page={page}
            shape="rounded"
            color="primary"
            onChange={handleChangePage}
          />
        </div>
      )}

      <TherapyForm
        open={showTherapyForm}
        onClose={() => {
          setShowTherapyForm(false);
        }}
        onCreate={async () => {
          setShowTherapyForm(false);
          await refetchGetTherapies({
            where,
            order,
            offset,
            limit,
          });
        }}
        onUpdate={async () => {
          setShowTherapyForm(false);
        }}
        therapy={currentTherapy}
      />

      <DialogError
        title="Errore"
        open={showErrorDialog}
        onClose={() => setShowErrorDialog(false)}
      >
        <Typography variant="body1" color="error">
          {errorMessage}
        </Typography>
      </DialogError>

      <DialogWarning
        title="Attenzione"
        open={showWarningDialog}
        onClose={() => setShowWarningDialog(false)}
        onContinue={() => deleteTherapy(currentTherapy?.id || null)}
      >
        <Typography variant="body1">
          Questa operazione è irreversibile. Sei sicuro di voler procedere?
        </Typography>
      </DialogWarning>
    </>
  );
}
