import React from "react";
import { useMutation } from "@apollo/client";
import {
  DocumentFields,
  UpdateDocument,
  CreateDocument,
  CreateDocumentVariables,
  UpdateDocumentVariables,
} from "types/generated/schemaTypes";
import {
  FormControl,
  Grid,
  TextField,
  Typography,
  Divider,
  FormControlLabel,
  FormHelperText,
  Switch,
  makeStyles
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CREATE_DOCUMENT, UPDATE_DOCUMENT } from "mutation";
import { DialogForm, ActionType } from "components";
import { formatError } from "utils/errors";
import { FileUpload } from "./fileUpload";

export interface DocumentFormValues {
  name: string;
  crmCode: string;
  unity: string;
  cover: string;
  available: boolean;
}

const defaultInitialValues = {
  name: "",
  crmCode: "",
  unity: "",
  cover: "",
  available: true,
};

const parseInitialValue = (input: DocumentFields): DocumentFormValues => {
  const values: DocumentFormValues = {
    name: input.name,
    crmCode: input.crmCode,
    cover: input.cover || "",
    unity: input.unity.toString(),
    available: input.available,
  };
  return values;
};

const validationSchema = Yup.object({
  name: Yup.string().required("Campo obbligatorio"),
  crmCode: Yup.string().required("Campo obbligatorio"),
  unity: Yup.string().required("Campo obbligatorio"),
});

const useStyles = makeStyles((theme) => ({
  availableSwitch: {
    "&$checked": {
      color: theme.palette.success.main,
      // color: "yellow",
    },
    "&$checked + $track": {
      backgroundColor: theme.palette.success.main,
    },
  },
  track: {
    backgroundColor: theme.palette.success.main,
  },
  checked: {},
}));


interface DocumentFormProps {
  open: boolean;
  onClose: () => void;
  onCreate: () => void;
  onUpdate: () => void;
  document: DocumentFields | null;
}

export const DocumentForm: React.FC<DocumentFormProps> = ({
  open,
  onClose,
  onCreate,
  onUpdate,
  document,
}) => {
  const [createDocumentMutation, { error: createDocumentError }] = useMutation<
    CreateDocument,
    CreateDocumentVariables
  >(CREATE_DOCUMENT, {
    onCompleted: async (data) => {
      formik.resetForm();
      onCreate();
    },
    onError: (err) => {
      console.log("Errore!", err);
    },
  });
  const [updateDocumentMutation, { error: updateDocumentError }] = useMutation<
    UpdateDocument,
    UpdateDocumentVariables
  >(UPDATE_DOCUMENT, {
    onCompleted: async (data) => {
      formik.resetForm();
      onUpdate();
    },
    onError: (err) => {
      console.log("Errore!", err);
    },
  });
  const classes = useStyles();

  const update = !!document;
  const formik = useFormik<DocumentFormValues>({
    initialValues: document
      ? parseInitialValue(document as DocumentFields)
      : defaultInitialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      let variables: CreateDocumentVariables | UpdateDocumentVariables;
      if (!update) {
        // create
        variables = {
          medicalDocumentData: {
            name: values.name,
            crmCode: values.crmCode,
            unity: parseInt(values.unity),
            cover: values.cover || null,
            available: values.available,
          },
        } as CreateDocumentVariables;
        await createDocumentMutation({ variables });
      } else {
        // update
        variables = {
          medicalDocumentData: {
            name: values.name,
            crmCode: values.crmCode,
            unity: parseInt(values.unity),
            cover: values.cover || null,
            available: values.available,
          },
          medicalDocumentId: (document as DocumentFields).id,
        } as UpdateDocumentVariables;
        await updateDocumentMutation({ variables });
      }
    },
  });

  return (
    <DialogForm
      open={open}
      title={
        update
          ? "Modifica un documento informativo"
          : "Aggiungi un documento informativo"
      }
      onClose={() => {
        formik.resetForm();
        onClose();
      }}
      actions={[
        {
          type: ActionType.EXIT,
          label: "CHIUDI SENZA SALVARE",
          callback: () => {
            formik.resetForm();
            onClose();
          },
          disabled: formik.isSubmitting,
        },
        {
          type: ActionType.SAVE,
          label: "SALVA",
          callback: formik.submitForm,
          disabled: formik.isSubmitting,
        },
      ]}
    >
      <div style={{ overflow: "hidden" }}>
        <Typography color="textSecondary" style={{ marginBottom: 36 }}>
          Inserisci tutte le informazioni richieste e salva per aggiungere un
          nuovo documento come materiale informativo.
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            {/* NAME */}
            <FormControl fullWidth={true}>
              <TextField
                name="name"
                error={formik.touched.name && !!formik.errors.name}
                helperText={
                  formik.touched.name &&
                  !!formik.errors.name &&
                  formik.errors.name
                }
                variant="standard"
                id="name"
                size="small"
                label="Descrizione*"
                value={formik.values.name}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* SKU */}
            <FormControl fullWidth={true}>
              <TextField
                name="crmCode"
                error={formik.touched.crmCode && !!formik.errors.crmCode}
                helperText={
                  formik.touched.crmCode &&
                  !!formik.errors.crmCode &&
                  formik.errors.crmCode
                }
                variant="standard"
                id="crmCode"
                size="small"
                label="Codice documento gestionale*"
                value={formik.values.crmCode}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* UNITY */}
            <FormControl fullWidth={true}>
              <TextField
                name="unity"
                error={formik.touched.unity && !!formik.errors.unity}
                helperText={
                  formik.touched.unity &&
                  !!formik.errors.unity &&
                  formik.errors.unity
                }
                variant="standard"
                id="unity"
                size="small"
                label="Unità per confezione*"
                value={formik.values.unity}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
                type="number"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FileUpload
              id="cover"
              onChange={(filename) => {
                formik.setFieldValue("cover", filename);
              }}
              label="Carica l'immagine del documento"
              labelSmall="Cover"
              url={formik.values.cover}
            />
            {formik.touched.cover && !!formik.errors.cover && (
              <FormHelperText error={true} variant="standard">
                {formik.errors.cover}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid item xs={12} md={6}>
            <div style={{ paddingLeft: 16 }}>
              <FormControlLabel
                control={
                  <Switch
                    classes={{
                      switchBase: classes.availableSwitch,
                      checked: classes.checked,
                      track: classes.track,
                    }}
                    checked={formik.values.available}
                    onChange={() => {
                      formik.setFieldValue(
                        "available",
                        !formik.values.available
                      );
                    }}
                    disableRipple
                    disableTouchRipple
                    disabled={formik.isSubmitting}
                    name="available"
                  />
                }
                label={
                  formik.values.available
                    ? "Materiale disponibile"
                    : "Materiale non disponibile"
                }
              />
              {formik.touched.available && !!formik.errors.available && (
                <FormHelperText error={true} variant="standard">
                  {formik.errors.available}
                </FormHelperText>
              )}
            </div>
          </Grid>
        </Grid>

        <Typography
          color="textSecondary"
          variant="caption"
          component="p"
          style={{ marginBottom: 30, marginTop: 15 }}
        >
          * I campi contrassegnati con l’asterisco sono obbligatori
        </Typography>
        <Divider />

        {(createDocumentError || updateDocumentError) && (
          <Typography color="error" variant="body1">
            {createDocumentError && formatError(createDocumentError)}
            {updateDocumentError && formatError(updateDocumentError)}
          </Typography>
        )}
      </div>
    </DialogForm>
  );
};
