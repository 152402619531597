import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import {
  UpdatePassword,
  UpdatePasswordVariables,
} from "types/generated/schemaTypes";
import {
  FormControl,
  Grid,
  Typography,
  Divider,
  InputLabel,
  InputAdornment,
  IconButton,
  Input,
  FormHelperText,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { UPDATE_PASSWORD } from "mutation";
import { DialogForm, ActionType } from "components";
import { formatError } from "utils/errors";

export interface PasswordFormValues {
  password: string;
  passwordCheck: string;
}

const defaultInitialValues = {
  password: "",
  passwordCheck: "",
};

const validationSchema = Yup.object({
  password: Yup.string()
    .required("Campo obbligatorio")
    .min(8, "La password deve essere lunga almeno 8 caratteri"),
  passwordCheck: Yup.string()
    .test("equal", "Le password non combaciano", function (v) {
      const ref = Yup.ref("password");
      return v === this.resolve(ref);
    })
    .required("Campo obbligatorio"),
});

interface PasswordFormProps {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
  userId: string;
}

export const PasswordForm: React.FC<PasswordFormProps> = ({
  open,
  onClose,
  onUpdate,
  userId,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordCheck, setShowPasswordCheck] = useState(false);
  const [updatePasswordMutation, { error: updatePasswordError }] = useMutation<
    UpdatePassword,
    UpdatePasswordVariables
  >(UPDATE_PASSWORD, {
    onCompleted: async (data) => {
      formik.resetForm();
      onUpdate();
    },
    onError: (err) => {
      console.log("Errore!", err);
    },
  });

  const formik = useFormik<PasswordFormValues>({
    initialValues: defaultInitialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const variables: UpdatePasswordVariables = {
        password: formik.values.password,
        userId,
      };
      await updatePasswordMutation({ variables });
    },
  });

  return (
    <DialogForm
      open={open}
      title="Modifica Password"
      onClose={() => {
        formik.resetForm();
        onClose();
      }}
      actions={[
        {
          type: ActionType.EXIT,
          label: "CHIUDI SENZA SALVARE",
          callback: () => {
            formik.resetForm();
            onClose();
          },
          disabled: formik.isSubmitting,
        },
        {
          type: ActionType.SAVE,
          label: "SALVA E CHIUDI",
          callback: formik.submitForm,
          disabled: formik.isSubmitting,
        },
      ]}
    >
      <div style={{ overflow: "hidden" }}>
        <Typography color="textSecondary" style={{ marginBottom: 36 }}>
          Per salvare le modifiche cliccare sul pulsante “salva e chiudi”.
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth={true} variant="standard">
              <InputLabel
                htmlFor="password"
                error={formik.touched.password && !!formik.errors.password}
              >
                Password*
              </InputLabel>
              <Input
                name="password"
                error={formik.touched.password && !!formik.errors.password}
                id="password"
                value={formik.values.password}
                onChange={(e) =>
                  formik.setFieldValue("password", e.target.value.trim())
                }
                disabled={formik.isSubmitting}
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText
                error={formik.touched.password && !!formik.errors.password}
                variant="standard"
              >
                {formik.touched.password &&
                  !!formik.errors.password &&
                  formik.errors.password}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth={true} variant="standard">
              <InputLabel
                htmlFor="passwordCheck"
                error={
                  formik.touched.passwordCheck && !!formik.errors.passwordCheck
                }
              >
                Verifica Password*
              </InputLabel>
              <Input
                name="passwordCheck"
                error={
                  formik.touched.passwordCheck && !!formik.errors.passwordCheck
                }
                id="passwordCheck"
                value={formik.values.passwordCheck}
                onChange={(e) =>
                  formik.setFieldValue("passwordCheck", e.target.value.trim())
                }
                disabled={formik.isSubmitting}
                type={showPasswordCheck ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle passwordCheck visibility"
                      onClick={() => setShowPasswordCheck(!showPasswordCheck)}
                      edge="end"
                    >
                      {showPasswordCheck ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText
                error={
                  formik.touched.passwordCheck && !!formik.errors.passwordCheck
                }
                variant="standard"
              >
                {formik.touched.passwordCheck &&
                  !!formik.errors.passwordCheck &&
                  formik.errors.passwordCheck}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <Divider style={{ marginTop: 20, marginBottom: 10 }} />

        {updatePasswordError && (
          <Typography color="error" variant="body1">
            {updatePasswordError && formatError(updatePasswordError)}
          </Typography>
        )}
      </div>
    </DialogForm>
  );
};
