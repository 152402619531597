import React from "react";
import { Chip, ChipProps } from "@material-ui/core";

interface ButtonChipDeleteProps {
  onClick: () => void;
  label: string;
}

export const ButtonChipDelete: React.FC<ButtonChipDeleteProps & ChipProps> = ({
  onClick,
  label,
  ...props
}) => {
  return (
    <Chip
      {...props}
      clickable={true}
      variant="outlined"
      size="small"
      color="secondary"
      label={label}
      onDelete={onClick}
      onClick={onClick}
    />
  );
};
