import { SvgIcon, SvgIconProps } from "@material-ui/core";

export function HourglassHalfFullIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 -2 14 24" {...props}>
      <defs>
        <path
          d="M6,2 L6,8 L6.01,8 L6,8.01 L10,12 L6,16 L6.01,16.01 L6,16.01 L6,22 L18,22 L18,16.01 L17.99,16.01 L18,16 L14,12 L18,8.01 L17.99,8 L18,8 L18,2 L6,2 Z M16,16.5 L16,18 L8,18 L8,16.5 L12,12.5 L16,16.5 Z M12,17 L12,18 L11,18 L11,17 L12,17 Z M13,16 L13,17 L12,17 L12,16 L13,16 Z M12,15 L12,16 L11,16 L11,15 L12,15 Z M13,14 L13,15 L12,15 L12,14 L13,14 Z M12,13 L12,14 L11,14 L11,13 L12,13 Z M16,4 L16,7.5 L15.5,8 L15,8 L15,8.5 L14.5,9 L14,9 L14,9.5 L12,11.5 L11.5,11 L12,11 L12,10 L11,10 L11,10.5 L10.5,10 L11,10 L11,9 L10,9 L10,9.5 L9.5,9 L10,9 L10,8 L9,8 L9,8.5 L8.5,8 L9,8 L9,7 L8,7 L8,4 L16,4 Z M13,9 L12,9 L12,10 L13,10 L13,9 Z M12,8 L11,8 L11,9 L12,9 L12,8 Z M14,8 L13,8 L13,9 L14,9 L14,8 Z M15,7 L14,7 L14,8 L15,8 L15,7 Z M13,7 L12,7 L12,8 L13,8 L13,7 Z M11,7 L10,7 L10,8 L11,8 L11,7 Z"
          id="path-1"
        ></path>
      </defs>
      <g strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Ordini---1" transform="translate(-783.000000, -399.000000)">
          <g id="Tabella" transform="translate(310.000000, 331.000000)">
            <g id="Lista" transform="translate(0.000000, 20.000000)">
              <g id="Riga-1" transform="translate(0.000000, 32.000000)">
                <g transform="translate(467.000000, 14.000000)">
                  <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-1"></use>
                  </mask>
                  <use
                    id="icon/action/hourglass_empty_24px"
                    fill="#2196F3"
                    xlinkHref="#path-1"
                  ></use>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
