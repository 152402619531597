import React from "react";
import {
  Paper,
  Typography,
  makeStyles,
  Divider,
  useTheme,
} from "@material-ui/core";
import { DocumentFields } from "types/generated/schemaTypes";
import { ButtonChipDelete } from "./buttonChipDelete";
import { ButtonChipUpdate } from "./buttonChipUpdate";
import { CounterTool } from "./counterTool";
import { DoneOutline } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 15,
    overflow: "hidden",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  coverWrapper: {
    textAlign: "center",
  },
  cover: {
    border: `solid 1px rgba(0,0,0,0.26)`,
  },
  title: {
    backgroundColor: theme.palette.primary.dark,
    color: "#ffffff",
    marginLeft: -theme.spacing(1),
    marginTop: -theme.spacing(1),
    width: `calc(100% + ${theme.spacing(2)}px)`,
    fontWeight: 700,
    textAlign: "center",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  entry: {
    padding: "8px 0",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  contentWrapper: {
    "& > *": {
      width: "100%",
    },
    display: "flex",
    flex: 1,
    width: "100%",
    flexWrap: "wrap",
    alignContent: "space-between",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(1),
  },
}));

interface DocumentItemProps {
  onUpdate: () => void;
  onDelete: () => void;
  onIncrement: () => void;
  onDecrement: () => void;
  isAgent: boolean;
  count: number;
  document: DocumentFields;
  shrink: boolean;
}

export const DocumentItem: React.FC<DocumentItemProps> = ({
  onDelete,
  onUpdate,
  document,
  onIncrement,
  onDecrement,
  isAgent,
  count,
  shrink,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Paper className={classes.root}>
      {!shrink && (
        <div className={classes.coverWrapper}>
          <img
            className={classes.cover}
            src={document.cover || ""}
            height={320}
            width="auto"
            alt={document.name}
          />
        </div>
      )}
      <Typography
        variant="body1"
        component="h3"
        color="primary"
        style={{ fontWeight: 700 }}
      >
        {document.crmCode}
      </Typography>
      <Typography
        variant="body1"
        component="h2"
        color="inherit"
        style={{ fontWeight: 700 }}
      >
        {document.name}
      </Typography>
      <Typography variant="body1" component="h2" color="inherit">
        {document.unity} pezzi per pacco
      </Typography>
      <Divider className={classes.divider} />
      <div className={classes.contentWrapper}>
        <div className={classes.buttonWrapper}>
          {!isAgent && (
            <>
              <ButtonChipDelete label="Elimina" onClick={onDelete} />
              <ButtonChipUpdate label="Modifica" onClick={onUpdate} />
            </>
          )}
          {isAgent && (
            <>
              <DoneOutline
                style={{ width: 35, height: 35 }}
                htmlColor={
                  count > 0 ? theme.palette.success.main : "transparent"
                }
              />
              <CounterTool
                onIncrement={onIncrement}
                onDecrement={onDecrement}
                count={count}
              />
            </>
          )}
        </div>
      </div>
    </Paper>
  );
};
