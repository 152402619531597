import React, { useState } from "react";
import {
  useApolloClient,
  useMutation,
  useQuery,
  useReactiveVar,
} from "@apollo/client";
import {
  Button,
  Grid,
  InputAdornment,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  useTheme,
  TextField,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import {
  Add as AddIcon,
  GetApp,
  Search as SearchIcon,
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import {
  GET_SALES_CLIENT,
  GET_SALES_CLIENTS,
  GET_PRODUCTS,
  GET_USERS,
} from "query";
import { UPDATE_SALES_CLIENT } from "mutation";
import {
  GetSalesClient,
  GetSalesClientVariables,
  GetSalesClientsVariables,
  GetSalesClients,
  SalesClientOrderInput,
  SalesClientWhereInput,
  GetUsers,
  GetUsersVariables,
  SalesClientFields,
  UserOrderInput,
  UpdateSalesClient,
  UpdateSalesClientVariables,
  GetProducts,
  GetProductsVariables,
} from "types/generated/schemaTypes";
import {
  SalesClientForm,
  FiltersWrapper,
  ButtonChipUpdate,
  ButtonChipDelete,
  DeleteSalesClientForm,
  ButtonChipOrder,
  SalesOrderForm,
} from "components";
import {
  AssignmentLate as NotVerifiedIcon,
  CheckCircle as HasAgentIcon,
  ReportProblem as HasNoAgentIcon,
  HowToReg as ActiveIcon,
} from "@material-ui/icons";
import { userRoleVar, userIdVar } from "localState";
import { UserRole } from "types/generated/schemaTypes";

const useStyles = makeStyles((theme) => ({
  noBorderTextField: {
    // backgroundColor: "yellow",
    "& .MuiInput-underline:before": {
      display: "none",
    },
  },
}));

type Status = "ALL" | "VERIFIED" | "NOT_VERIFIED";

export const ActiveSalesClients: React.FC = () => {
  const client = useApolloClient();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const role = useReactiveVar(userRoleVar);
  const userId = useReactiveVar(userIdVar);
  const isAgent = role === UserRole.AGENT;
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [currentSalesClient, setCurrentSalesClient] =
    useState<null | SalesClientFields>(null);
  const [searchString, setSearchString] = useState("");
  const [offset, setOffset] = useState(0);
  const [status, setStatus] = useState<Status>("ALL");
  const [filterAgent, setFilterAgent] = useState<string>("ALL");
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState<SalesClientOrderInput>(
    SalesClientOrderInput.NAME_ASC
  );
  const [showSalesClientForm, setShowSalesClientForm] = useState(false);
  const [showOrderForm, setShowOrderForm] = useState(false);
  const classes = useStyles();
  const {
    data: agentsData,
    loading: agentsLoading,
    error: agentsError,
  } = useQuery<GetUsers, GetUsersVariables>(GET_USERS, {
    fetchPolicy: "network-only",
    variables: {
      where: { active: true, role: UserRole.AGENT },
      order: UserOrderInput.LASTNAME_ASC,
      offset: 0,
      limit: 100,
    },
  });

  const [updateSalesClientMutation] = useMutation<
    UpdateSalesClient,
    UpdateSalesClientVariables
  >(UPDATE_SALES_CLIENT);

  const agentsFilterOptions = [
    { label: "Tutti gli agenti", id: "ALL" },
    { label: "Con agente", id: "ASSIGNED" },
    { label: "Senza agente", id: "NOT_ASSIGNED" },
  ];

  const agentsAssignmentOptions = [{ label: "-", id: "" }];

  if (agentsData?.getUsers.users)
    agentsData?.getUsers.users.forEach((agent) => {
      agentsFilterOptions.push({
        label: `${agent.name} ${agent.lastname}`,
        id: agent.id,
      });

      agentsAssignmentOptions.push({
        label: `${agent.name} ${agent.lastname}`,
        id: agent.id,
      });
    });

  const where: SalesClientWhereInput = {
    active: true,
  };

  if (status !== "ALL") where.verified = status === "VERIFIED";

  if (role === UserRole.AGENT) {
    where.agentId = userId;
  } else {
    if (filterAgent !== "ALL") {
      if (filterAgent === "NOT_ASSIGNED") {
        where.notAssigned = true;
      } else if (filterAgent === "ASSIGNED") {
        where.notAssigned = false;
      } else {
        where.agentId = filterAgent;
      }
    }
  }

  if (searchString.length > 2) where.searchPattern = searchString;

  const {
    data: salesClientsData,
    loading: clientsLoading,
    error: clientsError,
    refetch: refetchClients,
  } = useQuery<GetSalesClients, GetSalesClientsVariables>(GET_SALES_CLIENTS, {
    fetchPolicy: "network-only",
    variables: {
      where,
      order,
      offset,
      limit,
    },
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setOffset(newPage * limit);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLimit(parseInt(event.target.value, 10));
    setOffset(0);
  };

  const loadClient = async (salesClientId: string) => {
    const data = await client.query<GetSalesClient, GetSalesClientVariables>({
      query: GET_SALES_CLIENT,
      variables: { salesClientId },
      fetchPolicy: "cache-first",
    });
    setCurrentSalesClient(data.data.getSalesClient);
  };

  const { data: productsData } = useQuery<GetProducts, GetProductsVariables>(
    GET_PRODUCTS,
    {
      fetchPolicy: "cache-first",
      variables: {
        where: { archived: false, forSale: true },
        offset: 0,
        limit: 100,
      },
    }
  );

  return (
    <>
      <FiltersWrapper>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item xs={6} md={isAgent ? 3 : 2}>
            <FormControl fullWidth={true} variant="standard" size="medium">
              <InputLabel id="order-label">Ordina per</InputLabel>
              <Select
                name="order"
                value={order}
                labelId="order-label"
                id="order"
                label="Ordina per"
                onChange={(e) => {
                  setOffset(0);
                  setOrder(e.target.value as SalesClientOrderInput);
                }}
              >
                <MenuItem value={SalesClientOrderInput.CREATION_DESC}>
                  Data creazione ↓
                </MenuItem>
                <MenuItem value={SalesClientOrderInput.CREATION_ASC}>
                  Data creazione ↑
                </MenuItem>
                <MenuItem value={SalesClientOrderInput.NAME_DESC}>
                  Ragione sociale (Z-A)
                </MenuItem>
                <MenuItem value={SalesClientOrderInput.NAME_ASC}>
                  Ragione sociale (A-Z)
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {!isAgent && (
            <>
              <Grid item xs={6} md={2}>
                <FormControl fullWidth={true} variant="standard" size="medium">
                  <InputLabel id="order-label">Visualizza</InputLabel>
                  <Select
                    name="status"
                    value={status}
                    labelId="status-label"
                    id="status"
                    label="Visualizza"
                    onChange={(e) => {
                      setOffset(0);
                      setStatus(e.target.value as Status);
                    }}
                  >
                    <MenuItem value="ALL">Tutti i clienti</MenuItem>
                    <MenuItem value="VERIFIED">
                      Solo clienti verificati
                    </MenuItem>
                    <MenuItem value="NOT_VERIFIED">
                      Solo clienti non verificati
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6} md={2}>
                <FormControl fullWidth={true}>
                  <Autocomplete
                    options={agentsFilterOptions}
                    value={
                      agentsFilterOptions.find(
                        (agent) => agent.id === filterAgent
                      ) || null
                    }
                    getOptionSelected={(option, test) => {
                      return test.id === option.id;
                    }}
                    onChange={(event, newValue) => {
                      setFilterAgent(newValue?.id || "ALL");
                    }}
                    getOptionLabel={(option) => option.label}
                    // style={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Filtra per agente"
                        variant="standard"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </>
          )}
          <Grid item xs={6} md={3}>
            <FormControl fullWidth={true} variant="standard" size="medium">
              <InputLabel htmlFor="search">Cerca</InputLabel>
              <Input
                name="searchString"
                id="searchString"
                autoFocus={searchString.length > 2}
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            md={isAgent ? 6 : 3}
            style={{ textAlign: "right" }}
          >
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={() => {
                setCurrentSalesClient(null);
                setShowSalesClientForm(true);
              }}
              startIcon={<AddIcon />}
            >
              Aggiungi
            </Button>
          </Grid>
        </Grid>
      </FiltersWrapper>

   
      <div
        style={{
          // padding: "16px 10px 8px",
          // backgroundColor: "#FAFAFA",
          margin: "0 auto 15px",
          [theme.breakpoints.up("md")]: {
            // padding: "16px 16px 8px",
            margin: "0 auto 20px",
          },
        }}
      >
        <Button
          color="primary"
          variant="contained"
          size="small"
          href={`${process.env.REACT_APP_ENDPOINT}/active-sales-clients?userId=${userId}`}
          endIcon={<GetApp />}
        >
          CSV clienti attivi
        </Button>
      </div>
 

      {clientsLoading && <div>Caricamento dei clienti in corso</div>}
      {!clientsLoading &&
        !agentsLoading &&
        !agentsError &&
        !clientsError &&
        salesClientsData &&
        agentsData && (
          <>
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell variant="head" align="left">
                        STATO
                      </TableCell>
                      <TableCell variant="head" align="left">
                        NOME
                      </TableCell>
                      <TableCell variant="head" align="left">
                        P.I/C.F.
                      </TableCell>
                      {!isAgent && (
                        <TableCell
                          variant="head"
                          align="left"
                          style={{ minWidth: 190 }}
                        >
                          AGENTE DI RIFERIMENTO
                        </TableCell>
                      )}
                      {isAgent && (
                        <TableCell variant="head" align="left">
                          CONTATTI
                        </TableCell>
                      )}
                      <TableCell
                        variant="head"
                        align="left"
                        style={{ width: 190 }}
                      >
                        AZIONI
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {salesClientsData.getSalesClients.salesClients.map(
                      (client) => {
                        return (
                          <TableRow key={client.id}>
                            <TableCell align="left">
                              <Typography
                                component="span"
                                variant="body1"
                                color={client.active ? "textPrimary" : "error"}
                              >
                                {client.verified ? (
                                  <ActiveIcon
                                    htmlColor={theme.palette.success.main}
                                  />
                                ) : (
                                  <NotVerifiedIcon
                                    htmlColor={theme.palette.warning.main}
                                  />
                                )}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography
                                component="span"
                                variant="body1"
                                style={{
                                  color: client.verified
                                    ? theme.palette.text.primary
                                    : theme.palette.warning.main,
                                }}
                              >
                                {client.name}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography
                                component="span"
                                variant="body1"
                                style={{
                                  color: client.verified
                                    ? theme.palette.text.primary
                                    : theme.palette.warning.main,
                                }}
                              >
                                {client.vat}
                              </Typography>
                            </TableCell>

                            {isAgent && (
                              <TableCell align="left">
                                <Typography
                                  component="span"
                                  variant="body1"
                                  style={{
                                    color: client.verified
                                      ? theme.palette.text.primary
                                      : theme.palette.warning.main,
                                  }}
                                >
                                  <a
                                    href={`mailto:${client.email}`}
                                    style={{
                                      color: "inherit",
                                      textDecoration: "none",
                                    }}
                                  >
                                    {client.email}
                                  </a>
                                  {isMobile ? <br /> : " - "}
                                  <a
                                    href={`tel:${client.phone}`}
                                    style={{
                                      color: "inherit",
                                      textDecoration: "none",
                                    }}
                                  >
                                    {client.phone}
                                  </a>
                                </Typography>
                              </TableCell>
                            )}

                            {!isAgent && (
                              <TableCell
                                variant="head"
                                align="left"
                                style={{ width: 250 }}
                              >
                                <div style={{ display: "flex" }}>
                                  {client.agent ? (
                                    <HasAgentIcon
                                      style={{ marginRight: 16 }}
                                      htmlColor={theme.palette.success.main}
                                    />
                                  ) : (
                                    <HasNoAgentIcon
                                      style={{ marginRight: 16 }}
                                      htmlColor={theme.palette.error.main}
                                    />
                                  )}

                                  <FormControl fullWidth={true}>
                                    <Autocomplete
                                      options={agentsAssignmentOptions}
                                      value={
                                        agentsAssignmentOptions.find(
                                          (agent) => {
                                            return (
                                              agent.id === client.agent?.id
                                            );
                                          }
                                        ) || { label: "-", id: "" }
                                      }
                                      getOptionSelected={(option, test) => {
                                        return test.id === option.id;
                                      }}
                                      onChange={async (_, newValue) => {
                                        await updateSalesClientMutation({
                                          variables: {
                                            salesClientId: client.id,
                                            salesClientData: {
                                              agentId: newValue?.id || null,
                                            },
                                          },
                                        });
                                      }}
                                      getOptionLabel={(option) => option.label}
                                      renderInput={(params) => (
                                        <TextField
                                          className={classes.noBorderTextField}
                                          {...params}
                                          label=""
                                          variant="standard"
                                        />
                                      )}
                                    />
                                  </FormControl>
                                </div>
                              </TableCell>
                            )}
                            <TableCell align="left">
                              <div style={{ display: "flex" }}>
                                <ButtonChipDelete
                                  style={{ marginRight: 16 }}
                                  onClick={async () => {
                                    await loadClient(client.id);
                                    setShowDeleteForm(true);
                                    await refetchClients({
                                      where,
                                      order,
                                      offset,
                                      limit,
                                    });
                                  }}
                                  label="Elimina"
                                />
                                <ButtonChipUpdate
                                  onClick={async () => {
                                    await loadClient(client.id);
                                    setShowSalesClientForm(true);
                                  }}
                                  style={{ marginRight: isAgent ? 16 : 0 }}
                                  label="Modifica"
                                />
                                {isAgent && (
                                  <ButtonChipOrder
                                    onClick={async () => {
                                      await loadClient(client.id);
                                      setShowOrderForm(true);
                                    }}
                                    label="Richiesta"
                                  />
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                    {salesClientsData.getSalesClients.salesClients.length <
                      11 && (
                      <>
                        {new Array(
                          10 -
                            salesClientsData.getSalesClients.salesClients.length
                        )
                          .fill(10)
                          .map((_, index) => (
                            <TableRow key={index}>
                              <TableCell variant="head" align="left">
                                &nbsp;
                              </TableCell>
                              <TableCell variant="head" align="left">
                                &nbsp;
                              </TableCell>
                              <TableCell variant="head" align="left">
                                &nbsp;
                              </TableCell>

                              <TableCell variant="head" align="left">
                                &nbsp;
                              </TableCell>

                              <TableCell variant="head" align="left">
                                &nbsp;
                              </TableCell>
                            </TableRow>
                          ))}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                labelRowsPerPage="Totale clienti per pagina"
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} di ${count}`
                }
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={
                  salesClientsData ? salesClientsData.getSalesClients.total : 0
                }
                rowsPerPage={limit}
                page={Math.floor(offset / limit)}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </>
        )}

      {showSalesClientForm && (
        <SalesClientForm
          open={showSalesClientForm}
          onClose={() => {
            setShowSalesClientForm(false);
          }}
          onCreate={async () => {
            setShowSalesClientForm(false);
            await refetchClients({
              where,
              order,
              offset,
              limit,
            });
          }}
          onUpdate={async () => {
            setShowSalesClientForm(false);
          }}
          salesClient={currentSalesClient}
          agentId={role === UserRole.AGENT && userId ? userId : undefined}
        />
      )}

      {currentSalesClient?.id && showDeleteForm && (
        <DeleteSalesClientForm
          salesClientId={currentSalesClient.id}
          onClose={() => {
            setShowDeleteForm(false);
          }}
          onDelete={() => {
            setShowDeleteForm(false);
          }}
          open={showDeleteForm}
        />
      )}

      {showOrderForm && productsData && currentSalesClient && userId && (
        <SalesOrderForm
          agentId={userId}
          products={productsData.getProducts.products}
          open={showOrderForm}
          onClose={() => {
            setShowOrderForm(false);
          }}
          salesClients={[currentSalesClient]}
          onConfirm={async () => {
            setShowOrderForm(false);
          }}
          salesOrder={null}
          forceSelection={true}
        />
      )}
    </>
  );
};
