import { ProductFields, SalesOrderClosedFields_selectedSalesProducts } from "types/generated/schemaTypes";

export const getProductNameByProductId = (
  productId: string,
  products: ProductFields[]
) => {
  return products.find((product) => product.id === productId)?.name;
};

export const formatProductPrice = (price: number): string => {
  let temp = price.toString();
  if (temp.length === 2) {
    return `0.${temp}€`;
  }
  if (temp.length === 1) {
    return `0.0${temp}€`;
  }
  const formattedPrice = `${temp.substring(
    0,
    temp.length - 2
  )}.${temp.substring(temp.length - 2)}€`;
  return formattedPrice;
};

export const formatSalesProductByProductId = (
  productId: string,
  products: ProductFields[]
) => {
  const product = products.find((product) => product.id === productId);
  if (!product) return null;
  return (
    <>
      {product?.name} (EAN: {product.ean}) {formatProductPrice(product?.price)}
    </>
  );
};

export const formatSalesProductByProductDetails = (
  product: ProductFields | SalesOrderClosedFields_selectedSalesProducts,
  showPrice: boolean
) => {
  return (
    <>
      {product?.name}<br/>(EAN: {product.ean}) {showPrice && formatProductPrice(product?.price)}
    </>
  );
};

export const getProductDetailsByProductId = (
  productId: string,
  products: ProductFields[]
) => {
  const product = products.find((product) => product.id === productId);
  return product;
};
