import React from "react";
import { Chip, ChipProps } from "@material-ui/core";
import { Refresh as RefreshIcon } from "@material-ui/icons";

interface ButtonChipResumeProps {
  onClick: () => void;
}

export const ButtonChipResume: React.FC<ButtonChipResumeProps & ChipProps> = ({
  onClick,
  ...props
}) => {
  return (
    <Chip
      {...props}
      clickable={true}
      variant="default"
      size="small"
      color="primary"
      deleteIcon={<RefreshIcon />}
      label="Ripristina"
      onDelete={onClick}
      onClick={onClick}
    />
  );
};
