import React from "react";
import { useMutation } from "@apollo/client";
import {
  UpdateUser,
  UpdateUserVariables,
  Me_me,
} from "types/generated/schemaTypes";
import {
  FormControl,
  Grid,
  TextField,
  Typography,
  Divider,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { UPDATE_USER } from "mutation";
import { DialogForm, ActionType } from "components";
import { formatError } from "utils/errors";

export interface ProfileFormValues {
  name: string;
  lastname: string;
  email: string;
}

const validationSchema = Yup.object({
  name: Yup.string().required("Campo obbligatorio"),
  lastname: Yup.string().required("Campo obbligatorio"),
  email: Yup.string()
    .email("Formato mail non valido")
    .required("Campo obbligatorio"),
});

interface ProfileFormProps {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
  data: Me_me;
}

export const ProfileForm: React.FC<ProfileFormProps> = ({
  open,
  onClose,
  onUpdate,
  data,
}) => {
  const [updateProfileMutation, { error: updateProfileError }] = useMutation<
    UpdateUser,
    UpdateUserVariables
  >(UPDATE_USER, {
    onCompleted: async (data) => {
      formik.resetForm();
      onUpdate();
    },
    onError: (err) => {
      console.log("Errore!", err);
    },
  });

  const formik = useFormik<ProfileFormValues>({
    initialValues: {
      name: data.name,
      lastname: data.lastname,
      email: data.email,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const variables: UpdateUserVariables = {
        userData: {
          name: values.name,
          lastname: values.lastname,
          email: values.email,
        },
        userId: data.id,
      };
      await updateProfileMutation({ variables });
    },
  });

  return (
    <DialogForm
      open={open}
      title="Modifica Profilo"
      onClose={() => {
        formik.resetForm();
        onClose();
      }}
      actions={[
        {
          type: ActionType.EXIT,
          label: "CHIUDI SENZA SALVARE",
          callback: () => {
            formik.resetForm();
            onClose();
          },
          disabled: formik.isSubmitting,
        },
        {
          type: ActionType.SAVE,
          label: "SALVA E CHIUDI",
          callback: formik.submitForm,
          disabled: formik.isSubmitting,
        },
      ]}
    >
      <div style={{ overflow: "hidden" }}>
        <Typography color="textSecondary" style={{ marginBottom: 36 }}>
          Di seguito le informazioni relative al suo profilo. Per salvare le
          modifiche cliccare sul pulsante “Salva e chiudi” in fondo alla pagina.
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            {/* NAME */}
            <FormControl fullWidth={true}>
              <TextField
                name="name"
                error={formik.touched.name && !!formik.errors.name}
                helperText={
                  formik.touched.name &&
                  !!formik.errors.name &&
                  formik.errors.name
                }
                variant="standard"
                id="name"
                size="small"
                label="Nome*"
                value={formik.values.name}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* LASTNAME */}
            <FormControl fullWidth={true}>
              <TextField
                name="lastname"
                error={formik.touched.lastname && !!formik.errors.lastname}
                helperText={
                  formik.touched.lastname &&
                  !!formik.errors.lastname &&
                  formik.errors.lastname
                }
                variant="standard"
                id="lastname"
                size="small"
                label="Cognome*"
                value={formik.values.lastname}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* EMAIL */}
            <FormControl fullWidth={true}>
              <TextField
                name="email"
                error={formik.touched.email && !!formik.errors.email}
                helperText={
                  formik.touched.email &&
                  !!formik.errors.email &&
                  formik.errors.email
                }
                variant="standard"
                id="email"
                size="small"
                label="Email*"
                value={formik.values.email}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Divider style={{ marginTop: 20, marginBottom: 10 }} />

        {updateProfileError && (
          <Typography color="error" variant="body1">
            {updateProfileError && formatError(updateProfileError)}
          </Typography>
        )}
      </div>
    </DialogForm>
  );
};
