import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  RouteProps,
} from "react-router-dom";
import {
  Agents,
  AgentsHome,
  Clients,
  SalesClients,
  Documents,
  Home,
  Operators,
  Orders,
  Products,
  ProductsSamples,
  Profile,
  Therapies,
  Maintenance,
  SalesOrders,
  Settings,
} from "./routes";

import { PageLayout } from "./components";
import {
  GetMaintenanceMode,
  MaintenanceMode,
  UserRole,
} from "./types/generated/schemaTypes";
import { useQuery, useReactiveVar, useSubscription } from "@apollo/client";
import { isLoggedInVar, maintenanceModeVar, userRoleVar } from "localState";
import { MAINTENANCE_MODE } from "subscription";
import { GET_MAINTENANCE_MODE } from "query";
import type { NavigationMenuType } from "components/verticalNav";

interface ProtectedRouteProps extends RouteProps {
  roleAccess?: UserRole[];
  role: UserRole;
  isLoggedIn?: boolean;
  menu?: NavigationMenuType;
}

const ProtectedRoute: React.FC<ProtectedRouteProps & { comp: JSX.Element }> = ({
  isLoggedIn = false,
  roleAccess = [],
  role,
  menu = "multi",
  ...props
}) => {
  if (isLoggedIn) {
    if (roleAccess) {
      if (!roleAccess.includes(role)) {
        return <Route component={() => <Redirect to={{ pathname: "/" }} />} />;
      }
    }
    const C = () => props.comp || <>no comp</>;
    return <Route render={() => <PageLayout children={<C />} menu={menu} />} />;
  } else {
    const renderComponent = () => <Redirect to={{ pathname: "/login" }} />;
    return <Route {...props} component={renderComponent} render={undefined} />;
  }
};

const RouteManager: React.FC = () => {
  const role = useReactiveVar(userRoleVar);
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const maintenanceOn = useReactiveVar(maintenanceModeVar);

  useQuery<GetMaintenanceMode>(GET_MAINTENANCE_MODE, {
    onCompleted: (data) => {
      localStorage.setItem(
        "bga_maintenanceMode",
        data.getMaintenanceMode.toString()
      );
      maintenanceModeVar(data.getMaintenanceMode);
    },
    skip: !isLoggedIn,
  });

  useSubscription<MaintenanceMode>(MAINTENANCE_MODE, {
    onSubscriptionData: (data) => {
      maintenanceModeVar(data.subscriptionData.data?.maintenanceMode);
    },
  });

  return (
    <Router>
      {isLoggedIn ? (
        <>
          {role === UserRole.AGENT && maintenanceOn ? (
            <Switch>
              <Route exact path="/manutenzione">
                <PageLayout children={<Maintenance />} menu="hidden" />
              </Route>
              <Redirect from="/" to="/manutenzione" />
            </Switch>
          ) : (
            <>
              <Switch>
                <ProtectedRoute
                  isLoggedIn={true}
                  roleAccess={[UserRole.ADMIN]}
                  path="/gestione-operatori"
                  role={role}
                  comp={<Operators />}
                />
                <ProtectedRoute
                  isLoggedIn={true}
                  roleAccess={[UserRole.OPERATOR, UserRole.ADMIN]}
                  path="/richieste-campionature"
                  role={role}
                  comp={<Orders />}
                />
                <ProtectedRoute
                  isLoggedIn={true}
                  roleAccess={[UserRole.OPERATOR, UserRole.ADMIN]}
                  path="/ordini"
                  role={role}
                  comp={<SalesOrders />}
                />

                <ProtectedRoute
                  isLoggedIn={true}
                  roleAccess={[UserRole.OPERATOR, UserRole.ADMIN]}
                  role={role}
                  path="/gestione-agenti"
                  comp={<Agents />}
                />
                <ProtectedRoute
                  isLoggedIn={true}
                  roleAccess={[UserRole.OPERATOR, UserRole.ADMIN]}
                  role={role}
                  path="/clienti"
                  comp={<Clients />}
                />
                <ProtectedRoute
                  isLoggedIn={true}
                  roleAccess={[UserRole.OPERATOR, UserRole.ADMIN]}
                  role={role}
                  path="/clienti-vendite"
                  comp={<SalesClients />}
                />
                <ProtectedRoute
                  isLoggedIn={true}
                  roleAccess={[UserRole.OPERATOR, UserRole.ADMIN]}
                  role={role}
                  path="/prodotti"
                  comp={<Products />}
                />
                <ProtectedRoute
                  isLoggedIn={true}
                  roleAccess={[UserRole.OPERATOR, UserRole.ADMIN]}
                  role={role}
                  path="/assortimento-prodotti"
                  comp={<Therapies />}
                />
                <ProtectedRoute
                  isLoggedIn={true}
                  roleAccess={[UserRole.OPERATOR, UserRole.ADMIN]}
                  role={role}
                  path="/materiale-informativo"
                  comp={<Documents />}
                />
                <ProtectedRoute
                  isLoggedIn={true}
                  roleAccess={[UserRole.OPERATOR, UserRole.ADMIN]}
                  exact={true}
                  role={role}
                  path="/profilo"
                  comp={<Profile />}
                />
                {/**
                 * Agenti: Area campionature
                 */}
                <ProtectedRoute
                  isLoggedIn={true}
                  roleAccess={[UserRole.AGENT]}
                  menu="agenti-campionature"
                  path="/area-campionature/richieste"
                  role={role}
                  comp={<Orders />}
                />
                <ProtectedRoute
                  isLoggedIn={true}
                  roleAccess={[UserRole.AGENT]}
                  menu="agenti-campionature"
                  role={role}
                  path="/area-campionature/clienti"
                  comp={<Clients />}
                />
                <ProtectedRoute
                  isLoggedIn={true}
                  roleAccess={[UserRole.AGENT]}
                  menu="agenti-campionature"
                  role={role}
                  path="/area-campionature/materiale-informativo"
                  comp={<Documents />}
                />
                <ProtectedRoute
                  isLoggedIn={true}
                  roleAccess={[UserRole.AGENT]}
                  menu="agenti-campionature"
                  role={role}
                  path="/area-campionature/richiesta-prodotti"
                  comp={<ProductsSamples />}
                />
                <ProtectedRoute
                  isLoggedIn={true}
                  roleAccess={[UserRole.AGENT]}
                  menu="agenti-campionature"
                  role={role}
                  exact={true}
                  path="/area-campionature/profilo"
                  comp={<Profile />}
                />
                {/**
                 * Agenti: Area vendite
                 */}
                <ProtectedRoute
                  isLoggedIn={true}
                  roleAccess={[UserRole.AGENT]}
                  menu="agenti-vendite"
                  path="/area-vendite/richieste"
                  role={role}
                  comp={<SalesOrders />}
                />
                <ProtectedRoute
                  isLoggedIn={true}
                  roleAccess={[UserRole.AGENT]}
                  menu="agenti-vendite"
                  role={role}
                  path="/area-vendite/clienti"
                  comp={<SalesClients />}
                />
                <ProtectedRoute
                  isLoggedIn={true}
                  roleAccess={[UserRole.AGENT]}
                  menu="agenti-vendite"
                  role={role}
                  exact={true}
                  path="/area-vendite/profilo"
                  comp={<Profile />}
                />

                <ProtectedRoute
                  isLoggedIn={true}
                  roleAccess={[UserRole.OPERATOR, UserRole.ADMIN]}
                  role={role}
                  path="/settings"
                  comp={<Settings />}
                />
                {/**
                 * Pagina snodo agenti: vendite vs campionature
                 */}
                <ProtectedRoute
                  isLoggedIn={true}
                  roleAccess={[UserRole.AGENT]}
                  role={role}
                  path="/agenti"
                  comp={<AgentsHome />}
                />
                <Redirect
                  from="/"
                  to={role === UserRole.AGENT ? "/agenti" : "/richieste-campionature"}
                />
              </Switch>
            </>
          )}
        </>
      ) : (
        <Switch>
          <Route path="/reset-password" component={Home}></Route>
          <Route path="/login" component={Home}></Route>
          <Redirect from="/" to="/login" />
        </Switch>
      )}
    </Router>
  );
};

export default RouteManager;
