import React from "react";
import { useMutation } from "@apollo/client";
import {
  DeactivateClient,
  DeactivateClientVariables,
} from "types/generated/schemaTypes";
import { FormControl, Grid, TextField, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DEACTIVATE_CLIENT } from "mutation";
import { DialogForm, ActionType } from "components";
import { formatError } from "utils/errors";

export interface DeleteClientFormValues {
  notes: string;
}

const validationSchema = Yup.object({});

interface DeleteClientFormProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  clientId: string;
}

export const DeleteClientForm: React.FC<DeleteClientFormProps> = ({
  open,
  onDelete,
  onClose,
  clientId,
}) => {
  const [deleteClientMutation, { error: deleteClientError }] = useMutation<
    DeactivateClient,
    DeactivateClientVariables
  >(DEACTIVATE_CLIENT, {
    onCompleted: async (data) => {
      formik.resetForm();
      onDelete();
    },
    onError: (err) => {
      console.log("Errore!", err);
    },
  });

  const formik = useFormik<DeleteClientFormValues>({
    initialValues: {
      notes: "",
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const variables = {
        clientId,
        notes: values.notes || null,
      };
      await deleteClientMutation({ variables });
    },
  });

  return (
    <DialogForm
      open={open}
      title="Elimina cliente"
      onClose={() => {
        formik.resetForm();
        onClose();
      }}
      actions={[
        {
          type: ActionType.EXIT,
          label: "ANNULLA ELIMINAZIONE",
          callback: () => {
            formik.resetForm();
            onClose();
          },
          disabled: formik.isSubmitting,
        },
        {
          type: ActionType.SAVE,
          label: "ELIMINA",
          callback: formik.submitForm,
          disabled: formik.isSubmitting,
        },
      ]}
    >
      <div style={{ overflow: "hidden" }}>
        <Typography
          color="textSecondary"
          style={{ marginBottom: 36 }}
        ></Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12}>
            {/* NOTES */}
            <FormControl fullWidth={true}>
              <TextField
                name="notes"
                multiline={true}
                rows={6}
                error={formik.touched.notes && !!formik.errors.notes}
                helperText={
                  formik.touched.notes &&
                  !!formik.errors.notes &&
                  formik.errors.notes
                }
                variant="outlined"
                id="notes"
                label="Note"
                value={formik.values.notes}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
        </Grid>
        {deleteClientError && (
          <Typography color="error" variant="body1">
            {deleteClientError && formatError(deleteClientError)}
          </Typography>
        )}
      </div>
    </DialogForm>
  );
};
