import { gql } from "@apollo/client";
import {
  CLIENT_FIELDS,
  ORDER_FIELDS,
  ORDER_CLOSED_FIELDS,
  SALES_ORDER_FIELDS,
  SALES_ORDER_CLOSED_FIELDS,
  PRODUCT_FIELDS,
  SALES_CLIENT_FIELDS,
  USER_FIELDS,
  DOCUMENT_FIELDS,
} from "fragment";

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
    }
  }
`;

export const CREATE_PRODUCT = gql`
  ${PRODUCT_FIELDS}
  mutation CreateProduct($productData: CreateProductInput!) {
    createProduct(productData: $productData) {
      ...ProductFields
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  ${PRODUCT_FIELDS}
  mutation UpdateProduct($productId: ID!, $productData: UpdateProductInput!) {
    updateProduct(productId: $productId, productData: $productData) {
      ...ProductFields
    }
  }
`;

export const CREATE_THERAPY = gql`
  ${PRODUCT_FIELDS}
  mutation CreateTherapy($therapyData: CreateTherapyInput!) {
    createTherapy(therapyData: $therapyData) {
      id
      name
      therapyEntries {
        quantity
        products {
          ...ProductFields
        }
      }
    }
  }
`;

export const UPDATE_THERAPY = gql`
  ${PRODUCT_FIELDS}
  mutation UpdateTherapy($therapyId: ID!, $therapyData: UpdateTherapyInput!) {
    updateTherapy(therapyId: $therapyId, therapyData: $therapyData) {
      id
      name
      therapyEntries {
        quantity
        products {
          ...ProductFields
        }
      }
    }
  }
`;

export const DELETE_THERAPY = gql`
  mutation DeleteTherapy($therapyId: ID!) {
    deleteTherapy(therapyId: $therapyId) {
      message
    }
  }
`;

export const CREATE_USER = gql`
  ${USER_FIELDS}
  mutation CreateUser($userData: CreateUserInput!) {
    createUser(userData: $userData) {
      ...UserFields
    }
  }
`;

export const UPDATE_USER = gql`
  ${USER_FIELDS}
  mutation UpdateUser($userId: ID!, $userData: UpdateUserInput!) {
    updateUser(userId: $userId, userData: $userData) {
      ...UserFields
    }
  }
`;

export const SET_USER_STATUS = gql`
  ${USER_FIELDS}
  mutation SetUserStatus($userId: ID!, $active: Boolean!) {
    setUserStatus(userId: $userId, active: $active) {
      ...UserFields
    }
  }
`;

export const CREATE_CLIENT = gql`
  ${CLIENT_FIELDS}
  mutation CreateClient($clientData: CreateClientInput!) {
    createClient(clientData: $clientData) {
      ...ClientFields
    }
  }
`;

export const UPDATE_CLIENT = gql`
  ${CLIENT_FIELDS}
  mutation UpdateClient($clientId: ID!, $clientData: UpdateClientInput!) {
    updateClient(clientId: $clientId, clientData: $clientData) {
      ...ClientFields
    }
  }
`;

export const ACTIVATE_CLIENT = gql`
  ${CLIENT_FIELDS}
  mutation ActivateClient($clientId: ID!) {
    activateClient(clientId: $clientId) {
      ...ClientFields
    }
  }
`;

export const DEACTIVATE_CLIENT = gql`
  ${CLIENT_FIELDS}
  mutation DeactivateClient($clientId: ID!, $notes: String) {
    deactivateClient(clientId: $clientId, notes: $notes) {
      ...ClientFields
    }
  }
`;

export const CREATE_ORDER = gql`
  ${ORDER_FIELDS}
  mutation CreateOrder($orderData: CreateOrderInput!) {
    createOrder(orderData: $orderData) {
      ...OrderFields
    }
  }
`;

export const UPDATE_ORDER = gql`
  ${ORDER_FIELDS}
  mutation UpdateOrder($orderId: ID!, $orderData: UpdateOrderInput!) {
    updateOrder(orderId: $orderId, orderData: $orderData) {
      ...OrderFields
    }
  }
`;

export const CLOSE_ORDER = gql`
  ${ORDER_CLOSED_FIELDS}
  mutation CloseOrder(
    $orderId: ID!
    $finalStatus: OrderFinalStatus!
    $notes: String
  ) {
    closeOrder(orderId: $orderId, finalStatus: $finalStatus, notes: $notes) {
      ...OrderClosedFields
    }
  }
`;

export const DELETE_ORDER = gql`
  mutation DeleteOrder($orderId: ID!) {
    deleteOrder(orderId: $orderId) {
      message
    }
  }
`;

export const CREATE_DOCUMENT = gql`
  mutation CreateDocument($medicalDocumentData: CreateMedicalDocumentInput!) {
    createMedicalDocument(medicalDocumentData: $medicalDocumentData) {
      id
      name
      unity
      crmCode
      cover
    }
  }
`;

export const UPDATE_DOCUMENT = gql`
  ${DOCUMENT_FIELDS}
  mutation UpdateDocument(
    $medicalDocumentId: ID!
    $medicalDocumentData: UpdateMedicalDocumentInput!
  ) {
    updateMedicalDocument(
      medicalDocumentId: $medicalDocumentId
      medicalDocumentData: $medicalDocumentData
    ) {
      ...DocumentFields
    }
  }
`;

export const DELETE_DOCUMENT = gql`
  mutation DeleteDocument($medicalDocumentId: ID!) {
    deleteMedicalDocument(medicalDocumentId: $medicalDocumentId) {
      message
    }
  }
`;

export const DELETE_FILE = gql`
  mutation deleteFile($url: String!) {
    deleteFile(url: $url) {
      message
    }
  }
`;

export const REQUEST_DOCUMENTS_SAMPLES = gql`
  mutation RequestDocumentsSamples(
    $documentsSamples: [RequestDocumentsSamplesInput!]!
    $notes: String
  ) {
    requestDocumentsSamples(
      documentsSamples: $documentsSamples
      notes: $notes
    ) {
      message
    }
  }
`;

export const REQUEST_PRODUCTS_SAMPLES = gql`
  mutation RequestProductsSamples(
    $productsSamples: [RequestProductsSamplesInput!]!
    $notes: String
  ) {
    requestProductsSamples(productsSamples: $productsSamples, notes: $notes) {
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword(
    $resetToken: String!
    $password: String!
    $confirmPassword: String!
  ) {
    resetPassword(
      resetToken: $resetToken
      password: $password
      confirmPassword: $confirmPassword
    ) {
      message
    }
  }
`;

export const REQUEST_RESET_PASSWORD = gql`
  mutation RequestResetPassword($email: String!) {
    requestResetPassword(email: $email) {
      message
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($userId: ID!, $password: String!) {
    updatePassword(userId: $userId, password: $password) {
      message
    }
  }
`;

export const SET_MAINTENANCE_MODE = gql`
  mutation SetMaintenanceMode($active: Boolean!) {
    setMaintenanceMode(active: $active)
  }
`;

export const SET_SETTINGS = gql`
  mutation SetSettings($id: String!, $value: String!) {
    setSettings(id: $id, value: $value) {
      id
      value
    }
  }
`;

export const CREATE_SALES_CLIENT = gql`
  ${SALES_CLIENT_FIELDS}
  mutation CreateSalesClient($salesClientData: CreateSalesClientInput!) {
    createSalesClient(salesClientData: $salesClientData) {
      ...SalesClientFields
    }
  }
`;

export const UPDATE_SALES_CLIENT = gql`
  ${SALES_CLIENT_FIELDS}
  mutation UpdateSalesClient($salesClientId: ID!, $salesClientData: UpdateSalesClientInput!) {
    updateSalesClient(salesClientId: $salesClientId, salesClientData: $salesClientData) {
      ...SalesClientFields
    }
  }
`;

export const ACTIVATE_SALES_CLIENT = gql`
  ${SALES_CLIENT_FIELDS}
  mutation ActivateSalesClient($salesClientId: ID!) {
    activateSalesClient(salesClientId: $salesClientId) {
      ...SalesClientFields
    }
  }
`;

export const DEACTIVATE_SALES_CLIENT = gql`
  ${SALES_CLIENT_FIELDS}
  mutation DeactivateSalesClient($salesClientId: ID!, $notes: String) {
    deactivateSalesClient(salesClientId: $salesClientId, notes: $notes) {
      ...SalesClientFields
    }
  }
`;

export const CREATE_SALES_ORDER = gql`
  ${SALES_ORDER_FIELDS}
  mutation CreateSalesOrder($salesOrderData: CreateSalesOrderInput!) {
    createSalesOrder(salesOrderData: $salesOrderData) {
      ...SalesOrderFields
    }
  }
`;

export const UPDATE_SALES_ORDER = gql`
  ${SALES_ORDER_FIELDS}
  mutation UpdateSalesOrder($salesOrderId: ID!, $salesOrderData: UpdateSalesOrderInput!) {
    updateSalesOrder(salesOrderId: $salesOrderId, salesOrderData: $salesOrderData) {
      ...SalesOrderFields
    }
  }
`;

export const CLOSE_SALES_ORDER = gql`
  ${SALES_ORDER_CLOSED_FIELDS}
  mutation CloseSalesOrder(
    $salesOrderId: ID!
    $finalStatus: SalesOrderFinalStatus!
    $notes: String
    $discount: Int!
  ) {
    closeSalesOrder(salesOrderId: $salesOrderId, finalStatus: $finalStatus, notes: $notes, discount: $discount) {
      ...SalesOrderClosedFields
    }
  }
`;

export const DELETE_SALES_ORDER = gql`
  mutation DeleteSalesOrder($salesOrderId: ID!) {
    deleteSalesOrder(salesOrderId: $salesOrderId) {
      message
    }
  }
`;