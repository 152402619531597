import React, { useEffect, useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import {
  DialogInfo,
  LoginForm,
  RequestPasswordForm,
  ResetPasswordForm,
} from "../components";
import { useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    backgroundImage: 'url("home-background.png")',
    backgroundSize: "cover",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: 15,
    [theme.breakpoints.up("md")]: {
      // marginTop: 80,
      // overflow: "initial",
    },
  },
}));

export default function Home() {
  const classes = useStyles();
  const query = useQuery();
  const token = query.get("token");
  const [showRequestPasswordForm, setShowRequestPasswordForm] = useState(false);
  const [showRequestPasswordDone, setShowRequestPasswordDone] = useState(false);
  const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);
  const [showResetPasswordDone, setShowResetPasswordDone] = useState(false);
  const [showResetPasswordError, setShowResetPasswordError] = useState(false);

  useEffect(() => {
    if (token) setShowResetPasswordForm(true);
  }, [token]);

  return (
    <div className={classes.root}>
      <div>
        <div
          style={{
            marginBottom: 15,
          }}
        >
          <img
            src="/logo-small.png"
            style={{
              width: 267,
              height: 45,
            }}
            alt="Bromatech"
          />
        </div>
        <LoginForm onResetPassword={() => setShowRequestPasswordForm(true)} />
        <RequestPasswordForm
          open={showRequestPasswordForm}
          onClose={() => {
            setShowRequestPasswordForm(false);
          }}
          onSuccess={() => {
            setShowRequestPasswordForm(false);
            setShowRequestPasswordDone(true);
          }}
        />

        <ResetPasswordForm
          token={token || ""}
          open={showResetPasswordForm}
          onClose={() => {
            setShowResetPasswordForm(false);
          }}
          onSuccess={() => {
            setShowResetPasswordForm(false);
            setShowResetPasswordDone(true);
          }}
          onError={() => {
            setShowResetPasswordForm(false);
            setShowResetPasswordError(true);
          }}
        />
        <DialogInfo
          open={showResetPasswordError}
          title="Errore"
          onClose={() => setShowResetPasswordError(false)}
        >
          <Typography variant="body1">
            Si è verificato un errore durante il reset della password. Il link è
            scaduto.
          </Typography>
        </DialogInfo>
        <DialogInfo
          open={showResetPasswordDone}
          title="Password resettata"
          onClose={() => setShowResetPasswordDone(false)}
        >
          <Typography variant="body1">
            La password è stata reimpostata correttamente. Può loggarsi già da
            ora con le nuove credenziali.
          </Typography>
        </DialogInfo>
        <DialogInfo
          open={showRequestPasswordDone}
          title="Grazie per la sua richiesta"
          onClose={() => setShowRequestPasswordDone(false)}
        >
          <Typography variant="body1">
            Abbiamo ricevuto la sua richiesta. Se l'indirizzo email è valido
            riceverà un link per resettare la password nella casella mail da lei
            indicata.
          </Typography>
        </DialogInfo>
      </div>
    </div>
  );
}
