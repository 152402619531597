import React, { useState } from "react";
import { useApolloClient, useQuery, useReactiveVar } from "@apollo/client";
import {
  Grid,
  InputAdornment,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  useTheme,
  TextField,
  useMediaQuery,
} from "@material-ui/core";

import {
  Search as SearchIcon,
  CheckCircle as CheckCircleIcon,
  ReportProblem as ReportProblemIcon,
  RemoveCircle as RemoveCircleIcon,
  CalendarToday,
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { GET_ORDER_CLOSED, GET_ORDERS_CLOSED, GET_USERS } from "query";
import {
  GetOrderClosed,
  GetOrderClosedVariables,
  GetOrdersClosedVariables,
  GetOrdersClosed,
  OrderClosedOrderInput,
  OrderClosedWhereInput,
  GetUsers,
  GetUsersVariables,
  UserOrderInput,
  OrderClosedFields,
  OrderFinalStatus,
} from "types/generated/schemaTypes";
import { OrderDetail, FiltersWrapper } from "components";
import { userRoleVar, userIdVar } from "localState";
import { UserRole } from "types/generated/schemaTypes";
import { formatDate } from "utils/date";
import { ButtonChipDownload } from "./buttonChipDownload";
import { ButtonChipInfo } from "./buttonChipInfo";
import { KeyboardDatePicker } from "@material-ui/pickers";

function isValidDate(d: any) {
  return d instanceof Date && d.toString() !== 'Invalid Date'
}

export const ProcessedOrders: React.FC = () => {
  const client = useApolloClient();
  const theme = useTheme();
  const role = useReactiveVar(userRoleVar);
  const userId = useReactiveVar(userIdVar);
  const [currentOrder, setCurrentOrder] = useState<null | OrderClosedFields>(
    null
  );
  const [searchString, setSearchString] = useState("");
  const [offset, setOffset] = useState(0);
  const [showOrderDetail, setShowOrderDetail] = useState(false);
  const isAgent = role === UserRole.AGENT;
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState<OrderClosedOrderInput>(
    OrderClosedOrderInput.CREATION_DESC
  );
  const [agentFilter, setAgentFilter] = useState<string>("ALL");
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [fromDate, setFromDate] = useState<Date | null | undefined>(null);
  const [toDate, setToDate] = useState<Date | null | undefined>(null);
  const {
    data: operatorsData,
    loading: operatorsLoading,
    error: operatorsError,
  } = useQuery<GetUsers, GetUsersVariables>(GET_USERS, {
    fetchPolicy: "network-only",
    variables: {
      where: { active: true, role: UserRole.OPERATOR },
      order: UserOrderInput.LASTNAME_ASC,
      offset: 0,
      limit: 100,
    },
    skip: isAgent,
  });

  const where: OrderClosedWhereInput = {};

  if (role === UserRole.AGENT) {
    where.agentId = userId;
  }

  if (searchString.length > 2) where.searchPattern = searchString;

  if ( fromDate && isValidDate(fromDate) ) {
    where.startDate = fromDate.toISOString();
    console.log(fromDate.toISOString());
  }
  if ( toDate && isValidDate(toDate) ) {
    where.endDate = toDate.toISOString();
    console.log(toDate.toISOString());
  }

  const {
    data: agentsData,
    // loading: agentsLoading,
    // error: agentsError,
  } = useQuery<GetUsers, GetUsersVariables>(GET_USERS, {
    fetchPolicy: "network-only",
    variables: {
      where: { active: true, role: UserRole.AGENT },
      order: UserOrderInput.LASTNAME_ASC,
      offset: 0,
      limit: 100,
    },
    skip: isAgent,
  });
  const agentFilterOptions = [{ label: "Tutti le richieste", id: "ALL" }];
  if (agentsData?.getUsers.users)
    agentsData?.getUsers.users.forEach((operator) => {
      agentFilterOptions.push({
        label: `${operator.name} ${operator.lastname}`,
        id: operator.id,
      });
    });

  if (isAgent) where.agentId = userId;

  if (!isAgent && agentFilter !== "ALL") {
    where.agentId = agentFilter;
  }

  const {
    data: ordersData,
    loading: ordersLoading,
    error: ordersError,
  } = useQuery<GetOrdersClosed, GetOrdersClosedVariables>(GET_ORDERS_CLOSED, {
    fetchPolicy: "network-only",
    variables: {
      where,
      order,
      offset,
      limit,
    },
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setOffset(newPage * limit);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLimit(parseInt(event.target.value, 10));
    setOffset(0);
  };

  const loadOrder = async (orderId: string) => {
    const data = await client.query<GetOrderClosed, GetOrderClosedVariables>({
      query: GET_ORDER_CLOSED,
      variables: { orderId },
      fetchPolicy: "cache-first",
    });
    setCurrentOrder(data.data.getOrderClosed);
  };

  return (
    <>
      <FiltersWrapper>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item xs={6} md={3}>
            <FormControl fullWidth={true} variant="standard" size="medium">
              <InputLabel id="order-label">Ordina per</InputLabel>
              <Select
                name="order"
                value={order}
                labelId="order-label"
                id="order"
                label="Ordina per"
                onChange={(e) => {
                  setOffset(0);
                  setOrder(e.target.value as OrderClosedOrderInput);
                }}
              >
                <MenuItem value={OrderClosedOrderInput.CREATION_DESC}>
                  Data creazione ↓
                </MenuItem>
                <MenuItem value={OrderClosedOrderInput.CREATION_ASC}>
                  Data creazione ↑
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {!isAgent && (
            <Grid item xs={6} md={3}>
              <FormControl fullWidth={true}>
                <Autocomplete
                  options={agentFilterOptions}
                  value={
                    agentFilterOptions.find(
                      (agent) => agent.id === agentFilter
                    ) || null
                  }
                  getOptionSelected={(option, test) => {
                    return test.id === option.id;
                  }}
                  onChange={(event, newValue) => {
                    setAgentFilter(newValue?.id || "ALL");
                  }}
                  getOptionLabel={(option) => option.label}
                  // style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Visualizza"
                      variant="standard"
                    />
                  )}
                />
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12} md={3}>
            <FormControl fullWidth={true} variant="standard" size="medium">
              <InputLabel htmlFor="search">Cerca...</InputLabel>
              <Input
                name="searchString"
                id="searchString"
                autoFocus={searchString.length > 2}
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item xs={12} md={3}>
            <FormControl fullWidth={true}>
              <KeyboardDatePicker
                size="small"
                keyboardIcon={<CalendarToday />}
                autoOk
                variant="inline"
                inputVariant="outlined"
                label={fromDate ? "Data iniziale" : null}
                format="dd/MM/yyyy"
                placeholder="Data iniziale"
                invalidDateMessage={"Formato non valido"}
                name="dates"
                value={fromDate}
                InputAdornmentProps={{ position: "end" }}
                onChange={(date) => setFromDate(date as Date)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth={true}>
              <KeyboardDatePicker
                size="small"
                keyboardIcon={<CalendarToday />}
                autoOk
                variant="inline"
                inputVariant="outlined"
                label={toDate ? "Data finale" : null}
                format="dd/MM/yyyy"
                placeholder="Data finale"
                invalidDateMessage={"Formato non valido"}
                name="dates"
                value={toDate}
                InputAdornmentProps={{ position: "end" }}
                onChange={(date) => setToDate(date as Date)}
              />
            </FormControl>
          </Grid>
        </Grid>
      </FiltersWrapper>
      {ordersLoading && <div>Caricamento delle richieste in corso</div>}
      {!ordersLoading &&
        !ordersError &&
        !operatorsLoading &&
        !operatorsError &&
        ordersData &&
        (isAgent || operatorsData) && (
          <>
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {[UserRole.OPERATOR, UserRole.ADMIN].includes(role) && (
                        <TableCell variant="head" align="left">
                          {isMobile ? "" : "STATO"}
                        </TableCell>
                      )}
                      <TableCell variant="head" align="left">
                        DATA
                      </TableCell>
                      {[UserRole.OPERATOR, UserRole.ADMIN].includes(role) &&
                        !isMobile && (
                          <TableCell variant="head" align="left">
                            AGENTE
                          </TableCell>
                        )}
                      <TableCell
                        variant="head"
                        align="left"
                        style={{ minWidth: 160 }}
                      >
                        CLIENTE
                      </TableCell>
                      {role === UserRole.AGENT && (
                        <TableCell variant="head" align="left">
                          STATO
                        </TableCell>
                      )}
                      <TableCell
                        variant="head"
                        align="left"
                        style={{ width: isAgent ? 100 : 385 }}
                      >
                        AZIONI
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ordersData.getOrdersClosed.orders.map((order) => {
                      return (
                        <TableRow key={order.id}>
                          {[UserRole.OPERATOR, UserRole.ADMIN].includes(
                            role
                          ) && (
                            <TableCell align="left">
                              <Typography
                                component="span"
                                variant="body1"
                                color="textPrimary"
                              >
                                {order.finalStatus ===
                                OrderFinalStatus.APPROVED ? (
                                  <CheckCircleIcon
                                    style={{ marginRight: 16 }}
                                    htmlColor={theme.palette.success.main}
                                  />
                                ) : (
                                  <ReportProblemIcon
                                    style={{ marginRight: 16 }}
                                    htmlColor={theme.palette.error.main}
                                  />
                                )}
                              </Typography>
                            </TableCell>
                          )}

                          <TableCell align="left">
                            <Typography
                              component="span"
                              variant="body1"
                              color="textPrimary"
                            >
                              {formatDate(order.orderDate)}
                            </Typography>
                          </TableCell>

                          {[UserRole.OPERATOR, UserRole.ADMIN].includes(role) &&
                            !isMobile && (
                              <TableCell align="left">
                                <Typography
                                  component="span"
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  {order.agent.name} {order.agent.lastname}
                                </Typography>
                              </TableCell>
                            )}
                          <TableCell align="left">
                            <Typography
                              component="span"
                              variant="body1"
                              color="textSecondary"
                            >
                              {order.client.name} {order.client.lastname}
                            </Typography>
                          </TableCell>
                          {[UserRole.AGENT].includes(role) && (
                            <TableCell align="left">
                              <Typography
                                component="span"
                                variant="body1"
                                color={
                                  order.finalStatus ===
                                  OrderFinalStatus.APPROVED
                                    ? "textPrimary"
                                    : "error"
                                }
                                style={{
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                {order.finalStatus ===
                                OrderFinalStatus.APPROVED ? (
                                  <>
                                    <CheckCircleIcon
                                      style={{ marginRight: 16 }}
                                      htmlColor={theme.palette.success.main}
                                    />
                                    Richiesta presa in carico
                                  </>
                                ) : (
                                  <>
                                    <RemoveCircleIcon
                                      style={{ marginRight: 16 }}
                                      htmlColor={theme.palette.error.main}
                                    />
                                    Richiesta non presa in carico
                                  </>
                                )}
                              </Typography>
                            </TableCell>
                          )}

                          <TableCell align="left">
                            <div style={{ display: "flex" }}>
                              {[UserRole.ADMIN, UserRole.OPERATOR].includes(
                                role
                              ) && (
                                <>
                                  <ButtonChipDownload
                                    onClick={async () => {
                                      window.location.href = `${process.env.REACT_APP_ENDPOINT}/order-doc/${order.id}`;
                                    }}
                                    label={
                                      isMobile
                                        ? "Richiesta"
                                        : "Scarica Richiesta"
                                    }
                                    style={{ marginRight: 16 }}
                                  />
                                  <ButtonChipDownload
                                    onClick={async () => {
                                      window.location.href = `${process.env.REACT_APP_ENDPOINT}/order-csv/${order.id}`;
                                    }}
                                    label={isMobile ? "CSV" : "Scarica CSV"}
                                    style={{ marginRight: 16 }}
                                  />
                                </>
                              )}

                              <ButtonChipInfo
                                onClick={async () => {
                                  await loadOrder(order.id);
                                  setShowOrderDetail(true);
                                }}
                                label="Dettaglio"
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {ordersData.getOrdersClosed.orders.length < 11 && (
                      <>
                        {new Array(
                          10 - ordersData.getOrdersClosed.orders.length
                        )
                          .fill(10)
                          .map(() => (
                            <TableRow>
                              {[UserRole.OPERATOR, UserRole.ADMIN].includes(
                                role
                              ) &&
                                !isMobile && (
                                  <TableCell variant="head" align="left">
                                    &nbsp;
                                  </TableCell>
                                )}
                              {!isMobile && (
                                <TableCell variant="head" align="left">
                                  &nbsp;
                                </TableCell>
                              )}
                              <TableCell variant="head" align="left">
                                &nbsp;
                              </TableCell>
                              <TableCell variant="head" align="left">
                                &nbsp;
                              </TableCell>
                              <TableCell variant="head" align="left">
                                &nbsp;
                              </TableCell>
                            </TableRow>
                          ))}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                labelRowsPerPage="Totale richieste per pagina"
                labelDisplayedRows={({ from, to, count }) =>
                  `Richieste ${from}-${to} di ${count}`
                }
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={ordersData ? ordersData.getOrdersClosed.total : 0}
                rowsPerPage={limit}
                page={Math.floor(offset / limit)}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </>
        )}

      {showOrderDetail && currentOrder && (
        <OrderDetail
          isAgent={role === UserRole.AGENT}
          open={showOrderDetail}
          onClose={() => {
            setShowOrderDetail(false);
          }}
          order={currentOrder}
        />
      )}
    </>
  );
};
