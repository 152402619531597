import React from "react";
// import { makeStyles } from "@material-ui/core";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     padding: "16px 10px 8px",
//     backgroundColor: "#FAFAFA",
//     margin: "0 auto 15px",
//     [theme.breakpoints.up("md")]: {
//       padding: "25px 16px 8px",
//       margin: "0 auto 20px",
//     },
//   },
// }));

export default function Profile() {
  // const classes = useStyles();

  return (
    <>
      {/* <PageTitle title="Manutenzione" /> */}
      {/* <Breadcrumbs current="Profilo" /> */}
      <h1>Sito temporaneamente in manutenzione</h1>
    </>
  );
}
