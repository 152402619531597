import React from "react";
import { Switch, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { maintenanceModeVar } from "localState";
import { useMutation } from "@apollo/client";
import { SET_MAINTENANCE_MODE } from "mutation";
import {
  SetMaintenanceMode,
  SetMaintenanceModeVariables,
} from "types/generated/schemaTypes";

export const MaintenanceSwitch: React.FC = () => {
  const theme = useTheme();
  // const [checked, setChecked] = useState(maintenanceModeVar());
  const [setMaintenanceMode] =
    useMutation<SetMaintenanceMode, SetMaintenanceModeVariables>(
      SET_MAINTENANCE_MODE
    );

  const desktopLayout = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Switch
        checked={maintenanceModeVar()}
        onChange={async () => {
          // setChecked(!maintenanceModeVar());
          localStorage.setItem(
            "bga_maintenanceMode",
            (!maintenanceModeVar()).toString()
          );
          await setMaintenanceMode({
            variables: { active: !maintenanceModeVar() },
          });
          // setChecked(!checked);
          //maintenanceModeVar(!checked);
        }}
      />
      <Typography
        style={{ marginRight: 16, color: desktopLayout ? "inherit" : "white" }}
        variant="body1"
      >
        Modalità manutenzione
      </Typography>
    </div>
  );
};
