import React from "react";
import { Chip, ChipProps } from "@material-ui/core";
import { Info as InfoIcon } from "@material-ui/icons";

interface ButtonChipActivateProps {
  onClick: () => void;
}

export const ButtonChipActivate: React.FC<ButtonChipActivateProps & ChipProps> =
  ({ onClick, ...props }) => {
    return (
      <Chip
        {...props}
        clickable={true}
        variant="default"
        size="small"
        color="default"
        deleteIcon={<InfoIcon />}
        label="Attiva"
        onDelete={onClick}
        onClick={onClick}
      />
    );
  };
