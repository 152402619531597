import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Menu,
  Toolbar,
  makeStyles,
  useMediaQuery,
  useTheme,
  Avatar,
} from "@material-ui/core";
import { useQuery, useReactiveVar } from "@apollo/client";
import {
  Menu as MenuIcon,
  ExitToApp as ExitToAppIcon,
  Person as PersonIcon,
} from "@material-ui/icons";
import { ME } from "query";
import { Me as MeType } from "types/generated/schemaTypes";
import { colors } from "theme";
import { userRoleVar, isLoggedInVar } from "localState";
import { UserRole } from "types/generated/schemaTypes";
import { MaintenanceSwitch } from "./maintenanceSwitch";

interface StyleProps {
  sales: boolean;
}

const useStyles = makeStyles((theme) => ({
  toolbar: (props: StyleProps) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "fixed",
    top: 0,
    right: 0,
    left: 0,
    minHeight: 70,
    padding: "0 25px",
    backgroundColor: props.sales ? "#3eb7a0" : "rgb(0,44,85)",
    [theme.breakpoints.up("lg")]: {
      backgroundColor: "transparent",
      position: "absolute",
      left: "initial",
    },
  }),
  root: {
    background: "white",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  title: {
    flexGrow: 1,
  },
  menuLabel: {
    fontSize: 12,
    color: colors.text.secondary,
    fontWeight: 400,
    letterSpacing: "0.13px",
  },
  menuIcon: {
    minWidth: 0,
    marginRight: 15,
  },
  logo: {
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
}));

export const TopBar: React.FC<{
  onOpenMenu: () => void;
  salesArea: boolean;
}> = ({ onOpenMenu, salesArea = false }) => {
  const classes = useStyles({ sales: salesArea });
  const theme = useTheme();
  const history = useHistory();
  const menuAnchor = useRef(null);
  const role = useReactiveVar(userRoleVar);
  const { data: meData, loading: loadingMeData } = useQuery<MeType, null>(ME, {
    fetchPolicy: "network-only",
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(menuAnchor.current);
  };
  const desktopLayout = useMediaQuery(theme.breakpoints.up("lg"));
  const handleClose = () => {
    setAnchorEl(null);
  };

  // useSubscription<MaintenanceMode>(MAINTENANCE_MODE, {
  //   onSubscriptionData: (data) => {
  //     console.log("nuovi dati", data);
  //     maintenanceModeVar(data.subscriptionData.data?.maintenanceMode);
  //   },
  // });

  const logout = () => {
    localStorage.removeItem("bga_role");
    localStorage.removeItem("bga_userId");
    localStorage.removeItem("bga_token");
    isLoggedInVar(false);
  };
  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {!desktopLayout && (
            <IconButton
              aria-label="open menu"
              onClick={onOpenMenu}
              disableRipple={true}
              style={{ paddingLeft: 0 }}
            >
              <MenuIcon htmlColor="white" />
            </IconButton>
          )}
          {/* <a href="/">
            <img
              alt="Bromatech"
              src="/logo_bromatech_esteso.svg"
              width={130}
              height={31}
            />
          </a> */}
        </div>

        <div className={classes.logo}>
          <img
            style={{
              width: 177,
              height: 30,
            }}
            src="/logo-small.png"
            alt="Bromatech"
          />
        </div>

        {loadingMeData && !meData?.me ? null : (
          <div
            style={{ display: "flex", alignItems: "center" }}
            ref={menuAnchor}
          >
            {desktopLayout &&
              [UserRole.ADMIN, UserRole.OPERATOR].includes(role) && (
                <>
                  {/* {data && (data.maintenanceMode ? "ON" : "OFF")} */}
                  <MaintenanceSwitch />
                </>
              )}
            <Avatar
              onClick={handleClick}
              style={{
                backgroundColor: desktopLayout
                  ? theme.palette.primary.main
                  : "white",
                color: desktopLayout ? "white" : theme.palette.primary.main,
              }}
            >
              {meData?.me?.name[0].toUpperCase()}
              {meData?.me?.lastname[0].toUpperCase()}
            </Avatar>
          </div>
        )}
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => history.push("/profilo")}
            style={{ minWidth: 180 }}
          >
            <ListItemIcon className={classes.menuIcon}>
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              disableTypography={true}
              className={classes.menuLabel}
            >
              Profilo
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={logout} style={{ minWidth: 180 }}>
            <ListItemIcon className={classes.menuIcon}>
              <ExitToAppIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              disableTypography={true}
              className={classes.menuLabel}
            >
              Esci
            </ListItemText>
          </MenuItem>
        </Menu>
      </Toolbar>
    </div>
  );
};
