import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  ApolloLink,
  from,
  NormalizedCacheObject,
  InMemoryCache,
  split,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";
import { onError } from "@apollo/client/link/error";
import RouteManager from "./routeManager";
// import { cache, isLoggedInVar } from "./cache";
import { isLoggedInVar } from "./localState";
// import { typeDefs } from './typedefs'
import { theme } from "./theme";
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError) console.log(`[Network error]: ${networkError}`);
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, extensions }) => {
      if (extensions && extensions.code) {
        console.log(
          `[GraphQL error]: Message: ${message} Code: ${extensions.code}`
        );
        // handle errors differently based on its error code
        switch (extensions.code) {
          case "UNAUTHENTICATED":
            localStorage.removeItem("bga_token");
            localStorage.removeItem("bga_userId");
            localStorage.removeItem("bga_role");
            isLoggedInVar(false);
            break;
          default:
            break;
        }
      }
    });
  }
});

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_ENDPOINT}/graphql`,
  credentials: "include",
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem("bga_token");
  operation.setContext({
    headers: {
      authorization: token ? `Bearer: ${token}` : "",
    },
  });
  return forward(operation);
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_WS_ENDPOINT as string,
  options: {
    reconnect: true,
    // connectionParams: {
    //   authToken: localStorage.getItem("bga_token") || null,
    // },
  },
});

const defaultLink = from([authMiddleware, errorLink, httpLink]);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  defaultLink
);

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache: new InMemoryCache(),
  link: splitLink,
  resolvers: {},
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <RouteManager />
        <CssBaseline />
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
