export interface Province {
  label: string;
  value: string;
}

export const provinces: Province[] = [
  {
    label: "Agrigento",
    value: "AG",
  },
  {
    label: "Alessandria",
    value: "AL",
  },
  {
    label: "Ancona",
    value: "AN",
  },
  {
    label: "Arezzo",
    value: "AR",
  },
  {
    label: "Ascoli Piceno",
    value: "AP",
  },
  {
    label: "Asti",
    value: "AT",
  },
  {
    label: "Avellino",
    value: "AV",
  },
  {
    label: "Bari",
    value: "BA",
  },
  {
    label: "Barletta-Andria-Trani",
    value: "BT",
  },
  {
    label: "Belluno",
    value: "BL",
  },
  {
    label: "Benevento",
    value: "BN",
  },
  {
    label: "Bergamo",
    value: "BG",
  },
  {
    label: "Biella",
    value: "BI",
  },
  {
    label: "Bologna",
    value: "BO",
  },
  {
    label: "Bolzano/Bozen",
    value: "BZ",
  },
  {
    label: "Brescia",
    value: "BS",
  },
  {
    label: "Brindisi",
    value: "BR",
  },
  {
    label: "Cagliari",
    value: "CA",
  },
  {
    label: "Caltanissetta",
    value: "CL",
  },
  {
    label: "Campobasso",
    value: "CB",
  },
  {
    label: "Carbonia-Iglesias",
    value: "CI",
  },
  {
    label: "Caserta",
    value: "CE",
  },
  {
    label: "Catania",
    value: "CT",
  },
  {
    label: "Catanzaro",
    value: "CZ",
  },
  {
    label: "Chieti",
    value: "CH",
  },
  {
    label: "Como",
    value: "CO",
  },
  {
    label: "Cosenza",
    value: "CS",
  },
  {
    label: "Cremona",
    value: "CR",
  },
  {
    label: "Crotone",
    value: "KR",
  },
  {
    label: "Cuneo",
    value: "CN",
  },
  {
    label: "Enna",
    value: "EN",
  },
  {
    label: "Fermo",
    value: "FM",
  },
  {
    label: "Ferrara",
    value: "FE",
  },
  {
    label: "Firenze",
    value: "FI",
  },
  {
    label: "Foggia",
    value: "FG",
  },
  {
    label: "Forlì-Cesena",
    value: "FC",
  },
  {
    label: "Frosinone",
    value: "FR",
  },
  {
    label: "Genova",
    value: "GE",
  },
  {
    label: "Gorizia",
    value: "GO",
  },
  {
    label: "Grosseto",
    value: "GR",
  },
  {
    label: "Imperia",
    value: "IM",
  },
  {
    label: "Isernia",
    value: "IS",
  },
  {
    label: "L'Aquila",
    value: "AQ",
  },
  {
    label: "La Spezia",
    value: "SP",
  },
  {
    label: "Latina",
    value: "LT",
  },
  {
    label: "Lecce",
    value: "LE",
  },
  {
    label: "Lecco",
    value: "LC",
  },
  {
    label: "Livorno",
    value: "LI",
  },
  {
    label: "Lodi",
    value: "LO",
  },
  {
    label: "Lucca",
    value: "LU",
  },
  {
    label: "Macerata",
    value: "MC",
  },
  {
    label: "Mantova",
    value: "MN",
  },
  {
    label: "Massa-Carrara",
    value: "MS",
  },
  {
    label: "Matera",
    value: "MT",
  },
  {
    label: "Medio Campidano",
    value: "VS",
  },
  {
    label: "Messina",
    value: "ME",
  },
  {
    label: "Milano",
    value: "MI",
  },
  {
    label: "Modena",
    value: "MO",
  },
  {
    label: "Monza e della Brianza",
    value: "MB",
  },
  {
    label: "Napoli",
    value: "NA",
  },
  {
    label: "Novara",
    value: "NO",
  },
  {
    label: "Nuoro",
    value: "NU",
  },
  {
    label: "Ogliastra",
    value: "OG",
  },
  {
    label: "Olbia-Tempio",
    value: "OT",
  },
  {
    label: "Oristano",
    value: "OR",
  },
  {
    label: "Padova",
    value: "PD",
  },
  {
    label: "Palermo",
    value: "PA",
  },
  {
    label: "Parma",
    value: "PR",
  },
  {
    label: "Pavia",
    value: "PV",
  },
  {
    label: "Perugia",
    value: "PG",
  },
  {
    label: "Pesaro e Urbino",
    value: "PU",
  },
  {
    label: "Pescara",
    value: "PE",
  },
  {
    label: "Piacenza",
    value: "PC",
  },
  {
    label: "Pisa",
    value: "PI",
  },
  {
    label: "Pistoia",
    value: "PT",
  },
  {
    label: "Pordenone",
    value: "PN",
  },
  {
    label: "Potenza",
    value: "PZ",
  },
  {
    label: "Prato",
    value: "PO",
  },
  {
    label: "Ragusa",
    value: "RG",
  },
  {
    label: "Ravenna",
    value: "RA",
  },
  {
    label: "Reggio di Calabria",
    value: "RC",
  },
  {
    label: "Reggio nell'Emilia",
    value: "RE",
  },
  {
    label: "Rieti",
    value: "RI",
  },
  {
    label: "Rimini",
    value: "RN",
  },
  {
    label: "Roma",
    value: "RM",
  },
  {
    label: "Rovigo",
    value: "RO",
  },
  {
    label: "Salerno",
    value: "SA",
  },
  {
    label: "Sassari",
    value: "SS",
  },
  {
    label: "Savona",
    value: "SV",
  },
  {
    label: "Siena",
    value: "SI",
  },
  {
    label: "Siracusa",
    value: "SR",
  },
  {
    label: "Sondrio",
    value: "SO",
  },
  {
    label: "Sud Sardegna",
    value: "SU",
  },
  {
    label: "Taranto",
    value: "TA",
  },
  {
    label: "Teramo",
    value: "TE",
  },
  {
    label: "Terni",
    value: "TR",
  },
  {
    label: "Torino",
    value: "TO",
  },
  {
    label: "Trapani",
    value: "TP",
  },
  {
    label: "Trento",
    value: "TN",
  },
  {
    label: "Treviso",
    value: "TV",
  },
  {
    label: "Trieste",
    value: "TS",
  },
  {
    label: "Udine",
    value: "UD",
  },
  {
    label: "Valle d'Aosta/Vallée d'Aoste",
    value: "AO",
  },
  {
    label: "Varese",
    value: "VA",
  },
  {
    label: "Venezia",
    value: "VE",
  },
  {
    label: "Verbano-Cusio-Ossola",
    value: "VB",
  },
  {
    label: "Vercelli",
    value: "VC",
  },
  {
    label: "Verona",
    value: "VR",
  },
  {
    label: "Vibo Valentia",
    value: "VV",
  },
  {
    label: "Vicenza",
    value: "VI",
  },
  {
    label: "Viterbo",
    value: "VT",
  },
];
