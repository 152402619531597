import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  GetSalesClient_getSalesClient_salesAddresses as SalesAddressType,
  SalesClientFields,
  UpdateSalesClient,
  UpdateSalesClientVariables,
  CreateSalesClient,
  CreateSalesClientVariables,
  GetUsers,
  GetUsersVariables,
  UserOrderInput,
  CreateSalesAddressInput,
  UserRole,
} from "types/generated/schemaTypes";
import {
  FormControl,
  Grid,
  TextField,
  Typography,
  Divider,
  Switch,
  FormControlLabel,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
  makeStyles,
  Fab,
  IconButton,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CREATE_SALES_CLIENT, UPDATE_SALES_CLIENT } from "mutation";
import { GET_USERS } from "query";
import { SalesAddressForm, DialogForm, ActionType } from "components";
import { Autocomplete } from "@material-ui/lab";
import { formatError } from "utils";
import {
  Add as AddIcon,
  DeleteForever as DeleteIcon,
  Create as UpdateIcon,
} from "@material-ui/icons";
import { formatDate } from "utils/date";
import { provinces } from "utils/provinces";

const useStyles = makeStyles((theme) => ({
  verifiedSwitch: {
    "&$checked": {
      color: theme.palette.success.main,
      // color: "yellow",
    },
    "&$checked + $track": {
      backgroundColor: theme.palette.success.main,
    },
  },
  track: {
    backgroundColor: theme.palette.success.main,
  },
  checked: {},
}));

export interface SalesClientFormValues {
  name: string;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  sdi: string | null;
  iban: string | null;
  pec: string | null;
  email: string;
  vat: string;
  phone: string;
  active: boolean;
  agentId: string | null;
  verified: boolean;
  salesAddresses: {
    id?: string | null;
    destinationCode: string | null;
    name: string;
    addressLine: string;
    zip: string;
    city: string;
    hamlet: string | null;
    province: string;
    phone: string | null;
    notes: string | null;
  }[];
}

const defaultInitialValues = {
  name: "",
  addressLine: "",
  zip: "",
  city: "",
  hamlet: "",
  province: "",
  vat: "",
  sdi: "",
  pec: "",
  iban: "",
  email: "",
  phone: "",
  active: true,
  agentId: null,
  verified: false,
  salesAddresses: [],
};

const parseInitialValue = (input: SalesClientFields): SalesClientFormValues => {
  const values: SalesClientFormValues = {
    name: input.name,
    email: input.email,
    phone: input.phone,
    vat: input.vat,
    sdi: input.sdi || "",
    pec: input.pec || "",
    iban: input.iban || "",
    addressLine: input.addressLine,
    zip: input.zip,
    city: input.city,
    hamlet: input.hamlet || "",
    province: input.province,
    active: input.active,
    agentId: input.agent?.id || null,
    verified: input.verified,
    salesAddresses: input.salesAddresses.map((address) => ({
      id: address.id,
      destinationCode: address.destinationCode || "",
      name: address.name,
      addressLine: address.addressLine,
      zip: address.zip,
      city: address.city,
      hamlet: address.hamlet || "",
      province: address.province,
      phone: address.phone || "",
      notes: address.notes || "",
    })),
  };
  return values;
};

const getValidationSchema = (isOperator: boolean) => {
  return Yup.object({
    name: Yup.string().required("Campo obbligatorio"),
    email: Yup.string()
      .email("Formato mail non valido")
      .required("Campo obbligatorio"),
    addresses: Yup.array(),
    phone: Yup.string().required("Campo obbligatorio"),
    vat: Yup.string().required("Campo obbligatorio"),
    // pec: Yup.string().email("Formato mail non valido"),
    iban: Yup.string()
      .min(27, "Formato non non valido")
      .max(27, "Formato non non valido"),
    zip: Yup.string()
      .min(5, "Formato non non valido")
      .max(5, "Formato non non valido")
      .required("Campo obbligatorio"),
    city: Yup.string().required("Campo obbligatorio"),
    province: Yup.string().required("Campo obbligatorio"),
    pec: Yup.string().email("Formato mail non valido").nullable(),
    sdi: Yup.string().when("pec", {
      is: (pec: string) => {
        return !pec;
      },
      then: Yup.string().required("Inserire almeno un campo tra sdi e pec"),
      otherwise: Yup.string(),
    }),
    // sdi: Yup.string()
    //   .when("pec", {
    //     is: (pec: string) => {
    //       return !pec;
    //     },
    //     then: Yup.string()

    //       .required("Campo obbligatorio"),
    //     otherwise: Yup.string(),
    //   }),
  });
};

export const SalesAddressItem: React.FC<{
  salesAddress: CreateSalesAddressInput;
  onUpdate: () => void;
  onDelete: () => void;
  disabled?: boolean;
  isOperator: boolean;
}> = ({ salesAddress, onUpdate, onDelete, disabled = false, isOperator }) => {
  const isValid = !!salesAddress.destinationCode;
  return (
    <div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          {salesAddress.name && (
            <Typography
              color={isOperator ? (isValid ? "primary" : "error") : "primary"}
              variant="body2"
            >
              {salesAddress.name}
            </Typography>
          )}
          <Typography
            color={isOperator ? (isValid ? "primary" : "error") : "primary"}
            variant="body2"
          >
            {salesAddress.addressLine}
          </Typography>
          <Typography
            color={isOperator ? (isValid ? "primary" : "error") : "primary"}
            variant="body2"
          >
            {salesAddress.zip} {salesAddress.hamlet} {salesAddress.city} (
            {salesAddress.province}
          </Typography>
          {salesAddress.phone && (
            <Typography
              color={isOperator ? (isValid ? "primary" : "error") : "primary"}
              variant="body2"
            >
              {salesAddress.phone}
            </Typography>
          )}
          {salesAddress.notes && (
            <>
              <br />
              <Typography
                color={isOperator ? (isValid ? "primary" : "error") : "primary"}
                variant="body2"
                style={{ fontStyle: "italic" }}
              >
                {salesAddress.notes}
              </Typography>
            </>
          )}
        </div>
        <div>
          <IconButton onClick={onUpdate} disabled={disabled}>
            <UpdateIcon color={disabled ? "disabled" : "primary"} />
          </IconButton>
          <IconButton onClick={onDelete} disabled={disabled}>
            <DeleteIcon color={disabled ? "disabled" : "secondary"} />
          </IconButton>
        </div>
      </div>
      <Divider style={{ marginTop: 8, marginBottom: 8 }} />
    </div>
  );
};

interface AgentFormProps {
  open: boolean;
  agentId?: string;
  onClose: () => void;
  onCreate: () => void;
  onUpdate: () => void;
  salesClient: SalesClientFields | null;
}
export const SalesClientForm: React.FC<AgentFormProps> = ({
  open,
  onClose,
  onCreate,
  onUpdate,
  salesClient,
  agentId,
}) => {
  const classes = useStyles();
  const [createSalesClientMutation, { error: createSalesClientError }] =
    useMutation<CreateSalesClient, CreateSalesClientVariables>(
      CREATE_SALES_CLIENT,
      {
        onCompleted: async (data) => {
          formik.resetForm();
          onCreate();
        },
        onError: (err) => {
          console.log("Errore!", err);
        },
      }
    );
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [salesAddresses, setSalesAddresses] = useState<
    Array<{
      id?: string | null;
      destinationCode?: string | null;
      phone?: string | null;
      name: string;
      addressLine: string;
      zip: string;
      city: string;
      hamlet?: string | null;
      province: string;
      notes?: string | null;
    }>
  >(salesClient ? salesClient.salesAddresses : []);
  const [currentAddressIndex, setCurrentAddressIndex] = useState(-1);

  const [updateSalesClientMutation, { error: updateSalesClientError }] =
    useMutation<UpdateSalesClient, UpdateSalesClientVariables>(
      UPDATE_SALES_CLIENT,
      {
        onCompleted: async (data) => {
          formik.resetForm();
          onUpdate();
        },
        onError: (err) => {
          console.log("Errore!", err);
        },
      }
    );
  const { data: agentsData } = useQuery<GetUsers, GetUsersVariables>(
    GET_USERS,
    {
      fetchPolicy: "network-only",
      variables: {
        where: { active: true, role: UserRole.AGENT },
        order: UserOrderInput.LASTNAME_ASC,
        offset: 0,
        limit: 100,
      },
    }
  );
  const agentsOptions = agentsData
    ? agentsData?.getUsers.users.map((agent) => ({
        label: `${agent.name} ${agent.lastname}`,
        id: agent.id,
      }))
    : [];

  const update = !!salesClient;
  const formik = useFormik<SalesClientFormValues>({
    initialValues: salesClient
      ? parseInitialValue(salesClient as SalesClientFields)
      : defaultInitialValues,
    validationSchema: getValidationSchema(!agentId),
    enableReinitialize: true,
    onSubmit: async (values) => {
      let variables: CreateSalesClientVariables | UpdateSalesClientVariables;
      // update
      if (update) {
        variables = {
          salesClientData: {
            name: formik.values.name,
            addressLine: formik.values.addressLine,
            city: formik.values.city,
            hamlet: formik.values.hamlet || null,
            zip: formik.values.zip,
            province: formik.values.province,
            sdi: formik.values.sdi || null,
            pec: formik.values.pec || null,
            iban: formik.values.iban || null,
            vat: formik.values.vat,
            email: formik.values.email,
            phone: formik.values.phone,
            agentId: agentId || formik.values.agentId,
            salesAddresses: salesAddresses.map((address) => ({
              id: address.id || null,
              destinationCode: address.destinationCode || null,
              phone: address.phone || null,
              name: address.name,
              addressLine: address.addressLine,
              zip: address.zip,
              city: address.city,
              hamlet: address.hamlet || null,
              province: address.province,
              notes: address.notes || null,
            })),
          },
          salesClientId: (salesClient as SalesClientFields).id,
        } as UpdateSalesClientVariables;
        if (!agentId) {
          variables.salesClientData.verified = formik.values.verified;
        }

        await updateSalesClientMutation({ variables });
        // create
      } else {
        variables = {
          salesClientData: {
            name: formik.values.name,
            vat: formik.values.vat,
            email: formik.values.email,
            phone: formik.values.phone,
            agentId: agentId || formik.values.agentId,
            sdi: formik.values.sdi || null,
            pec: formik.values.pec || null,
            iban: formik.values.iban || null,
            addressLine: formik.values.addressLine,
            city: formik.values.city,
            hamlet: formik.values.hamlet || null,
            zip: formik.values.zip,
            province: formik.values.province,
            salesAddresses: salesAddresses.map((address) => ({
              destinationCode: address.destinationCode || null,
              name: address.name || null,
              phone: address.phone || null,
              addressLine: address.addressLine,
              zip: address.zip,
              city: address.city,
              hamlet: address.hamlet || null,
              province: address.province,
              notes: address.notes || null,
            })),
          },
        } as CreateSalesClientVariables;
        if (!agentId) {
          variables.salesClientData.verified = formik.values.verified;
        }
        await createSalesClientMutation({ variables });

        // variables = {
        //   userData: {
        //     name: values.name,
        //     lastname: values.lastname,
        //     email: values.email,
        //     crmCode: values.crmCode,
        //     role: "AGENT",
        //     password: Math.random().toString(36).slice(-8),
        //   },
        // } as CreateClientVariables;
      }
    },
  });

  return (
    <DialogForm
      open={open}
      title={update ? "Modifica Cliente" : "Aggiungi un Cliente"}
      onClose={() => {
        formik.resetForm();
        onClose();
      }}
      actions={[
        {
          type: ActionType.EXIT,
          label: "CHIUDI SENZA SALVARE",
          callback: () => {
            formik.resetForm();
            onClose();
          },
          disabled: formik.isSubmitting || showAddressForm,
        },
        {
          type: ActionType.SAVE,
          label: "SALVA",
          callback: formik.submitForm,
          disabled: formik.isSubmitting || showAddressForm || !isChanged,
        },
      ]}
    >
      <div style={{ overflow: "hidden" }}>
        <Typography color="textSecondary" style={{ marginBottom: 24 }}>
          Inserisci tutte le informazioni richieste e salva per aggiungere un
          Cliente.
        </Typography>
        <Typography
          color="textPrimary"
          style={{ marginBottom: 16, marginTop: 8, fontWeight: 700 }}
        >
          Dati di fatturazione
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            {/* NAME */}
            <FormControl fullWidth={true}>
              <TextField
                name="name"
                error={formik.touched.name && !!formik.errors.name}
                helperText={
                  formik.touched.name &&
                  !!formik.errors.name &&
                  formik.errors.name
                }
                variant="standard"
                id="name"
                size="small"
                label="Ragione sociale*"
                value={formik.values.name}
                onChange={(e) => {
                  formik.setFieldValue("name", e.target.value);
                  setIsChanged(true);
                }}
                disabled={formik.isSubmitting || showAddressForm}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* VAT */}
            <FormControl fullWidth={true}>
              <TextField
                name="vat"
                error={formik.touched.vat && !!formik.errors.vat}
                helperText={
                  formik.touched.vat && !!formik.errors.vat && formik.errors.vat
                }
                variant="standard"
                id="vat"
                size="small"
                label="P.I. / C.F.*"
                value={formik.values.vat}
                onChange={(e) => {
                  formik.setFieldValue("vat", e.target.value);
                  setIsChanged(true);
                }}
                disabled={formik.isSubmitting || showAddressForm}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={9}>
            {/* ADDRESS LINE */}
            <FormControl fullWidth={true}>
              <TextField
                name="addressLine"
                error={
                  formik.touched.addressLine && !!formik.errors.addressLine
                }
                helperText={
                  formik.touched.addressLine &&
                  !!formik.errors.addressLine &&
                  formik.errors.addressLine
                }
                variant="standard"
                id="addressLine"
                size="small"
                label="Indirizzo (Via, Piazza, ..., e civico)*"
                value={formik.values.addressLine}
                onChange={(e) => {
                  formik.setFieldValue("addressLine", e.target.value);
                  setIsChanged(true);
                }}
                disabled={formik.isSubmitting || showAddressForm}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            {/* CAP */}
            <FormControl fullWidth={true}>
              <TextField
                name="zip"
                error={formik.touched.zip && !!formik.errors.zip}
                helperText={
                  formik.touched.zip && !!formik.errors.zip && formik.errors.zip
                }
                variant="standard"
                id="zip"
                size="small"
                label="CAP*"
                value={formik.values.zip}
                onChange={(e) => {
                  formik.setFieldValue("zip", e.target.value);
                  setIsChanged(true);
                }}
                disabled={formik.isSubmitting || showAddressForm}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            {/* CITTÀ */}
            <FormControl fullWidth={true}>
              <TextField
                name="city"
                error={formik.touched.city && !!formik.errors.city}
                helperText={
                  formik.touched.city &&
                  !!formik.errors.city &&
                  formik.errors.city
                }
                variant="standard"
                id="city"
                size="small"
                label="Città*"
                value={formik.values.city}
                onChange={(e) => {
                  formik.setFieldValue("city", e.target.value);
                  setIsChanged(true);
                }}
                disabled={formik.isSubmitting || showAddressForm}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            {/* FRAZIONE */}
            <FormControl fullWidth={true}>
              <TextField
                name="hamlet"
                error={formik.touched.hamlet && !!formik.errors.hamlet}
                helperText={
                  formik.touched.hamlet &&
                  !!formik.errors.hamlet &&
                  formik.errors.hamlet
                }
                variant="standard"
                id="hamlet"
                size="small"
                label="Frazione"
                value={formik.values.hamlet}
                onChange={(e) => {
                  formik.setFieldValue("hamlet", e.target.value);
                  setIsChanged(true);
                }}
                disabled={formik.isSubmitting || showAddressForm}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            {/* PROVINCIA */}
            <FormControl fullWidth={true} variant="standard" size="small">
              <InputLabel
                error={formik.touched.province && !!formik.errors.province}
                id="province-label"
              >
                Provincia*
              </InputLabel>
              <Select
                name="province"
                value={formik.values.province}
                labelId="province-label"
                id="province"
                label="Provincia*"
                error={formik.touched.province && !!formik.errors.province}
                onChange={(e) => {
                  formik.setFieldValue("province", e.target.value);
                  setIsChanged(true);
                }}
                disabled={formik.isSubmitting || showAddressForm}
              >
                {provinces.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.province && !!formik.errors.province && (
                <FormHelperText error={true} variant="standard">
                  {formik.errors.province}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            {/* EMAIL */}
            <FormControl fullWidth={true}>
              <TextField
                name="email"
                error={formik.touched.email && !!formik.errors.email}
                helperText={
                  formik.touched.email &&
                  !!formik.errors.email &&
                  formik.errors.email
                }
                variant="standard"
                id="email"
                size="small"
                label="Email*"
                value={formik.values.email}
                onChange={(e) => {
                  formik.setFieldValue("email", e.target.value);
                  setIsChanged(true);
                }}
                disabled={formik.isSubmitting || showAddressForm}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            {/* PHONE */}
            <FormControl fullWidth={true}>
              <TextField
                name="phone"
                error={formik.touched.phone && !!formik.errors.phone}
                helperText={
                  formik.touched.phone &&
                  !!formik.errors.phone &&
                  formik.errors.phone
                }
                variant="standard"
                id="phone"
                size="small"
                label="Telefono*"
                value={formik.values.phone}
                onChange={(e) => {
                  formik.setFieldValue("phone", e.target.value);
                  setIsChanged(true);
                }}
                disabled={formik.isSubmitting || showAddressForm}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            {/* SDI */}
            <FormControl fullWidth={true}>
              <TextField
                name="sdi"
                error={formik.touched.sdi && !!formik.errors.sdi}
                helperText={
                  formik.touched.sdi && !!formik.errors.sdi && formik.errors.sdi
                }
                variant="standard"
                id="sdi"
                size="small"
                label="SDI"
                value={formik.values.sdi}
                onChange={(e) => {
                  formik.setFieldValue("sdi", e.target.value);
                  setIsChanged(true);
                }}
                disabled={formik.isSubmitting || showAddressForm}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            {/* PEC */}
            <FormControl fullWidth={true}>
              <TextField
                name="pec"
                error={formik.touched.pec && !!formik.errors.pec}
                helperText={
                  formik.touched.pec && !!formik.errors.pec && formik.errors.pec
                }
                variant="standard"
                id="pec"
                size="small"
                label="PEC"
                value={formik.values.pec}
                onChange={(e) => {
                  formik.setFieldValue("pec", e.target.value);
                  setIsChanged(true);
                }}
                disabled={formik.isSubmitting || showAddressForm}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            {/* IBAN */}
            <FormControl fullWidth={true}>
              <TextField
                name="iban"
                error={formik.touched.iban && !!formik.errors.iban}
                helperText={
                  formik.touched.iban &&
                  !!formik.errors.iban &&
                  formik.errors.iban
                }
                variant="standard"
                id="iban"
                size="small"
                label="IBAN"
                value={formik.values.iban}
                onChange={(e) => {
                  formik.setFieldValue("iban", e.target.value);
                  setIsChanged(true);
                }}
                disabled={formik.isSubmitting || showAddressForm}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Typography
          color="textPrimary"
          style={{ marginBottom: 16, fontWeight: 700 }}
        >
          Dettagli
        </Typography>
        <Grid container spacing={1}>
          {!agentId && (
            <>
              <Grid item xs={12} md={6}>
                {/* AGENTE DI RIFERIMENTO */}
                <FormControl fullWidth={true}>
                  <Autocomplete
                    disabled={formik.isSubmitting || showAddressForm}
                    options={agentsOptions}
                    value={
                      agentsOptions.find((agent) => {
                        return agent.id === formik.values.agentId;
                      }) || null
                    }
                    getOptionSelected={(option, test) => {
                      return test.id === option.id;
                    }}
                    onChange={(event, newValue) => {
                      formik.setFieldValue("agentId", newValue?.id || null);
                      setIsChanged(true);
                      //setFilterAgent(newValue?.id || "ALL");
                    }}
                    getOptionLabel={(option) => option.label}
                    // style={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Agente di riferimento"
                        variant="standard"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>
        <Typography
          color="textPrimary"
          style={{ marginBottom: 16, marginTop: 8, fontWeight: 700 }}
        >
          Dati di spedizione
        </Typography>

        {salesAddresses.length === 0 && (
          <Typography
            color="textSecondary"
            variant="body1"
            style={{ marginBottom: 16, marginTop: 8 }}
          >
            Non sono ancora presenti indirizzi per questo cliente.
          </Typography>
        )}

        {showAddressForm && (
          <SalesAddressForm
            addressToCopy={{
              name: formik.values.name || "",
              addressLine: formik.values.addressLine || "",
              zip: formik.values.zip || "",
              phone: formik.values.phone || "",
              city: formik.values.city || "",
              province: formik.values.province || "",
              hamlet: formik.values.hamlet || "",
            }}
            isAgent={!!agentId}
            open={showAddressForm}
            onClose={() => {
              setShowAddressForm(false);
            }}
            onEdit={() => setIsChanged(true)}
            onCreate={(createdSalesAddress: any) => {
              setSalesAddresses([...salesAddresses, createdSalesAddress]);
              setShowAddressForm(false);
            }}
            onUpdate={(updatedSalesAddress: any) => {
              setSalesAddresses(
                salesAddresses.map((_, index) => {
                  return index === currentAddressIndex
                    ? updatedSalesAddress
                    : salesAddresses[index];
                })
              );

              formik.setFieldValue(
                "salesAddresses",
                salesAddresses.map((_, index) => {
                  return index === currentAddressIndex
                    ? updatedSalesAddress
                    : salesAddresses[index];
                })
              );
              setShowAddressForm(false);
            }}
            salesAddress={
              currentAddressIndex > -1
                ? (salesAddresses[currentAddressIndex] as SalesAddressType)
                : null
            }
          />
        )}

        <Grid container spacing={1}>
          {!showAddressForm && (
            <Grid item xs={12} md={12}>
              {salesAddresses.map((salesAddress, index) => {
                return (
                  <SalesAddressItem
                    isOperator={!agentId}
                    key={index}
                    disabled={formik.isSubmitting || showAddressForm}
                    salesAddress={salesAddress}
                    onUpdate={() => {
                      setCurrentAddressIndex(index);
                      setShowAddressForm(true);
                    }}
                    onDelete={() => {
                      setIsChanged(true);
                      const newAddresses = [...salesAddresses];
                      newAddresses.splice(index, 1);
                      setSalesAddresses(newAddresses);
                      setCurrentAddressIndex(-1);
                    }}
                    // disabled={action.disabled}
                  />
                );
              })}
            </Grid>
          )}
          {!showAddressForm && (
            <Grid item xs={12} md={12}>
              <Fab
                variant="extended"
                color="primary"
                aria-label="add"
                onClick={() => {
                  setCurrentAddressIndex(-1);
                  setShowAddressForm(true);
                }}
                size="small"
              >
                <AddIcon />
                Aggiungi un indirizzo
              </Fab>
            </Grid>
          )}
        </Grid>

        <Typography
          color="textSecondary"
          variant="caption"
          component="p"
          style={{ marginBottom: 30, marginTop: 0 }}
        >
          * I campi contrassegnati con l’asterisco sono obbligatori
        </Typography>
        {salesClient && (
          <Grid container spacing={1}>
            {!agentId && (
              <Grid item xs={12} md={6}>
                <div style={{ paddingLeft: 16 }}>
                  <FormControlLabel
                    control={
                      <Switch
                        // color="primary"
                        classes={{
                          switchBase: classes.verifiedSwitch,
                          checked: classes.checked,
                          track: classes.track,
                        }}
                        checked={formik.values.verified}
                        onChange={() => {
                          setIsChanged(true);
                          formik.setFieldValue(
                            "verified",
                            !formik.values.verified
                          );
                        }}
                        disableRipple
                        disableTouchRipple
                        disabled={formik.isSubmitting}
                        name="verified"
                      />
                    }
                    label={
                      formik.values.verified
                        ? "Cliente verificato"
                        : "Cliente non verificato"
                    }
                  />
                  {formik.touched.verified && !!formik.errors.verified && (
                    <FormHelperText error={true} variant="standard">
                      {formik.errors.verified}
                    </FormHelperText>
                  )}
                </div>
              </Grid>
            )}

            <Grid item xs={12} md={6}>
              <div style={{ textAlign: agentId ? "left" : "right" }}>
                <Typography
                  variant="body1"
                  color="textPrimary"
                  display="inline"
                >
                  Ultimo ordine:{" "}
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  display="inline"
                >
                  {salesClient.lastOrder
                    ? formatDate(salesClient.lastOrder)
                    : "-"}
                </Typography>
              </div>
            </Grid>
          </Grid>
        )}
        <Divider />

        {(createSalesClientError || updateSalesClientError) && (
          <Typography color="error" variant="body1">
            {createSalesClientError && formatError(createSalesClientError)}
            {updateSalesClientError && formatError(updateSalesClientError)}
          </Typography>
        )}
      </div>
    </DialogForm>
  );
};
