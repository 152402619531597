import { SalesOrderPayment } from "types/generated/schemaTypes";

export const getPaymentLabel = (payment: SalesOrderPayment) => {
  let label = ""
  switch (payment) {
    case SalesOrderPayment.AS_IS:
      label = "Condizioni in essere"
      break;
      case SalesOrderPayment.DAY_30_DF:
      label = "30 gg d.f."
      break;
      case SalesOrderPayment.TRANSFER:
      label = "Bonifico bancario anticipato"
      break;
    default:
      break;
  }
  return label;
};
