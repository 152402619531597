import React from "react";
import { useMutation } from "@apollo/client";
import {
  UserFields,
  UpdateUser,
  UpdateUserVariables,
  CreateUser,
  CreateUserVariables,
} from "types/generated/schemaTypes";
import {
  FormControl,
  Grid,
  TextField,
  Typography,
  Divider,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CREATE_USER, UPDATE_USER } from "mutation";
import { DialogForm, ActionType } from "components";
import { formatError } from "utils/errors";

export interface OperatorFormValues {
  name: string;
  lastname: string;
  email: string;
  active: boolean;
  password?: string;
}

const defaultInitialValues = {
  name: "",
  lastname: "",
  email: "",
  active: true,
  password: "",
};

const parseInitialValue = (input: UserFields): OperatorFormValues => {
  const values: OperatorFormValues = {
    name: input.name,
    lastname: input.lastname,
    email: input.email,
    active: input.active,
  };
  return values;
};

const getValidationSchema = (update: boolean) => {
  return Yup.object({
    name: Yup.string().required("Campo obbligatorio"),
    lastname: Yup.string().required("Campo obbligatorio"),
    email: Yup.string()
      .email("Formato mail non valido")
      .required("Campo obbligatorio"),
    password: update
      ? Yup.string()
      : Yup.string()
          .min(8, "Lunghezza minima 8 caratteri")
          .required("Campo obbligatorio"),
  });
};

interface OperatorFormProps {
  open: boolean;
  onClose: () => void;
  onCreate: () => void;
  onUpdate: () => void;
  operator: UserFields | null;
}

export const OperatorForm: React.FC<OperatorFormProps> = ({
  open,
  onClose,
  onCreate,
  onUpdate,
  operator,
}) => {
  const [createOperatorMutation, { error: createOperatorError }] = useMutation<
    CreateUser,
    CreateUserVariables
  >(CREATE_USER, {
    onCompleted: async (data) => {
      formik.resetForm();
      onCreate();
    },
    onError: (err) => {
      console.log("Errore!", err);
    },
  });
  const [updateOperatorMutation, { error: updateOperatorError }] = useMutation<
    UpdateUser,
    UpdateUserVariables
  >(UPDATE_USER, {
    onCompleted: async (data) => {
      formik.resetForm();
      onUpdate();
    },
    onError: (err) => {
      console.log("Errore!", err);
    },
  });

  const update = !!operator;
  const formik = useFormik<OperatorFormValues>({
    initialValues: operator
      ? parseInitialValue(operator as UserFields)
      : defaultInitialValues,
    validationSchema: getValidationSchema(update),
    enableReinitialize: true,
    onSubmit: async (values) => {
      let variables: CreateUserVariables | UpdateUserVariables;
      if (!update) {
        // create
        variables = {
          userData: {
            name: values.name,
            lastname: values.lastname,
            email: values.email,
            password: values.password,
            role: "OPERATOR",
          },
        } as CreateUserVariables;
        await createOperatorMutation({ variables });
      } else {
        // update
        variables = {
          userData: {
            name: values.name,
            lastname: values.lastname,
            email: values.email,
            active: values.active,
          },
          userId: (operator as UserFields).id,
        } as UpdateUserVariables;
        await updateOperatorMutation({ variables });
      }
    },
  });

  return (
    <DialogForm
      open={open}
      title={update ? "Modifica Operatore" : "Aggiungi un Operatore"}
      onClose={() => {
        formik.resetForm();
        onClose();
      }}
      actions={[
        {
          type: ActionType.EXIT,
          label: "CHIUDI SENZA SALVARE",
          callback: () => {
            formik.resetForm();
            onClose();
          },
          disabled: formik.isSubmitting,
        },
        {
          type: ActionType.SAVE,
          label: "SALVA",
          callback: formik.submitForm,
          disabled: formik.isSubmitting,
        },
      ]}
    >
      <div style={{ overflow: "hidden" }}>
        <Typography color="textSecondary" style={{ marginBottom: 36 }}>
          Inserisci tutte le informazioni richieste e salva per aggiungere un
          Operatore.
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            {/* NAME */}
            <FormControl fullWidth={true}>
              <TextField
                name="name"
                error={formik.touched.name && !!formik.errors.name}
                helperText={
                  formik.touched.name &&
                  !!formik.errors.name &&
                  formik.errors.name
                }
                variant="standard"
                id="name"
                size="small"
                label="Nome*"
                value={formik.values.name}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* LASTNAME */}
            <FormControl fullWidth={true}>
              <TextField
                name="lastname"
                error={formik.touched.lastname && !!formik.errors.lastname}
                helperText={
                  formik.touched.lastname &&
                  !!formik.errors.lastname &&
                  formik.errors.lastname
                }
                variant="standard"
                id="lastname"
                size="small"
                label="Cognome*"
                value={formik.values.lastname}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* EMAIL */}
            <FormControl fullWidth={true}>
              <TextField
                name="email"
                error={formik.touched.email && !!formik.errors.email}
                helperText={
                  formik.touched.email &&
                  !!formik.errors.email &&
                  formik.errors.email
                }
                variant="standard"
                id="email"
                size="small"
                label="Email*"
                value={formik.values.email}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          {!update && (
            <Grid item xs={12} md={6}>
              {/* PASSWORD */}
              <FormControl fullWidth={true}>
                <TextField
                  name="password"
                  error={formik.touched.password && !!formik.errors.password}
                  helperText={
                    formik.touched.password &&
                    !!formik.errors.password &&
                    formik.errors.password
                  }
                  variant="standard"
                  id="password"
                  size="small"
                  label="Password*"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  disabled={formik.isSubmitting}
                />
              </FormControl>
            </Grid>
          )}
          {operator && (
            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={!formik.values.active}
                    onChange={() =>
                      formik.setFieldValue("active", !formik.values.active)
                    }
                    disabled={formik.isSubmitting}
                    name="active"
                    color={formik.values.active ? "default" : "secondary"}
                  />
                }
                label={formik.values.active ? "Attivo" : "Sospeso"}
              />
            </Grid>
          )}
        </Grid>

        <Typography
          color="textSecondary"
          variant="caption"
          component="p"
          style={{ marginBottom: 30, marginTop: 0 }}
        >
          * I campi contrassegnati con l’asterisco sono obbligatori
        </Typography>
        <Divider />

        {(createOperatorError || updateOperatorError) && (
          <Typography color="error" variant="body1">
            {createOperatorError && formatError(createOperatorError)}
            {updateOperatorError && formatError(updateOperatorError)}
          </Typography>
        )}
      </div>
    </DialogForm>
  );
};
