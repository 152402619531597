import React from "react";
import {
  SalesClientFields_salesAddresses as SalesAddressType,
  CreateSalesAddressInput,
  UpdateSalesAddressInput,
} from "types/generated/schemaTypes";
import {
  FormControl,
  Grid,
  TextField,
  Divider,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  Fab,
  Button,
  useTheme,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { provinces } from "utils/provinces";

import {
  Check as CheckIcon,
  Close as CloseIcon,
  FileCopy as CopyIcon,
} from "@material-ui/icons";

export interface SalesAddressFormValues {
  destinationCode: string | null;
  name: string;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  phone: string | null;
  notes: string | null;
}

const defaultInitialValues = {
  destinationCode: "",
  name: "",
  addressLine: "",
  zip: "",
  city: "",
  hamlet: "",
  province: "",
  phone: "",
  notes: "",
};

const parseInitialValue = (input: SalesAddressType): SalesAddressFormValues => {
  const values: SalesAddressFormValues = {
    destinationCode: input.destinationCode || "",
    name: input.name,
    addressLine: input.addressLine,
    zip: input.zip,
    city: input.city,
    hamlet: input.hamlet || "",
    province: input.province,
    phone: input.phone || "",
    notes: input.notes || "",
  };
  return values;
};

const validationSchema = Yup.object({
  name: Yup.string().required("Campo obbligatorio"),
  addressLine: Yup.string().required("Campo obbligatorio"),
  zip: Yup.string().required("Campo obbligatorio"),
  city: Yup.string().required("Campo obbligatorio"),
  province: Yup.string().required("Campo obbligatorio"),
  notes: Yup.string().max(200, "Massimo 200 caratteri"),
});

interface AddressToCopy {
  name: string;
  phone: string;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string;
  province: string;
}

interface SalesAddressFormProps {
  open: boolean;
  onClose: () => void;
  onEdit: () => void;
  onCreate: (salesAddressData: CreateSalesAddressInput) => void;
  onUpdate: (salesAddressData: UpdateSalesAddressInput) => void;
  salesAddress: SalesAddressType | null;
  isAgent: boolean;
  addressToCopy: AddressToCopy;
}

export const SalesAddressForm: React.FC<SalesAddressFormProps> = ({
  open,
  onClose,
  onEdit,
  onCreate,
  onUpdate,
  salesAddress,
  isAgent,
  addressToCopy,
}) => {
  const theme = useTheme();
  const update = !!salesAddress;
  const formik = useFormik<SalesAddressFormValues>({
    initialValues: salesAddress
      ? parseInitialValue(salesAddress as SalesAddressType)
      : defaultInitialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      let variables: any;
      if (update) {
        // update
        variables = {
          id: salesAddress?.id,
          name: formik.values.name,
          addressLine: formik.values.addressLine,
          city: formik.values.city,
          hamlet: formik.values.hamlet || null,
          zip: formik.values.zip,
          province: formik.values.province,
          phone: formik.values.phone || null,
          notes: formik.values.notes || null,
        };
        if (!isAgent)
          variables.destinationCode = formik.values.destinationCode || null;

        onUpdate(variables);
        formik.resetForm();
        onClose();
      } else {
        variables = {
          name: formik.values.name,
          addressLine: formik.values.addressLine,
          city: formik.values.city,
          hamlet: formik.values.hamlet || null,
          zip: formik.values.zip,
          province: formik.values.province,
          destinationCode: formik.values.destinationCode || null,
          phone: formik.values.phone || null,
          notes: formik.values.notes || null,
        };
        if (!isAgent)
          variables.destinationCode = formik.values.destinationCode || null;
        onCreate(variables);
        formik.resetForm();
        onClose();
      }
    },
  });

  return (
    <div>
      <div style={{ overflow: "hidden" }}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Button
              variant="outlined"
              color="primary"
              size="medium"
              startIcon={<CopyIcon />}
              onClick={() => {
                onEdit();
                formik.setFieldValue("name", addressToCopy.name);
                formik.setFieldValue("phone", addressToCopy.phone);
                formik.setFieldValue("addressLine", addressToCopy.addressLine);
                formik.setFieldValue("zip", addressToCopy.zip);
                formik.setFieldValue("province", addressToCopy.province);
                formik.setFieldValue("hamlet", addressToCopy.hamlet);
                formik.setFieldValue("city", addressToCopy.city);
              }}
            >
              Copia indirizzo fatturazione
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* NAME */}
            <FormControl fullWidth={true}>
              <TextField
                name="name"
                error={formik.touched.name && !!formik.errors.name}
                helperText={
                  formik.touched.name &&
                  !!formik.errors.name &&
                  formik.errors.name
                }
                variant="standard"
                id="name"
                size="small"
                label="Ragione sociale"
                value={formik.values.name}
                onChange={(e)=>{formik.setFieldValue('name',e.target.value);onEdit()}}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* PHONE */}
            <FormControl fullWidth={true}>
              <TextField
                name="phone"
                error={formik.touched.phone && !!formik.errors.phone}
                helperText={
                  formik.touched.phone &&
                  !!formik.errors.phone &&
                  formik.errors.phone
                }
                variant="standard"
                id="phone"
                size="small"
                label="Telefono"
                value={formik.values.phone}
                onChange={(e)=>{formik.setFieldValue('phone',e.target.value);onEdit()}}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          {!isAgent && (
            <Grid item xs={12} md={6}>
              {/* DESTINATION CODE */}
              <FormControl fullWidth={true}>
                <TextField
                  name="destinationCode"
                  error={
                    formik.touched.destinationCode &&
                    !!formik.errors.destinationCode
                  }
                  helperText={
                    formik.touched.destinationCode &&
                    !!formik.errors.destinationCode &&
                    formik.errors.destinationCode
                  }
                  variant="standard"
                  id="destinationCode"
                  size="small"
                  label="Codice destinazione"
                  value={formik.values.destinationCode}
                  onChange={(e)=>{formik.setFieldValue('destinationCode',e.target.value);onEdit()}}
                  disabled={formik.isSubmitting}
                />
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12} md={9}>
            {/* ADDRESS LINE */}
            <FormControl fullWidth={true}>
              <TextField
                name="addressLine"
                error={
                  formik.touched.addressLine && !!formik.errors.addressLine
                }
                helperText={
                  formik.touched.addressLine &&
                  !!formik.errors.addressLine &&
                  formik.errors.addressLine
                }
                variant="standard"
                id="addressLine"
                size="small"
                label="Indirizzo (Via, Piazza, ..., e civico)*"
                value={formik.values.addressLine}
                onChange={(e)=>{formik.setFieldValue('addressLine',e.target.value);onEdit()}}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            {/* CAP */}
            <FormControl fullWidth={true}>
              <TextField
                name="zip"
                error={formik.touched.zip && !!formik.errors.zip}
                helperText={
                  formik.touched.zip && !!formik.errors.zip && formik.errors.zip
                }
                variant="standard"
                id="zip"
                size="small"
                label="CAP*"
                value={formik.values.zip}
                onChange={(e)=>{formik.setFieldValue('zip',e.target.value);onEdit()}}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            {/* CITTÀ */}
            <FormControl fullWidth={true}>
              <TextField
                name="city"
                error={formik.touched.city && !!formik.errors.city}
                helperText={
                  formik.touched.city &&
                  !!formik.errors.city &&
                  formik.errors.city
                }
                variant="standard"
                id="city"
                size="small"
                label="Città*"
                value={formik.values.city}
                onChange={(e)=>{formik.setFieldValue('city',e.target.value);onEdit()}}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            {/* FRAZIONE */}
            <FormControl fullWidth={true}>
              <TextField
                name="hamlet"
                error={formik.touched.hamlet && !!formik.errors.hamlet}
                helperText={
                  formik.touched.hamlet &&
                  !!formik.errors.hamlet &&
                  formik.errors.hamlet
                }
                variant="standard"
                id="hamlet"
                size="small"
                label="Frazione"
                value={formik.values.hamlet}
                onChange={(e)=>{formik.setFieldValue('hamlet',e.target.value);onEdit()}}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            {/* PROVINCIA */}
            <FormControl fullWidth={true} variant="standard" size="small">
              <InputLabel
                error={formik.touched.province && !!formik.errors.province}
                id="province-label"
              >
                Provincia*
              </InputLabel>
              <Select
                name="province"
                value={formik.values.province}
                labelId="province-label"
                id="province"
                label="Provincia*"
                error={formik.touched.province && !!formik.errors.province}
                onChange={(e)=>{formik.setFieldValue('province',e.target.value);onEdit()}}
                disabled={formik.isSubmitting}
              >
                {provinces.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.province && !!formik.errors.province && (
                <FormHelperText error={true} variant="standard">
                  {formik.errors.province}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            {/* Notes */}
            <FormControl fullWidth={true}>
              <TextField
                name="notes"
                error={formik.touched.notes && !!formik.errors.notes}
                helperText={
                  formik.touched.notes &&
                  !!formik.errors.notes &&
                  formik.errors.notes
                }
                variant="outlined"
                multiline={true}
                rows={4}
                id="notes"
                size="small"
                label="Note"
                value={formik.values.notes}
                onChange={(e)=>{formik.setFieldValue('notes',e.target.value);onEdit()}}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <div style={{ textAlign: "right" }}>
              <Button
                startIcon={<CloseIcon />}
                onClick={onClose}
                variant="text"
                style={{ color: theme.palette.secondary.main, marginRight: 16 }}
              >
                ANNULLA
              </Button>
              <Fab
                onClick={() => {
                  formik.submitForm();
                }}
                variant="extended"
                size="small"
                color="primary"
              >
                <CheckIcon />
                Conferma
              </Fab>
            </div>
          </Grid>
        </Grid>

        {/* <Typography
          color="textSecondary"
          variant="caption"
          component="p"
          style={{ marginBottom: 30, marginTop: 0 }}
        >
          * I campi contrassegnati con l’asterisco sono obbligatori
        </Typography> */}
        <Divider />
      </div>
    </div>
  );
};
