import React from "react";
import {
  Breadcrumbs as BreadcrumbsUI,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    margin: "0px auto 25px",
    [theme.breakpoints.up("md")]: {
      margin: "0px auto 50px",
    },
  },
}));

export const Breadcrumbs: React.FC<{ prev?: string; current: string }> = ({
  prev,
  current,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <BreadcrumbsUI
      className={classes.breadcrumbs}
      aria-label="breadcrumb"
      maxItems={isMobile ? 2 : 3}
    >
      <Typography
        color="textSecondary"
        variant="body1"
        component="span"
        style={{ marginTop: "0.75em" }}
      >
        Home
      </Typography>
      {prev && (
        <Typography
          color="textSecondary"
          variant="body1"
          component="span"
          style={{ marginTop: "0.75em" }}
        >
          {prev}
        </Typography>
      )}
      <Typography
        color="textPrimary"
        variant="body1"
        component="span"
        style={{ marginTop: "0.75em" }}
      >
        {current}
      </Typography>
    </BreadcrumbsUI>
  );
};
