import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { SetSettings, SetSettingsVariables } from "types/generated/schemaTypes";
import {
  FormControl,
  Grid,
  Typography,
  Divider,
  InputLabel,
  InputAdornment,
  IconButton,
  Input,
  FormHelperText,
  TextField,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SET_SETTINGS } from "mutation";
import { DialogForm, ActionType } from "components";
import { formatError } from "utils/errors";

export interface BannerFormValues {
  content: string;
  enabled: boolean;
}

const defaultInitialValues = {
  content: "",
  enabled: false,
};

const validationSchema = Yup.object({});

interface BannerFormProps {
  data: {
    content: string;
    enabled: boolean;
  };
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
}

export const BannerForm: React.FC<BannerFormProps> = ({
  data,
  open,
  onClose,
  onUpdate,
}) => {
  const [setSettingsMutation, { error: setSettingsError }] = useMutation<
    SetSettings,
    SetSettingsVariables
  >(SET_SETTINGS, {
    onCompleted: async (data) => {
      formik.resetForm();
      onUpdate();
    },
    onError: (err) => {
      console.log("Errore!", err);
    },
  });

  const formik = useFormik<BannerFormValues>({
    initialValues: data
      ? { content: data.content, enabled: data.enabled }
      : defaultInitialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const variables: SetSettingsVariables = {
        value: JSON.stringify({
          enabled: formik.values.enabled,
          content: formik.values.content,
        }),
        id: "BANNER",
      };
      await setSettingsMutation({ variables });
    },
  });

  return (
    <DialogForm
      open={open}
      title="Modifica Banner"
      onClose={() => {
        formik.resetForm();
        onClose();
      }}
      actions={[
        {
          type: ActionType.EXIT,
          label: "CHIUDI SENZA SALVARE",
          callback: () => {
            formik.resetForm();
            onClose();
          },
          disabled: formik.isSubmitting,
        },
        {
          type: ActionType.SAVE,
          label: "SALVA E CHIUDI",
          callback: formik.submitForm,
          disabled: formik.isSubmitting,
        },
      ]}
    >
      <div style={{ overflow: "hidden" }}>
        <Typography color="textSecondary" style={{ marginBottom: 36 }}>
          Per salvare le modifiche cliccare sul pulsante “salva e chiudi”.
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12}>
            {/* DESCRIPTION */}
            <FormControl fullWidth={true}>
              <TextField
                name="content"
                multiline={true}
                rows={6}
                error={formik.touched.content && !!formik.errors.content}
                helperText={
                  formik.touched.content &&
                  !!formik.errors.content &&
                  formik.errors.content
                }
                variant="outlined"
                id="content"
                label="Contenuto banner"
                value={formik.values.content}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={!formik.values.enabled}
                  onChange={() =>
                    formik.setFieldValue("enabled", !formik.values.enabled)
                  }
                  disabled={formik.isSubmitting}
                  name="enabled"
                  color={formik.values.enabled ? "default" : "secondary"}
                />
              }
              label={
                formik.values.enabled
                  ? "Banner attivo: disattiva"
                  : "Banner non attivo: attiva"
              }
            />
          </Grid>
        </Grid>

        <Divider style={{ marginTop: 20, marginBottom: 10 }} />

        {setSettingsError && (
          <Typography color="error" variant="body1">
            {setSettingsError && formatError(setSettingsError)}
          </Typography>
        )}
      </div>
    </DialogForm>
  );
};
