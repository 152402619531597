import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { AddCircleOutline, RemoveCircleOutline } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  count: {
    border: `solid 1px ${theme.palette.divider}`,
    padding: "0 8px",
    margin: "0 6px",
    borderRadius: 4,
  },
}));

export const CounterTool: React.FC<{
  onDecrement: () => void;
  onIncrement: () => void;
  count: number;
}> = ({ onDecrement, onIncrement, count }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <RemoveCircleOutline
        color="primary"
        style={{ cursor: "pointer" }}
        onClick={onDecrement}
      />
      <div className={classes.count}>
        <Typography variant="body1">{count}</Typography>
      </div>
      <AddCircleOutline
        color="primary"
        style={{ cursor: "pointer" }}
        onClick={onIncrement}
      />
    </div>
  );
};
