import React from "react";
import { Chip, ChipProps } from "@material-ui/core";
import { Cancel as CancelIcon } from "@material-ui/icons";

interface ButtonChipSuspendProps {
  onClick: () => void;
}

export const ButtonChipSuspend: React.FC<ButtonChipSuspendProps & ChipProps> =
  ({ onClick, ...props }) => {
    return (
      <Chip
        {...props}
        clickable={true}
        variant="default"
        size="small"
        color="default"
        deleteIcon={<CancelIcon />}
        label="Sospendi"
        onDelete={onClick}
        onClick={onClick}
      />
    );
  };
