import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ApolloError, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  Paper,
  Typography,
  TextField,
  useTheme,
  useMediaQuery,
  makeStyles,
  FilledInput,
} from "@material-ui/core";
import { LOGIN } from "mutation";
import { decode } from "jsonwebtoken";
import { isLoggedInVar, userRoleVar, userIdVar } from "localState";
import {
  LoginVariables,
  Login as LoginType,
  UserRole,
} from "types/generated/schemaTypes";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: "0 auto",
    padding: 35,
    maxWidth: 800,
    [theme.breakpoints.up("md")]: {
      padding: 100,
    },
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
    textAlign: "center",
  },
  submit: {
    minWidth: 253,
    margin: theme.spacing(1, 0, 0.5),
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(3, 0, 0.5),
    },
  },
  invalidEmail: {
    backgroundColor: theme.palette.error.main,
    color: "white",
    textAlign: "left",
    padding: theme.spacing(1),
  },
  text: {
    margin: "0px auto 30px",
    [theme.breakpoints.up("sm")]: {
      // maxWidth: 405,
    },
  },
  loginButtonWrapper: {
    margin: "auto",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 253,
    },
  },
}));

interface LoginFormValues {
  email: string;
  password: string;
}

const formatError = (err: ApolloError): string => {
  return err.graphQLErrors[0].message;
};

interface LoginFormProps {
  onResetPassword: () => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onResetPassword }) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const enableElevation = useMediaQuery(theme.breakpoints.up("md")) ? 1 : 0;
  const formik = useFormik<LoginFormValues>({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Indirizzo email non valido")
        .required("Campo obbligatorio"),
      password: Yup.string().required("Campo obbligatorio"),
    }),
    onSubmit: async (values) => {
      await login({
        variables: { email: values.email, password: values.password },
      });
    },
  });

  const [login, { error: loginError, loading: isLoggingIn }] = useMutation<
    LoginType,
    LoginVariables
  >(LOGIN, {
    onCompleted: (data) => {
      const token = data.login.token;
      localStorage.setItem("bga_token", token);
      const { userId, role } = decode(token) as {
        userId: string;
        role: string;
      };
      localStorage.setItem("bga_role", role);
      localStorage.setItem("bga_userId", userId);
      userRoleVar(role as UserRole);
      userIdVar(userId);
      isLoggedInVar(true);
      history.push(role === UserRole.AGENT ? '/agenti' : '/richieste-campionature');
    },
    onError: (err) => {
      formik.resetForm();
    },
  });

  return (
    <Paper className={classes.paper} elevation={enableElevation}>
      <>
        {/* <Typography
          align="center"
          component="h1"
          variant="h3"
          style={{ marginBottom: 0 }}
        >
          Gestisci i tuoi clienti
        </Typography> */}
        <Typography
          className={classes.text}
          align="left"
          variant="subtitle1"
          component="p"
        >
          Gentile agente inserisci i dati del tuo account. Per informazioni:{" "}
          <a
            href="mailto:areagenti@bromatech.it"
            style={{ color: theme.palette.primary.main }}
          >
            areagenti@bromatech.it
          </a>
        </Typography>
        <Divider style={{ margin: "15px auto 30px" }} />
        <form
          onSubmit={formik.handleSubmit}
          className={classes.form}
          noValidate
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl style={{ width: "100%" }}>
                <TextField
                  name="email"
                  error={formik.touched.email && !!formik.errors.email}
                  helperText={
                    formik.touched.email &&
                    !!formik.errors.email &&
                    formik.errors.email
                  }
                  variant="filled"
                  id="email"
                  size="medium"
                  label="Email*"
                  value={formik.values.email}
                  onChange={(e) =>
                    formik.setFieldValue("email", e.target.value.trim())
                  }
                  disabled={isLoggingIn}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth={true} variant="filled">
                <InputLabel
                  htmlFor="password"
                  error={formik.touched.password && !!formik.errors.password}
                >
                  Password*
                </InputLabel>
                <FilledInput
                  name="password"
                  error={formik.touched.password && !!formik.errors.password}
                  id="password"
                  value={formik.values.password}
                  onChange={(e) =>
                    formik.setFieldValue("password", e.target.value.trim())
                  }
                  disabled={formik.isSubmitting}
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText
                  error={formik.touched.password && !!formik.errors.password}
                  variant="standard"
                >
                  {formik.touched.password &&
                    !!formik.errors.password &&
                    formik.errors.password}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          {loginError && (
            <p className={classes.invalidEmail}>{formatError(loginError)}</p>
          )}
          <div className={classes.loginButtonWrapper}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disableElevation
              size="large"
              fullWidth={true}
              disabled={isLoggingIn}
              className={classes.submit}
            >
              Accedi
            </Button>
            <Typography variant="body2" align="center">
              <Link
                underline="hover"
                onClick={onResetPassword}
                style={{
                  color: theme.palette.primary.main,
                  cursor: "pointer",
                }}
              >
                Non ricordo la mia password!
              </Link>
            </Typography>
          </div>
        </form>
      </>
    </Paper>
  );
};

export default LoginForm;
