import { Profession, Specialization } from "types/generated/schemaTypes";

export const professionsMap = {
  [Profession.BIOLOGO]: {
    label: "Biologo",
    specializations: [
      {
        specialization: Specialization.BIOLOGO_NUTRIZIONISTA,
        label: "Biologo nutrizionista",
      },
      {
        specialization: Specialization.ALTRO,
        label: "Altro",
      },
    ],
  },
  [Profession.DIETISTA]: {
    label: "Dietista",
    specializations: [
      {
        specialization: Specialization.DIETISTA,
        label: "Dietista",
      },
    ],
  },
  [Profession.DISCIPLINE_OLISTICHE]: {
    label: "Discipline olistiche",
    specializations: [
      {
        specialization: Specialization.ERBORISTA,
        label: "Erborista",
      },
      {
        specialization: Specialization.FITOTERAPIA,
        label: "Fitoterapista",
      },
      {
        specialization: Specialization.IRIDOLOGIA,
        label: "Iridologia",
      },
      {
        specialization: Specialization.KINESIOLOGIA,
        label: "Kinesiologia",
      },
      {
        specialization: Specialization.MASSOKINESITERAPIA,
        label: "Massokinesiterapia",
      },
      {
        specialization: Specialization.NATUROPATA,
        label: "Naturopata",
      },
      {
        specialization: Specialization.OMEOPATA,
        label: "Omeopatia",
      },
      // {
      //   specialization: Specialization.OSTEOPATIA,
      //   label: "Osteopata",
      // },
      {
        specialization: Specialization.RIFLESSOLOGIA,
        label: "Riflessologia",
      },
      {
        specialization: Specialization.ALTRO,
        label: "Altro",
      },
    ],
  },
  [Profession.FARMACISTA]: {
    label: "Farmacista",
    specializations: [
      {
        specialization: Specialization.FARMACISTA,
        label: "Farmacista",
      },
      {
        specialization: Specialization.ALTRO,
        label: "Altro",
      },
    ],
  },
  [Profession.FISIOTERAPISTA]: {
    label: "Fisioterapista",
    specializations: [
      {
        specialization: Specialization.FISIOTERAPIA,
        label: "Fisioterapia",
      },
    ],
  },
  [Profession.IGIENISTA_DENTALE]: {
    label: "Igienista dentale",
    specializations: [
      {
        specialization: Specialization.IGIENISTA_DENTALE,
        label: "Igienista dentale",
      },
    ],
  },
  [Profession.MEDICO_CHIRURGO]: {
    label: "Medico chirurgo",
    specializations: [
      {
        specialization: Specialization.ALLERGOLOGIA_IMMUNOLOGIA_CLINICA,
        label: "Allergologia e immunologia clinica",
      },
      {
        specialization: Specialization.ANESTESIA_RIANIMAZIONE,
        label: "Anestesia e rianimazione",
      },
      {
        specialization: Specialization.CARDIOLOGIA,
        label: "Cardiologia",
      },
      {
        specialization: Specialization.CHIRURGIA_GENERALE,
        label: "Chirurgia ",
      },
      {
        specialization: Specialization.DERMATOLOGIA_VENEREOLOGIA,
        label: "Dermatologia e venereologia",
      },
      {
        specialization: Specialization.EMATOLOGIA,
        label: "Ematologia",
      },
      {
        specialization: Specialization.ENDOCRINOLOGIA,
        label: "Endocrinologia",
      },
      {
        specialization: Specialization.FISIATRIA,
        label: "Fisiatria",
      },
      {
        specialization: Specialization.GASTROENTEROLOGIA,
        label: "Gastroenterologia",
      },
      {
        specialization: Specialization.GENETICA_MEDICA,
        label: "Genetica medica",
      },
      {
        specialization: Specialization.GERIATRIA,
        label: "Geriatria",
      },
      {
        specialization: Specialization.GINECOLOGIA_OSTETRICIA,
        label: "Ginecologia e ostetricia",
      },
      {
        specialization: Specialization.IGIENE_E_MEDICINA_PREVENTIVA,
        label: "Igiene e medicina preventiva",
      },
      {
        specialization: Specialization.MALATTIE_APPARATO_RESPIRATORIO,
        label: "Malattie dell'apparato respiratorio",
      },
      {
        specialization: Specialization.MALATTIE_INFETTIVE,
        label: "Malattie infettive",
      },
      {
        specialization: Specialization.MEDICINA_LAVORO,
        label: "Medicina del lavoro e sicurezza degli ambienti di lavoro",
      },
      {
        specialization: Specialization.MEDICINA_SPORT,
        label: "Medicina dello sport",
      },
      {
        specialization: Specialization.MEDICINA_GENERALE,
        label: "Medicina generale (medici di famiglia)",
      },
      {
        specialization: Specialization.MEDICINA_INTERNA,
        label: "Medicina interna",
      },
      {
        specialization: Specialization.MEDICINA_LEGALE,
        label: "Medicina legale",
      },
      {
        specialization: Specialization.MICROBIOLOGIA_VIROLOGIA,
        label: "Microbiologia e virologia",
      },
      {
        specialization: Specialization.NEFROLOGIA,
        label: "Nefrologia",
      },
      // {
      //   specialization: Specialization.NEUROCHIRURGIA,
      //   label: "Neurochirurgia",
      // },
      {
        specialization: Specialization.NEUROLOGIA,
        label: "Neurologia",
      },
      {
        specialization: Specialization.NEUROPSICHIATRIA_INFANTILE,
        label: "Neuropsichiatria infantile",
      },
      {
        specialization: Specialization.OFTALMOLOGIA,
        label: "Oculistica (Oftalmologia)",
      },
      {
        specialization: Specialization.ONCOLOGIA,
        label: "Oncologia",
      },
      {
        specialization: Specialization.ORTOPEDIA_TRAUMATOLOGIA,
        label: "Ortopedia e traumatologia",
      },
      {
        specialization: Specialization.OTORINOLARINGOIATRIA,
        label: "Otorinolaringoiatria",
      },
      {
        specialization: Specialization.PATOLOGIA_CLINICA,
        label:
          "Patologia clinica (laboratorio di analisi chimico-cliniche e microbiologia)",
      },
      {
        specialization: Specialization.PEDIATRIA,
        label: "Pediatria",
      },
      {
        specialization: Specialization.PRIVO_DI_SPECIALIZZAZIONE,
        label: "Privo di specializzazione",
      },
      {
        specialization: Specialization.PSICHIATRIA,
        label: "Psichiatria",
      },
      {
        specialization: Specialization.PSICOTERAPIA,
        label: "Psicoterapia",
      },
      {
        specialization: Specialization.REUMATOLOGIA,
        label: "Reumatologia",
      },
      {
        specialization: Specialization.SCIENZA_ALIMENTAZIONE_DIETETICA,
        label: "Scienza dell'alimentazione e dietetica",
      },
      {
        specialization: Specialization.UROLOGIA,
        label: "Urologia",
      },
      {
        specialization: Specialization.ALTRO,
        label: "Altro",
      },
    ],
  },
  [Profession.ODONTOIATRA]: {
    label: "Odontoiatra",
    specializations: [
      {
        specialization: Specialization.ODONTOIATRA,
        label: "Odontoiatria",
      },
    ],
  },
  [Profession.PROFESSIONI_SANITARIE_DELLA_RIABILITAZIONE]: {
    label: 'Professioni sanitarie della riabilitazione',
    specializations: [
      {
        specialization: Specialization.FISIOTERAPIA,
        label: 'Fisioterapista',
      },
      {
        specialization: Specialization.FISIOTERAPIA_DEL_PAVIMENTO_PELVICO,
        label: 'Fisioterapista del pavimento pelvico',
      },
      {
        specialization: Specialization.LOGOPEDISTA,
        label: 'Logopedista',
      },
      {
        specialization: Specialization.ORTOTTISTA_ASSISTENTE_DI_OFTALMOLOGIA,
        label: 'Ortottista - assistente di oftalmologia',
      },
      {
        specialization: Specialization.OSTEOPATIA,
        label: 'Osteopata',
      },
      {
        specialization: Specialization.PODOLOGO,
        label: 'Podologo',
      },
      {
        specialization: Specialization.ALTRO,
        label: 'Altro',
      },
    ],
  },
  [Profession.PROFESSIONI_SANITARIE_INFERMIERISTICHE_OSTETRICHE]: {
    label: 'Professioni sanitarie infermieristiche e ostetriche',
    specializations: [
      {
        specialization: Specialization.INFERMIERE,
        label: 'Infermiere',
      },
      {
        specialization: Specialization.INFERMIERE_PEDIATRICO,
        label: 'Infermiere pediatrico',
      },
      {
        specialization: Specialization.OSTETRICA,
        label: 'Ostetrica',
      },
    ],
  },
  [Profession.PROFESSIONI_TECNICO_SANITARIE]: {
    label: 'Professioni tecnico sanitarie',
    specializations: [
      {
        specialization: Specialization.DIETISTA,
        label: 'Dietista',
      },
      {
        specialization: Specialization.IGIENISTA_DENTALE,
        label: 'Igienista dentale',
      },
      {
        specialization: Specialization.TECNICO_ORTOPEDICO,
        label: 'Tecnico ortopedico',
      },
      {
        specialization: Specialization.TECNICO_SANITARIO_DI_LABORATORIO_MEDICO,
        label: 'Tecnico sanitario di laboratorio biomedico',
      },
      {
        specialization: Specialization.TECNICO_SANITARIO_DI_RADIOLOGIA_MEDICA,
        label: 'Tecnico sanitario di radiologia medica',
      },
      {
        specialization: Specialization.ALTRO,
        label: 'Altro',
      },
    ],
  },
  [Profession.PSICOLOGO]: {
    label: "Psicologo",
    specializations: [
      {
        specialization: Specialization.PSICOLOGIA,
        label: "Psicologia",
      },
      {
        specialization: Specialization.PSICOTERAPIA,
        label: "Psicoterapia",
      },
    ],
  },
  [Profession.ALTRO]: {
    label: "Altro",
    specializations: [
      {
        specialization: Specialization.ALTRO,
        label: "Altra professione",
      },
    ],
  },
};
