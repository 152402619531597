import React, { useState } from "react";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Grid,
  InputAdornment,
  Input,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Hidden,
  Fab,
  makeStyles,
  Divider,
  TextField,
  IconButton,
  useTheme,
} from "@material-ui/core";
import {
  Add as AddIcon,
  Search as SearchIcon,
  ViewModule,
} from "@material-ui/icons";
import { GET_DOCUMENT, GET_DOCUMENTS } from "query";
import { DELETE_DOCUMENT, REQUEST_DOCUMENTS_SAMPLES } from "mutation";
import {
  GetDocuments,
  GetDocumentsVariables,
  DocumentFields,
  MedicalDocumentOrderInput,
  MedicalDocumentWhereInput,
  GetDocument,
  GetDocumentVariables,
  DeleteDocument,
  DeleteDocumentVariables,
  UserRole,
  RequestDocumentsSamples,
  RequestDocumentsSamplesVariables,
} from "types/generated/schemaTypes";
import {
  DialogError,
  DialogWarning,
  Breadcrumbs,
  PageTitle,
  FiltersWrapper,
  DocumentItem,
  DialogRequest,
  DialogInfo,
} from "components";
import { formatError } from "utils/errors";
import { Pagination } from "@material-ui/lab";
import { DocumentForm } from "components/documentForm";
import { userRoleVar } from "localState";

const useStyles = makeStyles((theme) => ({
  sendRequest: {
    position: "fixed",
    bottom: 100,
    right: 20,
  },
}));

export default function GestioneDocumenti() {
  const client = useApolloClient();
  const classes = useStyles();
  const role = userRoleVar();
  const isAgent = role === UserRole.AGENT;
  const [selection, setSelection] = useState<{ [id: string]: number }>({});
  const [currentDocument, setCurrentDocument] = useState<null | DocumentFields>(
    null
  );
  const [searchString, setSearchString] = useState("");
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState<MedicalDocumentOrderInput>(
    MedicalDocumentOrderInput.NAME_ASC
  );
  const [isShrink, setIsShrink] = useState(false);
  const [showDocumentForm, setShowDocumentForm] = useState(false);
  const [showDialogRequest, setShowDialogRequest] = useState(false);
  const [notes, setNotes] = useState("");
  const [showRequestSent, setShowRequestSent] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const theme = useTheme();
  const limit = isAgent ? 16 : 12;

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setOffset((value - 1) * limit);
    setPage(value);
  };

  const where: MedicalDocumentWhereInput = {};

  if (isAgent) where.available = true;

  if (searchString.length > 2) where.searchPattern = searchString;

  const {
    data: getDocumentsData,
    loading: getDocumentsLoading,
    error: getDocumentsError,
    refetch: refetchGetDocuments,
  } = useQuery<GetDocuments, GetDocumentsVariables>(GET_DOCUMENTS, {
    fetchPolicy: "network-only",
    variables: {
      where,
      order,
      offset,
      limit,
    },
  });

  const { data: getAllDocumentsData, loading: getAllDocumentsLoading } =
    useQuery<GetDocuments, GetDocumentsVariables>(GET_DOCUMENTS, {
      fetchPolicy: "network-only",
      variables: {
        offset: 0,
        limit: 200,
      },
    });

  const [deleteDocumentMutation] = useMutation<
    DeleteDocument,
    DeleteDocumentVariables
  >(DELETE_DOCUMENT, {
    refetchQueries: [
      {
        query: GET_DOCUMENTS,
        variables: {
          where,
          order,
          offset,
          limit,
        },
      },
    ],
    onCompleted: () => {
      setShowWarningDialog(false);
    },
    onError: (err) => {
      setShowWarningDialog(false);
      setErrorMessage(formatError(err));
      setShowErrorDialog(true);
      console.log(err);
    },
  });

  const [sendRequestMutation] = useMutation<
    RequestDocumentsSamples,
    RequestDocumentsSamplesVariables
  >(REQUEST_DOCUMENTS_SAMPLES, {
    onCompleted: () => {
      setShowDialogRequest(false);
      setSelection({});
      setShowRequestSent(true);
    },
    onError: (err) => {
      setShowDialogRequest(false);
      setErrorMessage(formatError(err));
      setShowErrorDialog(true);
      console.log(err);
    },
  });

  const loadDocument = async (medicalDocumentId: string) => {
    const data = await client.query<GetDocument, GetDocumentVariables>({
      query: GET_DOCUMENT,
      variables: { medicalDocumentId },
      fetchPolicy: "cache-first",
    });
    setCurrentDocument(data.data.getMedicalDocument);
  };

  const deleteDocument = async (medicalDocumentId: string | null) => {
    if (medicalDocumentId)
      await deleteDocumentMutation({
        variables: {
          medicalDocumentId,
        },
      });
  };

  return (
    <>
      <PageTitle
        title={
          isAgent
            ? "RICHIESTA MATERIALE INFORMATIVO"
            : "GESTIONE MATERIALE INFORMATIVO"
        }
      />
      <Breadcrumbs
        current={
          isAgent ? "Materiale Informativo" : "Gestione Materiale Informativo"
        }
      />
      {isAgent && (
        <FiltersWrapper>
          <Typography style={{ fontWeight: 700 }} variant="body1">
            RISERVATO ESCLUSIVAMENTE AL PERSONALE SANITARIO (NON DIVULGABILE AL
            PUBBLICO)
          </Typography>
        </FiltersWrapper>
      )}
      <FiltersWrapper>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item xs={6} md={3}>
            <FormControl fullWidth={true} variant="standard" size="medium">
              <InputLabel id="order-label">Ordina per</InputLabel>
              <Select
                name="order"
                value={order}
                labelId="order-label"
                id="order"
                label="Ordina per"
                onChange={(e) => {
                  setOffset(0);
                  setOrder(e.target.value as MedicalDocumentOrderInput);
                }}
              >
                <MenuItem value={MedicalDocumentOrderInput.CREATION_DESC}>
                  Data creazione ↓
                </MenuItem>
                <MenuItem value={MedicalDocumentOrderInput.CREATION_ASC}>
                  Data creazione ↑
                </MenuItem>
                <MenuItem value={MedicalDocumentOrderInput.NAME_DESC}>
                  Nome (Z-A)
                </MenuItem>
                <MenuItem value={MedicalDocumentOrderInput.NAME_ASC}>
                  Nome (A-Z)
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControl fullWidth={true} variant="standard" size="medium">
              <InputLabel htmlFor="search">Cerca</InputLabel>
              <Input
                name="searchString"
                id="searchString"
                autoFocus={searchString.length > 2}
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          {/*
          // @ts-ignore */}
          <Hidden mdDown>
            <Grid item md={3}>
              &nbsp;
            </Grid>
          </Hidden>
          <Grid item xs={12} md={3} style={{ textAlign: "right" }}>
            {isAgent ? (
              <IconButton onClick={() => setIsShrink(!isShrink)}>
                <ViewModule
                  htmlColor="#000000"
                  style={{ transform: `scaleY(${isShrink ? "1.5" : "0.6"})` }}
                />
              </IconButton>
            ) : (
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={() => {
                  setCurrentDocument(null);
                  setShowDocumentForm(true);
                }}
                startIcon={<AddIcon />}
              >
                Aggiungi
              </Button>
            )}
          </Grid>
        </Grid>
      </FiltersWrapper>
      {getDocumentsLoading && (
        <div>Caricamento del material informativo in corso</div>
      )}
      <Grid container spacing={1}>
        {!getDocumentsLoading && !getDocumentsError && getDocumentsData && (
          <>
            {getDocumentsData.getMedicalDocuments.medicalDocuments.map(
              (document) => (
                <Grid item xs={12} sm={6} md={4} xl={3} key={document.id}>
                  <DocumentItem
                    shrink={isShrink}
                    isAgent={isAgent}
                    onDelete={async () => {
                      await loadDocument(document.id);
                      setShowWarningDialog(true);
                    }}
                    onUpdate={async () => {
                      await loadDocument(document.id);
                      setShowDocumentForm(true);
                    }}
                    onIncrement={() => {
                      const total = selection[document.id] || 0;
                      setSelection({ ...selection, [document.id]: total + 1 });
                    }}
                    onDecrement={() => {
                      const total = selection[document.id] || 0;
                      if (total > 1) {
                        setSelection({
                          ...selection,
                          [document.id]: total - 1,
                        });
                      } else {
                        const copy = Object.assign({}, selection);
                        delete copy[document.id];
                        setSelection({
                          ...copy,
                        });
                      }
                    }}
                    count={selection[document.id] || 0}
                    document={document}
                  />
                </Grid>
              )
            )}
          </>
        )}
      </Grid>
      {getDocumentsData &&
        getDocumentsData.getMedicalDocuments.medicalDocuments.length > 0 && (
          <div
            style={{
              marginTop: 30,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Pagination
              count={Math.ceil(
                getDocumentsData.getMedicalDocuments.total / limit
              )}
              // count={1}
              page={page}
              shape="rounded"
              color="primary"
              onChange={handleChangePage}
            />
          </div>
        )}

      <DocumentForm
        open={showDocumentForm}
        onClose={() => {
          setShowDocumentForm(false);
        }}
        onCreate={async () => {
          setShowDocumentForm(false);
          await refetchGetDocuments({
            where,
            order,
            offset,
            limit,
          });
        }}
        onUpdate={async () => {
          setShowDocumentForm(false);
        }}
        document={currentDocument}
      />

      <DialogError
        title="Errore"
        open={showErrorDialog}
        onClose={() => setShowErrorDialog(false)}
      >
        <Typography variant="body1" color="error">
          {errorMessage}
        </Typography>
      </DialogError>

      <DialogWarning
        title="Attenzione"
        open={showWarningDialog}
        onClose={() => setShowWarningDialog(false)}
        onContinue={() => deleteDocument(currentDocument?.id || null)}
      >
        <Typography variant="body1">
          Questa operazione è irreversibile. Sei sicuro di voler procedere?
        </Typography>
      </DialogWarning>

      <DialogRequest
        title="Riepilogo richiesta"
        disabled={false}
        open={showDialogRequest}
        onClose={() => setShowDialogRequest(false)}
        onSend={async () =>
          await sendRequestMutation({
            variables: {
              documentsSamples: Object.keys(selection).map((key) => {
                return { documentId: key, quantity: selection[key] };
              }),
              notes,
            },
          })
        }
      >
        <Typography variant="body1" color="textSecondary">
          Verifica l’elenco dei documenti selezionati.
          <br />
          Se devi aggiungere o togliere documenti chiudi questo pannello (le
          scelte effettuate non verranno perse) per modificare le tue scelte.
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          style={{ fontWeight: 700, marginTop: 20, marginBottom: 10 }}
        >
          Ordine
        </Typography>

        {getAllDocumentsData &&
          getAllDocumentsData.getMedicalDocuments &&
          Object.keys(selection).map((key) => (
            <Typography variant="body1" color="textPrimary">
              {selection[key]}x{" "}
              {
                getAllDocumentsData.getMedicalDocuments.medicalDocuments.find(
                  (doc) => doc.id === key
                )?.name
              }
            </Typography>
          ))}
        <Divider style={{ marginTop: 20, marginBottom: 25 }} />
        <FormControl fullWidth={true}>
          <TextField
            name="notes"
            variant="outlined"
            multiline={true}
            rows={4}
            id="notes"
            size="small"
            label="Note"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </FormControl>
        <Divider style={{ marginTop: 25, marginBottom: 25 }} />
      </DialogRequest>

      <DialogInfo
        onClose={() => {
          setShowRequestSent(false);
        }}
        title="La richiesta è stata inviata correttamente"
        closeLabel="OK"
        open={showRequestSent}
      >
        <Typography variant="body1">
          Per i dettagli sulla spedizione contattaci all’indirizzo{" "}
          <a
            href="mailto:areagenti@bromatech.it"
            style={{ color: theme.palette.primary.main }}
          >
            areagenti@bromatech.it
          </a>
        </Typography>
      </DialogInfo>

      {isAgent && (
        <Fab
          color="primary"
          aria-label="add"
          className={classes.sendRequest}
          onClick={() => setShowDialogRequest(true)}
          disabled={Object.keys(selection).length < 1 || getAllDocumentsLoading}
        >
          <AddIcon />
        </Fab>
      )}
    </>
  );
}
