import React, { useState } from "react";
import { Tabs, Tab } from "@material-ui/core";
import {
  PageTitle,
  Breadcrumbs,
  PendingOrders,
  ProcessedOrders,
} from "components";
import TabsWrapper from "components/tabsWrapper";

export default function Orders() {
  const [index, setIndex] = useState(0);
  return (
    <>
      <PageTitle title="GESTIONE RICHIESTE" />
      <Breadcrumbs
        prev="Gestione Richieste"
        current={["Richieste pending", "Storico richieste"][index]}
      />
      <TabsWrapper>
        <Tabs
          value={index}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_, newIndex: any) => {
            setIndex(newIndex);
          }}
        >
          <Tab label="RICHIESTE PENDING" />
          <Tab label="STORICO RICHIESTE" />
        </Tabs>
      </TabsWrapper>
      {index === 0 ? <PendingOrders /> : <ProcessedOrders />}
    </>
  );
}
