import React from "react";
import { useMutation } from "@apollo/client";
import {
  UserFields,
  UpdateUser,
  UpdateUserVariables,
  CreateUser,
  CreateUserVariables,
} from "types/generated/schemaTypes";
import {
  FormControl,
  Grid,
  TextField,
  Typography,
  Divider,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CREATE_USER, UPDATE_USER } from "mutation";
import { DialogForm, ActionType } from "components";
import { formatError } from "utils/errors";

export interface AgentFormValues {
  name: string;
  lastname: string;
  email: string;
  active: boolean;
  agentCrm: string;
}

const defaultInitialValues = {
  name: "",
  lastname: "",
  email: "",
  active: true,
  agentCrm: "",
};

const parseInitialValue = (input: UserFields): AgentFormValues => {
  const values: AgentFormValues = {
    name: input.name,
    lastname: input.lastname,
    email: input.email,
    agentCrm: input.agentCrm || "",
    active: input.active,
  };
  return values;
};

const validationSchema = Yup.object({
  name: Yup.string().required("Campo obbligatorio"),
  lastname: Yup.string().required("Campo obbligatorio"),
  email: Yup.string()
    .email("Formato mail non valido")
    .required("Campo obbligatorio"),
  agentCrm: Yup.string().required("Campo obbligatorio"),
});

interface AgentFormProps {
  open: boolean;
  onClose: () => void;
  onCreate: () => void;
  onUpdate: () => void;
  agent: UserFields | null;
}

export const AgentForm: React.FC<AgentFormProps> = ({
  open,
  onClose,
  onCreate,
  onUpdate,
  agent,
}) => {
  const [createAgentMutation, { error: createAgentError }] = useMutation<
    CreateUser,
    CreateUserVariables
  >(CREATE_USER, {
    onCompleted: async (data) => {
      formik.resetForm();
      onCreate();
    },
    onError: (err) => {
      console.log("Errore!", err);
    },
  });
  const [updateAgentMutation, { error: updateAgentError }] = useMutation<
    UpdateUser,
    UpdateUserVariables
  >(UPDATE_USER, {
    onCompleted: async (data) => {
      formik.resetForm();
      onUpdate();
    },
    onError: (err) => {
      console.log("Errore!", err);
    },
  });

  const update = !!agent;
  const formik = useFormik<AgentFormValues>({
    initialValues: agent
      ? parseInitialValue(agent as UserFields)
      : defaultInitialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      let variables: CreateUserVariables | UpdateUserVariables;
      if (!update) {
        // create
        variables = {
          userData: {
            name: values.name,
            lastname: values.lastname,
            email: values.email,
            agentCrm: values.agentCrm,
            role: "AGENT",
            password: Math.random().toString(36).slice(-8),
          },
        } as CreateUserVariables;
        await createAgentMutation({ variables });
      } else {
        // update
        variables = {
          userData: {
            name: values.name,
            lastname: values.lastname,
            email: values.email,
            agentCrm: values.agentCrm,
            active: values.active,
          },
          userId: (agent as UserFields).id,
        } as UpdateUserVariables;
        await updateAgentMutation({ variables });
      }
    },
  });

  return (
    <DialogForm
      open={open}
      title={update ? "Modifica Agente" : "Aggiungi un Agente"}
      onClose={() => {
        formik.resetForm();
        onClose();
      }}
      actions={[
        {
          type: ActionType.EXIT,
          label: "CHIUDI SENZA SALVARE",
          callback: () => {
            formik.resetForm();
            onClose();
          },
          disabled: formik.isSubmitting,
        },
        {
          type: ActionType.SAVE,
          label: "SALVA",
          callback: formik.submitForm,
          disabled: formik.isSubmitting,
        },
      ]}
    >
      <div style={{ overflow: "hidden" }}>
        <Typography color="textSecondary" style={{ marginBottom: 36 }}>
          Inserisci tutte le informazioni richieste e salva per aggiungere un
          Agente.
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            {/* NAME */}
            <FormControl fullWidth={true}>
              <TextField
                name="name"
                error={formik.touched.name && !!formik.errors.name}
                helperText={
                  formik.touched.name &&
                  !!formik.errors.name &&
                  formik.errors.name
                }
                variant="standard"
                id="name"
                size="small"
                label="Nome*"
                value={formik.values.name}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* LASTNAME */}
            <FormControl fullWidth={true}>
              <TextField
                name="lastname"
                error={formik.touched.lastname && !!formik.errors.lastname}
                helperText={
                  formik.touched.lastname &&
                  !!formik.errors.lastname &&
                  formik.errors.lastname
                }
                variant="standard"
                id="lastname"
                size="small"
                label="Cognome*"
                value={formik.values.lastname}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* EMAIL */}
            <FormControl fullWidth={true}>
              <TextField
                name="email"
                error={formik.touched.email && !!formik.errors.email}
                helperText={
                  formik.touched.email &&
                  !!formik.errors.email &&
                  formik.errors.email
                }
                variant="standard"
                id="email"
                size="small"
                label="Email*"
                value={formik.values.email}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* CODICE AGENTE CRM */}
            <FormControl fullWidth={true}>
              <TextField
                name="agentCrm"
                error={formik.touched.agentCrm && !!formik.errors.agentCrm}
                helperText={
                  formik.touched.agentCrm &&
                  !!formik.errors.agentCrm &&
                  formik.errors.agentCrm
                }
                variant="standard"
                id="agentCrm"
                size="small"
                label="Codice agente*"
                value={formik.values.agentCrm}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Typography
          color="textSecondary"
          variant="caption"
          component="p"
          style={{ marginBottom: 20, marginTop: 20 }}
        >
          * I campi contrassegnati con l’asterisco sono obbligatori
        </Typography>

        {agent && (
          <FormControlLabel
            control={
              <Switch
                checked={!formik.values.active}
                onChange={() =>
                  formik.setFieldValue("active", !formik.values.active)
                }
                disabled={formik.isSubmitting}
                name="active"
                color={formik.values.active ? "default" : "secondary"}
              />
            }
            label={
              formik.values.active
                ? "Agente attivo: sospendi"
                : "Agente sospeso: riattiva"
            }
          />
        )}
        <Divider style={{ marginTop: 20, marginBottom: 10 }} />

        {(createAgentError || updateAgentError) && (
          <Typography color="error" variant="body1">
            {createAgentError && formatError(createAgentError)}
            {updateAgentError && formatError(updateAgentError)}
          </Typography>
        )}
      </div>
    </DialogForm>
  );
};
