import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  Grid,
  InputAdornment,
  Input,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Fab,
  makeStyles,
  Divider,
  TextField,
  useTheme,
  TablePagination,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
} from "@material-ui/core";
import {
  Add as AddIcon,
  DoneOutline,
  Search as SearchIcon,
} from "@material-ui/icons";
import { GET_PRODUCTS } from "query";
import { REQUEST_PRODUCTS_SAMPLES } from "mutation";
import {
  GetProducts,
  GetProductsVariables,
  ProductOrderInput,
  ProductWhereInput,
  RequestProductsSamples,
  RequestProductsSamplesVariables,
} from "types/generated/schemaTypes";
import {
  DialogError,
  Breadcrumbs,
  PageTitle,
  FiltersWrapper,
  DialogRequest,
  DialogInfo,
  CounterTool,
} from "components";
import { formatError } from "utils/errors";

const useStyles = makeStyles((theme) => ({
  sendRequest: {
    position: "fixed",
    bottom: 100,
    right: 20,
  },
}));

export default function ProductsSamples() {
  const classes = useStyles();
  const [selection, setSelection] = useState<{ [id: string]: number }>({});

  const [searchString, setSearchString] = useState("");
  const [order, setOrder] = useState<ProductOrderInput>(
    ProductOrderInput.NAME_ASC
  );
  const [showDialogRequest, setShowDialogRequest] = useState(false);
  const [notes, setNotes] = useState("");
  const [showRequestSent, setShowRequestSent] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const theme = useTheme();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const handleChangePage = (event: unknown, newPage: number) => {
    setOffset(newPage * limit);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLimit(parseInt(event.target.value, 10));
    setOffset(0);
  };

  const where: ProductWhereInput = { archived: false, forSale: false };

  if (searchString.length > 2) where.searchPattern = searchString;

  const {
    data: getProductsData,
    loading: getProductsLoading,
    error: getProductsError,
  } = useQuery<GetProducts, GetProductsVariables>(GET_PRODUCTS, {
    fetchPolicy: "network-only",
    variables: {
      where,
      order,
      offset,
      limit,
    },
  });

  const { data: getAllProductsData, loading: getAllProductsLoading } = useQuery<
    GetProducts,
    GetProductsVariables
  >(GET_PRODUCTS, {
    fetchPolicy: "network-only",
    variables: {
      offset: 0,
      limit: 200,
    },
  });

  const [sendRequestMutation] = useMutation<
    RequestProductsSamples,
    RequestProductsSamplesVariables
  >(REQUEST_PRODUCTS_SAMPLES, {
    onCompleted: () => {
      setShowDialogRequest(false);
      setSelection({});
      setShowRequestSent(true);
    },
    onError: (err) => {
      setShowDialogRequest(false);
      setErrorMessage(formatError(err));
      setShowErrorDialog(true);
      console.log(err);
    },
  });

  return (
    <>
      <PageTitle title="RICHIESTA DI PRODOTTI CAMPIONE" />
      <Breadcrumbs current="Prodotti campione" />
      <FiltersWrapper>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item xs={6} md={3}>
            <FormControl fullWidth={true} variant="standard" size="medium">
              <InputLabel id="order-label">Ordina per</InputLabel>
              <Select
                name="order"
                value={order}
                labelId="order-label"
                id="order"
                label="Ordina per"
                onChange={(e) => {
                  setOffset(0);
                  setOrder(e.target.value as ProductOrderInput);
                }}
              >
                <MenuItem value={ProductOrderInput.CREATION_DESC}>
                  Data creazione ↓
                </MenuItem>
                <MenuItem value={ProductOrderInput.CREATION_ASC}>
                  Data creazione ↑
                </MenuItem>
                <MenuItem value={ProductOrderInput.NAME_DESC}>
                  Nome (Z-A)
                </MenuItem>
                <MenuItem value={ProductOrderInput.NAME_ASC}>
                  Nome (A-Z)
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControl fullWidth={true} variant="standard" size="medium">
              <InputLabel htmlFor="search">Cerca</InputLabel>
              <Input
                name="searchString"
                id="searchString"
                autoFocus={searchString.length > 2}
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      </FiltersWrapper>
      {getProductsLoading && (
        <div>Caricamento dei prodotti campione in corso</div>
      )}

      {!getProductsLoading && !getProductsError && getProductsData && (
        <>
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell variant="head" align="left">
                      NOME
                    </TableCell>
                    <TableCell
                      variant="head"
                      align="left"
                      style={{ width: 80 }}
                    >
                      QUANTITÀ
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getProductsData.getProducts.products.map((product) => {
                    return (
                      <TableRow key={product.id}>
                        <TableCell align="left">
                          <div style={{ display: "flex" }}>
                            <DoneOutline
                              htmlColor={
                                selection[product.id]
                                  ? theme.palette.success.main
                                  : "transparent"
                              }
                            />
                            <Typography
                              component="span"
                              variant="body1"
                              color="textSecondary"
                              style={{ marginLeft: 15 }}
                            >
                              {product.name}
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            component="span"
                            variant="body1"
                            color="textSecondary"
                          >
                            <CounterTool
                              onIncrement={() => {
                                const total = selection[product.id] || 0;
                                setSelection({
                                  ...selection,
                                  [product.id]: total + 1,
                                });
                              }}
                              onDecrement={() => {
                                const total = selection[product.id] || 0;
                                if (total > 1) {
                                  setSelection({
                                    ...selection,
                                    [product.id]: total - 1,
                                  });
                                } else {
                                  const copy = Object.assign({}, selection);
                                  delete copy[product.id];
                                  setSelection({
                                    ...copy,
                                  });
                                }
                              }}
                              count={selection[product.id] || 0}
                            />
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {getProductsData.getProducts.products.length < 11 && (
                    <>
                      {new Array(
                        10 - getProductsData.getProducts.products.length
                      )
                        .fill(10)
                        .map(() => (
                          <TableRow>
                            <TableCell variant="head" align="left">
                              &nbsp;
                            </TableCell>
                            <TableCell variant="head" align="left">
                              &nbsp;
                            </TableCell>
                          </TableRow>
                        ))}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              labelRowsPerPage="Totale prodotti per pagina"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} di ${count}`
              }
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={getProductsData ? getProductsData.getProducts.total : 0}
              rowsPerPage={limit}
              page={Math.floor(offset / limit)}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </>
      )}

      <DialogError
        title="Errore"
        open={showErrorDialog}
        onClose={() => setShowErrorDialog(false)}
      >
        <Typography variant="body1" color="error">
          {errorMessage}
        </Typography>
      </DialogError>

      <DialogRequest
        title="Riepilogo richiesta"
        disabled={false}
        open={showDialogRequest}
        onClose={() => setShowDialogRequest(false)}
        onSend={async () =>
          await sendRequestMutation({
            variables: {
              productsSamples: Object.keys(selection).map((key) => {
                return { productId: key, quantity: selection[key] };
              }),
              notes,
            },
          })
        }
      >
        <Typography variant="body1" color="textSecondary">
          Verifica l’elenco dei prodotti selezionati.
          <br />
          Se devi aggiungere o togliere prodotti chiudi questo pannello (le
          scelte effettuate non verranno perse) per modificare le tue scelte.
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          style={{ fontWeight: 700, marginTop: 20, marginBottom: 10 }}
        >
          Ordine
        </Typography>

        {getAllProductsData &&
          getAllProductsData.getProducts &&
          Object.keys(selection).map((key) => (
            <Typography variant="body1" color="textPrimary">
              {selection[key]}x{" "}
              {
                getAllProductsData.getProducts.products.find(
                  (doc) => doc.id === key
                )?.name
              }
            </Typography>
          ))}
        <Divider style={{ marginTop: 20, marginBottom: 25 }} />
        <FormControl fullWidth={true}>
          <TextField
            name="notes"
            variant="outlined"
            multiline={true}
            rows={4}
            id="notes"
            size="small"
            label="Note"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </FormControl>
        <Divider style={{ marginTop: 25, marginBottom: 25 }} />
      </DialogRequest>

      <DialogInfo
        onClose={() => {
          setShowRequestSent(false);
        }}
        title="La richiesta è stata inviata correttamente"
        closeLabel="OK"
        open={showRequestSent}
      >
        <Typography variant="body1">
          Per i dettagli sulla spedizione contattaci all’indirizzo{" "}
          <a
            href="mailto:areagenti@bromatech.it"
            style={{ color: theme.palette.primary.main }}
          >
            areagenti@bromatech.it
          </a>
        </Typography>
      </DialogInfo>

      <Fab
        color="primary"
        aria-label="add"
        className={classes.sendRequest}
        onClick={() => setShowDialogRequest(true)}
        disabled={Object.keys(selection).length < 1 || getAllProductsLoading}
      >
        <AddIcon />
      </Fab>
    </>
  );
}
