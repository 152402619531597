import { makeVar } from "@apollo/client";
import { UserRole } from "./types/generated/schemaTypes";

export const isLoggedInVar = makeVar<boolean>(
  !!localStorage.getItem("bga_token")
);

export const userRoleVar = makeVar<UserRole>(
  localStorage.getItem("bga_role") as UserRole
);

export const userIdVar = makeVar<string | null>(
  localStorage.getItem("bga_userId") || null
);

export const maintenanceModeVar = makeVar<boolean>(
  localStorage.getItem("bga_maintenanceMode") === "true"
);
