import React from "react";
import { Chip, ChipProps } from "@material-ui/core";
import { GetApp as Download } from "@material-ui/icons";

interface ButtonChipDownloadProps {
  onClick: () => void;
  label: string;
}

export const ButtonChipDownload: React.FC<ButtonChipDownloadProps & ChipProps> =
  ({ onClick, label, ...props }) => {
    return (
      <Chip
        {...props}
        clickable={true}
        variant="default"
        size="small"
        color="secondary"
        deleteIcon={<Download />}
        label={label}
        onDelete={onClick}
        onClick={onClick}
      />
    );
  };
