import React, { ReactNode } from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 15,
    [theme.breakpoints.up("md")]: {
      marginBottom: 30,
    },
  },
}));

const TabsWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
};

export default TabsWrapper;
