import React, { useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  OrderFields,
  TherapyFields,
  ProductFields,
  CreateOrder,
  CreateOrderVariables,
  UpdateOrderVariables,
  UpdateOrder,
  GetClient,
  GetClientVariables,
} from "types/generated/schemaTypes";
import {
  FormControl,
  Grid,
  TextField,
  Typography,
  Divider,
  makeStyles,
  RadioGroup,
  FormHelperText,
  Radio,
  FormControlLabel,
  Fab,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import {
  Add as AddIcon,
  DeleteForever as DeleteIcon,
} from "@material-ui/icons";

import { useFormik } from "formik";
import * as Yup from "yup";
import { CREATE_ORDER, UPDATE_ORDER } from "mutation";
import { GET_CLIENT } from "query";
import { DialogForm, ActionType } from "components";
import { formatError } from "utils/errors";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    width: "100%",
    borderBottom: `solid 1px ${theme.palette.divider}`,
    alignItems: "center",
    minHeight: 42,
    "&:last-child": {
      // marginBottom: 10,
    },
  },
  cellQuantity: {
    width: 30,
    fontSize: 12,
  },
  cellRadio: {},
  cellLabel: {
    paddingLeft: 27,
  },
  productRow: {
    paddingBottom: "10px !important",
    paddingTop: "10px !important",
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  radio: {
    "& .MuiFormControlLabel-label": {
      fontSize: 12,
    },
  },
}));

const normalizeProductsOut = (
  productIds: string[]
): {
  productId: string;
  quantity: number;
}[] => {
  const temp: {
    [productId: string]: number;
  } = {};
  productIds.forEach((id) => {
    if (temp[id]) {
      temp[id] = temp[id] + 1;
    } else {
      temp[id] = 1;
    }
  });
  return Object.keys(temp).map((key) => ({
    productId: key,
    quantity: temp[key],
  }));
};

const normalizeProductsIn = (
  products: {
    productId: string;
    quantity: number;
  }[]
): string[] => {
  const temp: string[] = [];
  products.forEach((item) => {
    for (let i = 0; i < item.quantity; i++) {
      temp.push(item.productId);
    }
  });
  return temp;
};

// selectedProducts: formik.values.productIds.map((id) => ({
//   productId: id,
//   quantity: 1,

export interface OrderFormValues {
  clientId: string;
  addressId: string;
  therapyId: string;
  therapyProductIds: string[];
  productIds: string[];
  deliveryNotes: string;
  isValid: boolean;
}

const defaultInitialValues = {
  clientId: "",
  addressId: "",
  therapyProductIds: [],
  therapyId: "",
  productIds: [],
  deliveryNotes: "",
  isValid: false,
};

const validationSchema = Yup.object({
  clientId: Yup.string().required("Campo obbligatorio"),
  addressId: Yup.string().required("Campo obbligatorio"),
  isValid: Yup.boolean().test({
    name: "isValid",
    exclusive: true,
    message: "Errore validazione",
    test: (_, ctx) => {
      return ctx.parent.productIds.length > 0 || ctx.parent.therapyId;
    },
  }),
});

interface OrderFormProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  order: OrderFields | null;
  therapies: TherapyFields[];
  products: ProductFields[];
  clients: Array<{ name: string; lastname: string; id: string }>;
  agentId: string;
  forceSelection: boolean;
}

const parseInitialValue = (input: OrderFields): OrderFormValues => {
  const values: OrderFormValues = {
    clientId: input.client.id,
    addressId: input.address.id,
    therapyId:
      input.selectedTherapies.length > 0
        ? input.selectedTherapies[0].therapyId
        : "",
    therapyProductIds:
      input.selectedTherapies.length > 0
        ? input.selectedTherapies[0].productIds
        : [],
    // productIds: input.selectedProducts.map((product) => product.productId),
    productIds: normalizeProductsIn(input.selectedProducts),
    deliveryNotes: input.deliveryNotes || "",
    isValid:
      input.selectedTherapies.length > 0 || input.selectedProducts.length > 0,
  };
  return values;
};

export const OrderForm: React.FC<OrderFormProps> = ({
  open,
  onClose,
  onConfirm,
  order,
  therapies,
  products,
  clients,
  agentId,
  forceSelection,
}) => {
  const classes = useStyles();
  const [addresses, setAddresses] = useState<
    { label: string; value: string; notes: string | null }[]
  >([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [getClientQuery] = useLazyQuery<GetClient, GetClientVariables>(
    GET_CLIENT,
    {
      onCompleted: (data) => {
        if (data.getClient) {
          setAddresses(
            data.getClient?.addresses.map((address) => ({
              label:
                (address.label ? `[${address.label}] ` : "") +
                `${address.addressLine}, ${address.zip}, ${
                  address.hamlet || ""
                }${address.hamlet ? " " : ""}${address.city} (${
                  address.province
                })${address.careOf ? "c/o " : ""}${address.careOf || ""}`,
              value: address.id,
              notes: address.notes,
            }))
          );
        }
      },
    }
  );

  useQuery<GetClient, GetClientVariables>(GET_CLIENT, {
    skip: !(order || forceSelection),
    variables: { clientId: order ? order?.client.id : clients[0].id },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.getClient) {
        formik.setFieldValue(
          "clientId",
          order ? order?.client.id : clients[0].id
        );
        setAddresses(
          data.getClient?.addresses.map((address) => ({
            label:
              (address.label ? `[${address.label}]` : "") +
              `${address.addressLine}, ${address.zip}, ${address.hamlet || ""}${
                address.hamlet ? " " : ""
              }${address.city} (${address.province})${
                address.careOf ? "c/o " : ""
              }${address.careOf || ""}`,
            value: address.id,
            notes: address.notes,
          }))
        );
      }
    },
  });

  const [createOrderMutation, { error: createOrderError }] = useMutation<
    CreateOrder,
    CreateOrderVariables
  >(CREATE_ORDER, {
    onCompleted: async (data) => {
      formik.resetForm();
      onConfirm();
    },
    onError: (err) => {
      console.log("Errore!", err);
    },
  });
  const [updateOrderMutation, { error: updateOrderError }] = useMutation<
    UpdateOrder,
    UpdateOrderVariables
  >(UPDATE_ORDER, {
    onCompleted: async (data) => {
      formik.resetForm();
      onConfirm();
    },
    onError: (err) => {
      console.log("Errore!", err);
    },
  });
  const [currentProduct, setCurrentProduct] = useState("");
  const formik = useFormik<OrderFormValues>({
    initialValues: order
      ? parseInitialValue(order as OrderFields)
      : defaultInitialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      // window.alert("submit");

      let variables: CreateOrderVariables | UpdateOrderVariables;
      if (order) {
        variables = {
          orderData: {
            deliveryNotes: formik.values.deliveryNotes || null,
            clientId: formik.values.clientId,
            addressId: formik.values.addressId,
            selectedTherapies: formik.values.therapyId
              ? [
                  {
                    therapyId: formik.values.therapyId,
                    productIds: formik.values.therapyProductIds,
                  },
                ]
              : [],

            selectedProducts: normalizeProductsOut(formik.values.productIds),
            // selectedProducts: formik.values.productIds.map((id) => ({
            //   productId: id,
            //   quantity: 1,
            // })),
          },
          orderId: order.id,
        } as UpdateOrderVariables;
        await updateOrderMutation({ variables });
      } else {
        variables = {
          orderData: {
            date: new Date().toISOString(),
            deliveryNotes: formik.values.deliveryNotes || null,
            clientId: formik.values.clientId,
            agentId,
            addressId: formik.values.addressId,
            selectedTherapies: formik.values.therapyId
              ? [
                  {
                    therapyId: formik.values.therapyId,
                    productIds: formik.values.therapyProductIds,
                  },
                ]
              : [],
            selectedProducts: normalizeProductsOut(formik.values.productIds),
            // selectedProducts: formik.values.productIds.map((id) => ({
            //   productId: id,
            //   quantity: 1,
            // })),
          },
        } as CreateOrderVariables;

        await createOrderMutation({ variables });
      }
    },
  });

  const clientsOptions = clients.map((client) => ({
    label: `${client.name} ${client.lastname}`,
    value: client.id,
  }));

  const productsOptions = products
    .map((product) => ({
      label: `${product.name}`,
      value: product.id,
    }))
    .sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

  const therapiesOptions = therapies.map((therapy) => ({
    label: `${therapy.name}`,
    value: therapy.id,
  }));

  const currentTherapy = formik.values.therapyId
    ? therapies.find((therapy) => therapy.id === formik.values.therapyId)
    : null;

  const getOther = (
    productId: string,
    therapy: TherapyFields
  ): string | null => {
    let other: null | string = null;
    therapy.therapyEntries.forEach((entry) => {
      if (
        entry.products.map((product) => product.id).includes(productId) &&
        entry.products.length === 2
      ) {
        other =
          entry.products[0].id === productId
            ? entry.products[1].id
            : entry.products[0].id;
      }
    });
    return other;
  };

  return (
    <DialogForm
      open={open}
      title="Richiesta campionatura prodotti"
      onClose={() => {
        formik.resetForm();
        onClose();
      }}
      actions={[
        {
          type: ActionType.EXIT,
          label: "CHIUDI SENZA SALVARE",
          callback: () => {
            formik.resetForm();
            onClose();
          },
          disabled: formik.isSubmitting,
        },
        {
          type: ActionType.SEND,
          label: order ? "MODIFICA RICHIESTA" : "INVIA RICHIESTA",
          callback: formik.submitForm,
          disabled: formik.isSubmitting,
        },
      ]}
    >
      <div style={{ overflow: "hidden" }}>
        <Typography color="textSecondary" style={{ marginBottom: 28 }}>
          Inserisci tutte le informazioni richieste e salva per aggiungere una
          richiesta di campionatura.
        </Typography>
        <Typography
          color="textPrimary"
          style={{ marginBottom: 13, fontWeight: 700 }}
        >
          Destinazione
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth={true}>
              <Autocomplete
                options={clientsOptions}
                disabled={forceSelection}
                value={
                  clientsOptions.find((client) => {
                    return client.value === formik.values.clientId;
                  }) || null
                }
                getOptionSelected={(option, test) => {
                  return test.value === option.value;
                }}
                onChange={async (_, newValue) => {
                  formik.setFieldValue("clientId", newValue?.value || "");
                  // Caricare gli indirizzi del cliente
                  if (newValue?.value) {
                    getClientQuery({
                      variables: { clientId: newValue?.value },
                    });
                  } else {
                    setAddresses([]);
                  }
                }}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={formik.touched.clientId && !!formik.errors.clientId}
                    helperText={
                      formik.touched.clientId &&
                      !!formik.errors.clientId &&
                      formik.errors.clientId
                    }
                    label="Operatore/personale sanitario"
                    variant="standard"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControl fullWidth={true}>
              <Autocomplete
                options={addresses}
                value={
                  addresses.find((address) => {
                    return address.value === formik.values.addressId;
                  }) || null
                }
                getOptionSelected={(option, test) => {
                  return test.value === option.value;
                }}
                onChange={async (_, newValue) => {
                  formik.setFieldValue("addressId", newValue?.value || "");

                  formik.setFieldValue(
                    "deliveryNotes",
                    newValue?.notes ? newValue?.notes : ""
                  );
                }}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={
                      formik.touched.addressId && !!formik.errors.addressId
                    }
                    helperText={
                      formik.touched.addressId &&
                      !!formik.errors.addressId &&
                      formik.errors.addressId
                    }
                    label="Indirizzo"
                    variant="standard"
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Typography
          color="textPrimary"
          style={{ marginTop: 12, marginBottom: 13, fontWeight: 700 }}
        >
          Note
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            {/* NOTE */}
            <FormControl fullWidth={true}>
              <TextField
                name="deliveryNotes"
                error={
                  formik.touched.deliveryNotes && !!formik.errors.deliveryNotes
                }
                helperText={
                  formik.touched.deliveryNotes &&
                  !!formik.errors.deliveryNotes &&
                  formik.errors.deliveryNotes
                }
                variant="outlined"
                multiline={true}
                rows={4}
                id="deliveryNotes"
                label="Note di consegna"
                value={formik.values.deliveryNotes}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
        </Grid>
        <div style={{ marginTop: 12, marginBottom: 13 }}>
          <Typography
            color="textPrimary"
            style={{ fontWeight: 700 }}
            display="inline"
          >
            Integrazione probiotica per il ripristino dell’equilibro del microbiota in**
          </Typography>
          {isMobile ? <br /> : <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
          <Typography
            variant="caption"
            display="inline"
            color="textSecondary"
            style={{ marginTop: 12, marginBottom: 13 }}
          >
            È possibile scegliere massimo un assortimento prodotti per ogni
            ordine.
          </Typography>
        </div>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth={true}>
              <Autocomplete
                options={therapiesOptions}
                value={
                  therapiesOptions.find((therapy) => {
                    return therapy.value === formik.values.therapyId;
                  }) || null
                }
                getOptionSelected={(option, test) => {
                  return test.value === option.value;
                }}
                onChange={async (_, newValue) => {
                  formik.setFieldValue("therapyId", newValue?.value);
                  const currentTherapy = newValue?.value
                    ? therapies.find(
                        (therapy) => therapy.id === newValue?.value
                      )
                    : null;
                  const values: string[] = [];
                  if (currentTherapy) {
                    currentTherapy.therapyEntries.forEach((entry) => {
                      values.push(entry.products[0].id);
                    });
                  }
                  formik.setFieldValue("therapyProductIds", [...values]);
                }}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Assortimento Prodotti"
                    variant="standard"
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          {currentTherapy ? (
            <>
              {currentTherapy.therapyEntries
                .slice()
                .sort((a, b) => {
                  if (a.products.length < b.products.length) {
                    return 1;
                  } else if (a.products.length > b.products.length) {
                    return -1;
                  } else {
                    return 0;
                  }
                })
                .map((entry, index) => {
                  if (entry.products.length > 1) {
                    return (
                      <React.Fragment key={index}>
                        <div className={classes.row}>
                          <div className={classes.cellQuantity}>
                            {entry.quantity}x
                          </div>
                          <FormControl
                            component="fieldset"
                            className={classes.cellRadio}
                          >
                            <RadioGroup
                              value={
                                [
                                  entry.products[0].id,
                                  entry.products[1].id,
                                ].filter((x) =>
                                  formik.values.therapyProductIds.includes(x)
                                )[0]
                              }
                              style={{
                                flexDirection: isMobile ? "column" : "row",
                              }}
                              onChange={(event) => {
                                const value = event.target.value;

                                const currentTherapy = therapies.find(
                                  (therapy) =>
                                    therapy.id === formik.values.therapyId
                                ) as TherapyFields;

                                const newValues =
                                  formik.values.therapyProductIds.map((id) => {
                                    const other = getOther(id, currentTherapy);

                                    if (other) {
                                      return other === value ? value : id;
                                    } else {
                                      return id;
                                    }
                                  });

                                formik.setFieldValue("therapyProductIds", [
                                  ...newValues,
                                ]);
                              }}
                            >
                              <FormControlLabel
                                className={classes.radio}
                                value={entry.products[0].id}
                                control={<Radio color="primary" size="small" />}
                                label={entry.products[0].name}
                              />
                              <FormControlLabel
                                className={classes.radio}
                                value={entry.products[1].id}
                                control={<Radio color="primary" size="small" />}
                                label={entry.products[1].name}
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <div className={classes.row}>
                        <div className={classes.cellQuantity}>
                          {entry.quantity}x
                        </div>

                        <div className={classes.cellLabel}>
                          <Typography variant="caption">
                            {entry.products[0].name}
                          </Typography>
                        </div>
                      </div>
                    );
                  }
                })}
            </>
          ) : null}
        </Grid>
        <div style={{ marginTop: 12, marginBottom: 13 }}>
          <Typography
            color="textPrimary"
            style={{ fontWeight: 700 }}
            display="inline"
          >
            Box Prodotti Campione
          </Typography>
          {isMobile ? <br /> : <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
          <Typography
            variant="caption"
            display="inline"
            color="textSecondary"
            style={{ marginTop: 12, marginBottom: 13 }}
          >
            È possibile scegliere un massimo di 4 box.
          </Typography>
        </div>

        <Grid container spacing={1}>
          {formik.values.productIds.length < 4 && (
            <>
              <Grid item xs={10} md={6}>
                <FormControl fullWidth={true}>
                  <Autocomplete
                    options={productsOptions}
                    value={
                      productsOptions.find((therapy) => {
                        return therapy.value === currentProduct;
                      }) || null
                    }
                    getOptionSelected={(option, test) => {
                      return test.value === option.value;
                    }}
                    // getOptionDisabled={(option) =>
                    //   formik.values.productIds.includes(option.value)
                    // }
                    onChange={async (_, newValue) => {
                      setCurrentProduct(newValue?.value || "");
                    }}
                    getOptionLabel={(option) => option.label}
                    disabled={
                      formik.isSubmitting ||
                      formik.values.productIds.length === 4
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Prodotto campione"
                        variant="standard"
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={2} md={1}>
                {/* ADD */}
                <Fab
                  size="small"
                  color="primary"
                  disableRipple={true}
                  disabled={!currentProduct}
                  onClick={() => {
                    formik.setFieldValue("productIds", [
                      ...formik.values.productIds,
                      currentProduct,
                    ]);
                    setCurrentProduct("");
                  }}
                >
                  <AddIcon />
                </Fab>
              </Grid>
            </>
          )}

          {formik.values.productIds.map((id, index) => {
            return (
              <React.Fragment key={`${id}-${index}`}>
                <Grid item xs={10} md={6} className={classes.productRow}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography variant="body2" color="primary">
                      {products.find((product) => product.id === id)?.name ||
                        ""}
                    </Typography>
                    <IconButton
                      aria-label="delete"
                      color="secondary"
                      style={{ padding: 0 }}
                      disableRipple={true}
                      onClick={() => {
                        let removed = false;
                        const newIds = formik.values.productIds.filter(
                          (_id) => {
                            if (removed) return true;
                            if (_id === id) removed = true;
                            return _id !== id;
                          }
                        );
                        // const therapyEntries = [...formik.values.therapyEntries];
                        formik.setFieldValue("productIds", [...newIds]);
                      }}
                    >
                      <DeleteIcon />
                      <Divider />
                    </IconButton>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}></Grid>
              </React.Fragment>
            );
          })}

          {formik.touched.isValid && (
            <Grid item xs={12} md={12}>
              <FormHelperText
                error={formik.touched.isValid && !!formik.errors.isValid}
              >
                Errore
              </FormHelperText>
            </Grid>
          )}
        </Grid>

        <Typography
          color="textSecondary"
          variant="caption"
          component="p"
          style={{ marginBottom: 5, marginTop: 20 }}
        >
          * I campi contrassegnati con l’asterisco sono obbligatori
        </Typography>
        <Typography
          color="textSecondary"
          variant="caption"
          component="p"
          style={{ marginBottom: 30, marginTop: 5 }}
        >
          ** Per procedere con l’ordine è obbligatorio selezionare almeno un
          assortimento prodotti o un box prodotto
        </Typography>
        <Divider />
        {createOrderError && (
          <Typography color="error" variant="body1">
            {createOrderError && formatError(createOrderError)}
          </Typography>
        )}
        {updateOrderError && (
          <Typography color="error" variant="body1">
            {updateOrderError && formatError(updateOrderError)}
          </Typography>
        )}
      </div>
    </DialogForm>
  );
};
