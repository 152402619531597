import React from "react";
import { useMutation } from "@apollo/client";
import {
  ProductFields,
  UpdateProduct,
  UpdateProductVariables,
  CreateProduct,
  CreateProductVariables,
} from "types/generated/schemaTypes";
import {
  FormControl,
  Grid,
  TextField,
  Typography,
  Divider,
  MenuItem,
  InputLabel,
  Select,
  Switch,
  FormControlLabel,
  FormHelperText,
  makeStyles,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CREATE_PRODUCT, UPDATE_PRODUCT } from "mutation";
import { DialogForm, ActionType } from "components";
import { formatError } from "utils/errors";

export interface ProductFormValues {
  name: string;
  sku: string;
  description: string;
  unity: string;
  ean: string;
  forSale: boolean;
  price: string;
  available: boolean;
}

const defaultInitialValues = {
  name: "",
  sku: "",
  description: "",
  unity: "",
  ean: "",
  forSale: false,
  price: "",
  available: true,
};

const parseInitialValue = (input: ProductFields): ProductFormValues => {
  const values: ProductFormValues = {
    name: input.name,
    sku: input.sku,
    description: input.description || "",
    ean: input.ean || "",
    forSale: input.forSale,
    unity: input.unity.toString(),
    price: input.price.toString(),
    available: input.available,
  };
  return values;
};

const validationSchema = Yup.object({
  name: Yup.string().required("Campo obbligatorio"),
  sku: Yup.string().required("Campo obbligatorio"),
  // ean: Yup.string().required("Campo obbligatorio"),
  // unity: Yup.string().required("Campo obbligatorio"),
  unity: Yup.string().when("forSale", {
    is: false,
    then: Yup.string().required("Campo obbligatorio"),
  }),
  ean: Yup.string().when("forSale", {
    is: true,
    then: Yup.string().required("Campo obbligatorio"),
  }),
  price: Yup.string().when("forSale", {
    is: true,
    then: Yup.string().required("Campo obbligatorio"),
  }),
});

const useStyles = makeStyles((theme) => ({
  availableSwitch: {
    "&$checked": {
      color: theme.palette.success.main,
      // color: "yellow",
    },
    "&$checked + $track": {
      backgroundColor: theme.palette.success.main,
    },
  },
  track: {
    backgroundColor: theme.palette.success.main,
  },
  checked: {},
}));

interface ProductFormProps {
  open: boolean;
  onClose: () => void;
  onCreate: () => void;
  onUpdate: () => void;
  product: ProductFields | null;
}

export const ProductForm: React.FC<ProductFormProps> = ({
  open,
  onClose,
  onCreate,
  onUpdate,
  product,
}) => {
  const [createProductMutation, { error: createProductError }] = useMutation<
    CreateProduct,
    CreateProductVariables
  >(CREATE_PRODUCT, {
    onCompleted: async (data) => {
      formik.resetForm();
      onCreate();
    },
    onError: (err) => {
      console.log("Errore!", err);
    },
  });
  const [updateProductMutation, { error: updateProductError }] = useMutation<
    UpdateProduct,
    UpdateProductVariables
  >(UPDATE_PRODUCT, {
    onCompleted: async (data) => {
      formik.resetForm();
      onUpdate();
    },
    onError: (err) => {
      console.log("Errore!", err);
    },
  });
  const classes = useStyles();
  const update = !!product;
  const formik = useFormik<ProductFormValues>({
    initialValues: product
      ? parseInitialValue(product as ProductFields)
      : defaultInitialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      let variables: CreateProductVariables | UpdateProductVariables;
      if (!update) {
        // create
        variables = {
          productData: {
            name: values.name,
            sku: values.sku,
            description: values.description || null,
            unity: values.forSale ? 0 : parseInt(values.unity),
            price: values.forSale ? parseInt(values.price) : 0,
            ean: values.ean || null,
            forSale: values.forSale,
            available: values.available,
          },
        } as CreateProductVariables;
        await createProductMutation({ variables });
      } else {
        // update
        variables = {
          productData: {
            name: values.name,
            sku: values.sku,
            description: values.description || null,
            unity: values.forSale ? 0 : parseInt(values.unity),
            price: values.forSale ? parseInt(values.price) : 0,
            ean: values.ean || null,
            forSale: values.forSale,
            available: values.available,
          },
          productId: (product as ProductFields).id,
        } as UpdateProductVariables;
        await updateProductMutation({ variables });
      }
    },
  });

  return (
    <DialogForm
      open={open}
      title={
        update
          ? "Modifica un prodotto campione"
          : "Aggiungi un prodotto campione"
      }
      onClose={() => {
        formik.resetForm();
        onClose();
      }}
      actions={[
        {
          type: ActionType.EXIT,
          label: "CHIUDI SENZA SALVARE",
          callback: () => {
            formik.resetForm();
            onClose();
          },
          disabled: formik.isSubmitting,
        },
        {
          type: ActionType.SAVE,
          label: "SALVA",
          callback: formik.submitForm,
          disabled: formik.isSubmitting,
        },
      ]}
    >
      <div style={{ overflow: "hidden" }}>
        <Typography color="textSecondary" style={{ marginBottom: 36 }}>
          Inserisci tutte le informazioni richieste e salva per aggiungere un
          nuovo prodotto.
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            {/* TIPOLOGIA 1 */}
            <FormControl fullWidth={true} size="small">
              <InputLabel
                // error={formik.touched.profession && !!formik.errors.profession}
                id="tipologia-label"
              >
                Tipologia*
              </InputLabel>
              <Select
                name="forSale"
                value={formik.values.forSale ? "vendita" : "campione"}
                labelId="forSale-label"
                id="forSale"
                label="Tipologia prodotto*"
                onChange={(e) => {
                  formik.setFieldValue("forSale", e.target.value === "vendita");
                }}
                disabled={formik.isSubmitting}
              >
                <MenuItem key={0} value={"vendita"}>
                  Prodotto vendita
                </MenuItem>
                <MenuItem key={1} value={"campione"}>
                  Prodotto campione
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {/* <Grid container spacing={1}>
          <FormControlLabel
            labelPlacement="start"
            control={
              <Switch
                color="primary"
                classes={
                  {
                    // switchBase: classes.verifiedSwitch,
                    // checked: classes.checked,
                    // track: classes.track,
                  }
                }
                checked={!formik.values.forSale}
                onChange={() =>
                  formik.setFieldValue("forSale", !formik.values.forSale)
                }
                disableRipple
                disableTouchRipple
                disabled={formik.isSubmitting}
                name="forSale"
              />
            }
            label="Prodotto campione"
          />
        </Grid> */}
        <Grid container spacing={1}>
          <Grid item xs={12} md={formik.values.forSale ? 6 : 5}>
            {/* NAME */}
            <FormControl fullWidth={true}>
              <TextField
                name="name"
                error={formik.touched.name && !!formik.errors.name}
                helperText={
                  formik.touched.name &&
                  !!formik.errors.name &&
                  formik.errors.name
                }
                variant="standard"
                id="name"
                size="small"
                label="Nome prodotto*"
                value={formik.values.name}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={formik.values.forSale ? 6 : 5}>
            {/* SKU */}
            <FormControl fullWidth={true}>
              <TextField
                name="sku"
                error={formik.touched.sku && !!formik.errors.sku}
                helperText={
                  formik.touched.sku && !!formik.errors.sku && formik.errors.sku
                }
                variant="standard"
                id="sku"
                size="small"
                label="Codice prodotto*"
                value={formik.values.sku}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          {!formik.values.forSale && (
            <Grid item xs={12} md={2}>
              {/* UNITY */}
              <FormControl fullWidth={true}>
                <TextField
                  name="unity"
                  error={formik.touched.unity && !!formik.errors.unity}
                  helperText={
                    formik.touched.unity &&
                    !!formik.errors.unity &&
                    formik.errors.unity
                  }
                  variant="standard"
                  id="unity"
                  size="small"
                  label="Unità per box*"
                  value={formik.values.unity}
                  onChange={formik.handleChange}
                  disabled={formik.isSubmitting}
                  type="number"
                />
              </FormControl>
            </Grid>
          )}
          {formik.values.forSale && (
            <>
              <Grid item xs={12} md={6}>
                {/* EAN */}
                <FormControl fullWidth={true}>
                  <TextField
                    name="ean"
                    error={formik.touched.ean && !!formik.errors.ean}
                    helperText={
                      formik.touched.ean &&
                      !!formik.errors.ean &&
                      formik.errors.ean
                    }
                    variant="standard"
                    id="ean"
                    size="small"
                    label="EAN*"
                    value={formik.values.ean}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                  />
                </FormControl>
              </Grid>
              {/* PRICE */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth={true}>
                  <TextField
                    name="price"
                    error={formik.touched.price && !!formik.errors.price}
                    helperText={
                      formik.touched.price &&
                      !!formik.errors.price &&
                      formik.errors.price
                    }
                    variant="standard"
                    id="price"
                    size="small"
                    label="Prezzo (€ cent)*"
                    value={formik.values.price}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                    type="number"
                  />
                </FormControl>
              </Grid>
            </>
          )}
          <Grid item xs={12} md={12}>
            {/* DESCRIPTION */}
            <FormControl fullWidth={true}>
              <TextField
                name="description"
                multiline={true}
                rows={6}
                error={
                  formik.touched.description && !!formik.errors.description
                }
                helperText={
                  formik.touched.description &&
                  !!formik.errors.description &&
                  formik.errors.description
                }
                variant="outlined"
                id="description"
                label="Descrizione prodotto"
                value={formik.values.description}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
        </Grid>
        {/* <Grid item xs={12} md={12}>
          <Grid item xs={12} md={6}>
            <div style={{ paddingLeft: 16 }}>
              <FormControlLabel
                control={
                  <Switch
                    classes={{
                      switchBase: classes.availableSwitch,
                      checked: classes.checked,
                      track: classes.track,
                    }}
                    checked={formik.values.available}
                    onChange={() => {
                      formik.setFieldValue(
                        "available",
                        !formik.values.available
                      );
                    }}
                    disableRipple
                    disableTouchRipple
                    disabled={formik.isSubmitting}
                    name="available"
                  />
                }
                label={
                  formik.values.available
                    ? "Prodotto disponibile"
                    : "Prodotto non disponibile"
                }
              />
              {formik.touched.available && !!formik.errors.available && (
                <FormHelperText error={true} variant="standard">
                  {formik.errors.available}
                </FormHelperText>
              )}
            </div>
          </Grid>
        </Grid> */}

        <Typography
          color="textSecondary"
          variant="caption"
          component="p"
          style={{ marginBottom: 30, marginTop: 0 }}
        >
          * I campi contrassegnati con l’asterisco sono obbligatori
        </Typography>
        <Divider />

        {(createProductError || updateProductError) && (
          <Typography color="error" variant="body1">
            {createProductError && formatError(createProductError)}
            {updateProductError && formatError(updateProductError)}
          </Typography>
        )}
      </div>
    </DialogForm>
  );
};
