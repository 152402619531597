import React from "react";

import {
  Grid,
  Typography,
  Divider,
  makeStyles,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";

import { DialogInfo } from "components";

import {
  ProductFields,
  SalesOrderClosedFields,
  SalesOrderFinalStatus,
} from "types/generated/schemaTypes";
import { Alert } from "@material-ui/lab";
import {
  formatProductPrice,
  formatSalesProductByProductDetails,
  getProductDetailsByProductId,
} from "utils/products";
import { getPaymentLabel } from "utils";

const useStyles = makeStyles((theme) => ({
  noPaddingBottom: {
    paddingBottom: "4px !important",
  },
}));

interface SalesOrderDetailProps {
  open: boolean;
  salesOrder: SalesOrderClosedFields;
  onClose: () => void;
  isAgent: boolean;
  products: ProductFields[];
}
export const SalesOrderDetail: React.FC<SalesOrderDetailProps> = ({
  open,
  salesOrder,
  onClose,
  isAgent = true,
  products,
}) => {
  const classes = useStyles();

  let totalPrice = 0;

  salesOrder.selectedSalesProducts.forEach((product) => {
    const price = (product?.price || 0) * product.quantitySale;
    totalPrice += price;
  });

  const finalPrice = totalPrice - (totalPrice * salesOrder.discount) / 100;

  return (
    <DialogInfo
      open={open}
      title="Dettaglio ordine"
      onClose={onClose}
      closeIcon="close"
      closeLabel="CHIUDI"
    >
      <div style={{ overflow: "hidden" }}>
        {salesOrder.finalStatus === SalesOrderFinalStatus.REFUSED && (
          <Alert
            severity="warning"
            variant="filled"
            style={{ marginBottom: 28 }}
          >
            <Typography variant="body1" style={{ fontWeight: 700 }}>
              L'ordine non è stato preso in carico
            </Typography>
            {salesOrder.salesOrderNotes && (
              <Typography variant="body2">
                {salesOrder.salesOrderNotes}
              </Typography>
            )}
          </Alert>
        )}
        <Typography
          color="textPrimary"
          style={{ marginBottom: 13, fontWeight: 700 }}
        >
          Dati di fatturazione
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={3} className={classes.noPaddingBottom}>
            <Typography
              color="inherit"
              style={{ fontWeight: 700, color: "rgba(0,0,0,0.26)" }}
            >
              RAGIONE SOCIALE
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} className={classes.noPaddingBottom}>
            <Typography color="textPrimary" style={{ fontWeight: 700 }}>
              {salesOrder.salesClient.name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} className={classes.noPaddingBottom}>
            <Typography
              color="textSecondary"
              style={{ fontWeight: 700, color: "rgba(0,0,0,0.26)" }}
            >
              P.I./C.F.
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} className={classes.noPaddingBottom}>
            {" "}
            {salesOrder.salesClient.vat}
          </Grid>

          <Grid item xs={12} md={3} className={classes.noPaddingBottom}>
            <Typography
              color="textSecondary"
              style={{ fontWeight: 700, color: "rgba(0,0,0,0.26)" }}
            >
              INDIRIZZO
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} className={classes.noPaddingBottom}>
            <Typography color="textPrimary">
              {salesOrder.salesClient.addressLine}, {salesOrder.salesClient.zip}{" "}
              {salesOrder.salesClient.hamlet} {salesOrder.salesClient.city} (
              {salesOrder.salesClient.province})
            </Typography>
          </Grid>

          <Grid item xs={12} md={3} className={classes.noPaddingBottom}>
            <Typography
              color="textSecondary"
              style={{ fontWeight: 700, color: "rgba(0,0,0,0.26)" }}
            >
              TELEFONO
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} className={classes.noPaddingBottom}>
            <Typography color="textPrimary">
              {salesOrder.salesClient.phone}
            </Typography>
          </Grid>

          <Grid item xs={12} md={3} className={classes.noPaddingBottom}>
            <Typography
              color="textSecondary"
              style={{ fontWeight: 700, color: "rgba(0,0,0,0.26)" }}
            >
              EMAIL
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} className={classes.noPaddingBottom}>
            <Typography color="textPrimary">
              {salesOrder.salesClient.email}
            </Typography>
          </Grid>

          <Grid item xs={12} md={3} className={classes.noPaddingBottom}>
            <Typography
              color="textSecondary"
              style={{ fontWeight: 700, color: "rgba(0,0,0,0.26)" }}
            >
              PEC
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} className={classes.noPaddingBottom}>
            <Typography color="textPrimary">
              {salesOrder.salesClient.pec || "-"}
            </Typography>
          </Grid>

          <Grid item xs={12} md={3} className={classes.noPaddingBottom}>
            <Typography
              color="textSecondary"
              style={{ fontWeight: 700, color: "rgba(0,0,0,0.26)" }}
            >
              SDI
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} className={classes.noPaddingBottom}>
            <Typography color="textPrimary">
              {salesOrder.salesClient.sdi || "-"}
            </Typography>
          </Grid>

          <Grid item xs={12} md={3} className={classes.noPaddingBottom}>
            <Typography
              color="textSecondary"
              style={{ fontWeight: 700, color: "rgba(0,0,0,0.26)" }}
            >
              IBAN
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} className={classes.noPaddingBottom}>
            <Typography color="textPrimary">
              {salesOrder.salesClient.iban}
            </Typography>
          </Grid>
        </Grid>
        <Divider style={{ margin: "24px 0" }} />
        <Typography
          color="textPrimary"
          style={{ marginBottom: 13, fontWeight: 700 }}
        >
          Dati di spedizione
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={3} className={classes.noPaddingBottom}>
            <Typography
              color="inherit"
              style={{ fontWeight: 700, color: "rgba(0,0,0,0.26)" }}
            >
              RAGIONE SOCIALE
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} className={classes.noPaddingBottom}>
            <Typography color="textPrimary" style={{ fontWeight: 700 }}>
              {salesOrder.salesClient.shippingName}
            </Typography>
          </Grid>

          <Grid item xs={12} md={3} className={classes.noPaddingBottom}>
            <Typography
              color="textSecondary"
              style={{ fontWeight: 700, color: "rgba(0,0,0,0.26)" }}
            >
              INDIRIZZO
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} className={classes.noPaddingBottom}>
            <Typography color="textPrimary">
              {salesOrder.salesClient.shippingAddressLine},{" "}
              {salesOrder.salesClient.shippingZip}{" "}
              {salesOrder.salesClient.shippingHamlet}{" "}
              {salesOrder.salesClient.shippingCity} (
              {salesOrder.salesClient.shippingProvince})
            </Typography>
          </Grid>

          <Grid item xs={12} md={3} className={classes.noPaddingBottom}>
            <Typography
              color="textSecondary"
              style={{ fontWeight: 700, color: "rgba(0,0,0,0.26)" }}
            >
              TELEFONO
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} className={classes.noPaddingBottom}>
            <Typography color="textPrimary">
              {salesOrder.salesClient.shippingPhone || "-"}
            </Typography>
          </Grid>
          {!isAgent && (
            <>
              <Grid item xs={12} md={3} className={classes.noPaddingBottom}>
                <Typography
                  color="textSecondary"
                  style={{ fontWeight: 700, color: "rgba(0,0,0,0.26)" }}
                >
                  CODICE DESTINAZIONE
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} className={classes.noPaddingBottom}>
                <Typography color="textPrimary">
                  {salesOrder.salesClient.destinationCode || "-"}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>

        {!isAgent && (
          <>
            <Divider style={{ margin: "24px 0" }} />
            <Typography
              color="textPrimary"
              style={{ marginBottom: 13, fontWeight: 700 }}
            >
              Agente di riferimento
            </Typography>

            <Grid container spacing={1}>
              <Grid item xs={12} md={12} className={classes.noPaddingBottom}>
                <Typography color="inherit">
                  {salesOrder.agent.name} {salesOrder.agent.lastname}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}

        <Divider style={{ margin: "24px 0" }} />
        <Typography
          color="textPrimary"
          style={{ marginBottom: 13, fontWeight: 700 }}
        >
          Note spedizione
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs={12} md={12} className={classes.noPaddingBottom}>
            <Typography color="inherit">
              {salesOrder.deliveryNotes || "Nessuna nota"}
            </Typography>
          </Grid>
        </Grid>
        <Divider style={{ margin: "24px 0" }} />
        {!isAgent && (
          <>
            <Typography
              color="textPrimary"
              style={{ marginBottom: 13, fontWeight: 700 }}
            >
              Note ordine
            </Typography>

            <Grid container spacing={1}>
              <Grid item xs={12} md={12} className={classes.noPaddingBottom}>
                <Typography color="inherit">
                  {salesOrder.salesOrderNotes || "Nessuna nota"}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ margin: "24px 0" }} />
          </>
        )}

        <Typography
          color="textPrimary"
          style={{ marginBottom: 13, fontWeight: 700 }}
        >
          Metodo di pagamento
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} className={classes.noPaddingBottom}>
            <Typography color="textPrimary">
              {getPaymentLabel(salesOrder.finalPayment)}
            </Typography>
          </Grid>
        </Grid>
        <Divider style={{ margin: "24px 0" }} />

        <Typography
          color="textPrimary"
          style={{ marginBottom: 13, fontWeight: 700 }}
        >
          Dettaglio ordine
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Prodotto</TableCell>
                    <TableCell>Unità vendita</TableCell>
                    <TableCell>Unità omaggio</TableCell>
                    <TableCell>Unità campione</TableCell>
                    {isAgent ?<TableCell></TableCell>: <TableCell>Totale</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {salesOrder.selectedSalesProducts.map((product) => {
                    if (product === null) return null;
                    return (
                      <TableRow key={product.id}>
                        <TableCell>
                          {formatSalesProductByProductDetails(
                            product,
                            !isAgent
                          )}
                        </TableCell>
                        <TableCell>{product.quantitySale}</TableCell>
                        <TableCell>{product.quantityDiscount}</TableCell>
                        <TableCell>{product.quantitySample}</TableCell>
                        {isAgent ? (<TableCell></TableCell>): (
                          <TableCell>
                            {formatProductPrice(
                              product.price * product.quantitySale
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}

                  <TableRow>
                    <TableCell colSpan={3} />
                    <TableCell colSpan={1}>Totale</TableCell>
                    <TableCell colSpan={1}>
                      {formatProductPrice(totalPrice)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3} />
                    <TableCell colSpan={1}>
                      <b>Totale scontato</b>
                      <br />
                      (Sconto {salesOrder.discount} %)
                    </TableCell>
                    <TableCell colSpan={1}>
                      <b>{formatProductPrice(finalPrice)}</b>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid item xs={12} md={9} className={classes.noPaddingBottom}>
          {/* {order.productUnities.map((product) => (
              <Typography color="textPrimary" display="block">
                {(product?.totalUnities as number) /
                  (product?.boxUnities as number)}
                x {product?.name}{" "}
                {!isAgent && <>({product?.totalUnities} unità totali)</>}
              </Typography>
            ))} */}
          {/* </Grid> */}
        </Grid>
      </div>
    </DialogInfo>
  );
};
