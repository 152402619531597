import React, { useState } from "react";
import { useApolloClient, useQuery, useReactiveVar } from "@apollo/client";
import {
  Grid,
  InputAdornment,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  useTheme,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import {
  Search as SearchIcon,
  CheckCircle as CheckCircleIcon,
  ReportProblem as ReportProblemIcon,
  RemoveCircle as RemoveCircleIcon,
  CalendarToday,
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import {
  GET_SALES_ORDER_CLOSED,
  GET_SALES_ORDERS_CLOSED,
  GET_USERS,
  GET_PRODUCTS,
} from "query";
import {
  GetSalesOrderClosed,
  GetSalesOrderClosedVariables,
  GetSalesOrdersClosedVariables,
  GetSalesOrdersClosed,
  SalesOrderClosedOrderInput,
  SalesOrderClosedWhereInput,
  GetUsers,
  GetUsersVariables,
  UserOrderInput,
  SalesOrderClosedFields,
  SalesOrderFinalStatus,
  GetProducts,
  GetProductsVariables,
} from "types/generated/schemaTypes";
import { SalesOrderDetail, FiltersWrapper } from "components";
import { userRoleVar, userIdVar } from "localState";
import { UserRole } from "types/generated/schemaTypes";
import { formatDate } from "utils/date";
import { ButtonChipDownload } from "./buttonChipDownload";
import { ButtonChipInfo } from "./buttonChipInfo";
import { KeyboardDatePicker } from "@material-ui/pickers";

function isValidDate(d: any) {
  return d instanceof Date && d.toString() !== "Invalid Date";
}

export const ProcessedSalesOrders: React.FC = () => {
  const client = useApolloClient();
  const theme = useTheme();
  const role = useReactiveVar(userRoleVar);
  const userId = useReactiveVar(userIdVar);
  const [currentSalesOrder, setCurrentSalesOrder] =
    useState<null | SalesOrderClosedFields>(null);
  const [searchString, setSearchString] = useState("");
  const [offset, setOffset] = useState(0);
  const [showSalesOrderDetail, setShowSalesOrderDetail] = useState(false);
  const isAgent = role === UserRole.AGENT;
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState<SalesOrderClosedOrderInput>(
    SalesOrderClosedOrderInput.CREATION_DESC
  );
  const [agentFilter, setAgentFilter] = useState<string>("ALL");
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [fromDate, setFromDate] = useState<Date | null | undefined>(null);
  const [toDate, setToDate] = useState<Date | null | undefined>(null);
  const {
    data: operatorsData,
    loading: operatorsLoading,
    error: operatorsError,
  } = useQuery<GetUsers, GetUsersVariables>(GET_USERS, {
    fetchPolicy: "network-only",
    variables: {
      where: { active: true, role: UserRole.OPERATOR },
      order: UserOrderInput.LASTNAME_ASC,
      offset: 0,
      limit: 100,
    },
    skip: isAgent,
  });

  const where: SalesOrderClosedWhereInput = {};

  if (role === UserRole.AGENT) {
    where.agentId = userId;
  }

  if (searchString.length > 2) where.searchPattern = searchString;

  const {
    data: agentsData,
    // loading: agentsLoading,
    // error: agentsError,
  } = useQuery<GetUsers, GetUsersVariables>(GET_USERS, {
    fetchPolicy: "network-only",
    variables: {
      where: { active: true, role: UserRole.AGENT },
      order: UserOrderInput.LASTNAME_ASC,
      offset: 0,
      limit: 100,
    },
    skip: isAgent,
  });
  const agentFilterOptions = [{ label: "Tutti le richieste", id: "ALL" }];
  if (agentsData?.getUsers.users)
    agentsData?.getUsers.users.forEach((operator) => {
      agentFilterOptions.push({
        label: `${operator.name} ${operator.lastname}`,
        id: operator.id,
      });
    });

  if (isAgent) where.agentId = userId;

  if (!isAgent && agentFilter !== "ALL") {
    where.agentId = agentFilter;
  }

  if (fromDate && isValidDate(fromDate)) {
    where.startDate = fromDate.toISOString();
    console.log(fromDate.toISOString());
  }
  if (toDate && isValidDate(toDate)) {
    where.endDate = toDate.toISOString();
    console.log(toDate.toISOString());
  }

  const {
    data: salesOrdersData,
    loading: salesOrdersLoading,
    error: salesOrdersError,
  } = useQuery<GetSalesOrdersClosed, GetSalesOrdersClosedVariables>(
    GET_SALES_ORDERS_CLOSED,
    {
      fetchPolicy: "network-only",
      variables: {
        where,
        order,
        offset,
        limit,
      },
    }
  );

  const {
    data: productsData,
    loading: productsLoading,
    error: productsError,
  } = useQuery<GetProducts, GetProductsVariables>(GET_PRODUCTS, {
    fetchPolicy: "cache-first",
    variables: {
      where: { archived: false, forSale: true },
      offset: 0,
      limit: 100,
    },
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setOffset(newPage * limit);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLimit(parseInt(event.target.value, 10));
    setOffset(0);
  };

  const loadSalesOrder = async (salesOrderId: string) => {
    const data = await client.query<
      GetSalesOrderClosed,
      GetSalesOrderClosedVariables
    >({
      query: GET_SALES_ORDER_CLOSED,
      variables: { salesOrderId },
      fetchPolicy: "network-only",
    });
    setCurrentSalesOrder(data.data.getSalesOrderClosed);
  };

  return (
    <>
      <FiltersWrapper>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item xs={6} md={3}>
            <FormControl fullWidth={true} variant="standard" size="medium">
              <InputLabel id="order-label">Ordina per</InputLabel>
              <Select
                name="order"
                value={order}
                labelId="order-label"
                id="order"
                label="Ordina per"
                onChange={(e) => {
                  setOffset(0);
                  setOrder(e.target.value as SalesOrderClosedOrderInput);
                }}
              >
                <MenuItem value={SalesOrderClosedOrderInput.CREATION_DESC}>
                  Data creazione ↓
                </MenuItem>
                <MenuItem value={SalesOrderClosedOrderInput.CREATION_ASC}>
                  Data creazione ↑
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {!isAgent && (
            <Grid item xs={6} md={3}>
              <FormControl fullWidth={true}>
                <Autocomplete
                  options={agentFilterOptions}
                  value={
                    agentFilterOptions.find(
                      (agent) => agent.id === agentFilter
                    ) || null
                  }
                  getOptionSelected={(option, test) => {
                    return test.id === option.id;
                  }}
                  onChange={(event, newValue) => {
                    setAgentFilter(newValue?.id || "ALL");
                  }}
                  getOptionLabel={(option) => option.label}
                  // style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Visualizza"
                      variant="standard"
                    />
                  )}
                />
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12} md={3}>
            <FormControl fullWidth={true} variant="standard" size="medium">
              <InputLabel htmlFor="search">Cerca...</InputLabel>
              <Input
                name="searchString"
                id="searchString"
                autoFocus={searchString.length > 2}
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={1} alignItems="flex-start">
          <Grid item xs={12} md={3}>
            <FormControl fullWidth={true}>
              <KeyboardDatePicker
                size="small"
                keyboardIcon={<CalendarToday />}
                autoOk
                variant="inline"
                inputVariant="outlined"
                label={fromDate ? "Data iniziale" : null}
                format="dd/MM/yyyy"
                placeholder="Data iniziale"
                invalidDateMessage={"Formato non valido"}
                name="dates"
                value={fromDate}
                InputAdornmentProps={{ position: "end" }}
                onChange={(date) => setFromDate(date as Date)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth={true}>
              <KeyboardDatePicker
                size="small"
                keyboardIcon={<CalendarToday />}
                autoOk
                variant="inline"
                inputVariant="outlined"
                label={toDate ? "Data finale" : null}
                format="dd/MM/yyyy"
                placeholder="Data finale"
                invalidDateMessage={"Formato non valido"}
                name="dates"
                value={toDate}
                InputAdornmentProps={{ position: "end" }}
                onChange={(date) => setToDate(date as Date)}
              />
            </FormControl>
          </Grid>
        </Grid>
      </FiltersWrapper>
      {salesOrdersLoading && <div>Caricamento degli ordini in corso</div>}
      {!salesOrdersLoading &&
        !salesOrdersError &&
        !operatorsLoading &&
        !operatorsError &&
        salesOrdersData &&
        (isAgent || operatorsData) && (
          <>
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {[UserRole.OPERATOR, UserRole.ADMIN].includes(role) && (
                        <TableCell variant="head" align="left">
                          {isMobile ? "" : "STATO"}
                        </TableCell>
                      )}
                      <TableCell variant="head" align="left">
                        DATA
                      </TableCell>
                      {[UserRole.OPERATOR, UserRole.ADMIN].includes(role) &&
                        !isMobile && (
                          <TableCell variant="head" align="left">
                            AGENTE
                          </TableCell>
                        )}
                      <TableCell
                        variant="head"
                        align="left"
                        style={{ minWidth: 160 }}
                      >
                        CLIENTE
                      </TableCell>
                      {role === UserRole.AGENT && (
                        <TableCell variant="head" align="left">
                          STATO
                        </TableCell>
                      )}
                      <TableCell
                        variant="head"
                        align="left"
                        style={{ width: isAgent ? 250 : 250 }}
                      >
                        AZIONI
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {salesOrdersData.getSalesOrdersClosed.salesOrders.map(
                      (order) => {
                        return (
                          <TableRow key={order.id}>
                            {[UserRole.OPERATOR, UserRole.ADMIN].includes(
                              role
                            ) && (
                              <TableCell align="left">
                                <Typography
                                  component="span"
                                  variant="body1"
                                  color="textPrimary"
                                >
                                  {order.finalStatus ===
                                  SalesOrderFinalStatus.APPROVED ? (
                                    <CheckCircleIcon
                                      style={{ marginRight: 16 }}
                                      htmlColor={theme.palette.success.main}
                                    />
                                  ) : (
                                    <ReportProblemIcon
                                      style={{ marginRight: 16 }}
                                      htmlColor={theme.palette.error.main}
                                    />
                                  )}
                                </Typography>
                              </TableCell>
                            )}

                            <TableCell align="left">
                              <Typography
                                component="span"
                                variant="body1"
                                color="textPrimary"
                              >
                                {formatDate(order.salesOrderDate)}
                              </Typography>
                            </TableCell>

                            {[UserRole.OPERATOR, UserRole.ADMIN].includes(
                              role
                            ) &&
                              !isMobile && (
                                <TableCell align="left">
                                  <Typography
                                    component="span"
                                    variant="body1"
                                    color="textSecondary"
                                  >
                                    {order.agent.name} {order.agent.lastname}
                                  </Typography>
                                </TableCell>
                              )}
                            <TableCell align="left">
                              <Typography
                                component="span"
                                variant="body1"
                                color="textSecondary"
                              >
                                {order.salesClient.shippingName}
                              </Typography>
                              {!isAgent && (
                                <>
                                  <br />
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    color="textPrimary"
                                  >
                                    ({order.salesClient.vat})
                                  </Typography>
                                </>
                              )}
                            </TableCell>
                            {[UserRole.AGENT].includes(role) && (
                              <TableCell align="left">
                                <Typography
                                  component="span"
                                  variant="body1"
                                  color={
                                    order.finalStatus ===
                                    SalesOrderFinalStatus.APPROVED
                                      ? "textPrimary"
                                      : "error"
                                  }
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                  {order.finalStatus ===
                                  SalesOrderFinalStatus.APPROVED ? (
                                    <>
                                      <CheckCircleIcon
                                        style={{ marginRight: 16 }}
                                        htmlColor={theme.palette.success.main}
                                      />
                                      Ordine preso in carico
                                    </>
                                  ) : (
                                    <>
                                      <RemoveCircleIcon
                                        style={{ marginRight: 16 }}
                                        htmlColor={theme.palette.error.main}
                                      />
                                      Ordine preso in carico
                                    </>
                                  )}
                                </Typography>
                              </TableCell>
                            )}

                            <TableCell align="left">
                              <div style={{ display: "flex" }}>
                                {[UserRole.ADMIN, UserRole.OPERATOR].includes(
                                  role
                                ) && (
                                  <>
                                    <ButtonChipDownload
                                      onClick={async () => {
                                        window.location.href = `${process.env.REACT_APP_ENDPOINT}/sales-order-doc/${order.id}`;
                                      }}
                                      label={
                                        isMobile
                                          ? "Richiesta"
                                          : "Scarica Richiesta"
                                      }
                                      style={{ marginRight: 16 }}
                                    />
                                  </>
                                )}
                                {[UserRole.AGENT].includes(role) && (
                                  <>
                                    <ButtonChipDownload
                                      onClick={async () => {
                                        window.location.href = `${process.env.REACT_APP_ENDPOINT}/sales-order-doc-agent/${order.id}`;
                                      }}
                                      label={
                                        isMobile
                                          ? "Richiesta"
                                          : "Scarica Richiesta"
                                      }
                                      style={{ marginRight: 16 }}
                                    />
                                  </>
                                )}

                                <ButtonChipInfo
                                  onClick={async () => {
                                    await loadSalesOrder(order.id);
                                    setShowSalesOrderDetail(true);
                                  }}
                                  label="Dettaglio"
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                    {salesOrdersData.getSalesOrdersClosed.salesOrders.length <
                      11 && (
                      <>
                        {new Array(
                          10 -
                            salesOrdersData.getSalesOrdersClosed.salesOrders
                              .length
                        )
                          .fill(10)
                          .map(() => (
                            <TableRow>
                              {[UserRole.OPERATOR, UserRole.ADMIN].includes(
                                role
                              ) &&
                                !isMobile && (
                                  <TableCell variant="head" align="left">
                                    &nbsp;
                                  </TableCell>
                                )}
                              {!isMobile && (
                                <TableCell variant="head" align="left">
                                  &nbsp;
                                </TableCell>
                              )}
                              <TableCell variant="head" align="left">
                                &nbsp;
                              </TableCell>
                              <TableCell variant="head" align="left">
                                &nbsp;
                              </TableCell>
                              <TableCell variant="head" align="left">
                                &nbsp;
                              </TableCell>
                            </TableRow>
                          ))}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                labelRowsPerPage="Totale ordini per pagina"
                labelDisplayedRows={({ from, to, count }) =>
                  `Richieste ${from}-${to} di ${count}`
                }
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={
                  salesOrdersData
                    ? salesOrdersData.getSalesOrdersClosed.total
                    : 0
                }
                rowsPerPage={limit}
                page={Math.floor(offset / limit)}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </>
        )}

      {showSalesOrderDetail && currentSalesOrder && productsData && (
        <SalesOrderDetail
          isAgent={role === UserRole.AGENT}
          open={showSalesOrderDetail}
          onClose={() => {
            setShowSalesOrderDetail(false);
          }}
          salesOrder={currentSalesOrder}
          products={productsData.getProducts.products}
        />
      )}
    </>
  );
};
