import React, { ReactNode } from "react";
import { useQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import { AlertTitle } from "@material-ui/lab";
import { GET_SETTINGS } from "query";
import { GetSettings, GetSettingsVariables } from "types/generated/schemaTypes";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  banner: {
    backgroundColor: theme.palette.primary.main,
    "& .MuiAlert-action": {
      alignItems: "flex-start",
    },
  },
}));

export const MessageBanner: React.FC = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const {
    error: errorSettings,
    data: dataSettings,
    loading: loadingSettings,
  } = useQuery<GetSettings, GetSettingsVariables>(GET_SETTINGS, {
    fetchPolicy: "network-only",
    variables: {
      id: "BANNER",
    },
  });

  let content = "";
  let showBanner = false;

  if (
    !loadingSettings &&
    !errorSettings &&
    dataSettings &&
    dataSettings.getSettings?.value
  ) {
    const value = JSON.parse(dataSettings.getSettings.value);
    content = value.content.replaceAll("\n", "<br>")
    showBanner = value.enabled
  }

  if (!showBanner || !content) return null

  return (
    <div className={classes.root}>
      <Collapse in={open}>
        <Alert
          className={classes.banner}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity="info"
          variant="filled"
        >
          <AlertTitle>Attenzione</AlertTitle>
          <div dangerouslySetInnerHTML={{__html: content}} />
        </Alert>
      </Collapse>
    </div>
  );
};
