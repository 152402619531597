import React from "react";
import { useMutation } from "@apollo/client";
import {
  DeactivateSalesClient,
  DeactivateSalesClientVariables,
} from "types/generated/schemaTypes";
import { FormControl, Grid, TextField, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DEACTIVATE_SALES_CLIENT } from "mutation";
import { DialogForm, ActionType } from "components";
import { formatError } from "utils/errors";

export interface DeleteSalesClientFormValues {
  notes: string;
}

const validationSchema = Yup.object({});

interface DeleteSalesClientFormProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  salesClientId: string;
}

export const DeleteSalesClientForm: React.FC<DeleteSalesClientFormProps> = ({
  open,
  onDelete,
  onClose,
  salesClientId,
}) => {
  const [deleteSalesClientMutation, { error: deleteSalesClientError }] = useMutation<
    DeactivateSalesClient,
    DeactivateSalesClientVariables
  >(DEACTIVATE_SALES_CLIENT, {
    onCompleted: async (data) => {
      formik.resetForm();
      onDelete();
    },
    onError: (err) => {
      console.log("Errore!", err);
    },
  });

  const formik = useFormik<DeleteSalesClientFormValues>({
    initialValues: {
      notes: "",
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const variables = {
        salesClientId,
        notes: values.notes || null,
      };
      await deleteSalesClientMutation({ variables });
    },
  });

  return (
    <DialogForm
      open={open}
      title="Elimina cliente"
      onClose={() => {
        formik.resetForm();
        onClose();
      }}
      actions={[
        {
          type: ActionType.EXIT,
          label: "ANNULLA ELIMINAZIONE",
          callback: () => {
            formik.resetForm();
            onClose();
          },
          disabled: formik.isSubmitting,
        },
        {
          type: ActionType.SAVE,
          label: "ELIMINA",
          callback: formik.submitForm,
          disabled: formik.isSubmitting,
        },
      ]}
    >
      <div style={{ overflow: "hidden" }}>
        <Typography
          color="textSecondary"
          style={{ marginBottom: 36 }}
        ></Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12}>
            {/* NOTES */}
            <FormControl fullWidth={true}>
              <TextField
                name="notes"
                multiline={true}
                rows={6}
                error={formik.touched.notes && !!formik.errors.notes}
                helperText={
                  formik.touched.notes &&
                  !!formik.errors.notes &&
                  formik.errors.notes
                }
                variant="outlined"
                id="notes"
                label="Note"
                value={formik.values.notes}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
        </Grid>
        {deleteSalesClientError && (
          <Typography color="error" variant="body1">
            {deleteSalesClientError && formatError(deleteSalesClientError)}
          </Typography>
        )}
      </div>
    </DialogForm>
  );
};
