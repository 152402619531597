import React, { useState } from "react";
import { Tabs, Tab, Button } from "@material-ui/core";
import {
  PageTitle,
  Breadcrumbs,
  ActiveSalesClients,
  DeletedSalesClients,
  DialogInfo,
} from "components";
import { userRoleVar } from "localState";
import { UserRole } from "types/generated/schemaTypes";
import { Alert } from "@material-ui/lab";

export default function GestioneClienti() {
  const [index, setIndex] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const isAgent = userRoleVar() === UserRole.AGENT;
  return (
    <>
      <PageTitle title="GESTIONE CLIENTI" />
      <Breadcrumbs
        prev={isAgent ? undefined : "Gestione Clienti"}
        current={
          isAgent
            ? "Gestione Clienti"
            : ["Utenti attivi", "Utenti eliminati"][index]
        }
      />
      {isAgent && (
        <Alert
          action={
            <Button
              color="inherit"
              onClick={() => setShowPopup(true)}
              size="small"
            >
              MAGGIORI INFORMAZIONI
            </Button>
          }
          severity="info"
          style={{ marginBottom: 20 }}
        >
          I dati richiesti sono trattati a norma del GDPR (Art. 13 del
          Regolamento UE 2016/679)
        </Alert>
      )}
      {isAgent ? (
        <ActiveSalesClients />
      ) : (
        <>
          <Tabs
            style={{ marginBottom: 30 }}
            value={index}
            indicatorColor="primary"
            textColor="primary"
            onChange={(_, newIndex) => {
              setIndex(newIndex);
            }}
          >
            <Tab label="UTENTI ATTIVI" />
            <Tab label="UTENTI ELIMINATI" />
          </Tabs>
          {index === 0 ? <ActiveSalesClients /> : <DeletedSalesClients />}
        </>
      )}
      <DialogInfo
        open={showPopup}
        title="INFORMATIVA SUL TRATTAMENTO DI DATI PERSONALI ai sensi dell’art. 13 del Regolamento (UE) 2016/679 (GDPR)"
        onClose={() => setShowPopup(false)}
        closeIcon="close"
        closeLabel="CHIUDI"
      >
        <p>
          Ai sensi e per gli effetti dell’art. 29 del Regolamento UE 2016/679
          (di seguito anche “GDPR”) e dell’art.2-quaterdecies del D.Lgs
          101/2018, BROMATECH SRL., in persona del legale rappresentante pro
          tempore, in qualità di “Titolare del Trattamento” dei dati personali e
          di “Responsabile del Trattamento”, nomina l’Agente addetto al
          trattamento dei dati personali nell’ambito dell’incarico di agenzia in
          essere con Bromatech srl. L’incarico ha ad oggetto il trattamento dei
          dati dei professionisti tramite inserimento dei loro dati in area
          riservata per favorire la gestione delle richieste e degli invii di
          prodotti campione e materiale informativo.
        </p>
        <p>L’incarico si riferisce ai dati e ai trattamenti sotto elencati:</p>
        <table>
          <tr>
            <td>TRATTAMENTO</td>
            <td>Gestione dati operatore/personale sanitario</td>
          </tr>
          <tr>
            <td>TIPOLOGIA DI DATI</td>
            <td>
              Nome, cognome, e-mail, telefono, CF, professione,
              specializzazione, indirizzo/i di spedizione
            </td>
          </tr>
          <tr>
            <td>CATEGORIE DI INTERESSATI</td>
            <td>Professionisti</td>
          </tr>
          <tr>
            <td>ASSET</td>
            <td>
              Gestione richieste invio prodotti campione e materiale informativo
            </td>
          </tr>
          <tr>
            <td>FINALITA’</td>
            <td>Area riservata agenti sito privato Bromatech</td>
          </tr>
        </table>
        <p>
          A tal fine vengono fornite informazioni ed istruzioni per
          l’assolvimento del compito assegnato:
        </p>
        <ul>
          <li>
            In forza di quanto previsto dall’art. 4 del GDPR è considerato
            trattamento "qualsiasi operazione o insieme di operazioni, compiute
            con o senza l'ausilio di processi automatizzati e applicate a dati
            personali o insieme di dati personali, come la raccolta, la
            registrazione, l'organizzazione, la strutturazione, la
            conservazione, l’adattamento o la modifica, l’estrazione, la
            consultazione, l’uso, la comunicazione mediante trasmissione,
            diffusione o qualsiasi altra forma di messa a disposizione, il
            raffronto o l’interconnessione, la limitazione, la cancellazione o
            la distruzione".
          </li>
          <li>
            le operazioni di trattamento devono essere compiute osservando le
            istruzioni del Titolare alle quali vi è obbligo di attenersi
            scrupolosamente;
          </li>
          <li>
            i dati personali devono essere trattati unicamente per le finalità
            inerenti all’attività svolta;
          </li>
          <li>
            il trattamento dei dati deve essere effettuato in modo lecito e
            corretto;
          </li>
          <li>
            è necessaria la verifica costante dei dati ed il loro aggiornamento;
          </li>
          <li>
            è necessaria la verifica costante della completezza e pertinenza dei
            dati trattati;
          </li>
          <li>
            devono essere rispettate le misure di sicurezza predisposte dal
            Titolare;
          </li>
          <li>
            in ogni operazione del trattamento deve essere garantita la massima
            riservatezza ed in particolare: divieto di comunicazione e/o
            diffusione dei dati senza la preventiva autorizzazione del Titolare;
          </li>
          <li>
            l’accesso ai dati dovrà essere limitato all’espletamento
            dell’incarico e non è consentito l’accesso a dati la cui conoscenza
            non è necessaria all’adempimento dei compiti affidati all’addetto;
          </li>
          <li>
            in caso di interruzione, anche temporanea, del lavoro verificare che
            i dati trattati non siano accessibili a terzi non autorizzati;
          </li>
          <li>
            le proprie credenziali di autenticazione all’area riservata devono
            essere riservate; gli obblighi relativi alla riservatezza, alla
            comunicazione ed alla diffusione dovranno essere osservati anche in
            seguito a modifica dell’incarico e/o cessazione dello stesso;
          </li>
          <li>
            Non modificare i trattamenti esistenti o introdurre nuovi
            trattamenti senza l’esplicita autorizzazione del Titolare del
            trattamento; informare il Titolare in caso di incidente di sicurezza
            che coinvolga i dati;
          </li>
          <li>
            raccogliere, registrare e conservare i dati presenti nei documenti e
            nei supporti informatici avendo cura che l’accesso ad essi sia
            possibile solo ai soggetti autorizzati;
          </li>
          <li>
            eseguire qualsiasi altra operazione di trattamento nei limiti del
            proprio incarico e nel rispetto delle norme di legge.
          </li>
        </ul>
        <p>
          Il presente incarico è strettamente collegato e funzionale
          all’incarico svolto da ciascun addetto. L'Addetto dichiara di aver
          ricevuto le istruzioni e si impegna, dopo averne presa visione, ad
          adottare tutte le misure necessarie alla loro attuazione. L'addetto
          dovrà osservare scrupolosamente tutte le istruzioni ricevute e le
          misure di sicurezza già in atto, o che verranno comunicate in seguito
          dal titolare o dal responsabile del trattamento. La Sua lettura e
          comprensione della presente informativa costituisce consapevole
          accettazione degli obblighi assunti. (INFORMATIVA AGGIORNATA A OTTOBRE
          2021)
        </p>
      </DialogInfo>
    </>
  );
}
