import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD } from "mutation";
import {
  ResetPassword,
  ResetPasswordVariables,
} from "types/generated/schemaTypes";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ActionType, DialogForm } from "components";
import { formatError } from "utils";
import { Visibility, VisibilityOff } from "@material-ui/icons";

export interface ResetPasswordFormValues {
  password: string;
  passwordCheck: string;
}

const defaultInitialValues = {
  password: "",
  passwordCheck: "",
};

const validationSchema = Yup.object({
  password: Yup.string().required("Campo obbligatorio"),
  passwordCheck: Yup.string()
    .test("equal", "Le password non coincidono", function (v) {
      const ref = Yup.ref("password");
      return v === this.resolve(ref);
    })
    .required("Campo obbligatorio"),
});

interface ResetPasswordFormProps {
  open: boolean;
  token: string;
  onClose: () => void;
  onSuccess: () => void;
  onError: () => void;
}

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  open,
  onClose,
  onSuccess,
  onError,
  token,
}) => {
  const [formError, setFormError] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordCheck, setShowPasswordCheck] = useState(false);
  const [resetPasswordMutation] = useMutation<
    ResetPassword,
    ResetPasswordVariables
  >(RESET_PASSWORD, {
    onError: (err) => {
      setFormError(formatError(err));
      setIsSending(false);
    },
    onCompleted: () => {
      onSuccess();
      setIsSending(false);
      formik.resetForm();
    },
  });
  const formik = useFormik<ResetPasswordFormValues>({
    initialValues: defaultInitialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsSending(true);
      await resetPasswordMutation({
        variables: {
          password: values.password,
          confirmPassword: values.passwordCheck,
          resetToken: token,
        },
      });
    },
  });
  return (
    <DialogForm
      open={open}
      title="Reset password"
      onClose={() => {
        formik.resetForm();
        onClose();
      }}
      actions={[
        {
          type: ActionType.EXIT,
          label: "ESCI SENZA SALVARE",
          callback: () => {
            formik.resetForm();
            onClose();
          },
          disabled: isSending,
        },
        {
          type: ActionType.SEND,
          label: "SALVA E CHIUDI",
          callback: formik.submitForm,
          disabled: isSending,
        },
      ]}
    >
      <div style={{ overflow: "hidden" }}>
        <Typography color="textSecondary" style={{ marginBottom: 28 }}>
          Inserisci la nuova password e clicca sul pulsante "Salva e chiudi" per
          salvarla.
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            {/* PASSWORD */}
            <FormControl fullWidth={true} variant="standard" size="small">
              <InputLabel htmlFor="password">Password*</InputLabel>
              <Input
                name="password"
                error={formik.touched.password && !!formik.errors.password}
                id="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText
                error={formik.touched.password && !!formik.errors.password}
                variant="standard"
              >
                {(formik.touched.password &&
                  !!formik.errors.password &&
                  formik.errors.password) ||
                  "La password deve essere lunga almeno 8 caratteri"}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* VERIFICA PASSWORD */}
            <FormControl fullWidth={true} variant="standard" size="small">
              <InputLabel htmlFor="passwordCheck">
                Verifica password*
              </InputLabel>
              <Input
                name="passwordCheck"
                error={
                  formik.touched.passwordCheck && !!formik.errors.passwordCheck
                }
                id="passwordCheck"
                value={formik.values.passwordCheck}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
                type={showPasswordCheck ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle passwordCheck visibility"
                      onClick={() => setShowPasswordCheck(!showPasswordCheck)}
                      edge="end"
                    >
                      {showPasswordCheck ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText
                error={
                  formik.touched.passwordCheck && !!formik.errors.passwordCheck
                }
                variant="standard"
              >
                {formik.touched.passwordCheck &&
                  !!formik.errors.passwordCheck &&
                  formik.errors.passwordCheck}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        {formError && (
          <Typography color="error" variant="body1">
            {formError}
          </Typography>
        )}
      </div>
    </DialogForm>
  );
};
