import React, { ReactNode } from "react";
import {
  Dialog,
  DialogActions,
  makeStyles,
  Typography,
  Button,
  Divider,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { colors } from "theme";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  closeButton: {
    width: 25,
    height: 25,
    position: "absolute",
    top: 10,
    right: 10,
    cursor: "pointer",
    overflow: "hidden",
  },
  divider: {
    margin: "35px auto 40px",
  },
  actions: {
    marginTop: 0,
    // justifyContent: 'space-between',
    flexDirection: "column-reverse",
    // gap: '30px',
    flexWrap: "wrap",
    [theme.breakpoints.up("sm")]: {
      marginTop: 20,
      justifyContent: "flex-end",
      flexDirection: "row",
    },
    "& > *": {
      margin: 15,
    },
  },
}));

interface DialogErrorProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  title?: string;
}

export const DialogError: React.FC<DialogErrorProps> = ({
  open,
  onClose,
  children,
  title,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      maxWidth="md"
      fullWidth={true}
      open={open}
      scroll="body"
      className={classes.root}
    >
      <div className={classes.closeButton} onClick={onClose}>
        <Close
          htmlColor={colors.text.primary}
          style={{
            width: 35,
            height: 35,
            top: -6,
            left: -6,
            position: "absolute",
          }}
        />
      </div>

      {title && (
        <Typography
          variant="h5"
          color="error"
          align="left"
          style={{ marginBottom: "0.75em" }}
        >
          {title}
        </Typography>
      )}
      {children}
      <Divider style={{ marginTop: "2em" }} />
      <DialogActions className={classes.actions} disableSpacing={true}>
        <Button
          variant="outlined"
          color="primary"
          size="medium"
          startIcon={<Close />}
          onClick={onClose}
        >
          CHIUDI
        </Button>
      </DialogActions>
    </Dialog>
  );
};
