import React from "react";
import {
  ClientFields_addresses as AddressType,
  CreateAddressInput,
  UpdateAddressInput,
} from "types/generated/schemaTypes";
import {
  FormControl,
  Grid,
  TextField,
  Divider,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  Fab,
  Button,
  useTheme,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { provinces } from "utils/provinces";
import { regions } from "utils/regions";

import { Check as CheckIcon, Close as CloseIcon } from "@material-ui/icons";

export interface AddressFormValues {
  destinationCode: string | null;
  label: string | null;
  addressLine: string;
  zip: string;
  city: string;
  hamlet: string | null;
  province: string;
  region: string;
  careOf: string | null;
  notes: string | null;
}

const defaultInitialValues = {
  destinationCode: "",
  label: "",
  addressLine: "",
  zip: "",
  city: "",
  hamlet: "",
  province: "",
  region: "",
  careOf: "",
  notes: "",
};

const parseInitialValue = (input: AddressType): AddressFormValues => {
  const values: AddressFormValues = {
    destinationCode: input.destinationCode || "",
    label: input.label || "",
    addressLine: input.addressLine,
    zip: input.zip,
    city: input.city,
    hamlet: input.hamlet || "",
    province: input.province,
    region: input.region,
    careOf: input.careOf || "",
    notes: input.notes || "",
  };
  return values;
};

const validationSchema = Yup.object({
  addressLine: Yup.string().required("Campo obbligatorio"),
  zip: Yup.string().required("Campo obbligatorio"),
  city: Yup.string().required("Campo obbligatorio"),
  province: Yup.string().required("Campo obbligatorio"),
  region: Yup.string().required("Campo obbligatorio"),
  notes: Yup.string().max(200, "Massimo 200 caratteri"),
});

interface AddressFormProps {
  open: boolean;
  onClose: () => void;
  onEdit: () => void;
  onCreate: (addressData: CreateAddressInput) => void;
  onUpdate: (addressData: UpdateAddressInput) => void;
  address: AddressType | null;
  isAgent: boolean;
}

export const AddressForm: React.FC<AddressFormProps> = ({
  open,
  onClose,
  onEdit,
  onCreate,
  onUpdate,
  address,
  isAgent,
}) => {
  const theme = useTheme();
  const update = !!address;
  const formik = useFormik<AddressFormValues>({
    initialValues: address
      ? parseInitialValue(address as AddressType)
      : defaultInitialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      let variables: any;
      if (update) {
        // update
        variables = {
          id: address?.id,
          addressLine: formik.values.addressLine,
          city: formik.values.city,
          hamlet: formik.values.hamlet || null,
          zip: formik.values.zip,
          province: formik.values.province,
          region: formik.values.region,
          label: formik.values.label || null,
          careOf: formik.values.careOf || null,
          notes: formik.values.notes || null,
        };
        if (!isAgent)
          variables.destinationCode = formik.values.destinationCode || null;

        onUpdate(variables);
        formik.resetForm();
        onClose();
      } else {
        variables = {
          addressLine: formik.values.addressLine,
          city: formik.values.city,
          hamlet: formik.values.hamlet || null,
          zip: formik.values.zip,
          province: formik.values.province,
          region: formik.values.region,
          label: formik.values.label || null,
          careOf: formik.values.careOf || null,
          destinationCode: formik.values.destinationCode || null,
          notes: formik.values.notes || null,
        };
        if (!isAgent)
          variables.destinationCode = formik.values.destinationCode || null;
        onCreate(variables);
        formik.resetForm();
        onClose();
      }
    },
  });

  return (
    <div>
      <div style={{ overflow: "hidden" }}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            {/* LABEL */}
            <FormControl fullWidth={true}>
              <TextField
                name="label"
                error={formik.touched.label && !!formik.errors.label}
                helperText={
                  formik.touched.label &&
                  !!formik.errors.label &&
                  formik.errors.label
                }
                variant="standard"
                id="label"
                size="small"
                label="Dai un nome all'indirizzo"
                value={formik.values.label}
                onChange={(e) => {
                  formik.setFieldValue("label", e.target.value);
                  onEdit();
                }}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          {!isAgent && (
            <Grid item xs={12} md={6}>
              {/* DESTINATION CODE */}
              <FormControl fullWidth={true}>
                <TextField
                  name="destinationCode"
                  error={
                    formik.touched.destinationCode &&
                    !!formik.errors.destinationCode
                  }
                  helperText={
                    formik.touched.destinationCode &&
                    !!formik.errors.destinationCode &&
                    formik.errors.destinationCode
                  }
                  variant="standard"
                  id="destinationCode"
                  size="small"
                  label="Codice destinazione"
                  value={formik.values.destinationCode}
                  onChange={(e) => {
                    formik.setFieldValue("destinationCode", e.target.value);
                    onEdit();
                  }}
                  disabled={formik.isSubmitting}
                />
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12} md={9}>
            {/* ADDRESS LINE */}
            <FormControl fullWidth={true}>
              <TextField
                name="addressLine"
                error={
                  formik.touched.addressLine && !!formik.errors.addressLine
                }
                helperText={
                  formik.touched.addressLine &&
                  !!formik.errors.addressLine &&
                  formik.errors.addressLine
                }
                variant="standard"
                id="addressLine"
                size="small"
                label="Indirizzo (Via, Piazza, ..., e civico)*"
                value={formik.values.addressLine}
                onChange={(e) => {
                  formik.setFieldValue("addressLine", e.target.value);
                  onEdit();
                }}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            {/* CAP */}
            <FormControl fullWidth={true}>
              <TextField
                name="zip"
                error={formik.touched.zip && !!formik.errors.zip}
                helperText={
                  formik.touched.zip && !!formik.errors.zip && formik.errors.zip
                }
                variant="standard"
                id="zip"
                size="small"
                label="CAP*"
                value={formik.values.zip}
                onChange={(e) => {
                  formik.setFieldValue("zip", e.target.value);
                  onEdit();
                }}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            {/* CITTÀ */}
            <FormControl fullWidth={true}>
              <TextField
                name="city"
                error={formik.touched.city && !!formik.errors.city}
                helperText={
                  formik.touched.city &&
                  !!formik.errors.city &&
                  formik.errors.city
                }
                variant="standard"
                id="city"
                size="small"
                label="Città*"
                value={formik.values.city}
                onChange={(e) => {
                  formik.setFieldValue("city", e.target.value);
                  onEdit();
                }}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            {/* FRAZIONE */}
            <FormControl fullWidth={true}>
              <TextField
                name="hamlet"
                error={formik.touched.hamlet && !!formik.errors.hamlet}
                helperText={
                  formik.touched.hamlet &&
                  !!formik.errors.hamlet &&
                  formik.errors.hamlet
                }
                variant="standard"
                id="hamlet"
                size="small"
                label="Frazione"
                value={formik.values.hamlet}
                onChange={(e) => {
                  formik.setFieldValue("hamlet", e.target.value);
                  onEdit();
                }}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            {/* PROVINCIA */}
            <FormControl fullWidth={true} variant="standard" size="small">
              <InputLabel
                error={formik.touched.province && !!formik.errors.province}
                id="province-label"
              >
                Provincia*
              </InputLabel>
              <Select
                name="province"
                value={formik.values.province}
                labelId="province-label"
                id="province"
                label="Provincia*"
                error={formik.touched.province && !!formik.errors.province}
                onChange={(e) => {
                  formik.setFieldValue("province", e.target.value);
                  onEdit();
                }}
                disabled={formik.isSubmitting}
              >
                {provinces.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.province && !!formik.errors.province && (
                <FormHelperText error={true} variant="standard">
                  {formik.errors.province}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            {/* REGIONE */}
            <FormControl fullWidth={true} variant="standard" size="small">
              <InputLabel
                error={formik.touched.province && !!formik.errors.province}
                id="region-label"
              >
                Regione*
              </InputLabel>
              <Select
                name="region"
                value={formik.values.region}
                labelId="region-label"
                id="region"
                label="Regione*"
                error={formik.touched.region && !!formik.errors.region}
                onChange={(e) => {
                  formik.setFieldValue("region", e.target.value);
                  onEdit();
                }}
                disabled={formik.isSubmitting}
              >
                {regions.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.region && !!formik.errors.region && (
                <FormHelperText error={true} variant="standard">
                  {formik.errors.region}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            {/* C/O */}
            <FormControl fullWidth={true}>
              <TextField
                name="careOf"
                error={formik.touched.careOf && !!formik.errors.careOf}
                helperText={
                  formik.touched.careOf &&
                  !!formik.errors.careOf &&
                  formik.errors.careOf
                }
                variant="standard"
                id="careOf"
                size="small"
                label="C/O"
                value={formik.values.careOf}
                onChange={(e) => {
                  formik.setFieldValue("careOf", e.target.value);
                  onEdit();
                }}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            {/* Notes */}
            <FormControl fullWidth={true}>
              <TextField
                name="notes"
                error={formik.touched.notes && !!formik.errors.notes}
                helperText={
                  formik.touched.notes &&
                  !!formik.errors.notes &&
                  formik.errors.notes
                }
                variant="outlined"
                multiline={true}
                rows={4}
                id="notes"
                size="small"
                label="Note"
                value={formik.values.notes}
                onChange={(e) => {
                  formik.setFieldValue("notes", e.target.value);
                  onEdit();
                }}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <div style={{ textAlign: "right" }}>
              <Button
                startIcon={<CloseIcon />}
                onClick={onClose}
                variant="text"
                style={{ color: theme.palette.secondary.main, marginRight: 16 }}
              >
                ANNULLA
              </Button>
              <Fab
                onClick={() => {
                  formik.submitForm();
                }}
                variant="extended"
                size="small"
                color="primary"
              >
                <CheckIcon />
                Conferma
              </Fab>
            </div>
          </Grid>
        </Grid>

        {/* <Typography
          color="textSecondary"
          variant="caption"
          component="p"
          style={{ marginBottom: 30, marginTop: 0 }}
        >
          * I campi contrassegnati con l’asterisco sono obbligatori
        </Typography> */}
        <Divider />
      </div>
    </div>
  );
};
