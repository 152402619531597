import { gql } from "@apollo/client";
import {
  CLIENT_FIELDS,
  DOCUMENT_FIELDS,
  ORDER_CLOSED_FIELDS,
  ORDER_FIELDS,
  SALES_CLIENT_FIELDS,
  PRODUCT_FIELDS,
  USER_FIELDS,
  SALES_ORDER_FIELDS,
  SALES_ORDER_CLOSED_FIELDS,
} from "fragment";
export const ME = gql`
  query Me {
    me {
      id
      email
      role
      name
      lastname
    }
  }
`;

export const GET_PRODUCTS = gql`
  ${PRODUCT_FIELDS}
  query GetProducts(
    $offset: Int
    $limit: Int
    $where: ProductWhereInput
    $order: ProductOrderInput
  ) {
    getProducts(where: $where, limit: $limit, offset: $offset, order: $order) {
      total
      products {
        ...ProductFields
      }
    }
  }
`;

export const GET_PRODUCT = gql`
  ${PRODUCT_FIELDS}
  query GetProduct($productId: ID!) {
    getProduct(productId: $productId) {
      ...ProductFields
    }
  }
`;

export const GET_THERAPY = gql`
  ${PRODUCT_FIELDS}
  query GetTherapy($therapyId: ID!) {
    getTherapy(therapyId: $therapyId) {
      id
      name
      therapyEntries {
        quantity
        products {
          ...ProductFields
        }
      }
    }
  }
`;

export const GET_THERAPIES = gql`
  ${PRODUCT_FIELDS}
  query GetTherapies(
    $offset: Int
    $limit: Int
    $where: TherapyWhereInput
    $order: TherapyOrderInput
  ) {
    getTherapies(where: $where, limit: $limit, offset: $offset, order: $order) {
      total
      therapies {
        id
        name
        therapyEntries {
          quantity
          products {
            ...ProductFields
          }
        }
      }
    }
  }
`;

export const GET_USERS = gql`
  ${USER_FIELDS}
  query GetUsers(
    $offset: Int
    $limit: Int
    $where: UserWhereInput
    $order: UserOrderInput
  ) {
    getUsers(where: $where, limit: $limit, offset: $offset, order: $order) {
      total
      users {
        ...UserFields
      }
    }
  }
`;

export const GET_USER = gql`
  ${USER_FIELDS}
  query GetUser($userId: ID!) {
    getUser(userId: $userId) {
      ...UserFields
    }
  }
`;

export const GET_CLIENTS = gql`
  ${CLIENT_FIELDS}
  query GetClients(
    $offset: Int
    $limit: Int
    $where: ClientWhereInput
    $order: ClientOrderInput
  ) {
    getClients(where: $where, limit: $limit, offset: $offset, order: $order) {
      total
      clients {
        ...ClientFields
      }
    }
  }
`;

export const GET_CLIENTS_SIMPLE = gql`
  query GetClientsSimple(
    $offset: Int
    $limit: Int
    $where: ClientWhereInput
    $order: ClientOrderInput
  ) {
    getClients(where: $where, limit: $limit, offset: $offset, order: $order) {
      total
      clients {
        id
        name
        lastname
      }
    }
  }
`;

export const GET_CLIENT = gql`
  ${CLIENT_FIELDS}
  query GetClient($clientId: ID!) {
    getClient(clientId: $clientId) {
      ...ClientFields
    }
  }
`;

export const GET_ORDERS = gql`
  ${ORDER_FIELDS}
  query GetOrders(
    $offset: Int
    $limit: Int
    $where: OrderWhereInput
    $order: OrderOrderInput
  ) {
    getOrders(where: $where, limit: $limit, offset: $offset, order: $order) {
      total
      orders {
        ...OrderFields
      }
    }
  }
`;

export const GET_ORDER = gql`
  ${ORDER_FIELDS}
  query GetOrder($orderId: ID!) {
    getOrder(orderId: $orderId) {
      ...OrderFields
    }
  }
`;

export const GET_ORDERS_CLOSED = gql`
  ${ORDER_CLOSED_FIELDS}
  query GetOrdersClosed(
    $offset: Int
    $limit: Int
    $where: OrderClosedWhereInput
    $order: OrderClosedOrderInput
  ) {
    getOrdersClosed(
      where: $where
      limit: $limit
      offset: $offset
      order: $order
    ) {
      total
      orders {
        ...OrderClosedFields
      }
    }
  }
`;

export const GET_ORDER_CLOSED = gql`
  ${ORDER_CLOSED_FIELDS}
  query GetOrderClosed($orderId: ID!) {
    getOrderClosed(orderId: $orderId) {
      ...OrderClosedFields
    }
  }
`;

export const GET_DOCUMENT = gql`
  ${DOCUMENT_FIELDS}
  query GetDocument($medicalDocumentId: ID!) {
    getMedicalDocument(medicalDocumentId: $medicalDocumentId) {
      ...DocumentFields
    }
  }
`;

export const GET_DOCUMENTS = gql`
  ${DOCUMENT_FIELDS}
  query GetDocuments(
    $offset: Int
    $limit: Int
    $where: MedicalDocumentWhereInput
    $order: MedicalDocumentOrderInput
  ) {
    getMedicalDocuments(
      where: $where
      limit: $limit
      offset: $offset
      order: $order
    ) {
      total
      medicalDocuments {
        ...DocumentFields
      }
    }
  }
`;

export const GET_SIGNED_URL = gql`
  query getSignedUrl($filename: String!) {
    getSignedUrl(filename: $filename) {
      uploadUrl
      finalUrl
    }
  }
`;

export const GET_MAINTENANCE_MODE = gql`
  query GetMaintenanceMode {
    getMaintenanceMode
  }
`;

export const GET_SETTINGS = gql`
  query GetSettings($id: String!) {
    getSettings(id: $id) {
      id
      value
    }
  }
`;

export const GET_SALES_CLIENTS = gql`
  ${SALES_CLIENT_FIELDS}
  query GetSalesClients(
    $offset: Int
    $limit: Int
    $where: SalesClientWhereInput
    $order: SalesClientOrderInput
  ) {
    getSalesClients(where: $where, limit: $limit, offset: $offset, order: $order) {
      total
      salesClients {
        ...SalesClientFields
      }
    }
  }
`;

export const GET_SALES_CLIENTS_SIMPLE = gql`
  query GetSalesClientsSimple(
    $offset: Int
    $limit: Int
    $where: SalesClientWhereInput
    $order: SalesClientOrderInput
  ) {
    getSalesClients(where: $where, limit: $limit, offset: $offset, order: $order) {
      total
      salesClients {
        id
        name
      }
    }
  }
`;

export const GET_SALES_CLIENT = gql`
  ${SALES_CLIENT_FIELDS}
  query GetSalesClient($salesClientId: ID!) {
    getSalesClient(salesClientId: $salesClientId) {
      ...SalesClientFields
    }
  }
`;

export const GET_SALES_ORDERS = gql`
  ${SALES_ORDER_FIELDS}
  query GetSalesOrders(
    $offset: Int
    $limit: Int
    $where: SalesOrderWhereInput
    $order: SalesOrderOrderInput
  ) {
    getSalesOrders(where: $where, limit: $limit, offset: $offset, order: $order) {
      total
      salesOrders {
        ...SalesOrderFields
      }
    }
  }
`;

export const GET_SALES_ORDER = gql`
  ${SALES_ORDER_FIELDS}
  query GetSalesOrder($salesOrderId: ID!) {
    getSalesOrder(salesOrderId: $salesOrderId) {
      ...SalesOrderFields
    }
  }
`;

export const GET_SALES_ORDERS_CLOSED = gql`
  ${SALES_ORDER_CLOSED_FIELDS}
  query GetSalesOrdersClosed(
    $offset: Int
    $limit: Int
    $where: SalesOrderClosedWhereInput
    $order: SalesOrderClosedOrderInput
  ) {
    getSalesOrdersClosed(
      where: $where
      limit: $limit
      offset: $offset
      order: $order
    ) {
      total
      salesOrders {
        ...SalesOrderClosedFields
      }
    }
  }
`;

export const GET_SALES_ORDER_CLOSED = gql`
  ${SALES_ORDER_CLOSED_FIELDS}
  query GetSalesOrderClosed($salesOrderId: ID!) {
    getSalesOrderClosed(salesOrderId: $salesOrderId) {
      ...SalesOrderClosedFields
    }
  }
`;
