import React from "react";
import { useMutation } from "@apollo/client";
import {
  CloseSalesOrder,
  CloseSalesOrderVariables,
  SalesOrderFinalStatus,
  SalesOrderFields,
  ProductFields,
  SalesOrderFields_selectedSalesProducts,
} from "types/generated/schemaTypes";
import {
  FormControl,
  Grid,
  TextField,
  Typography,
  Divider,
  makeStyles,
  RadioGroup,
  FormHelperText,
  Radio,
  FormControlLabel,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CLOSE_SALES_ORDER } from "mutation";
import { DialogForm, ActionType } from "components";
import { formatError } from "utils/errors";
import { formatDate } from "utils/date";
import {
  formatProductPrice,
  formatSalesProductByProductDetails,
  getProductDetailsByProductId,
} from "utils/products";
import { Alert } from "@material-ui/lab";
import { getPaymentLabel } from "utils";

const useStyles = makeStyles((theme) => ({
  noPaddingBottom: {
    paddingBottom: "4px !important",
  },
  recapTable: {
    width: "100%",
  },
}));

export interface ManageSalesOrderFormValues {
  finalStatus: SalesOrderFinalStatus | null;
  notes: string;
  discount: number;
}

const validationSchema = Yup.object({
  finalStatus: Yup.mixed().required("Campo obbligatorio"),
});

interface ManageSalesOrderFormProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (salesOrderClosedId: string, accepted: boolean) => void;
  salesOrder: SalesOrderFields;
  products: ProductFields[];
}

export const ManageSalesOrderForm: React.FC<ManageSalesOrderFormProps> = ({
  open,
  onClose,
  onConfirm,
  salesOrder,
  products,
}) => {
  const classes = useStyles();
  const [closeSalesOrderMutation, { error: closeSalesOrderError }] =
    useMutation<CloseSalesOrder, CloseSalesOrderVariables>(CLOSE_SALES_ORDER, {
      onCompleted: async (data) => {
        formik.resetForm();
        onConfirm(
          data.closeSalesOrder.id,
          data.closeSalesOrder.finalStatus === SalesOrderFinalStatus.APPROVED
        );
      },
      onError: (err) => {
        console.log("Errore!", err);
      },
    });

  const formik = useFormik<ManageSalesOrderFormValues>({
    initialValues: { finalStatus: null, notes: "", discount: salesOrder.discount },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const variables = {
        finalStatus: values.finalStatus || SalesOrderFinalStatus.APPROVED,
        notes: values.notes || null,
        salesOrderId: salesOrder.id,
        discount: values.discount,
      };
      await closeSalesOrderMutation({ variables });
    },
  });

  let totalPrice = 0;

  salesOrder.selectedSalesProducts.forEach(
    (product: SalesOrderFields_selectedSalesProducts) => {
      const productDetails = getProductDetailsByProductId(
        product.productId,
        products
      );
      const price = (productDetails?.price || 0) * product.quantitySale;
      totalPrice += price;
    }
  );

  const finalPrice = totalPrice - (totalPrice * formik.values.discount) / 100;

  return (
    <DialogForm
      open={open}
      title="Convalida l'ordine"
      onClose={() => {
        formik.resetForm();
        onClose();
      }}
      actions={[
        {
          type: ActionType.EXIT,
          label: "CHIUDI SENZA SALVARE",
          callback: () => {
            formik.resetForm();
            onClose();
          },
          disabled: formik.isSubmitting,
        },
        {
          type: ActionType.CHECK,
          label: "CONFERMA",
          callback: formik.submitForm,
          disabled:
            formik.isSubmitting || !salesOrder.salesAddress.destinationCode,
        },
      ]}
    >
      <div style={{ overflow: "hidden" }}>
        <Typography color="textSecondary" style={{ marginBottom: 28 }}>
          Verifica e convalida l'ordine.
        </Typography>
        <Typography
          color="textPrimary"
          style={{ marginBottom: 13, fontWeight: 700 }}
        >
          Cliente
        </Typography>

        {!salesOrder.salesAddress.destinationCode && (
          <Alert severity="error" variant="filled" style={{ marginBottom: 28 }}>
            ATTENZIONE: Questo cliente non è ancora stato convalidato e non è
            possibile procedere con l'ordine (verificare che l'indirizzo abbia
            un codice di destinazione abbinato)
          </Alert>
        )}

        <Grid container spacing={1}>
          <Grid item xs={4} md={3} className={classes.noPaddingBottom}>
            <Typography
              color="inherit"
              style={{ fontWeight: 700, color: "rgba(0,0,0,0.26)" }}
            >
              RAGIONE SOCIALE
            </Typography>
          </Grid>
          <Grid item xs={8} md={9} className={classes.noPaddingBottom}>
            <Typography color="textPrimary" style={{ fontWeight: 700 }}>
              {salesOrder.salesAddress.name}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} className={classes.noPaddingBottom}>
            <Typography
              color="textSecondary"
              style={{ fontWeight: 700, color: "rgba(0,0,0,0.26)" }}
            >
              ULTIMO ORDINE
            </Typography>
          </Grid>
          <Grid item xs={8} md={9} className={classes.noPaddingBottom}>
            <Typography color="textPrimary">
              {salesOrder.salesClient.lastOrder
                ? formatDate(new Date(salesOrder.salesClient.lastOrder))
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} className={classes.noPaddingBottom}>
            <Typography
              color="textSecondary"
              style={{ fontWeight: 700, color: "rgba(0,0,0,0.26)" }}
            >
              EMAIL
            </Typography>
          </Grid>
          <Grid item xs={8} md={9} className={classes.noPaddingBottom}>
            <Typography color="primary">
              <a
                href={`mailto:{order.client.email}`}
                style={{ color: "inherit", textDecoration: "none" }}
              >
                {salesOrder.salesClient.email}
              </a>
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} className={classes.noPaddingBottom}>
            <Typography
              color="textSecondary"
              style={{ fontWeight: 700, color: "rgba(0,0,0,0.26)" }}
            >
              CODICE
            </Typography>
          </Grid>
          <Grid item xs={8} md={9} className={classes.noPaddingBottom}>
            <Typography color="textPrimary">
              {salesOrder.salesAddress.destinationCode || "-"}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} className={classes.noPaddingBottom}>
            <Typography
              color="textSecondary"
              style={{ fontWeight: 700, color: "rgba(0,0,0,0.26)" }}
            >
              INDIRIZZO
            </Typography>
          </Grid>
          <Grid item xs={8} md={9} className={classes.noPaddingBottom}>
            <Typography color="textPrimary">
              {salesOrder.salesAddress.addressLine},{" "}
              {salesOrder.salesAddress.zip} {salesOrder.salesAddress.hamlet}{" "}
              {salesOrder.salesAddress.city} ({salesOrder.salesAddress.province}
              )
              {/* {order.address.careOf ? (
                <>
                  <br />
                  {order.address.careOf}
                </>
              ) : null} */}
            </Typography>
          </Grid>
        </Grid>
        <Divider style={{ margin: "24px 0" }} />
        <Typography
          color="textPrimary"
          style={{ marginBottom: 13, fontWeight: 700 }}
        >
          Agente
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={4} md={3} className={classes.noPaddingBottom}>
            <Typography
              color="inherit"
              style={{ fontWeight: 700, color: "rgba(0,0,0,0.26)" }}
            >
              NOMINATIVO
            </Typography>
          </Grid>
          <Grid item xs={8} md={9} className={classes.noPaddingBottom}>
            <Typography color="textPrimary" style={{ fontWeight: 700 }}>
              {salesOrder.agent.name} {salesOrder.agent.lastname}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} className={classes.noPaddingBottom}>
            <Typography
              color="textSecondary"
              style={{ fontWeight: 700, color: "rgba(0,0,0,0.26)" }}
            >
              EMAIL
            </Typography>
          </Grid>
          <Grid item xs={8} md={9} className={classes.noPaddingBottom}>
            <Typography color="primary">
              <a
                href={`mailto:{order.client.email}`}
                style={{ color: "inherit", textDecoration: "none" }}
              >
                {salesOrder.agent.email}
              </a>
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} className={classes.noPaddingBottom}>
            <Typography
              color="textSecondary"
              style={{ fontWeight: 700, color: "rgba(0,0,0,0.26)" }}
            >
              CODICE
            </Typography>
          </Grid>
          <Grid item xs={8} md={9} className={classes.noPaddingBottom}>
            <Typography color="textPrimary">
              {salesOrder.agent.agentCrm}
            </Typography>
          </Grid>
        </Grid>

        <Divider style={{ margin: "24px 0" }} />

        <Typography
          color="textPrimary"
          style={{ marginBottom: 13, fontWeight: 700 }}
        >
          Ordine
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Prodotto</TableCell>
                    <TableCell>Unità vendita</TableCell>
                    <TableCell>Unità omaggio</TableCell>
                    <TableCell>Unità campione</TableCell>
                    <TableCell>Totale</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {salesOrder.selectedSalesProducts.map((product) => {
                    const productDetails = getProductDetailsByProductId(
                      product.productId,
                      products
                    );
                    if (!productDetails) return null;
                    return (
                      <TableRow key={product.productId}>
                        <TableCell>
                          {formatSalesProductByProductDetails(
                            productDetails,
                            true
                          )}
                        </TableCell>
                        <TableCell>{product.quantitySale}</TableCell>
                        <TableCell>{product.quantityDiscount}</TableCell>
                        <TableCell>{product.quantitySample}</TableCell>
                        <TableCell>
                          {formatProductPrice(
                            productDetails.price * product.quantitySale
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  <TableRow>
                    <TableCell colSpan={3} />
                    <TableCell colSpan={1}>Totale</TableCell>
                    <TableCell colSpan={1}>
                      {formatProductPrice(totalPrice)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3} />
                    <TableCell colSpan={1}>
                      <b>Totale scontato</b>
                    </TableCell>
                    <TableCell colSpan={1}>
                      <b>{formatProductPrice(finalPrice)}</b>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        <Divider style={{ margin: "24px 0" }} />
        <Typography
          color="textPrimary"
          style={{ marginBottom: 13, fontWeight: 700 }}
        >
          Note ordine
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} className={classes.noPaddingBottom}>
            <Typography color="textPrimary">
              {salesOrder.deliveryNotes || "Non ci sono note per quest'ordine."}
            </Typography>
          </Grid>
        </Grid>

        <Divider style={{ margin: "24px 0" }} />
        <Typography
          color="textPrimary"
          style={{ marginBottom: 13, fontWeight: 700 }}
        >
          Metodo di pagamento
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} className={classes.noPaddingBottom}>
            <Typography color="textPrimary">
              {getPaymentLabel(salesOrder.payment)}
            </Typography>
          </Grid>
        </Grid>

        <Divider style={{ margin: "24px 0" }} />
        <Typography
          color="textPrimary"
          style={{ marginBottom: 13, fontWeight: 700 }}
        >
          Gestione{" "}
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth={true}>
              <TextField
                name="discount"
                variant="standard"
                id="discount"
                size="small"
                label="Sconto %"
                value={formik.values.discount}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
                type="number"
                inputProps={{ step: 0.5 }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <RadioGroup
              style={{ flexDirection: "row" }}
              name="finalStatus"
              value={formik.values.finalStatus}
              onChange={formik.handleChange}
            >
              <FormControlLabel
                value={SalesOrderFinalStatus.APPROVED}
                control={<Radio color="primary" />}
                label="Ordine valido"
              />
              <FormControlLabel
                value={SalesOrderFinalStatus.REFUSED}
                control={<Radio color="primary" />}
                label="Ordine rifiutato"
              />
            </RadioGroup>

            {formik.touched.finalStatus && !!formik.errors.finalStatus && (
              <FormHelperText error={true} variant="standard">
                {formik.errors.finalStatus}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} md={4}></Grid>
          <Grid item xs={12} md={12}>
            {/* NOTE */}
            <FormControl fullWidth={true}>
              <TextField
                name="notes"
                error={formik.touched.notes && !!formik.errors.notes}
                helperText={
                  formik.touched.notes &&
                  !!formik.errors.notes &&
                  formik.errors.notes
                }
                variant="outlined"
                multiline={true}
                rows={4}
                id="notes"
                label="Note"
                value={formik.values.notes}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Typography
          color="textSecondary"
          variant="caption"
          component="p"
          style={{ marginBottom: 30, marginTop: 0 }}
        >
          * I campi contrassegnati con l’asterisco sono obbligatori
        </Typography>
        <Divider />

        {closeSalesOrderError && (
          <Typography color="error" variant="body1">
            {closeSalesOrderError && formatError(closeSalesOrderError)}
          </Typography>
        )}
      </div>
    </DialogForm>
  );
};
