import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  TherapyFields,
  UpdateTherapy,
  UpdateTherapyVariables,
  CreateTherapy,
  CreateTherapyVariables,
  GetProductsVariables,
  GetProducts,
  ProductOrderInput,
} from "types/generated/schemaTypes";
import {
  FormControl,
  Grid,
  TextField,
  Typography,
  Divider,
  Fab,
  IconButton,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { GET_PRODUCTS } from "query";
import { CREATE_THERAPY, UPDATE_THERAPY } from "mutation";
import { DialogForm, ActionType } from "components";
import { formatError } from "utils/errors";
import { Autocomplete } from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/DeleteForever";

const useStyles = makeStyles((theme) => ({
  summaryGrid: {
    paddingBottom: "4px !important",
    [theme.breakpoints.up("md")]: {
      paddingBottom: "8px !important",
    },
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  choice: {
    paddingBottom: "16px !important",
  },
}));

interface TherapyEntry {
  productIds: string[];
  quantity: string;
}

export interface TherapyFormValues {
  name: string;
  therapyEntries: TherapyEntry[];
}

const defaultInitialValues = {
  name: "",
  therapyEntries: [],
};

const parseInitialValue = (input: TherapyFields): TherapyFormValues => {
  const values: TherapyFormValues = {
    name: input.name,
    therapyEntries: input.therapyEntries.map((entry) => ({
      productIds: entry.products.map((product) => product.id),
      quantity: entry.quantity.toString(),
    })),
  };
  return values;
};

const validationSchema = Yup.object({
  name: Yup.string().required("Campo obbligatorio"),
});

interface TherapyFormProps {
  open: boolean;
  onClose: () => void;
  onCreate: () => void;
  onUpdate: () => void;
  therapy: TherapyFields | null;
}

export const TherapyForm: React.FC<TherapyFormProps> = ({
  open,
  onClose,
  onCreate,
  onUpdate,
  therapy,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [createTherapyMutation, { error: createTherapyError }] = useMutation<
    CreateTherapy,
    CreateTherapyVariables
  >(CREATE_THERAPY, {
    onCompleted: async (data) => {
      formik.resetForm();
      onCreate();
    },
    onError: (err) => {
      console.log("Errore!", err);
    },
  });
  const [updateTherapyMutation, { error: updateTherapyError }] = useMutation<
    UpdateTherapy,
    UpdateTherapyVariables
  >(UPDATE_THERAPY, {
    onCompleted: async (data) => {
      formik.resetForm();
      onUpdate();
    },
    onError: (err) => {
      console.log("Errore!", err);
    },
  });

  const [productId1, setProductId1] = useState<string | null>(null);
  const [productId2, setProductId2] = useState<string | null>(null);
  const [quantity, setQuantity] = useState<string>("");

  const { data: productsData } = useQuery<GetProducts, GetProductsVariables>(
    GET_PRODUCTS,
    {
      fetchPolicy: "network-only",
      variables: {
        where: { archived: false, forSale: false },
        order: ProductOrderInput.NAME_ASC,
        offset: 0,
        limit: 50,
      },
    }
  );

  const products = productsData?.getProducts
    ? productsData?.getProducts.products
    : [];

  const productOptions = products.map((product) => ({
    id: product.id,
    name: product.name,
  }));

  const update = !!therapy;
  const formik = useFormik<TherapyFormValues>({
    initialValues: therapy
      ? parseInitialValue(therapy as TherapyFields)
      : defaultInitialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      let variables: CreateTherapyVariables | UpdateTherapyVariables;
      if (!update) {
        // create
        variables = {
          therapyData: {
            name: values.name,
            therapyEntries: values.therapyEntries.map((entry) => ({
              productsIds: entry.productIds,
              quantity: parseInt(entry.quantity),
            })),
          },
        } as CreateTherapyVariables;
        await createTherapyMutation({ variables });
      } else {
        // update
        variables = {
          therapyData: {
            name: values.name,
            therapyEntries: values.therapyEntries.map((entry) => ({
              productsIds: entry.productIds,
              quantity: parseInt(entry.quantity),
            })),
          },
          therapyId: (therapy as TherapyFields).id,
        } as UpdateTherapyVariables;
        await updateTherapyMutation({ variables });
      }
    },
  });

  return (
    <DialogForm
      open={open}
      title={update ? "Modifica assortimento" : "Aggiungi un assortimento"}
      onClose={() => {
        formik.resetForm();
        onClose();
      }}
      actions={[
        {
          type: ActionType.EXIT,
          label: "CHIUDI SENZA SALVARE",
          callback: () => {
            formik.resetForm();
            onClose();
          },
          disabled: formik.isSubmitting,
        },
        {
          type: ActionType.SAVE,
          label: "SALVA",
          callback: formik.submitForm,
          disabled: formik.isSubmitting,
        },
      ]}
    >
      <div style={{ overflow: "hidden" }}>
        <Typography color="textSecondary" style={{ marginBottom: 36 }}>
          Inserisci tutte le informazioni richieste e salva per aggiungere un
          nuovo assortimento prodotti.
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            {/* NAME */}
            <FormControl fullWidth={true}>
              <TextField
                name="name"
                error={formik.touched.name && !!formik.errors.name}
                helperText={
                  formik.touched.name &&
                  !!formik.errors.name &&
                  formik.errors.name
                }
                variant="standard"
                id="name"
                size="small"
                label="Nome assortimento*"
                value={formik.values.name}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} className={classes.choice}>
            <div
              style={{ display: "flex", alignItems: "center", paddingTop: 20 }}
            >
              <Typography
                color="textSecondary"
                variant="caption"
                component="p"
                paragraph={false}
                style={{ marginBottom: 0, marginTop: 0, width: 130 }}
              >
                Scegli i prodotti
              </Typography>
              <Divider style={{ width: "calc(100% - 130px)" }} />
            </div>
          </Grid>
          {/* <div style={{ height: 16 }} /> */}
          {/* <Grid item xs={12} md={10} alignContent="center">
          </Grid> */}
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            {/* PRODOTTO 1 */}
            <FormControl fullWidth={true}>
              <Autocomplete
                options={productOptions}
                value={
                  productOptions.find((product) => product.id === productId1) ||
                  null
                }
                getOptionSelected={(option, test) => {
                  return test.id === option.id;
                }}
                onChange={(event, newValue) => {
                  setProductId1(newValue?.id || null);
                }}
                getOptionLabel={(option) => option.name}
                // style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Prodotto variante 1*"
                    variant="standard"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            {/* PRODOTTO 2 */}
            <FormControl fullWidth={true}>
              <Autocomplete
                options={productOptions}
                value={
                  productOptions.find((product) => product.id === productId2) ||
                  null
                }
                getOptionSelected={(option, test) => {
                  return test.id === option.id;
                }}
                onChange={(event, newValue) => {
                  setProductId2(newValue?.id || null);
                }}
                getOptionLabel={(option) => option.name}
                // style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Prodotto variante 2"
                    variant="standard"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={10} md={3}>
            {/* SKU */}
            <FormControl fullWidth={true}>
              <TextField
                name="quantity"
                // error={formik.touched.sku && !!formik.errors.sku}
                // helperText={
                //   formik.touched.sku && !!formik.errors.sku && formik.errors.sku
                // }
                value={quantity}
                type="number"
                variant="standard"
                id="quantity"
                size="small"
                label="Numero di box*"
                // value={formik.values.sku}
                onChange={(event) => setQuantity(event.target.value)}
                // disabled={formik.isSubmitting}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2} md={1}>
            {/* ADD */}
            <Fab
              size="small"
              color="primary"
              disableRipple={true}
              disabled={!(productId1 && quantity)}
              onClick={() => {
                const newEntry = {
                  quantity,
                  productIds: [productId1],
                };
                if (productId2) newEntry.productIds.push(productId2);
                formik.setFieldValue("therapyEntries", [
                  ...formik.values.therapyEntries,
                  newEntry,
                ]);
                setQuantity("");
                setProductId1(null);
                setProductId2(null);
              }}
            >
              <AddIcon />
            </Fab>
          </Grid>
          <Grid item xs={12} md={12}>
            <div style={{ height: isMobile ? 0 : 10 }} />
          </Grid>
          {formik.values &&
            formik.values.therapyEntries
              .slice()
              .sort((a, b) => {
                if (a.productIds.length < b.productIds.length) {
                  return 1;
                } else if (a.productIds.length > b.productIds.length) {
                  return -1;
                } else {
                  return 0;
                }
              })
              .map((entry, index) => {
                if (isMobile) {
                  return (
                    <Grid item xs={10} md={10}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          paddingBottom: 8,
                          borderBottom: `solid 1px ${theme.palette.divider}`,
                        }}
                      >
                        <div>
                          <div>
                            <Typography variant="body2" color="primary">
                              {
                                products.find(
                                  (product) =>
                                    product.id === entry.productIds[0]
                                )?.name
                              }
                            </Typography>

                            <Typography variant="body2" color="primary">
                              {entry.productIds.length > 1
                                ? products.find(
                                    (product) =>
                                      product.id === entry.productIds[1]
                                  )?.name
                                : "- -"}
                            </Typography>
                          </div>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            variant="body2"
                            color="primary"
                            style={{ marginRight: 8 }}
                          >
                            {entry.quantity} Box
                          </Typography>
                          <IconButton
                            aria-label="delete"
                            color="secondary"
                            style={{ padding: 0 }}
                            disableRipple={true}
                            onClick={() => {
                              const therapyEntries = [
                                ...formik.values.therapyEntries,
                              ];

                              formik.setFieldValue(
                                "therapyEntries",
                                therapyEntries
                              );
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </div>
                    </Grid>
                  );
                }
                return (
                  <Grid item xs={12} md={12}>
                    <Grid container spacing={0}>
                      <Grid className={classes.summaryGrid} item xs={12} md={4}>
                        <Typography variant="body2" color="primary">
                          {
                            products.find(
                              (product) => product.id === entry.productIds[0]
                            )?.name
                          }
                        </Typography>
                      </Grid>
                      <Grid className={classes.summaryGrid} item xs={12} md={4}>
                        <div style={{ paddingLeft: 8 }}>
                          <Typography variant="body2" color="primary">
                            {entry.productIds.length > 1
                              ? products.find(
                                  (product) =>
                                    product.id === entry.productIds[1]
                                )?.name
                              : "- -"}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid className={classes.summaryGrid} item xs={12} md={3}>
                        <div
                          style={{
                            paddingLeft: 8,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="body2" color="primary">
                            {entry.quantity} Box
                          </Typography>
                          <IconButton
                            aria-label="delete"
                            color="secondary"
                            style={{ padding: 0 }}
                            disableRipple={true}
                            onClick={() => {
                              const therapyEntries = [
                                ...formik.values.therapyEntries,
                              ];

                              formik.setFieldValue(
                                "therapyEntries",
                                therapyEntries
                              );
                            }}
                          >
                             <IconButton
                            aria-label="delete"
                            color="secondary"
                            style={{ padding: 0 }}
                            disableRipple={true}
                            onClick={() => {
                              const therapyEntries = [
                                ...formik.values.therapyEntries,
                              ];

                              formik.setFieldValue(
                                "therapyEntries",
                                therapyEntries
                              );
                            }}
                          >

                            <DeleteIcon />
                          </IconButton>
                            <Divider />
                          </IconButton>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
        </Grid>
        <Typography
          color="textSecondary"
          variant="caption"
          component="p"
          style={{ marginBottom: 30, marginTop: 10 }}
        >
          * I campi contrassegnati con l’asterisco sono obbligatori
        </Typography>
        <Divider />

        {(createTherapyError || updateTherapyError) && (
          <Typography color="error" variant="body1">
            {createTherapyError && formatError(createTherapyError)}
            {updateTherapyError && formatError(updateTherapyError)}
          </Typography>
        )}
      </div>
    </DialogForm>
  );
};
