import React from "react";
import { useReactiveVar } from "@apollo/client";
import { Link as RouterLink, useRouteMatch } from "react-router-dom";
import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  useMediaQuery,
  useTheme,
  Divider,
  Typography,
} from "@material-ui/core";
import { colors } from "theme";
import {
  Undo as BackIcon,
  Receipt as OrdiniIcon,
  AssignmentInd as AgentiIcon,
  Face as ClientiIcon,
  LocalPharmacy as ProdottiIcon,
  Assignment as TerapieIcon,
  ImportContacts as MaterialeIcon,
  Person as PersonIcon,
  SvgIconComponent,
  ExitToApp as ExitToAppIcon,
  FiberManualRecord as DotIcon,
  Settings as SettingsIcon,
} from "@material-ui/icons";
import { userRoleVar, isLoggedInVar } from "localState";
import { UserRole } from "types/generated/schemaTypes";
import { MaintenanceSwitch } from "./maintenanceSwitch";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    height: "100%",
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(4, 2),
    },
  },
  itemMenu: {
    paddingTop: 8,
    paddingBottom: 8,
    "&.Mui-selected": {
      backgroundColor: "rgba(255,255,255,0.05)",
    },
    "&.Mui-selected:hover": {
      backgroundColor: "rgba(255,255,255,0.05)",
    },
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.05)",
    },
  },
}));

export type NavigationMenuType =
  | "hidden"
  | "multi"
  | "agenti"
  | "admin"
  | "operatori"
  | "agenti"
  | "agenti-campionature"
  | "agenti-vendite";

interface MenuItemProps {
  icon: React.ReactElement<SvgIconComponent>;
  label: string;
  to: string;
  callback: () => void;
}

function CustomMenuItem(props: MenuItemProps) {
  const { label, to, icon, callback } = props;
  const classes = useStyles();
  const match = useRouteMatch({
    path: to,
  });
  return (
    <RouterLink to={to} style={{ textDecoration: "none" }} onClick={callback}>
      <MenuItem className={classes.itemMenu} selected={!!match}>
        <ListItemIcon color="#ffffff" style={{ minWidth: 0, marginRight: 15 }}>
          <icon.type {...icon.props} {...{ htmlColor: "#ffffff" }} />
        </ListItemIcon>
        <ListItemText
          disableTypography={false}
          primaryTypographyProps={{
            variant: "body1",
            style: {
              position: "relative",
              top: 1,
              color: "white",
              whiteSpace: "break-spaces",
            },
          }}
        >
          {label}
        </ListItemText>
      </MenuItem>
    </RouterLink>
  );
}

function LogoutItem() {
  const classes = useStyles();
  return (
    <MenuItem
      className={classes.itemMenu}
      onClick={() => {
        localStorage.removeItem("bga_role");
        localStorage.removeItem("bga_userId");
        localStorage.removeItem("bga_token");
        isLoggedInVar(false);
      }}
    >
      <ListItemIcon
        color={colors.text.primary}
        style={{ minWidth: 0, marginRight: 15 }}
      >
        <ExitToAppIcon htmlColor={"#ffffff"} />
      </ListItemIcon>
      <ListItemText
        disableTypography={false}
        primaryTypographyProps={{
          variant: "body1",
          style: { position: "relative", top: 1, color: "#ffffff" },
        }}
      >
        Esci
      </ListItemText>
    </MenuItem>
  );
}

const VerticalNav: React.FC<{
  closeMenu: () => void;
  menu: NavigationMenuType;
}> = ({ closeMenu, menu }) => {
  const theme = useTheme();

  const desktopLayout = useMediaQuery(theme.breakpoints.up("lg"));
  const role = useReactiveVar(userRoleVar);
  return (
    <nav
      style={{
        position: "relative",
        top: desktopLayout ? 0 : 0,
      }}
    >
      <div style={{ padding: "0 15px" }}>
        {desktopLayout && (
          <Divider
            style={{
              marginBottom: 15,
              backgroundColor: "rgba(216,216,216)",
              borderTop: "1px solid rgba(0,0,0,0.87)",
              height: "2px",
            }}
          />
        )}
        {!desktopLayout && [UserRole.ADMIN, UserRole.OPERATOR].includes(role) && (
          <>
            <div
              style={{
                height: 64,
                display: "flex",
                width: "100%",
                padding: "8px 0",
              }}
            >
              <MaintenanceSwitch />
            </div>
            <div
              style={{
                marginBottom: 32,
              }}
            >
              <Divider
                style={{
                  marginBottom: 30,
                  backgroundColor: "#0D3C61",
                  borderTop: "1px solid rgba(0,0,0,0.87)",
                  height: "2px",
                }}
              />
            </div>
          </>
        )}

        {role === UserRole.ADMIN && (
          <>
            <CustomMenuItem
              callback={closeMenu}
              to="/gestione-operatori"
              label="Operatori"
              icon={<AgentiIcon />}
            />
          </>
        )}
        {[UserRole.OPERATOR, UserRole.ADMIN].includes(role) && (
          <>
            <CustomMenuItem
              callback={closeMenu}
              to="/richieste-campionature"
              label="Richieste campionature"
              icon={<OrdiniIcon />}
            />
            <CustomMenuItem
              callback={closeMenu}
              to="/ordini"
              label="Ordini"
              icon={<OrdiniIcon />}
            />
            <CustomMenuItem
              callback={closeMenu}
              to="/gestione-agenti"
              label="Agenti"
              icon={<AgentiIcon />}
            />
            <CustomMenuItem
              callback={closeMenu}
              to="/clienti"
              label="Operatore / personale sanitario"
              icon={<ClientiIcon />}
            />
            <CustomMenuItem
              callback={closeMenu}
              to="/clienti-vendite"
              label="Clienti Vendite"
              icon={<ClientiIcon />}
            />
            <CustomMenuItem
              callback={closeMenu}
              to="/prodotti"
              label="Prodotti"
              icon={<ProdottiIcon />}
              // icon={<DotIcon fontSize="small" />}
            />
            <CustomMenuItem
              callback={closeMenu}
              to="/assortimento-prodotti"
              label="Assortimento Prodotti"
              // icon={
              //   <DotIcon
              //     fontSize="small"
              //     style={{ transform: "scale(0.65)" }}
              //   />
              // }
              icon={<TerapieIcon />}
            />
            <CustomMenuItem
              callback={closeMenu}
              to="/materiale-informativo"
              label="Materiale Informativo"
              icon={<MaterialeIcon />}
            />
            <CustomMenuItem
              callback={closeMenu}
              to="/profilo"
              label="Profilo"
              icon={<PersonIcon />}
            />
            <CustomMenuItem
              callback={closeMenu}
              to="/settings"
              label="Settings"
              icon={<SettingsIcon />}
            />
            <LogoutItem />
          </>
        )}
      </div>
      <div style={{ padding: "0 15px" }}>
        {role === UserRole.AGENT && (
          <>
            {menu === "agenti-campionature" && (
              <>
                <Typography
                  variant="caption"
                  style={{
                    color: "rgba(255,255,255,0.7)",
                    fontSize: 13,
                    fontWeight: 500,
                  }}
                >
                  ANAGRAFICA
                </Typography>
                <CustomMenuItem
                  callback={closeMenu}
                  to="/area-campionature/clienti"
                  label="Operatore / Personale sanitario"
                  icon={<DotIcon fontSize="small" />}
                />
                <Divider
                  style={{
                    marginBottom: 15,
                    marginTop: 15,
                    backgroundColor: "rgba(216,216,216)",
                    borderTop: "1px solid rgba(0,0,0,0.87)",
                    height: "2px",
                  }}
                />
                <Typography
                  variant="caption"
                  style={{
                    color: "rgba(255,255,255,0.7)",
                    fontSize: 13,
                    fontWeight: 500,
                  }}
                >
                  RICHIESTE OPERATORE / PERSONALE SANITARIO
                </Typography>
                <CustomMenuItem
                  callback={closeMenu}
                  to="/area-campionature/richieste"
                  label="Prodotti Campione"
                  icon={<DotIcon fontSize="small" />}
                />
                <Divider
                  style={{
                    marginBottom: 15,
                    marginTop: 15,
                    backgroundColor: "rgba(216,216,216)",
                    borderTop: "1px solid rgba(0,0,0,0.87)",
                    height: "2px",
                  }}
                />
                <Typography
                  variant="caption"
                  style={{
                    color: "rgba(255,255,255,0.7)",
                    fontSize: 13,
                    fontWeight: 500,
                  }}
                >
                  RICHIESTE AGENTE
                </Typography>
                <CustomMenuItem
                  callback={closeMenu}
                  to="/area-campionature/richiesta-prodotti"
                  label="Prodotti Campione"
                  icon={<DotIcon fontSize="small" />}
                />
                <CustomMenuItem
                  callback={closeMenu}
                  to="/area-campionature/materiale-informativo"
                  label="Materiale Informativo"
                  icon={<DotIcon fontSize="small" />}
                />
                <Divider
                  style={{
                    marginBottom: 15,
                    marginTop: 15,
                    backgroundColor: "rgba(216,216,216)",
                    borderTop: "1px solid rgba(0,0,0,0.87)",
                    height: "2px",
                  }}
                />
              </>
            )}
            {menu === "agenti-vendite" && (
              <>
                <Typography
                  variant="caption"
                  style={{
                    color: "rgba(255,255,255,0.7)",
                    fontSize: 13,
                    fontWeight: 500,
                  }}
                >
                  ANAGRAFICA
                </Typography>
                <CustomMenuItem
                  callback={closeMenu}
                  to="/area-vendite/clienti"
                  label="Clienti"
                  icon={<DotIcon fontSize="small" />}
                />
                <Divider
                  style={{
                    marginBottom: 15,
                    marginTop: 15,
                    backgroundColor: "rgba(216,216,216)",
                    borderTop: "1px solid rgba(0,0,0,0.87)",
                    height: "2px",
                  }}
                />
                <Typography
                  variant="caption"
                  style={{
                    color: "rgba(255,255,255,0.7)",
                    fontSize: 13,
                    fontWeight: 500,
                  }}
                >
                  GESTIONE ORDINI
                </Typography>
                <CustomMenuItem
                  callback={closeMenu}
                  to="/area-vendite/richieste"
                  label="Ordine prodotti"
                  icon={<DotIcon fontSize="small" />}
                />
                <Divider
                  style={{
                    marginBottom: 15,
                    marginTop: 15,
                    backgroundColor: "rgba(216,216,216)",
                    borderTop: "1px solid rgba(0,0,0,0.87)",
                    height: "2px",
                  }}
                />
              </>
            )}
            <Typography
              variant="caption"
              style={{
                color: "rgba(255,255,255,0.7)",
                fontSize: 13,
                fontWeight: 500,
              }}
            >
              AREA PERSONALE
            </Typography>
            <CustomMenuItem
              callback={closeMenu}
              to={`${
                menu === "agenti-campionature"
                  ? "/area-campionature"
                  : "/area-vendite"
              }/profilo`}
              label="Profilo"
              icon={<PersonIcon />}
            />
            {["agenti-campionature", "agenti-vendite"].includes(menu) && <>
            <CustomMenuItem
              callback={closeMenu}
              to={`${
                menu === "agenti-campionature"
                  ? "/area-vendite/richieste"
                  : "/area-campionature/richieste"
              }`}
              label={`Vai all'area ${
                menu === "agenti-campionature"
                  ? "vendite"
                  : "campionature"
              }`}
              icon={<BackIcon />}
            />


            </>}
            <LogoutItem />
          </>
        )}
      </div>
    </nav>
  );
};

export default VerticalNav;
