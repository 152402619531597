export const isValidFiscalCode = (code: string): boolean => {
  if (code.length !== 16) return false;
  let valid = false;
  let sum = 0;
  for (let i = 0; i < 15; i++) {
    const test = code[i].toUpperCase();
    const isEven = (i + 1) % 2 === 0;
    switch (test) {
      case "0":
      case "A":
        sum += isEven ? 0 : 1;
        break;
      case "1":
      case "B":
        sum += isEven ? 1 : 0;
        break;
      case "2":
      case "C":
        sum += isEven ? 2 : 5;
        break;
      case "3":
      case "D":
        sum += isEven ? 3 : 7;
        break;
      case "4":
      case "E":
        sum += isEven ? 4 : 9;
        break;
      case "5":
      case "F":
        sum += isEven ? 5 : 13;
        break;
      case "6":
      case "G":
        sum += isEven ? 6 : 15;
        break;
      case "7":
      case "H":
        sum += isEven ? 7 : 17;
        break;
      case "8":
      case "I":
        sum += isEven ? 8 : 19;
        break;
      case "9":
      case "J":
        sum += isEven ? 9 : 21;
        break;
      case "K":
        sum += isEven ? 10 : 2;
        break;
      case "L":
        sum += isEven ? 11 : 4;
        break;
      case "M":
        sum += isEven ? 12 : 18;
        break;
      case "N":
        sum += isEven ? 13 : 20;
        break;
      case "O":
        sum += isEven ? 14 : 11;
        break;
      case "P":
        sum += isEven ? 15 : 3;
        break;
      case "Q":
        sum += isEven ? 16 : 6;
        break;
      case "R":
        sum += isEven ? 17 : 8;
        break;
      case "S":
        sum += isEven ? 18 : 12;
        break;
      case "T":
        sum += isEven ? 19 : 14;
        break;
      case "U":
        sum += isEven ? 20 : 16;
        break;
      case "V":
        sum += isEven ? 21 : 10;
        break;
      case "W":
        sum += isEven ? 22 : 22;
        break;
      case "X":
        sum += isEven ? 23 : 25;
        break;
      case "Y":
        sum += isEven ? 24 : 24;
        break;
      case "Z":
        sum += isEven ? 25 : 23;
        break;
      default:
        sum -= 10000;
    }
  }
  if (sum < 0) return false;
  const rest = sum % 26;
  const alfabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  if (Number.isNaN(parseInt(`${code[6]}${code[7]}`))) return false;
  valid = code[15].toUpperCase() === alfabet[rest];
  return valid;
};
