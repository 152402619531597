import React, { useState } from "react";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Grid,
  InputAdornment,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Hidden,
} from "@material-ui/core";
import { Add as AddIcon, Search as SearchIcon } from "@material-ui/icons";
import { GET_PRODUCT, GET_PRODUCTS } from "query";
import { UPDATE_PRODUCT } from "mutation";
import {
  UpdateProduct,
  UpdateProductVariables,
  GetProduct,
  GetProductVariables,
  GetProductsVariables,
  GetProducts,
  ProductOrderInput,
  ProductWhereInput,
  ProductFields,
} from "types/generated/schemaTypes";
import {
  ButtonChipDelete,
  ButtonChipUpdate,
  DialogError,
  DialogWarning,
  Breadcrumbs,
  PageTitle,
  ProductForm,
  FiltersWrapper,
} from "components";
import { formatError } from "utils/errors";

export default function GestioneProdotti() {
  const client = useApolloClient();
  const [currentProduct, setCurrentProduct] = useState<null | ProductFields>(
    null
  );
  const [searchString, setSearchString] = useState("");
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState<ProductOrderInput>(
    ProductOrderInput.NAME_ASC
  );
  const [tipologia, setTipologia] = useState<"all"|"campione"|"vendita">(
    "all"
  );
  const [showProductForm, setShowProductForm] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleChangePage = (event: unknown, newPage: number) => {
    setOffset(newPage * limit);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLimit(parseInt(event.target.value, 10));
    setOffset(0);
  };

  const where: ProductWhereInput = { archived: false };

  if (tipologia === 'campione') where.forSale = false;
  if (tipologia === 'vendita') where.forSale = true;

  if (searchString.length > 2) where.searchPattern = searchString;

  const {
    data: productsData,
    loading: productsLoading,
    error: productsError,
    refetch: refetchProducts,
  } = useQuery<GetProducts, GetProductsVariables>(GET_PRODUCTS, {
    fetchPolicy: "network-only",
    variables: {
      where,
      order: order,
      offset,
      limit,
    },
  });

  const [deleteProductMutation] = useMutation<
    UpdateProduct,
    UpdateProductVariables
  >(UPDATE_PRODUCT, {
    refetchQueries: [
      {
        query: GET_PRODUCTS,
        variables: {
          where,
          order: order,
          offset,
          limit,
        },
      },
    ],
    onCompleted: () => {
      setShowWarningDialog(false);
    },
    onError: (err) => {
      setShowWarningDialog(false);
      setErrorMessage(formatError(err));
      setShowErrorDialog(true);
      console.log(err);
    },
  });

  const loadProduct = async (productId: string) => {
    const data = await client.query<GetProduct, GetProductVariables>({
      query: GET_PRODUCT,
      variables: { productId },
      fetchPolicy: "cache-first",
    });
    setCurrentProduct(data.data.getProduct);
  };

  const deleteProduct = async (productId: string | null) => {
    if (productId)
      await deleteProductMutation({
        variables: {
          productId,
          productData: { archived: true },
        },
      });
  };

  return (
    <>
      <PageTitle title="GESTIONE PRODOTTI" />
      <Breadcrumbs current="Gestione Prodotti" />
      <FiltersWrapper>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item xs={6} md={3}>
            <FormControl fullWidth={true} variant="standard" size="medium">
              <InputLabel id="order-label">Ordina per</InputLabel>
              <Select
                name="order"
                value={order}
                labelId="order-label"
                id="order"
                label="Ordina per"
                onChange={(e) => {
                  setOffset(0);
                  setOrder(e.target.value as ProductOrderInput);
                }}
              >
                <MenuItem value={ProductOrderInput.CREATION_DESC}>
                  Data creazione ↓
                </MenuItem>
                <MenuItem value={ProductOrderInput.CREATION_ASC}>
                  Data creazione ↑
                </MenuItem>
                <MenuItem value={ProductOrderInput.NAME_DESC}>
                  Nome (Z-A)
                </MenuItem>
                <MenuItem value={ProductOrderInput.NAME_ASC}>
                  Nome (A-Z)
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControl fullWidth={true} variant="standard" size="medium">
              <InputLabel id="order-label">Tipologia</InputLabel>
              <Select
                name="tipologia"
                value={tipologia}
                labelId="tipologia-label"
                id="tipologia"
                label="Tipologia prodotto"
                onChange={(e) => {
                  setOffset(0);
                  setTipologia(e.target.value as "all"|"campione"|"vendita");
                }}
              >
                <MenuItem value="all">
                  Tutti i prodotti 
                </MenuItem>
                <MenuItem value={"campione"}>
                  Prodotti campione
                </MenuItem>
                <MenuItem value={"vendita"}>
                  Prodotti vendita
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControl fullWidth={true} variant="standard" size="medium">
              <InputLabel htmlFor="search">Cerca</InputLabel>
              <Input
                name="searchString"
                id="searchString"
                autoFocus={searchString.length > 2}
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          {/* <Hidden mdDown>
            <Grid item md={3}>
              &nbsp;
            </Grid>
          </Hidden> */}
          <Grid item xs={12} md={3} style={{ textAlign: "right" }}>
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={() => {
                setCurrentProduct(null);
                setShowProductForm(true);
              }}
              startIcon={<AddIcon />}
            >
              Aggiungi
            </Button>
          </Grid>
        </Grid>
      </FiltersWrapper>
      {productsLoading && <div>Caricamento dei prodotti in corso</div>}
      {!productsLoading && !productsError && productsData && (
        <>
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell variant="head" align="left">
                      NOME
                    </TableCell>
                    <TableCell variant="head" align="left">
                      CODICE
                    </TableCell>
                    <TableCell variant="head" align="left">
                      UNITÀ PER BOX
                    </TableCell>
                    <TableCell
                      variant="head"
                      align="left"
                      style={{ minWidth: 210, width: 210 }}
                    >
                      AZIONI
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productsData.getProducts.products.map((product) => {
                    return (
                      <TableRow key={product.id}>
                        <TableCell align="left">
                          <Typography
                            component="span"
                            variant="body1"
                            color="textSecondary"
                          >
                            {product.name}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            component="span"
                            variant="body1"
                            color="textSecondary"
                          >
                            {product.sku}
                          </Typography>
                        </TableCell>
                        <TableCell variant="head" align="left">
                          <Typography
                            component="span"
                            variant="body1"
                            color="textSecondary"
                          >
                            {product.forSale ? "-" : product.unity}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <ButtonChipDelete
                            style={{ marginRight: 16 }}
                            onClick={async () => {
                              await loadProduct(product.id);
                              setShowWarningDialog(true);
                            }}
                            label="Elimina"
                          />

                          <ButtonChipUpdate
                            onClick={async () => {
                              await loadProduct(product.id);
                              setShowProductForm(true);
                            }}
                            label="Modifica"
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {productsData.getProducts.products.length < 11 && (
                    <>
                      {new Array(10 - productsData.getProducts.products.length)
                        .fill(10)
                        .map(() => (
                          <TableRow>
                            <TableCell variant="head" align="left">
                              &nbsp;
                            </TableCell>
                            <TableCell variant="head" align="left">
                              &nbsp;
                            </TableCell>
                            <TableCell variant="head" align="left">
                              &nbsp;
                            </TableCell>
                            <TableCell variant="head" align="left">
                              &nbsp;
                            </TableCell>
                          </TableRow>
                        ))}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              labelRowsPerPage="Totale prodotti per pagina"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} di ${count}`
              }
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={productsData ? productsData.getProducts.total : 0}
              rowsPerPage={limit}
              page={Math.floor(offset / limit)}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </>
      )}

      <ProductForm
        open={showProductForm}
        onClose={() => {
          setShowProductForm(false);
        }}
        onCreate={async () => {
          setShowProductForm(false);
          await refetchProducts({
            where,
            order,
            offset,
            limit,
          });
        }}
        onUpdate={async () => {
          setShowProductForm(false);
        }}
        product={currentProduct}
      />

      <DialogError
        title="Errore"
        open={showErrorDialog}
        onClose={() => setShowErrorDialog(false)}
      >
        <Typography variant="body1" color="error" paragraph={true}>
          {errorMessage}
        </Typography>
        <Typography variant="body1" color="textPrimary">
          Per poter eliminare un prodotto è necessario che prima venga eliminato
          dall'assortimento prodotti e/o dagli ordini pending che lo contengono.
        </Typography>
      </DialogError>

      <DialogWarning
        title="Attenzione"
        open={showWarningDialog}
        onClose={() => setShowWarningDialog(false)}
        onContinue={() => deleteProduct(currentProduct?.id || null)}
      >
        <Typography variant="body1">
          Questa operazione è irreversibile. Sei sicuro di voler procedere?
        </Typography>
      </DialogWarning>
    </>
  );
}
