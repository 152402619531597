import { SvgIcon, SvgIconProps } from "@material-ui/core";

export function UserBlockedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 -4 24 24">
      <defs>
        <path
          d="M5.92893219,4.51471863 L21.4852814,20.0710678 L20.0710678,21.4852814 L18.586,20 L4,20 L4,18 C4,15.34 9.33,14 12,14 C12.1896166,14 12.3926364,14.0067583 12.6061992,14.0202676 L11.1210189,12.5357885 C9.21367579,12.3567351 7.69675177,10.8398111 7.51769839,8.93246792 L4.51471863,5.92893219 L5.92893219,4.51471863 Z M16,8 C16,5.79 14.21,4 12,4 C10.7445952,4 9.62471936,4.57761183 8.89150058,5.48170749 L14.5221264,11.1053862 C15.4240208,10.3721128 16,9.25362938 16,8 Z"
          id="path-1"
        ></path>
      </defs>
      <g
        id="Desktop"
        stroke="none"
        stroke-width="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Clienti---1" transform="translate(-1154.000000, -531.000000)">
          <g id="Tabella" transform="translate(310.000000, 253.000000)">
            <g id="Lista" transform="translate(0.000000, 20.000000)">
              <g id="Riga-5" transform="translate(0.000000, 240.000000)">
                <g
                  id="34.-Icons/filled/toggle/star_24px"
                  transform="translate(840.000000, 14.000000)"
                >
                  <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-1"></use>
                  </mask>
                  <use
                    id="icon/content/how_to_reg_block_24px"
                    fill="#F44336"
                    fillRule="nonzero"
                    xlinkHref="#path-1"
                  ></use>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
